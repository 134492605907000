import React from 'react';


export default function Hero() {
  return (
    
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%"
	  viewBox="0 0 2500 1000" enableBackground="new 0 0 2500 1000" xmlSpace="preserve">

<g id="deco">
	<polygon fill="#82CBD5" points="2500,805.295 2500,1001.504 500.146,657.644 	"/>
	<polygon fill="#0F514F" points="1377.086,1001.504 2500,1001.504 494.71,656.056 	"/>
</g>
<g id="Volley">
	<g>
		<ellipse fill="#0F514F" cx="859.428" cy="88.274" rx="23.035" ry="24.731"/>
		<path fill="#82CBD5" d="M843.481,306.099c0,0-36.792-22.829-27.92-39.475s16.403-22.453,25.262-22.641
			c8.86-0.188,25.25,12.178,29.68,16.934c4.43,4.756,8.122,9.036,8.122,9.036v6.659l2.51,18.073l-16.391,16.646L843.481,306.099z"/>
		<path fill="#82CBD5" d="M816.016,327.66c-3.544-18.39,14.767-34.243,14.767-34.243s9.888-44.469,12.404-50.097
			s14.175-31.707,14.175-31.707s-4.135-8.244-2.363-20.292s11.813-26,17.128-33.609c5.315-7.609,15.189,2.372,13.153,7.61
			c-2.036,5.238-4.882,14.268-6.655,17.122c-3.476,5.594,7.726,0.644,5.019,5.707c-4.015,7.511-15.651,30.438-15.651,30.438
			l-7.088,29.804l-22.445,62.779C838.46,311.172,819.56,346.05,816.016,327.66z"/>
		<path fill="#82CBD5" d="M845.696,303.721c-5.192,1.958-32.023,3.329-31.516,41.377c0.507,38.048,8.924,68.486-7.467,101.778
			c-16.391,33.292-1.772,61.827,0,71.339c1.772,9.513,8.86,56.12,6.645,58.499c-2.215,2.377-38.982,7.133-58.474,16.646
			c-19.492,9.512-30.566-0.477-33.667,0c-3.101,0.475-25.25,39.475-25.25,39.475s0,13.791,3.544,17.121c3.544,3.328,6.645,0,6.645,0
			s21.264-24.238,29.237-28.766c7.974-4.527,71.321-9.283,85.497-11.66c14.175-2.379,18.542-3.805,26.769-26.158
			c8.228-22.354,18.416-63.253,18.416-63.253l22.592-90.688c0.394-9.724,1.344-15.626,1.772-32.493
			c0.294-11.569-1.329-25.682-1.329-25.682l-3.556-51.365l36.958-44.23v-13.575l-36.433-53.643c0,0-9.225-22.194-15.722-29.17
			c-6.497-6.976-13.585,0-13.585,0l2.363,18.389l1.181,8.244l10.928,12.683h7.383c0,0,4.724,12.049,11.222,20.292
			c6.498,8.244,5.907,24.097,5.907,24.097l-17.128,26.634l-27.761,13.316"/>
		<path fill="#82CBD5" d="M799.182,464.473c0,0,11.075,35.195,51.83,48.035c40.755,12.842,53.159,19.499,53.159,19.499
			s-17.867,8.086-25.545,17.121c-7.679,9.037-25.841,25.684-33.815,28.537c-7.974,2.854-26.136,7.609-27.022,10.463
			s14.176,41.377,14.176,48.986s9.746,14.268,11.075,15.695c1.329,1.426,2.215-8.561,0.886-18.072
			c-1.329-9.514,1.329-23.781,4.873-31.391s15.947-15.219,23.921-17.598c7.974-2.377,43.856-20.313,54.487-27.039
			c10.632-6.729,27.908-5.777,24.808-26.703c-3.101-20.925-50.944-65.632-62.904-77.997c-11.961-12.366-60.84-24.731-63.498-24.731
			S803.612,420.718,799.182,464.473z"/>
		<path fill="#0F514F" d="M885.553,441.926c0,0,11.972,16.364,17.879,24.609c5.907,8.244,21.854,25.998,21.854,25.998
			s-50.795,13.317-52.567,27.27c-7.303,0-7.605,1.346-7.605,1.346l-12.682,46.956c0,0-22.831-22.938-41.141-7.719
			c-9.818-27.5-12.796-56.61-11.222-97.022c1.772-45.499,33.776-33.641,43.875-29.15
			C864.659,443.426,885.553,441.926,885.553,441.926z"/>
	</g>
</g>
<g id="calendrier">
	<g>
		<g>
			<rect x="93.931" y="433.014" fill="#FFFFFF" width="476.772" height="438.958"/>
			<g>
				<path fill="#62C0BD" d="M570.758,864.471c-30.273,0-60.546,0-90.818,0c-63.109,0-126.217,0-189.326,0
					c-54.38,0-108.76,0-163.141,0c-11.163,0-22.325,0-33.487,0c2.482,2.52,4.965,5.037,7.447,7.557c0-32.25,0-64.502,0-96.752
					c0-64.031,0-128.063,0-192.092c0-46.73,0-93.46,0-140.19c0-3.309,0-6.617,0-9.925c-2.519,2.482-5.037,4.965-7.556,7.448
					c30.273,0,60.546,0,90.818,0c63.109,0,126.217,0,189.326,0c54.38,0,108.76,0,163.141,0c11.163,0,22.325,0,33.487,0
					c-2.482-2.519-4.965-5.038-7.447-7.556c0,32.251,0,64.501,0,96.753c0,64.029,0,128.061,0,192.092c0,46.73,0,93.459,0,140.189
					c0,3.309,0,6.617,0,9.926c0,9.686,15.003,9.771,15.003,0.107c0-32.25,0-64.502,0-96.752c0-64.031,0-128.063,0-192.092
					c0-46.73,0-93.46,0-140.19c0-3.309,0-6.617,0-9.925c0-4.065-3.341-7.556-7.447-7.556c-30.273,0-60.546,0-90.818,0
					c-63.109,0-126.217,0-189.326,0c-54.38,0-108.76,0-163.141,0c-11.163,0-22.325,0-33.487,0c-4.065,0-7.556,3.342-7.556,7.448
					c0,32.251,0,64.501,0,96.753c0,64.029,0,128.061,0,192.092c0,46.73,0,93.459,0,140.189c0,3.309,0,6.617,0,9.926
					c0,4.064,3.341,7.555,7.447,7.555c30.273,0,60.546,0,90.818,0c63.109,0,126.217,0,189.326,0c54.38,0,108.76,0,163.141,0
					c11.163,0,22.325,0,33.487,0C580.336,879.475,580.421,864.471,570.758,864.471z"/>
			</g>
		</g>
		<g>
			<rect x="250.585" y="582.645" fill="#82CBD5" width="51.083" height="48.713"/>
		</g>
		<g>
			<rect x="340.263" y="582.645" fill="#62C0BD" width="51.083" height="48.713"/>
		</g>
		<g>
			<rect x="427.672" y="583.91" fill="#62C0BD" width="51.083" height="48.715"/>
		</g>
		<g>
			<rect x="160.907" y="667.184" fill="#82CBD5" width="51.083" height="48.715"/>
		</g>
		<g>
			<rect x="250.585" y="667.184" fill="#82CBD5" width="51.083" height="48.715"/>
		</g>
		<g>
			<rect x="160.907" y="753.895" fill="#82CBD5" width="51.083" height="48.711"/>
		</g>
		<g>
			<rect x="250.585" y="753.895" fill="#82CBD5" width="51.083" height="48.711"/>
		</g>
		<g>
			<rect x="100.743" y="433.014" fill="#0F514F" width="469.96" height="92.126"/>
		</g>
		<g>
			<rect x="376.839" y="715.898" fill="#0F514F" width="117.062" height="111.629"/>
		</g>
		<polygon fill="#E01834" points="515.879,705.852 546.3,728.359 466.835,818.316 411.212,765.209 439.901,754.16 465.705,780.385"/>
	</g>
</g>
<g id="corps_x5F_cheval">
	<g id="Layer_3">
	</g>
</g>
<g id="Text">
	<g>
		<path fill="#4D4C4B" d="M1025.431,280.775c8.369,2.813,12.555,8.616,12.555,17.407c0,4.256-1.126,8.001-3.376,11.236
			c-2.215,3.2-5.205,5.46-8.968,6.778c-3.764,1.319-9.126,1.979-16.089,1.979h-41.938v-1.688c1.336-0.351,2.233-0.825,2.69-1.424
			c0.457-0.598,0.686-1.636,0.686-3.112v-59.557c0-1.477-0.229-2.523-0.686-3.139c-0.458-0.615-1.354-1.081-2.69-1.398v-1.688h42.94
			c4.22,0,7.71,0.335,10.471,1.002c2.76,0.669,5.196,1.776,7.306,3.323c4.853,3.482,7.28,8.476,7.28,14.981
			C1035.612,272.405,1032.218,277.504,1025.431,280.775z M979.273,277.24h27.748c3.938,0,6.884-0.122,8.836-0.369
			c1.952-0.245,3.753-0.703,5.407-1.371c2.039-0.845,3.622-2.145,4.748-3.904c1.125-1.758,1.688-3.798,1.688-6.119
			c0-3.094-1.002-5.731-3.007-7.912c-1.618-1.723-3.719-2.91-6.304-3.561c-2.584-0.65-6.55-0.977-11.896-0.977h-27.22V277.24z
			 M979.273,311.318h28.908c4.149,0,7.227-0.132,9.232-0.396c2.004-0.265,3.868-0.782,5.591-1.557
			c2.11-0.984,3.754-2.461,4.933-4.431c1.178-1.97,1.767-4.255,1.767-6.858c0-3.446-1.038-6.383-3.112-8.81
			c-1.724-2.004-3.922-3.367-6.594-4.088c-2.673-0.721-6.788-1.081-12.344-1.081h-28.38V311.318z"/>
		<path fill="#4D4C4B" d="M1084.196,257.828c10.515,0,18.991,2.743,25.426,8.229c6.858,5.803,10.287,13.47,10.287,22.999
			c0,5.944-1.688,11.395-5.064,16.354c-6.366,9.354-16.547,14.031-30.543,14.031c-10.445,0-18.885-2.742-25.321-8.229
			c-6.857-5.838-10.287-13.364-10.287-22.578c0-6.225,1.67-11.816,5.012-16.774c3.551-5.24,8.527-9.056,14.929-11.447
			C1073.03,258.76,1078.217,257.898,1084.196,257.828z M1084.196,263.947c-8.581,0-15.492,2.638-20.731,7.913
			c-4.572,4.571-6.857,10.163-6.857,16.774c0,6.612,2.286,12.204,6.857,16.775c5.24,5.274,12.186,7.912,20.837,7.912
			s15.596-2.638,20.837-7.912c4.571-4.571,6.858-10.058,6.858-16.459c0-6.928-2.287-12.625-6.858-17.091
			C1099.863,266.655,1092.882,264.018,1084.196,263.947z"/>
		<path fill="#4D4C4B" d="M1165.223,257.828c10.515,0,18.991,2.743,25.426,8.229c6.858,5.803,10.287,13.47,10.287,22.999
			c0,5.944-1.688,11.395-5.064,16.354c-6.366,9.354-16.547,14.031-30.543,14.031c-10.445,0-18.885-2.742-25.321-8.229
			c-6.857-5.838-10.287-13.364-10.287-22.578c0-6.225,1.67-11.816,5.012-16.774c3.551-5.24,8.527-9.056,14.929-11.447
			C1154.057,258.76,1159.244,257.898,1165.223,257.828z M1165.223,263.947c-8.581,0-15.492,2.638-20.731,7.913
			c-4.572,4.571-6.857,10.163-6.857,16.774c0,6.612,2.286,12.204,6.857,16.775c5.24,5.274,12.186,7.912,20.837,7.912
			s15.596-2.638,20.837-7.912c4.571-4.571,6.858-10.058,6.858-16.459c0-6.928-2.287-12.625-6.858-17.091
			C1180.89,266.655,1173.909,264.018,1165.223,263.947z"/>
		<path fill="#4D4C4B" d="M1223.091,295.334v16.881c0,1.442,0.228,2.471,0.686,3.086c0.457,0.616,1.354,1.1,2.69,1.451v1.424
			h-14.296v-1.424c1.336-0.352,2.233-0.835,2.69-1.451c0.457-0.615,0.686-1.644,0.686-3.086v-47.16c0-1.477-0.229-2.514-0.686-3.112
			c-0.458-0.598-1.354-1.072-2.69-1.424v-1.425h14.296v1.425c-1.337,0.353-2.234,0.827-2.69,1.426
			c-0.458,0.599-0.686,1.637-0.686,3.115v23.344l28.274-22.029c1.935-1.512,3.131-2.506,3.588-2.98s0.686-0.94,0.686-1.398
			c0-0.738-0.633-1.23-1.898-1.477v-1.425h16.616v1.425c-1.407,0.281-2.374,0.545-2.901,0.791s-1.354,0.81-2.479,1.688
			l-23.583,18.406l26.114,33.185c0.703,0.879,1.311,1.45,1.82,1.714s1.257,0.414,2.242,0.449v1.424h-15.694v-1.424
			c1.545-0.387,2.317-0.861,2.317-1.425c0-0.387-0.614-1.406-1.842-3.06l-20.554-26.544L1223.091,295.334z"/>
		<path fill="#4D4C4B" d="M1282.173,312.215v-47.16c0-1.477-0.229-2.514-0.686-3.112c-0.458-0.598-1.354-1.072-2.69-1.424v-1.425
			h14.296v1.425c-1.337,0.352-2.233,0.826-2.69,1.424c-0.457,0.599-0.686,1.636-0.686,3.112v47.16c0,1.442,0.229,2.471,0.686,3.086
			c0.457,0.616,1.354,1.1,2.69,1.451v1.424h-14.296v-1.424c1.336-0.352,2.232-0.835,2.69-1.451
			C1281.943,314.686,1282.173,313.657,1282.173,312.215z"/>
		<path fill="#4D4C4B" d="M1315.459,268.325v43.89c0,1.442,0.22,2.471,0.66,3.086c0.439,0.616,1.345,1.1,2.717,1.451v1.424h-13.03
			v-1.424c1.372-0.352,2.276-0.835,2.717-1.451c0.439-0.615,0.659-1.644,0.659-3.086v-47.16c0-1.477-0.229-2.514-0.686-3.112
			c-0.457-0.598-1.354-1.072-2.69-1.424v-1.425h19.202v1.425c-1.83,0.598-2.744,1.513-2.744,2.743c0,1.055,0.668,2.32,2.006,3.798
			l34.867,39.036v-41.041c0-1.477-0.229-2.514-0.686-3.112c-0.457-0.598-1.354-1.072-2.689-1.424v-1.425h13.029v1.425
			c-1.336,0.352-2.234,0.826-2.689,1.424c-0.459,0.599-0.686,1.636-0.686,3.112v47.16c0,1.442,0.219,2.471,0.658,3.086
			c0.439,0.616,1.346,1.1,2.717,1.451v1.424h-15.456v-1.424c1.829-0.457,2.743-1.179,2.743-2.163c0-0.738-0.844-2.058-2.532-3.957
			L1315.459,268.325z"/>
		<path fill="#4D4C4B" d="M1444.912,264.633l-7.254,11.605l-1.213-0.738c0.352-1.23,0.527-2.058,0.527-2.479
			c0-0.914-1.67-2.339-5.008-4.273c-5.414-3.06-11.16-4.589-17.24-4.589c-7.453,0-13.639,2.146-18.559,6.436
			c-5.484,4.748-8.227,10.885-8.227,18.41c0,7.14,2.516,12.978,7.545,17.514c4.957,4.537,11.5,6.805,19.623,6.805
			c4.078,0,7.965-0.606,11.658-1.819c3.691-1.214,6.822-2.893,9.389-5.038v-10.867h-13.293c-1.477,0-2.523,0.229-3.139,0.687
			s-1.08,1.354-1.398,2.69h-1.424v-12.503h1.424c0.316,1.352,0.781,2.251,1.396,2.701c0.613,0.45,1.658,0.676,3.133,0.676h23.271
			v1.424c-1.352,0.316-2.25,0.783-2.701,1.397c-0.449,0.616-0.674,1.662-0.674,3.14v6.119c0,1.477,0.225,2.523,0.674,3.139
			c0.451,0.615,1.35,1.081,2.701,1.397v1.213c-9.137,7.843-19.766,11.764-31.889,11.764c-9.559,0-17.395-2.566-23.508-7.701
			c-3.27-2.778-5.834-6.119-7.697-10.023c-2.002-4.148-3.004-8.457-3.004-12.924c0-6.611,2.004-12.572,6.014-17.883
			c3.588-4.712,8.352-8.177,14.295-10.392c4.432-1.653,9.18-2.479,14.244-2.479c4.359,0,8.342,0.527,11.947,1.582
			c3.605,1.056,7.922,2.938,12.951,5.645c0.809,0.422,1.389,0.634,1.74,0.634c0.422,0,1.248-0.703,2.48-2.11L1444.912,264.633z"/>
		<path fill="#4D4C4B" d="M1544.4,246.17v1.477l-49.459,63.672h43.605c1.477,0,2.523-0.229,3.137-0.687
			c0.615-0.456,1.082-1.353,1.398-2.689h1.688v13.609h-1.688c-0.316-1.337-0.783-2.233-1.398-2.69s-1.66-0.686-3.139-0.686h-57.867
			v-1.53l49.658-63.618h-42.852c-1.479,0-2.525,0.232-3.141,0.696c-0.615,0.465-1.082,1.358-1.398,2.68h-1.688v-13.609h1.688
			c0.316,1.351,0.783,2.251,1.398,2.701s1.662,0.675,3.139,0.675H1544.4z"/>
		<path fill="#4D4C4B" d="M1588.027,257.828c10.514,0,18.99,2.743,25.426,8.229c6.857,5.803,10.285,13.47,10.285,22.999
			c0,5.944-1.688,11.395-5.063,16.354c-6.367,9.354-16.547,14.031-30.543,14.031c-10.445,0-18.887-2.742-25.322-8.229
			c-6.857-5.838-10.285-13.364-10.285-22.578c0-6.225,1.67-11.816,5.01-16.774c3.553-5.24,8.529-9.056,14.93-11.447
			C1576.859,258.76,1582.047,257.898,1588.027,257.828z M1588.027,263.947c-8.582,0-15.492,2.638-20.732,7.913
			c-4.572,4.571-6.857,10.163-6.857,16.774c0,6.612,2.285,12.204,6.857,16.775c5.24,5.274,12.186,7.912,20.838,7.912
			c8.65,0,15.596-2.638,20.836-7.912c4.57-4.571,6.857-10.058,6.857-16.459c0-6.928-2.287-12.625-6.857-17.091
			C1603.693,266.655,1596.713,264.018,1588.027,263.947z"/>
		<path fill="#4D4C4B" d="M1644.629,268.325v43.89c0,1.442,0.219,2.471,0.66,3.086c0.438,0.616,1.344,1.1,2.715,1.451v1.424h-13.029
			v-1.424c1.373-0.352,2.277-0.835,2.717-1.451c0.439-0.615,0.66-1.644,0.66-3.086v-47.16c0-1.477-0.229-2.514-0.686-3.112
			c-0.457-0.598-1.355-1.072-2.691-1.424v-1.425h19.203v1.425c-1.83,0.598-2.744,1.513-2.744,2.743c0,1.055,0.668,2.32,2.004,3.798
			l34.869,39.036v-41.041c0-1.477-0.229-2.514-0.686-3.112c-0.457-0.598-1.354-1.072-2.689-1.424v-1.425h13.029v1.425
			c-1.338,0.352-2.234,0.826-2.691,1.424c-0.457,0.599-0.686,1.636-0.686,3.112v47.16c0,1.442,0.221,2.471,0.66,3.086
			c0.439,0.616,1.346,1.1,2.717,1.451v1.424h-15.457v-1.424c1.828-0.457,2.744-1.179,2.744-2.163c0-0.738-0.844-2.058-2.533-3.957
			L1644.629,268.325z"/>
		<path fill="#4D4C4B" d="M1721.541,290.534v21.892h36.504c1.477,0,2.523-0.225,3.139-0.675s1.082-1.351,1.398-2.701h1.477v12.502
			h-1.477c-0.316-1.337-0.773-2.233-1.371-2.69c-0.6-0.457-1.637-0.686-3.113-0.686h-47.477v-1.424
			c1.373-0.352,2.277-0.835,2.717-1.451c0.439-0.615,0.66-1.644,0.66-3.086v-47.16c0-1.477-0.229-2.514-0.686-3.112
			c-0.457-0.598-1.355-1.072-2.691-1.424v-1.425h47.107c1.477,0,2.514-0.229,3.113-0.686c0.598-0.457,1.072-1.354,1.424-2.69h1.424
			v12.502h-1.424c-0.352-1.35-0.826-2.251-1.424-2.701c-0.6-0.449-1.637-0.675-3.113-0.675h-36.188v19.94h23.053
			c1.477,0,2.514-0.229,3.113-0.686c0.596-0.457,1.072-1.354,1.424-2.69h1.424v12.502h-1.424c-0.352-1.351-0.836-2.251-1.451-2.701
			s-1.645-0.675-3.086-0.675H1721.541z"/>
	</g>
	<text transform="matrix(1 0 0 1 960.7026 443.0869)" fill="#0F514F" fontFamily="'YuGothicUI-Light'" fontSize="60">Réservations d’activités en ligne</text>
	
		<line fill="#0F514F" stroke="#0F514F" strokeWidth="7" strokeMiterlimit="10" x1="1072.186" y1="360.664" x2="1574.902" y2="360.664"/>
</g>
<g id="corps_x5F_cavalier">
	<g>
		<path fill="#E01834" d="M1287.5,651.844c-2.59,1.631-12.184,3.057-14.537,7.729c-2.356,4.675-3.129,8.65-2.025,11.041
			c1.105,2.396,8.283,0,8.283,0l13.804-6.622l7.174-8.283l19.696-24.845l31.284-42.329l-25.766-25.763
			c0,0-23.091,62.048-18.368,49.354c4.725-12.694-9.178,23.429-11.813,27.572C1292.596,643.841,1290.095,650.214,1287.5,651.844z"/>
		<path fill="#E01834" d="M1264.981,808.624c0,0-50.625,34.155-55.713,38.756c-5.084,4.604-12.596,17.927-13.563,21.316
			s6.784,17.442,6.298,25.675c-0.485,8.238-1.452,21.318-1.452,27.132s6.783,9.204,9.206,11.626
			c2.419,2.424,22.837,26.711,22.766,27.024c-0.072,0.311,11.879,7.49,16.236,8.823c4.354,1.334,8.607-13.674,7.019-17.924
			c-1.588-4.25-3.147-10.414-8.719-15.019c-5.57-4.601-17.441-2.42-17.441-2.42l-9.69-12.595l-1.936-24.953
			c0,0-1.211-24.463,2.905-27.855c4.119-3.39,81.389-32.457,81.389-32.457L1264.981,808.624z"/>
		<path fill="#E01834" d="M1148.107,608.785l22.166-11.869c0,0,21.448-0.773,31.972,0c10.525,0.775,19.52-5.087,27.979,2.059
			c8.456,7.146,11.626,16.352,21.071,17.805c9.447,1.453,14.172,8.355,14.172,8.355l21.437,21.438l23.981,12.354l3.637,8.26
			l-43.604,9.907l-56.682-25.07l-59.587-31.974l-14.171-5.814L1148.107,608.785z"/>
		<path fill="#E01834" d="M1531.896,810.43c0,0,36.597,38.162,47.499,49.061c10.898,10.902,16.59,27.964,16.717,44.691
			c0.086,11.986-0.079,23.581-1.608,28.669c-1.533,5.084-4.924,9.883-15.889,17.724c-10.968,7.841-12.299,18.483-12.299,18.483
			l25.432,2.138c0,0,2.722-5.328,6.542-9.604c3.17-3.55,8.478-6.661,13.563-11.991c5.091-5.331,0.968-65.402,6.059-90.109
			c-24.467-24.22-32.701-28.581-43.008-55.227c11.031-27.615,32.569-56.91,0-97.49"/>
		<path fill="#E01834" d="M1122.796,628.648c-4.119-5.088-8.48-25.919-8.48-25.919h2.422l9.691,7.267v-10.657l5.814-2.423
			l7.265,5.813l3.875,7.992c0,0,71.214,5.572,121.598,58.862c12.112,0,12.112,0,26.159-4.361
			c-6.297,2.424,25.678,1.938,25.678,1.938s40.211,9.69,56.682,6.299c16.474-3.392,46.992-24.708,51.837-23.254
			s-2.422,13.08-3.392,15.018c3.647,5.732,1.849,11.144,5.042,11.144c3.194,0,57.938-14.533,91.366-4.846
			c33.429,9.689,38.273,17.926,38.273,17.926s39.724-7.753,59.103,13.563s41.179,33.912,48.929,37.305
			c7.753,3.39-13.563,4.358-13.563,4.358s19.722,14.244,17.438,12.596c-2.282-1.65-23.253-6.299-23.253-6.299
			s15.986,50.384,29.069,60.073c13.078,9.688,44.083,1.458,52.983,6.811c8.901,5.349,12.901,17.897,12.901,17.897
			s-25.195-2.419-59.024,9.782c-32.54-2.512-49.417-35.188-54.824-52.898c-2.698-8.832-3.37-45.064-16.472-64.433
			c-13.168-19.464-38.756-22.042-38.756-22.042s10.657,14.775,12.595,22.527s1.456,39.24-24.702,59.104
			c-26.164,19.861-64.921,51.35-62.982,96.89c0,7.754-10.172,9.69-15.021,14.05c-4.843,4.357-51.35,43.115-58.616,52.319
			c-9.693,1.453-26.164,7.268-24.225,18.895c-10.174-0.968-26.16,0.97-31.487-7.75c11.626-14.051,25.189-13.563,25.189-13.563
			l49.414-40.696c0,0,15.505-11.144,21.458-27.767c4.875-13.615,6.645-42.477,6.885-52.893c0.242-10.415,0.631-20.314,0.631-20.314
			s-17.003,22.195-126.729,26.313c-16.056,1.988-30.804,2.987-39.192,3.216c-5.073,4.803-4.475,11.166-20.567,20.929
			c-1.64,3.496-6.954,14.667-12.285,27.751c-5.33,13.077-12.382,33.496-13.322,37.73c-0.942,4.233-9.441,40.411-9.441,46.076
			l-6.546,0.968c0,0-7.751,8.234-5.813,13.079c1.938,4.848-0.512,11.244-0.512,11.244s-11.6,4.263-25.649-0.585
			c1.697-7.265,2.908-9.2,13.08-15.986c7.752-8.72,7.269-9.445,9.69-19.619c2.423-10.176,11.626-38.27,12.112-51.836
			c0.486-13.566,9.204-54.02,10.173-68.555c0.384-5.751-1.129-11.047-3.805-16.982c-4.083-9.063-10.875-19.625-17.753-35.577
			c-12.839-54.502-29.046-73.124-25.918-69.034c3.126,4.09-8.961-11.386-17.2-33.43c-1.088,6.784-6.163,14.684-9.081,17.32
			c-5.567,5.036-17.32,13.199-17.32,13.199l-5.628,13.264c0,0,1.025,8.056-1.153,11.447c-2.181,3.391-15.504,3.387-16.958,8.231
			c-1.452,4.845-9.689,7.751-18.41,2.907c-2.776-1.542-2.932-4.781-4.36-6.053c-3.055-2.72-6.016-2.966-5.813-4.605
			c2.665-21.554,3.359-26.646,4.36-35.604c1.003-8.96,4.845-30.763,3.394-38.517
			C1118.193,656.504,1122.311,633.493,1122.796,628.648z"/>
		<path fill="#E01834" d="M1288.479,474.954c2.703-9.969,9.575-23.465,28.767-21.247c18.158,2.101,32.273,26.696,16.77,45.833
			c-4.842,6.782-6.055,5.328-6.055,5.328s-5.329,3.876-2.907,9.69c2.421,5.812,14.987,19.89,20.317,33.938
			c5.328,14.051,7.03,36.397,18.174,50.931c19.343,23.106,21.922,40.953,12.903,54.146c-9.02,13.193-47.521,70.752-47.521,70.752
			s-1.938,2.422,1.454,3.875c3.392,1.454,24.707,36.819,37.304,81.873c1.453,6.298,1.938,19.862,1.938,19.862h-16.471l-3.392-2.906
			l-28.1,2.906l-6.299-1.936l2.424-6.783l14.049-5.33c0,0,3.395-1.691,10.658-3.875c-2.42-22.039-42.146-73.394-44.085-84.78
			c-0.481-7.021,10.82-31.913,13.565-39.24c2.745-7.33,13.444-36.695,19.644-46.289c-1.783-3.703-16.736-34.734-16.736-34.734
			l-36.334,39.241c0,0-12.354,11.628-10.899,15.985c-2.269,2.669-16.067,4.361-16.067,4.361s-7.241-7.176-0.148-18.895
			c10.399-7.628,14.598-6.889,22.855-16.706s30.858-42.024,30.858-42.024s-9.802-4.046-10.528-20.396
			c-0.332-7.474,9.555-25.422,9.816-33.146c0.216-6.359-3.683-14.949-3.683-14.949l-5.25-0.559
			c-15.256-8.231-11.718-24.371-7.142-32.199c0.704-1.203-3.188-1.349-3.188-1.349s-3.03-0.762-0.589-3.941
			C1288.853,482.653,1287.847,477.281,1288.479,474.954z"/>
	</g>
</g>
<g id="surf_x5F_body">
	<g>
		<path fill="#82CBD5" stroke="#B1DDE4" strokeMiterlimit="10" d="M2114.263,304.066c0,0,76.715,224.725,244.097,248.75
			c-34.871-46.496-95.896-110.429-95.896-110.429l-42.813-58.505l-55.135-85.313L2114.263,304.066z"/>
		<path fill="#82CBD5" d="M2260.72,108.784c0,0-53.767-0.96-44.17-1.453c9.596-0.493-67.709,6.393-81.947,6.393
			c-14.24,0-26.445,4.94-33.564,16.418c-4.113,6.89-10.025,21.068-10.025,21.068s-10.752-1.162-15.111,5.231
			c-4.358,6.393-5.23,17.726,0,24.41c5.23,6.684,11.624,5.521,19.18,1.744s6.103-2.325,11.043-7.846
			c4.939-5.521,3.187-12.413,13.803-20.778s17-10.897,17-10.897l12.205,4.068c0,0-58.7,42.427-32.547,85.435
			c24.991,22.085,44.752,16.273,60.443,27.316c14.53,16.854,33.128,26.734,33.128,26.734l-4.068,4.068
			c0,0-48.238,10.461-61.606,9.88c-13.367-0.581-17.436,0.024-17.436,0.024s-10.461,13.343,5.231,24.385
			c25.572,4.068,39.521,20.924,45.333,22.667c5.812,1.743,12.785,3.358,12.785,3.358l2.325-10.914l-6.974-2.906l-13.368-12.785
			c0,0,81.366,2.324,88.341,5.812s37.777,4.068,37.196-11.624c-0.581-15.692-33.709-53.469-33.709-53.469l-22.085-35.54
			l-27.316-26.346c0,0,21.504-2.605,33.709,0s30.223-7.275,37.777-12.506c7.556-5.23,29.641-5.812,29.641-5.812
			s56.375,81.367,58.119,84.272c1.744,2.906,20.923,27.316,24.41,35.453c3.486,8.137,6.393,22.085,9.88,29.06
			c0.581,11.043,0,19.18,3.487,19.76c3.486,0.582,5.23-8.717,3.486-11.042c-1.743-2.325,0.684-7.556,0.684-7.556
			s23.727,26.154,25.471,26.734c1.743,0.582,3.486-5.23,0-22.085c-3.487-16.854-8.137-2.905-19.761-24.41
			c-11.624-21.504-18.598-49.401-18.598-49.401s-13.948-21.504-29.641-43.008c-5.812-15.692-12.495-26.734-22.085-41.264
			c-10.752-37.196-31.966-49.401-31.966-49.401s-42.735-18.19-59.728-20.923"/>
		<path fill="#82CBD5" d="M2291.522,69.845c-9.59,0-19.76,4.94-23.247,12.786s-6.394,28.478-6.394,28.478s-5.871-2.547-4.985,3.917
			c0.884,6.463,2.487,11.993,5.276,17.588c1.453,17.436,11.623,33.418,20.051,34.29c8.428,0.872,19.18-5.812,25.572-16.854
			c8.137-14.53,8.137-14.53,8.137-14.53s5.23-5.23,6.393-11.042c1.163-5.812-3.486-8.718-3.486-8.718s5.23-8.718,1.162-23.829
			C2315.933,76.819,2301.112,69.845,2291.522,69.845z"/>
		<path fill="#82CBD5" d="M2157.34,185.96c-17.895-5.883-30.649-1.95-37.636,4.313s-33.852,54,49.065,63.298
			c21.696,4.649,41.017,4.419,56.69,3.223c-7.33,8.335-32.932,67.805-40.895,122.459c-1.725,6.564-18.032,22.771-5.424,28.229
			c12.609,5.459,48.82,29.447,48.82,29.447s31.816-9.01,33.838-15.338c2.02-6.326-29.572-17.984-29.572-17.984
			s-18.973-13.141-19.165-22.634c-0.192-9.491,35.923-99.455,50.545-123.827c11.062-27.863-33.774-45.049-51.386-50.828
			C2194.609,200.541,2175.233,191.843,2157.34,185.96z"/>
	</g>
</g>
<g id="parapente">
	<g>
		<path fill="#82CBD5" d="M15.4,398.281c0,0-26.784-263.554,251.771-276.412C146.107,247.219,15.4,398.281,15.4,398.281z"/>
		<path fill="#82CBD5" d="M124.678,145.44c-24.639,8.569,346.069-298.96,517.469,10.712c35.514,64.161,2.975,83.167-162.953-1.202
			c-59.018-33.346-83.764-41.605-159.073-46.055c-80.981-4.786-195.444,41.374-195.444,41.374"/>
		<g>
			<polygon fill="#82CBD5" points="31.296,380.021 43.801,388.798 56.23,397.675 81.083,415.438 130.438,451.428 142.775,460.429 
				155.025,469.543 179.511,487.794 228.476,524.301 277.075,561.295 301.375,579.792 313.511,589.058 325.567,598.431 
				373.782,635.935 397.717,654.916 409.681,664.411 421.569,674.006 421.329,674.326 226.072,527.492 31.056,380.34 			"/>
		</g>
		<g>
			<polygon fill="#82CBD5" points="108.877,292.633 119.405,304.219 129.835,315.887 150.69,339.229 191.957,386.29 
				202.271,398.057 212.476,409.917 232.865,433.655 273.638,481.135 313.947,529.01 334.102,552.946 344.162,564.93 
				354.121,576.999 393.943,625.287 413.636,649.618 423.478,661.787 433.225,674.036 432.92,674.296 270.594,483.723 
				108.573,292.892 			"/>
		</g>
		<g>
			<polygon fill="#82CBD5" points="194.18,199.205 202.109,213.813 209.926,228.479 225.552,257.814 256.283,316.746 
				263.963,331.481 271.515,346.28 286.595,375.89 316.749,435.113 346.358,494.61 361.163,524.359 368.545,539.243 
				375.809,554.188 404.847,613.972 419.109,643.994 426.236,659.007 433.251,674.076 432.894,674.256 313.18,436.91 
				193.822,199.384 			"/>
		</g>
		<g>
			<polygon fill="#82CBD5" points="479.395,154.954 479.66,171.188 479.8,187.419 480.07,219.881 480.027,284.798 480.014,301.027 
				479.856,317.254 479.516,349.707 478.826,414.613 477.528,479.511 476.879,511.959 476.532,528.184 476.052,544.405 
				474.115,609.293 472.858,641.731 472.225,657.951 471.467,674.169 471.067,674.163 474.832,414.553 478.994,154.948 			"/>
		</g>
		<g>
			<polygon fill="#82CBD5" points="550.182,176.204 548.229,191.847 546.152,207.469 541.989,238.713 533.086,301.111 
				530.857,316.709 528.486,332.286 523.719,363.435 514.178,425.731 504.034,487.932 498.962,519.032 496.405,534.579 
				493.715,550.105 482.941,612.207 477.27,643.213 474.429,658.715 471.465,674.197 471.069,674.135 510.231,425.107 
				549.787,176.142 			"/>
		</g>
		<path fill="#82CBD5" d="M460.821,668.541c0,0,8.838-12.054-4.82-18.48c-13.661-6.429-16.873,18.48-16.873,18.48
			s-7.932,2.409-7.769,14.463c0.164,12.052,10.313,45.8,27.588,44.996c8.302,0,14.866-6.293,14.866-6.293s4.228,2.813,14.889-0.131
			c4.558-1.91,10.687-11.34,10.687-11.34l24.911,27.409l15.266-8.842l-13.66-6.427l-10.445-20.891c0,0,29.729-0.271,34.016-2.413
			c4.285-2.141,6.693-10.444,6.962-16.069s-7.232-3.215-7.232-3.215l-6.16,9.913c0,0-29.729-13.393-35.889-12.99
			c-6.161,0.401-20.037,10.31-20.037,10.31l-7.927-4.821L460.821,668.541z"/>
		<g>
			<path fill="#82CBD5" d="M620.552,201.59c-0.251-0.17,0.586-0.048,0.426,0.115l-0.013,0.146l-0.04,0.252l-0.093,0.487
				l-0.207,0.961l-0.439,1.912l-0.914,3.812l-1.879,7.608l-3.953,15.16l-8.015,30.287l-16.733,60.368l-4.202,15.086l-4.343,15.045
				l-8.721,30.079l-17.479,60.147l-18.092,59.964l-9.055,29.979l-4.55,14.983l-4.679,14.944l-18.744,59.77l-9.654,29.801
				l-4.833,14.898l-4.955,14.862l-0.384-0.113l71.114-240.224l35.587-120.103l17.69-60.081l8.773-30.062l4.335-15.045l2.132-7.532
				l1.04-3.772l0.501-1.89l0.236-0.946l0.107-0.472l0.044-0.231l0.013-0.099c-0.133,0.032,0.591,0.18,0.317,0.113L620.552,201.59z"
				/>
		</g>
	</g>
</g>
<g id="cerceau">
	<g>
		<g>
			<path fill="#0F514F" d="M896.866,700.652c-0.052-0.681-0.559-1.226-1.16-1.681c-0.609-0.45-1.313-0.807-2.038-1.117
				c-1.456-0.616-3.002-1.059-4.559-1.442c-3.122-0.756-6.314-1.257-9.514-1.677c-6.405-0.82-12.862-1.28-19.321-1.611
				c-6.459-0.366-12.937-0.368-19.406-0.396c-6.472,0.003-12.942,0.097-19.412,0.259c-12.938,0.346-25.863,1.154-38.77,2.045
				c-12.906,0.955-25.79,2.169-38.651,3.518c-12.863,1.347-25.696,2.909-38.5,4.579c-6.404,0.822-12.798,1.694-19.18,2.619
				l-19.112,2.887c-6.361,0.982-12.705,2.03-19.019,3.166c-6.316,1.123-12.59,2.405-18.833,3.649
				c-6.228,1.285-12.423,2.698-18.36,4.407c-2.952,0.857-5.87,1.797-8.455,2.865c-1.272,0.525-2.497,1.112-3.303,1.622
				c-0.391,0.235-0.674,0.491-0.581,0.416c0.047-0.052,0.222-0.198,0.504-0.703c0.131-0.242,0.299-0.615,0.413-1.083
				c0.063-0.244,0.103-0.494,0.134-0.785l0.021-0.432c-0.015-0.333,0.039,0.118-0.057-0.859l0.019,0.151
				c0.018,0.165-0.006-0.106-0.056-0.363c-0.036-0.212-0.13-0.522-0.167-0.637c-0.037-0.122-0.081-0.241-0.131-0.358
				c-0.18-0.445-0.401-0.788-0.562-1.01c-0.348-0.459-0.541-0.578-0.594-0.623c-0.101-0.06,0.216,0.154,0.637,0.334
				c0.87,0.394,2.166,0.805,3.499,1.149c2.709,0.699,5.73,1.226,8.772,1.665c6.118,0.869,12.449,1.41,18.795,1.82
				c12.709,0.856,25.565,1.356,38.432,1.521c12.871,0.206,25.776,0.324,38.687,0.155c12.912-0.109,25.842-0.354,38.762-0.802
				c12.93-0.452,25.854-1.031,38.769-1.886c6.455-0.44,12.907-0.932,19.356-1.478c6.447-0.557,12.897-1.114,19.323-1.881
				c6.429-0.736,12.851-1.541,19.26-2.437c6.404-0.921,12.816-1.828,19.163-3.085c6.351-1.224,12.682-2.573,18.912-4.274
				c3.11-0.859,6.202-1.798,9.189-2.979c1.488-0.596,2.959-1.248,4.315-2.061c0.674-0.407,1.322-0.859,1.862-1.39
				c0.534-0.523,0.958-1.167,0.917-1.803L896.866,700.652z M897.662,700.574c0.058,1.027-0.547,1.84-1.134,2.436
				c-0.611,0.609-1.3,1.091-2.005,1.526c-1.417,0.863-2.913,1.538-4.423,2.158c-3.028,1.226-6.136,2.194-9.26,3.085
				c-6.255,1.761-12.595,3.165-18.954,4.442c-12.726,2.531-25.546,4.513-38.393,6.264c-25.701,3.463-51.524,5.94-77.385,7.787
				c-25.865,1.821-51.779,3.013-77.718,3.211c-12.968,0.08-25.944-0.073-38.909-0.784c-6.481-0.367-12.961-0.863-19.417-1.726
				c-3.227-0.442-6.45-0.964-9.643-1.756c-1.595-0.403-3.185-0.863-4.742-1.532c-0.777-0.34-1.549-0.729-2.281-1.275
				c-0.364-0.275-0.72-0.597-1.026-1.01c-0.154-0.204-0.289-0.439-0.396-0.696c-0.106-0.229-0.187-0.651-0.194-0.787
				c-0.073-1.315,0.633-2.142,1.247-2.788c0.649-0.644,1.36-1.136,2.083-1.58c1.45-0.879,2.961-1.555,4.484-2.175
				c3.053-1.227,6.172-2.19,9.307-3.075c6.274-1.749,12.624-3.138,18.991-4.4c12.741-2.5,25.571-4.45,38.425-6.168
				c25.715-3.398,51.546-5.809,77.413-7.589c25.868-1.754,51.785-2.878,77.718-3.009c12.965-0.047,25.938,0.14,38.891,0.884
				c6.475,0.384,12.948,0.896,19.387,1.773c3.217,0.448,6.43,0.978,9.599,1.771c1.582,0.404,3.157,0.865,4.679,1.524
				c0.759,0.333,1.508,0.715,2.198,1.233c0.343,0.261,0.671,0.561,0.944,0.931c0.138,0.184,0.255,0.391,0.348,0.612
				c0.092,0.208,0.153,0.52,0.167,0.688L897.662,700.574z"/>
		</g>
		<g>
			<path fill="#E01834" d="M750.727,589.575c0,0,5.582,13.65,0,16.346c-5.583,2.694-21.753-6.913-25.123-8.174
				c-3.369-1.26-26.277-17.43-28.972-20.124c-2.695-2.695-17.519-22.909-23.582-31.668c-6.064-8.759-12.129-18.192-12.129-18.192
				s0-14.148,4.717-21.562c-0.673-10.105-10.106-1.067-11.454,5.531c-5.177-5.101-7.638-10.92-7.638-10.92l-9.881-21.562
				c0,0-5.824-2.564-5.389,0c0.435,2.566,6.063,22.908,6.063,22.908s-12.128-22.607-14.15-24.105
				c-2.021-1.499-8.75,3.884-6.737,7.935c2.014,4.049,12.801,25.743,12.801,25.743s-12.127-9.573-16.17-12.269
				c-4.717,2.695,30.32,34.364,34.363,39.079c4.042,4.718,20.213,40.428,28.299,49.859c8.085,9.433,45.142,40.427,49.185,47.84
				c4.044,7.41,19.541,43.794,20.214,53.9c-4.042,15.496-49.186,127.345-51.881,154.296c-2.695,26.949-22.692,84.896-10.335,125.32
				c-8.529,13.477-17.963,25.604-7.856,25.604s29.647,0,29.647,0l2.693-20.214l10.781-26.952l-4.042-10.777l28.298-74.792
				l-5.39-10.106c0,0,21.562-45.142,33.016-39.077c10.78,14.822,59.292,70.072,68.051,91.634
				c8.759,21.561,22.909,44.468,26.278,46.489c3.369,2.021,11.454,2.021,11.454,2.021s-2.695,4.044,0,8.085
				c2.695,4.042,5.392,8.76,5.392,8.76s-15.498,16.844-12.128,20.888c8.084,0,24.255,6.737,33.013,4.042
				c8.759-2.695,20.888-16.171,20.888-16.171l-8.759-19.54c0,0,0-11.455-2.021-13.476c-2.021-2.021-9.433,0.674-7.411-3.366
				c2.021-4.043,3.369-11.457,3.369-13.477c0-2.021-68.052-102.413-68.052-102.413l-14.822-68.726l-0.675-50.534
				c0,0,7.075-34.025,1.686-60.978c0-22.57,1.009-22.234-2.021-43.672c16.842-13.263,21.589-39.995,30.205-46.613
				c8.616-6.619,44.809-49.634,54.916-57.719c10.107-8.086,10.23-8.66,16.738-14.718c6.507-6.058,9.202-8.753,14.37-11.898
				c2.021-3.817-12.241,1.46-12.241,1.46s14.485-14.711,17.18-21.224c2.695-6.513,2.472-6.964-2.021-3.819
				c-4.492,3.144-14.374,13.027-14.374,13.027l-7.748,7.074l-13.588,11.566c0,0-50.757,40.875-59.741,48.513
				c-8.983,7.636-22.519,30.265-32.624,40.709c-10.105,10.441-35.421,25.77-35.421,25.77L750.727,589.575z"/>
			<g>
				<path fill="#0F514F" d="M823.69,719.551c5.42-1.059,10.828-2.165,16.216-3.332c5.395-1.122,10.776-2.285,16.123-3.549
					c5.342-1.286,10.675-2.555,15.925-4.026c5.232-1.473,10.46-3.078,15.254-5.115c1.186-0.518,2.349-1.044,3.405-1.616
					c1.041-0.558,2.041-1.211,2.553-1.729c0.253-0.229,0.32-0.47,0.138-0.156c-0.038,0.078-0.088,0.201-0.12,0.354
					c-0.015,0.075-0.03,0.162-0.037,0.251l-0.007,0.136c0.007,0.139-0.021-0.116,0.023,0.347l-0.021-0.128
					c-0.01-0.077-0.016-0.079,0.002,0.07c0.013,0.098,0.033,0.196,0.061,0.291l0.045,0.16c0.062,0.174,0.117,0.293,0.167,0.378
					c0.206,0.313,0.056,0.113-0.257-0.031c-0.314-0.168-0.769-0.365-1.263-0.546c-2.048-0.733-4.632-1.248-7.186-1.647
					c-2.581-0.397-5.232-0.688-7.902-0.923c-10.707-0.896-21.642-1.2-32.562-1.201c-5.464-0.003-10.936,0.065-16.41,0.188
					c-5.473,0.098-10.95,0.202-16.428,0.377c-10.956,0.337-21.914,0.85-32.866,1.496c-21.908,1.18-43.816,2.736-65.65,4.859
					c-21.849,1.904-43.652,4.393-65.247,7.614c-10.787,1.587-21.562,3.379-31.996,5.802c-2.602,0.61-5.177,1.267-7.681,1.994
					c-2.495,0.722-4.945,1.549-7.1,2.429c-1.06,0.438-2.059,0.934-2.677,1.328c-0.29,0.176-0.475,0.362-0.346,0.192
					c0.059-0.095,0.223-0.306,0.4-0.8c0.089-0.25,0.159-0.554,0.197-0.923c0.012-0.177,0.022-0.369,0.011-0.561
					c-0.004-0.076-0.07-0.735-0.035-0.378l0.013,0.129c-0.016-0.157-0.042-0.316-0.08-0.472c-0.04-0.166-0.088-0.313-0.103-0.364
					c-0.052-0.158-0.12-0.31-0.175-0.43c-0.132-0.263-0.245-0.436-0.353-0.57c-0.208-0.259-0.311-0.316-0.323-0.32
					c-0.026,0.007,0.246,0.208,0.6,0.391c0.742,0.389,1.816,0.795,2.941,1.143c2.28,0.705,4.832,1.259,7.41,1.733
					c2.587,0.473,5.233,0.866,7.899,1.212c5.337,0.687,10.755,1.179,16.188,1.659c10.876,0.918,21.843,1.428,32.822,1.81
					c21.97,0.87,44.013,0.945,66.109,1.146l0.047,0.799c-22.021,1.329-44.078,2.185-66.147,2.275
					c-11.034,0.034-22.073-0.117-33.103-0.651c-5.514-0.273-11.026-0.641-16.527-1.205c-5.495-0.593-10.999-1.273-16.399-2.727
					c-1.346-0.379-2.686-0.814-3.987-1.424c-0.648-0.311-1.289-0.662-1.888-1.137c-0.584-0.482-1.184-1.074-1.379-2.063
					c-0.071-0.388-0.021-0.911,0.13-1.303c0.148-0.409,0.372-0.757,0.616-1.063c0.494-0.609,1.07-1.075,1.658-1.497
					c1.188-0.827,2.447-1.466,3.723-2.045c5.128-2.255,10.482-3.714,15.837-5.093c10.734-2.676,21.606-4.659,32.5-6.442
					c21.8-3.513,43.733-6.069,65.704-8.15c21.979-1.998,44.003-3.474,66.05-4.431c22.047-0.932,44.124-1.391,66.196-0.835
					c11.033,0.312,22.074,0.821,33.059,2.14c2.745,0.341,5.485,0.74,8.21,1.263c2.72,0.539,5.442,1.149,8.068,2.211
					c0.654,0.274,1.301,0.58,1.922,0.964c0.615,0.39,1.231,0.844,1.687,1.533c0.11,0.173,0.213,0.358,0.283,0.564
					c0.081,0.197,0.127,0.479,0.14,0.613c0.04,0.487-0.052,0.885-0.209,1.273c-0.325,0.761-0.858,1.317-1.402,1.802
					c-1.11,0.956-2.353,1.639-3.607,2.263c-1.259,0.616-2.549,1.146-3.849,1.637c-5.213,1.931-10.577,3.308-15.955,4.568
					c-5.383,1.244-10.801,2.315-16.232,3.298c-10.864,1.956-21.786,3.557-32.725,4.983L823.69,719.551z"/>
			</g>
			<path fill="#E01834" d="M749.86,587.729c6.661,5.216-22.909-6.737-15.497-34.361c7.412-27.625,22.909-51.208,46.49-37.732
				c23.582,13.477,22.908,37.732,17.519,52.556c2.021,12.801-8.759,31.666-18.192,34.361
				C764.684,606.595,743.201,582.514,749.86,587.729z"/>
		</g>
	</g>
</g>
<g id="plongee">
	<g>
		<path fill="#0F514F" d="M1983.514,806.711l70.2,43.616c0,0,20.063-13.265,34.446-20.635
			c41.271-15.919,56.894-34.197,65.737-35.966c17.396,0.001,51.298,28.936,68.544,45.593c17.247,16.657,78.265,50.806,83.57,56.111
			c5.307,5.307,0,22.117,0,22.117s-61.733-14.966-88.438-37.151s-57.485-47.757-57.485-47.757s-77.824,33.607-84.898,42.451
			c-7.075,8.844-69.869,15.034-69.869,15.034l-55.716-62.792l8.844-43.335L1983.514,806.711z"/>
		<path fill="#0F514F" d="M1831.097,681.409c0,0-26.531-6.19-33.606,14.15s0,27.416,0,31.838c-14.15,4.422-14.15-0.884-14.15,4.422
			c0,5.307,2.653,19.457,9.729,19.457s8.844-6.191,14.15-6.191c5.306,0,14.149,6.191,14.149,6.191l9.729,30.953l-7.075,7.075
			c0,0-2.653,10.612,7.075,18.572c-3.538,14.15,0.885,22.109,5.307,26.531c-4.422,7.96-2.653,15.035,0,16.804
			s10.612,9.728,10.612,9.728s-8.844,17.688-4.422,25.647c4.422,7.96,11.497,4.422,15.919,0s6.19-23.878,6.19-23.878v-11.497
			l7.96-14.15c0,0,1.769,10.612,0,15.919c6.19,7.075,6.19,7.075,6.19,7.075s7.075,8.844,13.266,5.306
			c6.191-3.537-2.653-22.993,0-23.878s7.96,10.612,7.96,10.612s22.109-11.497,26.531-16.803c7.96,6.19,73.404,68.981,91.977,73.403
			c18.571,4.423,91.976-2.652,117.623,6.191c32.722,0,40.397-0.611,65.741,2.66c11.286,1.457,57.22,8.289,104.061-22.117
			c25.647-12.381,18.572-30.069,11.497-40.682c-14.149-1.769-64.56,21.226-64.56,21.226l-109.368,0.295
			c0,0-19.317,13.828-48.346,9.434c-29.028-4.394-45.54-4.796-74.88-13.267c-16.508-17.392-45.988-68.098-45.988-68.098
			l23.879,6.191c0,0,30.068-12.382,26.531-36.261c-3.538-23.878-122.93-80.479-122.93-80.479l-30.069,14.199l-16.804,7.91
			l-8.844-22.994l-11.497-12.383"/>
	</g>
</g>
<g id="tennis">
	<g>
		<path fill="#0F514F" d="M1846.598,184.04c-5.128,3.737-7.795,6.641-7.795,13.571c0,6.929-3.465,20.031-3.465,20.031l1.732,7.11
			c0,0,0,17.035,1.732,23.099c1.731,6.063,3.753,8.965,15.879,6.367c12.127-2.599,22.522-16.761,27.431-19.071
			c4.909-2.31,11.261-21.078,10.395-29.74s-10.973-19.922-21.654-22.521C1860.169,180.287,1851.727,180.303,1846.598,184.04z"/>
		<path fill="#0F514F" d="M1835.338,255.171c0,0-17.036,7.872-22.233,24.818c-5.198,16.946-6.353,111.878-13.283,128.222
			c-6.93,16.344-7.673,74.579-1.897,93.636c5.775,19.057,11.003,22.668,18.357,21.889c9.9-1.053,16.025-25.121,16.025-25.121
			s17.609-35.214,25.693-37.216c8.084-2.002,29.181,32.852,34.587,40.448c5.404,7.596,16.378,38.201,27.928,44.555
			c4.619,25.986,8.085,28.295,13.859,34.068c5.773,5.777,24.254,40.424,25.985,48.508c1.732,8.086-1.154,18.48,0,23.1
			c1.155,4.619,8.661,8.662,10.972,12.703c2.31,4.043,2.311,33.488,21.944,26.563c19.633-6.928,12.704-21.363,2.887-33.49
			c-9.816-12.129-12.947-25.41-12.947-25.41l-23.433-62.365l-16.746-38.689l-15.592-42.735l-22.521-61.788l1.155-16.169
			l-8.085-24.253c0,0-0.541-58.875,5.774-61.212c23.965,4.619,34.938-0.579,61.789-8.085c14.437-1.732,9.082,4.462,16.589,11.392
			c4.392,4.392,15.794-1.042,20.369-5.618c-5.197-9.239,38.113-30.477,45.62-37.471c-0.359-6.021-4.042-5.46-4.042-5.46
			l-39.268,23.297l-10.979,3.736c0,0-2.305-4.313-10.966-7.201c-8.661-2.888-22.152-2.31-22.152-2.31s-13.105,2.168-18.848,1.154
			c-5.742-1.015-15.015,0.866-23.676-2.31c-0.577-8.951-0.577-16.368-0.577-16.368s-14.438-22.322-32.916-28.674
			c-2.599-9.529,1.403-20.91,1.403-20.91L1835.338,255.171z"/>
		<path fill="#0F514F" d="M1898.571,314.259c0,0,36.669,63.232,42.443,73.05c5.839,1.269,11.838,12.415,11.838,12.415
			s8.085,8.315,11.549,8.488c-1.732,7.103-13.39,1.684-13.39,1.684s-5.378,18.503-16.061,15.305
			c-13.281,7.215-12.762,0.011-13.36-1.801c-0.856-2.596,7.38-6.271,6.432-10.972c-1.139-5.634-1.444-18.19-1.444-18.19
			l-40.712-40.134L1898.571,314.259z"/>
		<path fill="#0F514F" d="M1815.957,447.248l15.501,22.169c0,0,12.699,17.322,23.002,18.832c13.662,2,8.587-25.035,8.587-25.035
			l-29.468-42.411L1815.957,447.248z"/>
	</g>
</g>
</svg>
  )
}
