


import React from 'react';

export const ActivityLogo = (props)=>(

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width={props.width} height={props.height} viewBox="0 0 250 250" enableBackground="new 0 0 250 250" xmlSpace="preserve">
<g id="cerceau">
	<g>
		<g>
			<path fill="#125250" d="M178.181,119.889c-0.02-0.275-0.226-0.497-0.469-0.68c-0.246-0.183-0.532-0.328-0.825-0.453
				c-0.592-0.25-1.217-0.429-1.847-0.583c-1.266-0.308-2.558-0.51-3.854-0.679c-2.594-0.333-5.208-0.519-7.823-0.652
				c-2.616-0.148-5.239-0.15-7.858-0.162c-2.623,0.001-5.242,0.04-7.862,0.105c-5.24,0.14-10.474,0.468-15.7,0.829
				c-5.227,0.387-10.444,0.879-15.652,1.424c-5.21,0.546-10.408,1.178-15.592,1.855c-2.594,0.333-5.183,0.686-7.768,1.061
				l-7.74,1.169c-2.576,0.397-5.146,0.821-7.702,1.282c-2.558,0.455-5.099,0.974-7.627,1.478c-2.521,0.521-5.031,1.094-7.435,1.785
				c-1.196,0.347-2.377,0.728-3.424,1.16c-0.516,0.212-1.012,0.449-1.338,0.656c-0.159,0.096-0.273,0.199-0.235,0.17
				c0.019-0.022,0.09-0.081,0.204-0.286c0.053-0.097,0.121-0.249,0.168-0.438c0.025-0.099,0.041-0.2,0.053-0.317l0.009-0.175
				c-0.006-0.136,0.016,0.048-0.022-0.348l0.007,0.062c0.007,0.065-0.002-0.043-0.023-0.147c-0.015-0.087-0.052-0.213-0.067-0.258
				c-0.015-0.049-0.033-0.099-0.053-0.146c-0.073-0.18-0.163-0.318-0.228-0.409c-0.141-0.186-0.219-0.234-0.241-0.253
				c-0.041-0.023,0.087,0.063,0.258,0.136c0.352,0.159,0.877,0.326,1.417,0.465c1.097,0.283,2.321,0.498,3.553,0.675
				c2.478,0.352,5.042,0.571,7.611,0.737c5.146,0.348,10.354,0.55,15.564,0.616c5.212,0.082,10.438,0.131,15.667,0.063
				c5.229-0.045,10.465-0.145,15.698-0.325c5.235-0.183,10.469-0.419,15.701-0.765c2.614-0.178,5.227-0.378,7.838-0.598
				c2.61-0.226,5.222-0.451,7.826-0.763c2.602-0.298,5.203-0.624,7.799-0.986c2.595-0.373,5.19-0.739,7.761-1.25
				c2.573-0.495,5.136-1.042,7.659-1.731c1.259-0.348,2.511-0.728,3.72-1.207c0.604-0.241,1.198-0.504,1.749-0.834
				c0.272-0.166,0.534-0.349,0.754-0.563c0.216-0.212,0.388-0.473,0.371-0.731V119.889z M178.503,119.858
				c0.023,0.416-0.221,0.745-0.46,0.986c-0.246,0.247-0.525,0.442-0.811,0.617c-0.574,0.351-1.181,0.624-1.793,0.875
				c-1.224,0.497-2.484,0.889-3.748,1.25c-2.534,0.712-5.103,1.281-7.678,1.799c-5.153,1.024-10.344,1.827-15.547,2.536
				c-10.408,1.402-20.866,2.406-31.339,3.153c-10.476,0.737-20.97,1.221-31.475,1.301c-5.251,0.033-10.506-0.03-15.757-0.318
				c-2.624-0.147-5.249-0.349-7.863-0.698c-1.307-0.18-2.612-0.391-3.905-0.711c-0.646-0.164-1.29-0.35-1.92-0.62
				c-0.314-0.137-0.627-0.295-0.923-0.518c-0.148-0.109-0.292-0.24-0.416-0.408c-0.062-0.083-0.117-0.178-0.16-0.281
				c-0.043-0.094-0.076-0.263-0.078-0.319c-0.03-0.532,0.256-0.868,0.504-1.13c0.263-0.261,0.551-0.459,0.843-0.639
				c0.588-0.355,1.199-0.631,1.816-0.881c1.237-0.497,2.5-0.887,3.77-1.245c2.541-0.708,5.112-1.271,7.691-1.782
				c5.159-1.012,10.354-1.802,15.561-2.498c10.415-1.378,20.875-2.353,31.35-3.074c10.476-0.711,20.973-1.166,31.475-1.219
				c5.249-0.018,10.505,0.057,15.749,0.358c2.623,0.156,5.244,0.363,7.851,0.719c1.303,0.182,2.604,0.395,3.89,0.717
				c0.64,0.164,1.278,0.35,1.894,0.617c0.308,0.135,0.611,0.289,0.892,0.5c0.137,0.105,0.27,0.227,0.381,0.376
				c0.058,0.075,0.104,0.159,0.14,0.249c0.04,0.083,0.063,0.21,0.068,0.279V119.858z"/>
		</g>
		<g>
			<path fill="#E01A35" d="M118.998,74.905c0,0,2.26,5.529,0,6.621c-2.261,1.091-8.81-2.8-10.174-3.311
				c-1.364-0.509-10.641-7.059-11.733-8.149c-1.091-1.091-7.094-9.278-9.549-12.825c-2.457-3.547-4.913-7.367-4.913-7.367
				s0-5.731,1.911-8.733c-0.273-4.092-4.093-0.432-4.639,2.241c-2.096-2.065-3.093-4.422-3.093-4.422l-4.001-8.733
				c0,0-2.359-1.038-2.182,0c0.175,1.04,2.455,9.278,2.455,9.278s-4.912-9.155-5.73-9.762s-3.543,1.573-2.729,3.212
				c0.815,1.64,5.184,10.426,5.184,10.426s-4.911-3.876-6.548-4.968c-1.91,1.091,12.279,13.917,13.916,15.826
				c1.638,1.91,8.187,16.372,11.461,20.191c3.273,3.821,18.28,16.372,19.918,19.375c1.638,3.001,7.914,17.735,8.186,21.827
				c-1.638,6.277-19.919,51.573-21.011,62.487c-1.091,10.915-9.189,34.381-4.185,50.752c-3.455,5.457-7.275,10.368-3.182,10.368
				c4.093,0,12.006,0,12.006,0l1.09-8.186l4.366-10.915l-1.637-4.365l11.459-30.289l-2.183-4.092c0,0,8.732-18.281,13.372-15.825
				c4.366,6.003,24.012,28.378,27.56,37.108c3.546,8.732,9.278,18.009,10.643,18.828c1.364,0.818,4.637,0.818,4.637,0.818
				s-1.091,1.638,0,3.275c1.093,1.636,2.184,3.547,2.184,3.547s-6.276,6.821-4.912,8.459c3.274,0,9.822,2.729,13.372,1.636
				c3.546-1.091,8.458-6.548,8.458-6.548l-3.548-7.913c0,0,0-4.639-0.817-5.457c-0.82-0.819-3.821,0.272-3.002-1.363
				c0.817-1.639,1.364-4.64,1.364-5.458c0-0.819-27.559-41.475-27.559-41.475l-6.004-27.834l-0.273-20.464
				c0,0,2.867-13.779,0.683-24.695c0-9.14,0.41-9.004-0.816-17.686c6.818-5.372,8.74-16.197,12.231-18.878
				c3.488-2.681,18.146-20.1,22.239-23.374c4.094-3.275,4.142-3.508,6.778-5.962c2.635-2.453,3.728-3.544,5.819-4.818
				c0.817-1.546-4.956,0.591-4.956,0.591s5.864-5.957,6.956-8.595c1.093-2.638,1.002-2.821-0.819-1.547
				c-1.818,1.273-5.821,5.276-5.821,5.276l-3.138,2.865l-5.502,4.685c0,0-20.556,16.553-24.193,19.645
				c-3.638,3.093-9.121,12.257-13.211,16.487c-4.093,4.228-14.347,10.437-14.347,10.437L118.998,74.905z"/>
			<g>
				<path fill="#125250" d="M148.547,127.543c2.194-0.429,4.384-0.877,6.567-1.349c2.184-0.456,4.363-0.927,6.528-1.438
					c2.164-0.521,4.324-1.035,6.448-1.63c2.119-0.597,4.239-1.247,6.179-2.072c0.479-0.209,0.952-0.422,1.38-0.654
					c0.42-0.226,0.825-0.49,1.033-0.699c0.103-0.093,0.128-0.19,0.057-0.064c-0.017,0.031-0.036,0.082-0.05,0.143
					c-0.005,0.031-0.013,0.065-0.015,0.102l-0.004,0.055c0.004,0.056-0.008-0.047,0.012,0.14l-0.008-0.051
					c-0.006-0.033-0.008-0.033,0,0.027c0.004,0.041,0.012,0.081,0.022,0.119l0.018,0.064c0.026,0.071,0.05,0.12,0.068,0.153
					c0.085,0.126,0.023,0.045-0.104-0.012c-0.126-0.068-0.312-0.148-0.511-0.221c-0.83-0.297-1.875-0.506-2.909-0.667
					c-1.047-0.16-2.119-0.279-3.202-0.374c-4.333-0.362-8.764-0.486-13.187-0.488c-2.212,0-4.427,0.027-6.645,0.077
					c-2.215,0.041-4.434,0.081-6.653,0.153c-4.436,0.135-8.874,0.343-13.309,0.605c-8.873,0.479-17.745,1.109-26.587,1.969
					c-8.849,0.77-17.678,1.777-26.424,3.082c-4.368,0.642-8.732,1.369-12.958,2.35c-1.054,0.246-2.097,0.513-3.11,0.808
					c-1.011,0.292-2.003,0.627-2.875,0.983c-0.43,0.178-0.834,0.378-1.084,0.538c-0.117,0.071-0.193,0.147-0.141,0.078
					c0.024-0.039,0.091-0.125,0.162-0.323c0.037-0.102,0.065-0.225,0.081-0.374c0.005-0.073,0.009-0.151,0.004-0.229
					c-0.001-0.03-0.029-0.297-0.014-0.152l0.005,0.053c-0.006-0.064-0.016-0.129-0.033-0.192c-0.015-0.067-0.036-0.126-0.041-0.147
					c-0.021-0.064-0.049-0.125-0.071-0.174c-0.053-0.107-0.099-0.177-0.143-0.231c-0.085-0.104-0.126-0.126-0.13-0.13
					c-0.011,0.005,0.1,0.085,0.242,0.158c0.301,0.159,0.736,0.324,1.191,0.465c0.924,0.284,1.958,0.509,3.001,0.702
					c1.047,0.189,2.119,0.35,3.199,0.489c2.161,0.279,4.355,0.478,6.556,0.672c4.404,0.372,8.846,0.579,13.292,0.733
					c8.898,0.353,17.825,0.383,26.773,0.464l0.018,0.324c-8.917,0.538-17.85,0.885-26.788,0.921
					c-4.468,0.015-8.938-0.047-13.406-0.264c-2.232-0.11-4.465-0.259-6.693-0.488c-2.225-0.24-4.454-0.516-6.641-1.104
					c-0.545-0.153-1.088-0.33-1.615-0.578c-0.263-0.125-0.522-0.266-0.764-0.46c-0.237-0.194-0.48-0.435-0.559-0.835
					c-0.029-0.157-0.009-0.368,0.053-0.528c0.06-0.164,0.15-0.306,0.25-0.43c0.2-0.246,0.433-0.437,0.671-0.606
					c0.481-0.335,0.991-0.594,1.507-0.828c2.077-0.913,4.245-1.504,6.414-2.062c4.346-1.084,8.749-1.887,13.161-2.61
					c8.829-1.422,17.71-2.457,26.609-3.3c8.9-0.809,17.819-1.407,26.749-1.794c8.928-0.377,17.869-0.564,26.807-0.339
					c4.469,0.126,8.938,0.333,13.39,0.867c1.109,0.139,2.22,0.3,3.322,0.513c1.103,0.218,2.205,0.463,3.268,0.895
					c0.266,0.111,0.527,0.235,0.779,0.391c0.248,0.158,0.497,0.341,0.684,0.621c0.044,0.07,0.086,0.145,0.115,0.229
					c0.03,0.079,0.052,0.193,0.057,0.247c0.016,0.198-0.023,0.358-0.086,0.516c-0.132,0.308-0.348,0.534-0.567,0.729
					c-0.449,0.388-0.953,0.665-1.461,0.917c-0.511,0.249-1.032,0.464-1.558,0.662c-2.112,0.782-4.283,1.34-6.462,1.851
					c-2.182,0.503-4.373,0.938-6.575,1.335c-4.399,0.792-8.821,1.439-13.252,2.019L148.547,127.543z"/>
			</g>
			<path fill="#E01A35" d="M118.648,74.158c2.697,2.112-9.278-2.728-6.277-13.916c3.002-11.187,9.278-20.738,18.83-15.28
				c9.547,5.458,9.275,15.28,7.094,21.284c0.817,5.184-3.549,12.824-7.369,13.916C124.65,81.798,115.95,72.046,118.648,74.158z"/>
		</g>
	</g>
</g>
</svg>
)