


import React from 'react';

export const Balloons = (props)=>(

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width={props.width} height={props.height} viewBox="0 0 250 250" enableBackground="new 0 0 250 250" xmlSpace="preserve">
<g id="Layer_1">
	<path id="Ballon02_2_" fill="#016666" d="M163.712,62.899c-0.797,23.824-18.525,42.706-39.593,42.172
		c-21.071-0.533-37.502-20.28-36.702-44.105c0.793-23.824,18.522-42.707,39.594-42.174
		C148.079,19.327,164.513,39.073,163.712,62.899z"/>
	<path id="Ballon03" fill="#9ACDCD" d="M93.63,211.334c0,0,1.359,4.749,2.967,5.947c-4.133,0.397-5.969,5.896-5.795,3.347
		c2.579-4.146-3.775-7.507-3.775-7.507c-20.717,3.382-41.61-12.874-46.665-36.299c-3.659-16.951,1.975-33.013,13.218-41.878
		c4.296-3.388,9.411-5.724,15.138-6.657c20.716-3.378,41.608,12.874,46.664,36.301C119.876,185.407,110.349,204.886,93.63,211.334z"
		/>
	<path id="shadow_x5F_b03_4_" opacity="0.28" fill="#666633" d="M119.259,119.703c7.113,12.919,0.493,28.545-14.785,34.902
		c-15.278,6.356-33.429,1.036-40.542-11.884c-7.112-12.919-0.493-28.542,14.783-34.898
		C93.995,101.467,112.146,106.784,119.259,119.703z"/>
	<path id="shadow_x5F_b03_5_" opacity="0.33" fill="#666633" d="M127.33,85.586c6.341,14.932,2.383,29.956-8.839,33.555
		c-11.219,3.598-25.454-5.589-31.791-20.523c-6.339-14.932-2.382-29.952,8.837-33.551C106.759,61.469,120.992,70.654,127.33,85.586z
		"/>
	<path id="Ballon02" fill="#CC1236" d="M134.627,107.98c3.937,23.587-9.782,44.842-30.643,47.47
		c-20.861,2.634-40.962-14.358-44.896-37.946C55.15,93.916,68.87,72.662,89.73,70.031C110.591,67.402,130.691,84.392,134.627,107.98
		z"/>
	<path fill="#CC1236" d="M144.182,211.334c0,0-1.359,4.749-2.967,5.947c4.132,0.397,5.968,5.896,5.794,3.347
		c-2.58-4.146,3.777-7.507,3.777-7.507c20.716,3.382,41.608-12.874,46.663-36.299c5.056-23.427-7.639-45.155-28.355-48.535
		c-20.718-3.378-41.608,12.874-46.665,36.301C117.937,185.407,127.464,204.886,144.182,211.334z"/>
	<path id="shadow_x5F_b03_1_" opacity="0.21" fill="#666633" d="M168.235,178.143c-5.936,17.969-21.89,29.756-35.632,26.324
		c-13.741-3.43-20.067-20.775-14.132-38.744c5.936-17.97,21.888-29.755,35.627-26.325
		C167.844,142.828,174.173,160.174,168.235,178.143z"/>
	<path id="shadow_x5F_b02" opacity="0.28" fill="#666633" d="M174.074,107.198c-3.02,20.695-18.766,36.004-35.171,34.195
		c-16.402-1.808-27.249-20.051-24.229-40.745c3.021-20.693,18.765-36,35.169-34.19C166.245,68.266,177.097,86.506,174.074,107.198z"
		/>
	<path id="shadow_x5F_b03_2_" opacity="0.29" fill="#666633" d="M179.471,147.4c-10.985,12.657-29.146,16.854-40.561,9.37
		c-11.417-7.485-11.765-23.813-0.78-36.476c10.985-12.661,29.142-16.856,40.559-9.372
		C190.104,118.406,190.454,134.737,179.471,147.4z"/>
	<path id="Ballon02_1_" fill="#9ACDCD" d="M191.856,121.333c-5.499,22.289-27.242,37.136-48.565,33.161
		c-21.328-3.976-34.154-25.267-28.654-47.557c5.498-22.29,27.241-37.138,48.569-33.162
		C184.529,77.751,197.357,99.042,191.856,121.333z"/>
	<path id="shadow_x5F_b03_3_" opacity="0.33" fill="#666633" d="M145.155,117.619c16.014,4.352,25.271,18.248,20.673,31.042
		c-4.599,12.795-21.308,19.639-37.323,15.29c-16.015-4.348-25.269-18.243-20.674-31.039
		C112.429,120.12,129.138,113.275,145.155,117.619z"/>
	<path id="shadow_x5F_b03" opacity="0.25" fill="#666633" d="M143.401,164.823c-1.531,21.821-16.897,38.761-34.322,37.837
		c-17.422-0.924-30.305-19.362-28.773-41.184c1.532-21.819,16.897-38.756,34.319-37.833
		C132.049,124.568,144.934,143.004,143.401,164.823z"/>
	<path id="Ballon03_1_" fill="#016666" d="M120.281,209.248c0,0-0.983,4.998-0.069,6.688c-3.988-1.031-8.259,3.586-6.902,1.215
		c4.322-3.073,0.063-8.454,0.063-8.454c-20.633-3.863-32.192-26.521-25.821-50.603c6.371-24.084,28.26-40.475,48.895-36.608
		c20.628,3.865,32.187,26.521,25.817,50.602C156.604,193.491,138.681,208.816,120.281,209.248z"/>
	<g>
		<path fill="#346767" d="M143.114,242.123c5.744,0.051,11.415-0.416,16.987-1.306c5.569-0.88,11.039-2.166,16.325-3.914
			c10.578-3.469,20.404-8.838,28.763-15.989c8.388-7.126,15.254-15.979,20.702-25.734c5.479-9.764,9.535-20.392,13.059-31.328
			c-1.077,11.443-3.966,22.823-8.854,33.467c-4.89,10.615-11.94,20.472-21.017,28.161c-9.029,7.729-19.95,13.165-31.361,15.944
			c-5.709,1.391-11.538,2.168-17.355,2.313C154.554,243.826,148.722,243.493,143.114,242.123z"/>
	</g>
	<g>
		<path fill="#346767" d="M246.812,158.477c-1.668-1.884-3.226-3.202-4.784-4.237c-1.557-1.026-3.11-1.74-4.817-2.338
			c-1.702-0.595-3.559-1.063-5.581-1.525c-2.027-0.479-4.191-0.898-6.603-1.722c1.956-1.635,4.229-2.555,6.648-2.996
			c2.415-0.413,5.025-0.299,7.523,0.584c2.488,0.856,4.822,2.558,6.281,4.806C246.919,153.28,247.578,156.004,246.812,158.477z"/>
	</g>
	<g>
		<path fill="#346767" d="M140.952,226.121c-1.119,2.254-1.758,4.191-2.131,6.025c-0.367,1.828-0.445,3.536-0.357,5.342
			c0.088,1.802,0.35,3.697,0.681,5.746c0.317,2.059,0.741,4.222,0.884,6.766c-2.25-1.199-3.955-2.96-5.273-5.037
			c-1.29-2.083-2.164-4.546-2.283-7.191c-0.141-2.628,0.56-5.431,2.096-7.627C136.096,227.973,138.373,226.339,140.952,226.121z"/>
	</g>
</g>
<g id="Guirelande">
	<g id="Guirelande_x5F_paht">
		<g>
			<g>
				<g>
					<path fill="none" stroke="#B0D7D7" stroke-width="4" stroke-miterlimit="10" d="M32.164,133.683
						c-4.837,1.942-9.624,4.167-14.306,6.771c-4.677,2.617-9.27,5.571-13.595,9.277c-0.667-6.505-0.556-12.411-0.148-18.301
						c0.427-5.864,1.225-11.608,2.334-17.283C14.526,121.701,23.137,128.325,32.164,133.683z"/>
				</g>
				<g>
					<path fill="none" stroke="#B0D7D7" stroke-width="4" stroke-miterlimit="10" d="M40.702,97.057
						c-10.428,0.798-21.012,2.705-31.566,5.684c3.077-11.124,7.394-21.952,13.044-32.179C27.52,80.291,33.779,89.132,40.702,97.057z
						"/>
				</g>
				<g>
					<path fill="none" stroke="#B0D7D7" stroke-width="4" stroke-miterlimit="10" d="M60.054,65.329
						c-10.026-2.827-20.679-4.472-31.686-4.907c6.539-9.746,14.454-18.695,23.489-26.351C53.335,45.118,56.132,55.61,60.054,65.329z
						"/>
				</g>
				<g>
					<path fill="none" stroke="#B0D7D7" stroke-width="4" stroke-miterlimit="10" d="M89.124,42.848
						c-8.333-6.27-17.614-11.72-27.751-16.094c9.704-6.765,20.315-12.093,31.259-16.017C90.24,21.562,89.029,32.351,89.124,42.848z"
						/>
				</g>
				<g>
					<path fill="none" stroke="#B0D7D7" stroke-width="4" stroke-miterlimit="10" d="M124.955,31.384
						c-6.17-8.488-13.167-16.62-21.112-24.176c11.136-3.036,22.447-4.789,33.678-5.588
						C132.481,11.379,128.225,21.336,124.955,31.384z"/>
				</g>
				<g>
					<path fill="none" stroke="#B0D7D7" stroke-width="4" stroke-miterlimit="10" d="M163.835,29.529
						c-4.344-9.624-9.279-19.118-14.938-28.408c11.182-0.186,22.254,0.469,33.167,1.726
						C175.446,11.523,169.322,20.427,163.835,29.529z"/>
				</g>
			</g>
		</g>
	</g>
</g>
<g id="Layer_2">
</g>
</svg>
)