import React, { Component } from 'react';
import '../App.css';
import {StyledFormCardAdmin, RoundButton, Label, LabelHours } from '../common/index';
import axios from 'axios';
import connectionStrings from "../data/connectionStrings";
import {SelectInput} from "./SelectInput";
import { parseFromTimeZone } from 'date-fns-timezone';




const SERVER = connectionStrings.SERVER;
const hoursToChoose = [ "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22"];
const minutesToChoose = ["00","15", "30", "45"];
const OPTIONS = {
    AccessControlAllowOrigin : "*", // tenter de désactiver ça, a priori plus besoin
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    SameSite : 'None',
    Secure : 'true'    
};

export default class DayCard extends Component {
    constructor(props){ 
        super(props);        
        this.state={
            isChecked: false,
            timeToDel: null,
            message: "",
            processing: false,
            selectedHour: "",
            selectedMinute: "",
            newTime:null, 
            hourList: this.props.hours,
            defaultValue: "",
            resp:null
        }
        this._isMounted = false;
        this.inputRef = React.createRef();
   
    }



 
componentDidUpdate(prevState){
 if(this.state.message !== ""){
     this.inputRef.current.focus(); // si message, on focus
 }

}

componentDidMount(){
    this._isMounted = true;
    (this.props.hours && this.props.hours.length < 1) && this.setState ({info : "Aucun créneau horaire, souhaitez-vous en créer un?"})
}
componentWillUnmount(){
    this._isMounted = false;
    this.setState({message: ""});
}

setCheckedValues=(cbValue)=>{
   
    if (this.props.sortedtimestamps !== undefined){
      const timestamps = this.props.sortedtimestamps;
      if (timestamps.hour_table !== undefined){
          const element  = timestamps.hour_table.find(hour => hour === cbValue);          
          return element ? true : false;
      } else{
        console.log("pas de tableau d'heures")
          return false;
      }
  }else{
      console.log("pas chargé")
      return "... chargement en cours";
  }
    
  }
   
handleCheckBoxChange=(event)=>{
    
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
        [name]: value,       
      });
   
   /* this.setState({
    [name]: value
}); */
    console.log("l'élément checked : " + target.value);
  }

  setDayString(numDay){
    let label;
    switch (numDay) {

        case 0:
            label = "dimanche";
        break;
        case 1:
            label = "lundi";
        break;
        case 2:
            label = "mardi";
        break;
        case 3:
            label = "mercredi";
        break;
        case 4:
            label = "jeudi";
        break;
        case 5:
            label = "vendredi";
        break;
        case 6:
            label = "samedi";
        break;  
        default:
            label="";
            break;
        
    }    
    return label;
}

formatToUtcDate(date){        
    // Set the date to "2018-09-01T16:01:36.386Z"
    const utcDate = parseFromTimeZone(date, { timeZone: 'Europe/Berlin' }); 
    return utcDate;
  }

handleSubmit = async (event)=>{
    
    event.preventDefault();    
    this.setState ({info : ""}); // reset message nouveau créneau
    let value="";
    const inValidHour = this.state.selectedHour === "" || this.state.selectedHour === null || this.state.selectedHour === undefined;
    const inValidMin = this.state.selectedMinute === "" || this.state.selectedMinute === null || this.state.selectedMinute === undefined;
    if( inValidHour || inValidMin){
        value=null;
    }else{
        value = this.state.selectedHour + "h" + this.state.selectedMinute;
    }
    const time = {
        value: value,
        dt_effect: this.formatToUtcDate(Date.now()),
        dt_expirity: "infinity",
        num_day: this.props.num_day,
        category: this.props.category
    }

    if (time.value === null || time.value === undefined || time.value === ""){
        this.setState({message: "Vous devez choisir un créneau horaire à ajouter avant de valider"})
        this.inputRef.current.focus();
    }else{
    /*On vérifie si le créneau horaire existe déjà*/
    
    const existingTimeStamp = this.state.hourList ? this.state.hourList.find(otime => otime.value === time.value) : null;
    console.log("le créneau trouvé : " + existingTimeStamp);
    // si le créneau n'existe pas déjà, on lance la création
    if (existingTimeStamp === null || existingTimeStamp === undefined){
        const newTime = await this.addNewTime(time);
        if (newTime !== undefined){
            if (newTime.message){
                this.setState({message: newTime.message});           
            } else {
            this.setState({newTime: newTime.newTime});
            this.setState({message: "Nouveau créneau enregistré"})
            console.log("nouveau créneau enregistré");
            // mise à jour de la liste
            let hourList = this.state.hourList;           
            hourList = this.state.hourList ? [...this.state.hourList, newTime.newTime] : [newTime.newTime];              
            this.setState({hourList});
            this.setState({defaultValue: ""}); // mise à jour du select
            setTimeout(() =>this.setState({message: ""}), 2000);
            }
        }else{
            this.setState({message: "...enregistrement en cours"});
            console.log("... enregistrement en cours");
    
        }
        
        this.setState({newTime});
        
    }else{ 
        this.setState({message: "Ce créneau existe déjà"});        
    }
 }
}

handleSelectChange = (value, position)=>{
    this.setState({message: ""});
    if (position === 1){
        this.setState({selectedHour: value});
        console.log("handleselectchange, les heures : " + value)
    }else{
        this.setState({selectedMinute: value});
        console.log("handleselectchange, les minutes : " + value)
    }
    
}

handleDelete =  async (id, e) =>{
    e.preventDefault();    
    const resp =  await this.deleteTime(id);   
    if (resp !== undefined ){ 
        if (resp.message){
        console.log("la réponse : " + resp.message);
        this.setState({message : resp.message}); // si c un message d'erreur, on l'affiche    
        }else{
        // mise à jour de la liste
        if(resp.timeid !== undefined){
            console.log("id supprimé : " + resp.timeid);
            
        const hourList = this.state.hourList;     
        const newList = hourList.filter((hour)=> hour.id !== resp.timeid);
        this.setState({hourList: newList});        
        }
    }
    }else{
        this.setState({message: "...Suppression en cours"});
        console.log("...undefined");
    }
}

 _updateTime = async (id)=> { 
    const CancelToken = axios.CancelToken;
    console.log("dans deletetime, hour to delete " + id);
    /***           
    * APPEL PUT AXIOS 
    * @param mail 
    */
    const options = OPTIONS;
   
    let cancel;
    try {    
    // set dt expirity to now for the moment, change if not compatible with timestamps frontend
    console.log("url : " + `${SERVER}/time/${id}`);

    console.log("date à entrer : " + this.formatToUtcDate(Date.now())); //pas en utc!
        const res = this._isMounted && await axios.put(`${SERVER}/time/${id}`,
        {
      
        dt_expirity: this.formatToUtcDate(Date.now())
        }
        , options,
        {cancelToken: new CancelToken(function executor(cancelParam) {
            // An executor function receives a cancel function as a parameter
            cancel = cancelParam;
        })}
        )

    if (res !== undefined){
        const json = res.data.json;
        console.log("la réponse json : " + json);
        if (res.data !== undefined){
            if (res.data.error){
                const message = "Erreur lors de la suppression du créneau, merci de réessayer ultérieurement"            
                console.log("erreur pendant la suppression : " + res.data.error);
                cancel();
                return message;

            }else{ 
                console.log("on retourne la réponse : " + res.data.time);
                const time = res.data.time;
                cancel();
            return time;
            }
        }
    }else{       
        return "...Suppression en cours";        
    }

    } catch (error) {
        cancel();
        return "La suppression a échoué, réessayez ultérieurement";
    }

}

deleteTime = async (id)=> { 
    const CancelToken = axios.CancelToken;
    console.log("dans deletetime, hour to delete " + id);
    /***           
    * APPEL PUT AXIOS 
    * @param mail 
    */
    const options = OPTIONS;
    let cancel;
    try {    
        // set dt expirity to now for the moment, change if not compatible with timestamps frontend
        console.log("url : " + `${SERVER}/time/${id}`);    
        const res = this._isMounted && await axios.delete(`${SERVER}/time/${id}`, 
        options,
        {cancelToken: new CancelToken(function executor(cancelParam) {
        // An executor function receives a cancel function as a parameter
        cancel = cancelParam;
        })}
        );
        if (res !== undefined){            
            if (res.data !== undefined){
                if (res.data.error){
                    const message = "Erreur lors de la suppression du créneau, merci de réessayer ultérieurement"            
                    console.log("erreur pendant la suppression : " + res.data.error);
                    cancel();
                    return ({message: message});

                }else{ 
                    if(res.data.delTime !== undefined){
                    console.log("on retourne la réponse : " + res.data.delTime);
                    const time = res.data.delTime;
                    cancel();
                    return ({timeid:time});
                    }
                }
            }
        }else{
            console.log("res undefined");
            return ({message: "...Suppression en cours"});          
        }

    } catch (error) {
        console.log("catch axios del");
        return ({message: "...Suppression en cours"});
    }
    cancel();

}

addNewTime = async (time)=>{
    console.log("on rentre dans addNewTime");
    const CancelToken = axios.CancelToken;
    if (time === undefined || time === null || time==="" || time===" h "){
      
        const message =  "Merci de choisir un créneau horaire avant de valider";
        console.log("créneau non choisi");
        return {message: message};
        
    }else{        
            const options = OPTIONS;            
            this.setState({isProcessing: true});
            let cancel;           
          
            
            try {
            const resp = this._isMounted && await axios.post(`${SERVER}/time`,
            // add new params
            { 
                value : time.value,
                dt_effect: time.dt_effect,
                dt_expirity: time.dt_expirity,
                num_day : time.num_day,
                category: time.category
            }
            ,
            options,
            {cancelToken : new CancelToken(function executor(cancelParam){
                cancel = cancelParam
            })});

            if (resp !== undefined){
                if (resp.data.error){
                    const message = resp.data.error;                    
                    console.log("on reçoit une erreur : " + resp.data.error);
                    cancel();
                    this.setState({isProcessing: false});                    
                    return {message: message};
                }else{
                    const newTime = resp.data.time;
                    console.log("on reçoit l'heure modifiée : " + resp.data.time);
                    this.setState({isProcessing: false});
                    cancel();
                    return {newTime: newTime};
                }
            }else{
                const message = "... enregistrement en cours"
                return {message: message};
            }
        } catch (error) {           
            const message =  "Vous devez choisir un créneau horaire à ajouter avant de valider";
            console.log("erreur pendant l'enregistrement cu créneau : " + error);
            return {message: message};
            
        }
       
    }
   
}

    


    

    render() { 
        const {num_day} = this.props
        const hourList = this.state.hourList !== undefined && this.state.hourList
        const day = this.setDayString(num_day)
        var random_boolean = Math.random() < 0.5;
        const info = this.state.info
        const message = this.state.message
        return (
            <>
            
            <StyledFormCardAdmin onSubmit={this.handleSubmit}>
            <div className="cardlineadmin">
                <div className="bookingcolumnadmin">
                    <span className="table-header">{day}</span>
                </div>
                
                
            </div>
            <div className="cardlineadmin">
                {hourList && hourList.map((hour, key)=>
                <LabelHours key={hour.id}>{hour ? hour.value : ""}                     
                      <button className="btnless" type="button" value={hour.value} onClick={(e)=>this.handleDelete(hour.id, e)}><label className="labelless">-</label></button>
                </LabelHours>
     
               
                )}
            </div>
            <div className="cardlinefull"><Label isvalid={true}>{info}</Label></div>
            <span className="label-card">Ajouter un créneau</span>
                    <div className="cardlineadmin">
                    <div className="bookingcolumnadmin">
                    
                    <SelectInput 
                     className="select_input"
                     onChange={(e)=>{this.handleSelectChange(e.target.value, 1)}} 
                     table={hoursToChoose}                    
                     defaultvalue={this.state.defaultValue}
                     />
                    <span className="label-card">H</span>
                    <SelectInput className="select_input" ref={this.inputRef} onChange={(e)=>this.handleSelectChange(e.target.value, 2)} table={minutesToChoose}/>
                    </div> 
                    
                   
                   <div className="bookingcolumnadmin">
                   <RoundButton type="submit"> 
                    +
                    </RoundButton>
                    </div>
                    <div className="carlinefull"><Label isvalid={false}>{message}</Label></div>
                    
                    </div>
       
            </StyledFormCardAdmin>
           </>
            )
        }
    }
