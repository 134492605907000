import React from "react";
import {AiOutlineClose} from 'react-icons/ai';
import CgvDetails from "./CgvDetails";

const ModalCgv=(props)=> {

return (
    props.isOpen ? 
    <div className="modal-div" onClick={props.closeClick()}>
        <div className="cgv-div">
            
            <button className="close-button" onClick={props.closeClick()}>
          
             <AiOutlineClose/>
            </button>
          
        <h2 className="cgv-title"><span className="first-letter">C</span><span>ONDITIONS</span> 
         &nbsp;<span className="first-letter">G</span><span>ÉNÉRALES</span>
         &nbsp;<span className="first-letter">D</span><span>E</span> 
         &nbsp;<span className="first-letter">V</span><span>ENTE</span>
        </h2>
        <h2>APPLICATION DE RÉSERVATIONS EN LIGNE
            <span className="no-cap">&nbsp; </span>
        </h2>
        <span>Les présentes conditions générales portent sur les réservations 
        de prestations en ligne proposées par l'application &nbsp;
        </span>
        
        {/* 01- Prestataire */}
        <CgvDetails 
            titleH3=" 01 - Le prestataire"
            titleDetail='Nom et prénom' 
            spanDetail='Vos nom et prénom'
        />
        <CgvDetails 
            titleDetail='Adresse de domiciliation' 
            spanDetail='Votre adresse'
        />
        <CgvDetails 
            titleDetail='Téléphone' 
            spanDetail='Votre numéro de téléphone'
        />
        <CgvDetails 
            titleDetail='Adresse mel' 
            spanDetail={<a href= "#"><span>contact@latoiledenbouyssou.fr</span></a>}
        />
        <CgvDetails 
            titleDetail='TVA Intracommunautaire' 
            spanDetail="Votre numéro de TVA"
        />
        {/* 02- Le client */}
         <CgvDetails 
            titleH3=" 02 - Le client"           
            spanDetail="Toute personne physique souhaitant réserver une des activités proposées"
        />
        <CgvDetails 
            titleH3="03 - Zones géographiques concernées par la vente de prestations" 
            spanDetail="Zone concernée"
            spanDetail02=""
        />
        <CgvDetails 
            titleH3="04 - Services : conditions et durée" 
            spanDetail="L'application propose des réservations en ligne avec versement d'arrhes ou non"
            spanDetail02="Pour les prestations récurrentes les créneaux sont ouverts par cycles. "
            spanDetail03="Pour les prestations ponctuelles, les créneaux sont définis pour des dates précises"
        />
        <CgvDetails 
            titleH3="05 - Prix" 
            spanDetail="Le montant des arrhes est calculé en fonction du type de prestation et du nombre participants. "
            spanDetail02="Il est affiché avant validation de la réservation au même titre que l'heure et la date du rendez-vous."
            spanDetail03="Ce montant est ensuite déduit du règlement final de la prestation."
        />
         <CgvDetails 
            titleH3="06 - Conditions de paiement" 
            spanDetail="Le règlement des arrhes se fait comptant par carte bancaire à la validation de la réservation et est débité immédiatement."
        />
         <CgvDetails 
            titleH3="07 - Espace et compte client" 
            spanDetail="La création de compte requiert le minimum d'informations nécessaires à une réservation : le nom, le pseudo (afin de personnaliser l'interface utilisateur), un numéro de contact et une adresse mail."
        />
         <CgvDetails 
            titleH3="08 - Processus de commande" 
            spanDetail="Le client crée un compte utilisateur, le valide, puis se connecte pour effectuer une réservation."
            spanDetail02="Il choisit la prestation parmi celles proposées."
            spanDetail03="Il renseigne ensuite le nombre de personnes concernées, l'heure et la date de son rendez-vous ; puis, suivant la prestation désirée, il précise l'âge ou le niveau du pratiquant"
            spanDetail04="Un résumé de son rendez-vous et du montant des arrhes est affiché."
            spanDetail05="Il renseigne les informations nécessaires au paiement par carte bleue (numéro, CVC, date d'expiration) puis valide la réservation."
            spanDetail06="Une référence de paiement lui est fournie par l'application et un résumé de sa réservation est envoyé par mail."
        />
         <CgvDetails 
            titleH3="09 - Modalités de fourniture des prestations" 
            spanDetail="Vos modalités de fourniture de prestations"
            spanDetail02=""
            spanDetail03=""
            spanDetail04=""
        />
        <CgvDetails 
            titleH3="10 - Annulation de commande " 
            spanDetail={<span>Le client peut annuler la commande par mail auprès de <a href= "mailto:contact@latoiledenbouyssou.fr">contact@latoiledenbouyssou.fr</a></span>}
            spanDetail02="Les arrhes sont non remboursables car elles ont été créées dans le but que le rendez-vous soit honoré."
            spanDetail03=" En revanche, la réservation peut être modifiée.
            "
        />
        <CgvDetails 
            titleH3="11 - Préavis de modification et de réclamation" 
            spanDetail="Les rendez-vous sont modifiables jusqu'à trois heures avant le début de la prestation."
        />
        <CgvDetails 
            titleH3="12 - Modalités de modification de commande (changement de rendez-vous)" 
            spanDetail="Le client se rend dans la liste de ses prochaines réservations, choisit le nouveau créneau (la date puis l'heure) en face du récapitulatif de son ancien rendez-vous et valide sa modification."
            spanDetail02="Un message de confirmation lui est alors affiché et un nouveau mail lui est envoyé."
        />
        <CgvDetails 
            titleH3="13 - Modalités de réclamation" 
            spanDetail="Un remboursement peut être demandé en cas de problème technique indépendant de la volonté des deux parties."
            spanDetail02="La référence de paiement fournie au client lors de la réservation lui sera alors demandée."
        />
         <CgvDetails 
            titleH3="14 - Traitement des données personnelles" 
            spanDetail="Les données personnelles des clients sont collectées pour l'ouverture du compte et la vente des prestations."
            spanDetail02="Afin de pouvoir réaliser une réservation, les données personnelles suivantes sont collectées: "
            lstDetails02={["- le nom du client, ","- son numéro de téléphone, ", "- son adresse mail."].map((anElt, index)=>(<p key={index}><span>{anElt}</span></p>))}
            spanDetail03={
            <span>
                Le module <span className="stripe"> Stripe </span>
                utilisé pour le paiement en ligne se réserve le droit 
                de collecter les informations liées à la carte de paiement 
                afin de pouvoir effectuer un remboursement en cas de problème 
                technique et d'effectuer un suivi personnalisé du règlement.
                </span>}
            spanDetail04 = {
            <span>
                
                Se rapprocher des conditions générales de vente de <span className="stripe"> Stripe </span>
                pour plus de détails : <a href = "https://stripe.com/fr/legal"><span className="address-club">stripe.com/fr/legal. </span></a>
                <span>Votre entreprise / collectivité ne peut pas être tenue responsable des événements fortuits liés à cette détention.</span>
  
            </span>}
        />
        <CgvDetails 
            titleH3="15 - Responsable de traitement des données personnelles" 
            spanDetail="Le responsable"
        />
        <CgvDetails 
            titleH3="16- Moyens dont dispose le client pour supprimer ses données personnelles" 
            spanDetail= 
            {
                <p>       
                <span>
                Les données personnelles du client peuvent être supprimées par simple demande 
                auprès de {<a href = "mailto:contact@latoiledenbouyssou.fr">mail de contact</a>}
                </span>
                </p>
            }/>
            <CgvDetails 
            titleH3="17- Service de médiation" 
            spanDetail= 
            {
                <span>       
                Votre service de médiation
                
                </span>
            }
        />
        <CgvDetails 
            titleH3="18- Date de mise en vigueur des CGV" 
            spanDetail= "Les conditions générales de vente sont valable dès la mise en service de l’application."            
        />
       
        
       
        
        </div>
    </div>    
     : 
     <div className="closed-div">
       
     </div> 
)
}
export default ModalCgv