import React from "react";

const Badge=(props)=> {
return (
<div
className='badge'>
    {props.children}
</div>

)
}
export default Badge