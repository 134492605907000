import React from 'react';
import { useLocation } from 'react-router-dom';
import Cpresta from './PrestaClass';


/**
 * 
 * @param {*} props 
 * @returns class component
 * wrapper to use useLocation
 */
 const Presta = props => {   
    const location = useLocation() 
    console.log("location in wrapper: ", location) ;
    return <Cpresta location={location} {...props} />
  }

export default Presta

