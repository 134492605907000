import React, { Component } from 'react';
import AdminDayCard from './AdminDayCard';
import '../App.css';
import {StyledFormCardsDaysAdmin, Label, RoundButton, FormCardsDaysAdmin} from '../common';
import addMilliseconds from 'date-fns/addMilliseconds';
import axios from 'axios';
import connectionStrings from '../data/connectionStrings';
import { withUser } from '../contexts/UserContext';

const SERVER = connectionStrings.SERVER;

class Days extends Component {
    
    


constructor(props){
    super(props);
    this._isMounted = false;    
    this.OPTIONS = {
        AccessControlAllowOrigin : this.props.context.handleAccesControl(this.props.context.serverStatus), 
        xsrfCookieName: 'XSRF-TOKEN',
        xsrfHeaderName: 'X-XSRF-TOKEN',
        SameSite : 'None',
        Secure : 'true'    
    };

    this.state = {   
        validityDate:""  ,
        message:"",
        finalTimeStamps:[],
        validTimestamps: [],
        hours: [],
        tempTimes: [],
        recievedTimeStamps:[],
        numDay: 0
     
    }
}

async componentDidMount(){
    this._isMounted = true;
    if (!window.navigator.onLine){
        this.setState({message: "Oups, il semblerait que vous ayez perdu votre connexion internet"});
    }else{
        const resp = this._isMounted && await this.getTimeStampsWithTimes();
        if (resp !== undefined){
            if (resp.message){
                this.setState({message: "Aucun créneau disponible"});
            }else{ // récupération des heures
                if(resp.timestamps !== undefined){                   
                this.setState({validTimestamps: resp.timestamps}); 
                // on trie par catégorie            
                let finalTimeStamps;
                switch (this.props.category) {
                    case 0:
                        // unique (et age)
                        finalTimeStamps = this.sortTimeStampsByCat(0);
                        break;
                    case 1:
                        // cours / balade (avec niveau)
                        finalTimeStamps = this.sortTimeStampsByCat(1);
                        break;
                    case 3:
                        // anniversaire ludique (sans niveau)
                        finalTimeStamps = this.sortTimeStampsByCat(3);
                        break;
                
                    default:
                        break;
                }
                console.log("timestamps : ", finalTimeStamps);
                this.setState({finalTimeStamps: finalTimeStamps});          
            }
        
        }
        }else{
            this.setState({message : "...Chargement en cours"});
        } 
    }
}
handleSelectChange =(e)=>{
    console.log("la value : ", e.target.value);
    this.setState({numDay: e.target.value}, ()=>{                    
        console.log("numday à envoyer : ", this.state.numDay);
    });  
   
}
onFormSubmit = async (e)=>{
    e.preventDefault();
    console.log("formulaire soumis, num day à envoyer : ", this.state.numDay);
    console.log("formulaire soumis, category à envoyer : ", this.props.category);
    const response = await this.createRecurrentTimeStamp(this.state.numDay, this.props.category);
    if (response !== undefined){
    if (response.message){
        this.setState({message: response.message});
    }else{
        console.log("la reponse : ", response);
        const createdTs = [...this.state.finalTimeStamps, response.recurrentTimeStamp];
        this.setState({finalTimeStamps: createdTs});
    }
}
}


createRecurrentTimeStamp = async (pNumDay, pCategoryId)=>{
    console.log("on rentre dans createRecurrentTimeStamp");
    const CancelToken = axios.CancelToken;
    if (pNumDay === undefined || pNumDay === null || pNumDay === "-1"){        
        const message =  "Merci de choisir un jour pour la création d'une carte créneaux";
        console.log("jour pas sélectionné");
        return {message: message};     
        
    }else{       
        console.log("on lance le traitement")      
        const options = this.OPTIONS;
        let cancel; 
        
        try {
            const resp = await axios.post(`${SERVER}/recurrenttimestamp`,
            // add new params
            {                
                num_day: pNumDay,
                category: pCategoryId,
                is_to_disable: false
            },
            options,
            {cancelToken : new CancelToken(function executor(cancelParam){
                cancel = cancelParam
            })});
            
            if (resp !== undefined){
                if (resp.data.error){
                    const message = resp.data.error;                    
                    console.log("on reçoit une erreur : " + resp.data.error);
                    cancel();                              
                    return {message: message};
                }else{
                    const newTimeStamp = resp.data.timestamp;
                    console.log("on reçoit le créneau créé : " , newTimeStamp);                    
                    cancel();
                    return {recurrentTimeStamp: newTimeStamp};
                }
            }else{
                const message = "... Enregistrement en cours"
                return {message: message};
            }
        } catch (error) {           
            const message =  "Une erreur est survenue pendant la création du créneau récurrent";
            console.log("erreur pendant la créantion du créneau : " + error);
            return {message: message};
        }
        
    }
}

/**
 * CONTEXT
 * @param {*} timeStamps 
 */
setValidTimes(timeStamps){ 
    console.log("on rentre dans setvalditimes")       
    if(timeStamps !== undefined){
        console.log("dans setValidTimes : not undefined")
        let newTimeStamp;
        let filteredTimeStamps=[];
        timeStamps.forEach(timeStamp => {
            
            newTimeStamp = {
                category : timeStamp.category,
                created_at : timeStamp.created_at,
                updated_at : timeStamp.updated_at,
                id : timeStamp.id,
                num_day: timeStamp.num_day,
                times :                
                timeStamp.times.filter(time=>
                (time.dt_expirity === "infinity"? Infinity : time.dt_expirity) > Date.now() && new Date (time.dt_effect) <= Date.now())
                
            }
            filteredTimeStamps.push(newTimeStamp);
          
        });            
      
    console.log("les heures filtrées : " + filteredTimeStamps);
    return filteredTimeStamps;
    
}
}

/**
 * @returns 
 */
getTimeStampsWithTimes = async ()=>{
    const CancelToken = axios.CancelToken;
    let cancel;
    try {
          const resp = await axios.get(`${SERVER}/admintimes`, {
            headers:{'timesheader': 'times'}
            },            
            {cancelToken: new CancelToken(function executor(cancelParam) {
                // An executor function receives a cancel function as a parameter
                cancel = cancelParam;
          })});
          if (resp !== undefined){              
                if(resp.data.error){
                      console.log("Erreur pendant le chargement des heures : " + resp.data.error);
                      cancel();
                      return {message: "Une erreur est survenue pendant le chargement des heures"};
                }else{
                      console.log("les heures récupérées : " + resp.data.timestamps);
                      cancel();
                      return {timestamps : resp.data.timestamps};
                }
          }else{
                console.log("... chargement en cours");
                return {message : "... chargement en cours"};
          }
    } catch (error) {
          console.log("erreur dans la récupération des heures : " + error);
          cancel()
          return {message : "Erreur dans la récupération des heures : " + error};
    }
}

handleValidityDate=()=>{
    // 3 mois après la date courante
    const milliDatePast3 = addMilliseconds(new Date(), 7776000000); 
    return milliDatePast3.toLocaleDateString();
}

componentWillUnmount(){
    this._isMounted = false;
}

filterTimeStampsByNumDay(numDay){ // la requete renvoie déjà un order by dt_expirity desc, vérifier si ok
    console.log("on rentre dans timestampbynumday");
    const filteredTs = this.state.finalTimeStamps && this.props.finalTimeStamps.filter(ts=>ts.num_day === numDay);
    console.log("les timestamp par numday " + filteredTs);   
    return filteredTs;
}

sortTimeStampsByCat(p_category){
    const sortedTimeStamps = this.state.validTimestamps;
    console.log("timeStamps récupérés : " + this.state.validTimestamps);
   
    if (sortedTimeStamps !== undefined && sortedTimeStamps.length > 0){       
        const byCat = sortedTimeStamps.filter(ts => ts.category === p_category && ts.is_to_disable !== true);
        if (p_category === 0){
            console.log("les créneaux par baby : " , byCat);
        }else{
            console.log("les créneaux par presta ext. " , byCat);
        }
        return byCat;   
    }else{
        this.setState({message: "Aucun créneau n'a encore été créé pour cette activité, souhaitez-vous en créer un?"});
        return [];
    }
}



 setCategoryString(nCategory){
    let response = "";
    switch (nCategory) {
        case 0:
        response =  "Baby";
        break;
        case 1:
        response =  "Prestation encadrée";
        
    }
    return response;
}



    render(){
       const finalTimeStamps = this.state.finalTimeStamps !== undefined && this.state.finalTimeStamps
       const selectDay = [
        {id: 0, label: "dimanche"},
        {id: 1, label: "lundi"},
        {id: 2, label: "mardi"},
        {id: 3, label: "mercredi"},
        {id: 4, label: "jeudi"},
        {id: 5, label: "vendredi"},
        {id: 6, label: "samedi"}
        ] ;
       const message = this.state.message
       const category = this.props.category
    return(
        <> 
        <Label isValid={false}>{message}</Label>
        <FormCardsDaysAdmin  onSubmit={this.onFormSubmit}>
        <p><span>Merci de sélectionner un nouveau jour pour créer une carte d'horaires</span></p>
        {selectDay !== null && <select className="select_input_day" onChange={(e)=>{this.handleSelectChange(e)}}>
                {selectDay.map(aDay=>(
                <option                 
                key={aDay.id} 
                value={aDay.id}>
                    {aDay.label}               
                </option>))}
            </select>}
            <RoundButton type="submit">+</RoundButton>
        </FormCardsDaysAdmin>
        <StyledFormCardsDaysAdmin>
           
        {finalTimeStamps.map((ts, index)=>      
            <AdminDayCard 
            key={index}
            num_day = {ts.num_day}
            hours= {ts.times}
            category={category}
            selectedHour={this.state.selectedHour}
            selectedMinute={this.state.selectedMinute}
            onChange={(e)=>this.handleSelectChange}
            /> 
              
            )// fin map
        }
        </StyledFormCardsDaysAdmin>
        </>
        )
            
    }  
}
export default withUser(Days)