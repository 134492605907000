import React, { Component } from 'react';
import { fr } from 'date-fns/locale';

import '../App.css';
import {withBooking} from "../contexts/BookingContext";
import FullDatePicker from './DatePickerFromTsx';



/**
 * On fait remonter les états de datepicker et des radio button dans stripe component
 */




class DatePicker extends Component {
  RbForwardedRef = React.createRef();
  constructor(props){
    super(props);
    this.is_Mounted = false;
   
    
  }

  componentDidMount(){
    this.is_Mounted = true;
    

  }

  componentWillUnmount(){
    this._isMounted = false;
  }
 
  /**
   * 
   * @param {table} items
   * @returns {table} on remplace le tableau d'horaires par la propriété true ou false pour désactiver la date si tab des heures vide
   */
  listToDisable=(items, category)=>{
    /*trie les data à renvoyer en fonction de la catégorie*/
    let daysPerCategory = [];
    items.forEach(element => {
      if (element.fields.category.toString() === category.toString()){
        daysPerCategory.push(element);
      };     
      
      console.log("la catégorie : " + category)
      
    });
    console.log("longueur liste par category : " + daysPerCategory.length);
    let dateList = daysPerCategory.map((item, index)=>{
      let id = item.sys.id
      let numday = item.fields.numday
      let category = item.fields.category 
      let day = item.fields.day
      let isToDisable = item.fields.possiblehours.length === 0 ? true : false
      let timeSlot = {...items.fields, id, day, isToDisable, category, numday}
      console.log("le timeslot : " + timeSlot.category)       
      return timeSlot // évite de push chaque timeSlot dans dateList
  })
  console.log("longueur de la liste à désactiver : " + dateList.length)
 return dateList;
  }

  
  

  onFormSubmit=(event)=>{    
    event.preventDefault();
    console.log("horaire sélectionné : " + this.state.selectedOption);
    console.log("jour sélectionné : " + this.state.date)
  }

 handleDisabledByCat(cat, date){
   if (cat !== "2"){
    if (this.props.context && this.props.daysToDisable ){      
      return this.props.context.handleDayDisable(date, this.props.daysToDisable, this.props.tsToDisable, cat);
    }else{
      return date;
    }
   }else{ // stages
    if (this.props.days){
     return this.props.context.handleStageDayDisable(date, this.props.days);

   }
 }
}

handleHighLightByCat(cat, date){  
  let isToHighLight = false;
  const clickedDate = this.props.date && this.props.date;
  const stageDates = this.props.days && this.props.days;
  const isFullWeek = this.props.isFullWeek && this.props.isFullWeek;
  // si le numday n'est pas entre les dates de début et de fin de stage, on désactive
  if (cat === "2" && isFullWeek === true){    
    if (stageDates !== undefined && stageDates !== null && date !== null && date !== undefined && clickedDate !== undefined && clickedDate !== null){             
      
      const DatesOfWeek = stageDates.filter(stageTs => stageTs.dt_start <= clickedDate.toISOString() && stageTs.dt_end >= clickedDate.toISOString());
      const theDay = DatesOfWeek.find(stageTs => stageTs.dt_start <= date.toISOString() && stageTs.dt_end >= date.toISOString());
      const theClickedDateInStage = DatesOfWeek.find(stageTs => stageTs.dt_start <= clickedDate.toISOString() && stageTs.dt_end >= clickedDate.toISOString());
      console.log("les dates de la semaine : " , DatesOfWeek);
      if (theDay !== undefined && theClickedDateInStage !== undefined){
        console.log("on highlight : " + date);
        isToHighLight = true;
      }else{
        isToHighLight = false;
      }      
    }
  }
  return isToHighLight;
}


  render() { 
   
   /* const modifiers = {   
    disabled: date => this.props.category ? this.handleDisabledByCat(this.props.category.toString(), date):true, // disable les créneaux non dispos de datatoupdate
    highlight: date => this.props.category ? this.handleHighLightByCat(this.props.category.toString(), date): false // nothing highlighted by default
  }
 */
 
  const date = this.props.date
  const modifiers = {   
    disabled: date => this.props.category !== undefined ? this.handleDisabledByCat(this.props.category, date):true, // disable les créneaux non dispos
    highlight: date => this.props.category ? this.handleHighLightByCat(this.props.category.toString(), date.date): false // nothing highlighted by default
}
const newModifiers = {   
  disabled: date => this.props.category !== undefined ? this.handleDisabledByCat(this.props.category, date):true, // disable les créneaux non dispos
 
}
  
   
   const datePickerProps = {
    startDate: date && new Date(date),
    endDate: null,
    onStartDateChange: this.props.onDateChange,
    onEndDateChange: null,
    name:"calendar",
    format:'dd MMM yyyy',
    locale:"fr-fr",
    modifiers: modifiers    
}
    return (   
     <div className='calendar-form-container'>
        <div className="form-title">
          <p>
            {'Sélectionnez votre date parmi celles disponibles'}           
          </p>
        </div>
         <FullDatePicker {...datePickerProps}/>
      </div>
         
      
  
    )
    
  }
}
DatePicker.defaultProps = {
  modifiersClassNames : {highlight: '-highlight'},
  
}

export default withBooking(DatePicker)


