import React, {Component } from 'react';
import axios from 'axios';
import connectionStrings from '../data/connectionStrings';



const UserContext = React.createContext();

const SERVER = connectionStrings.SERVER;
const USER = `${SERVER}/user`;
const LOGOUT = `${SERVER}/logout`;
const CancelToken = axios.CancelToken;



/**
 * création de user - méthodes de connexion et de déconnexion
 */
class UserProvider extends Component {
    STATUSOPTION = {        
        AccessControlAllowOrigin : "*",
        xsrfCookieName: 'XSRF-TOKEN',
        xsrfHeaderName: 'X-XSRF-TOKEN',
        SameSite : 'None',
        Secure : 'true'            
       };
    
    handleAccesControl = async (serverStatus)=>{        
        // on demande son statut au serveur        
        if (serverStatus !== undefined){            
            // local
            if (serverStatus.toString() === "0" ){
                return 'http://localhost';
                // dev
            }else if (serverStatus.toString() === "1"){
                return 'https://beta.bookingzone.fr';
                
            }else{// prod 2 / 3
                return 'https://bookingzone.fr'; 
                
            }
        }
    }
    
 

    constructor(props){
        super(props); 
        this._isMounted = false;
    }
  
    state = {     
        user: {
            id: -1,
            username: "",
            authenticated: false,
            email: "",
            password: "",
            bookings: []
          }, // visiteur quelconque
        dbUser: {
            id: -1,
            username: "",
            is_confirmed: false,
            mail: "",
            password: "",
            bookings: [],
            status: null,
            phone:"",
            name:""
        },
        isAuth: -1, // 0 notAuth - 1 mailToSend - 2 isAuth - 3 isLogedOut - 4 Err serv - 5 Admin
        isEmailSent: false,
        message: "",
        isDone: false, 
        goTo : false,
        goToAc: false,
        anUpdateIsDone: false,
        updateError: "",
        captchaIsOk: null, // init à null pour renvoyer une demande si échec
        selectedDate: null,
        selectedValue:'',
        choiceIsOk: false,
        tsIsOk: false,
        quantityError: "",
        isDateClicked: false,
        serverStatus: undefined, // - 0 : local - 1: dev - 2 : prod
        stripeCltKey: "",
        captchaRes: false,
        captchaMessage: "",
        isBookingSubmitted: false,
        ageError: "",
        levelError: "",
        isFullWeek: false,
        endOfCaptchaQuery: false,
        showSlide: false,
        
        options : {
            AccessControlAllowOrigin : "",
            xsrfCookieName: 'XSRF-TOKEN',
            xsrfHeaderName: 'X-XSRF-TOKEN',
            SameSite : 'None',
            Secure : 'true'            
        }
    }
    
   
   
    
    async componentDidMount(){
        this._isMounted = true;
        
        if (this._isMounted){
           
            const serverStatusRes = await this.getServerStatus();         
            if (serverStatusRes !== undefined){                            
                this.setState({serverStatus : serverStatusRes.serverStatus}); 
                const accessCtrl = await this.handleAccesControl(serverStatusRes.serverStatus);
                // accessCtrlAllowOrigin by server type         
                if (accessCtrl !== undefined){
                    console.log ("options not undefined : " + accessCtrl);
                    const options = this.state.options;
                    this.setState({options : {...options , AccessControlAllowOrigin : accessCtrl}})                
                }
            }
            // set clientApiKeys
            
            const stripeCltApiRes = this.getClientApiKey("stripe");
            if ( stripeCltApiRes !== undefined){
                if (stripeCltApiRes.error){
                    this.setState({message: stripeCltApiRes.error});
                }else{
                    this.setState({stripeCltKey: stripeCltApiRes.clientApiKey})
                }
            }
        
        }
    }

    componentDidUpdate(prevState){
     
    }

    componentWillUnmount(){ // démontage
       this.setState({updateError: ""});
       this._isMounted = false;
    }
    setShowSlide=(pShowSlide)=>{
        this.setState({showSlide: pShowSlide});
    }
    setIsFullWeek=(pIsFullWeek)=>{
        this.setState({isFullWeek: pIsFullWeek});
    }
    setEndOfCaptchaQuery=()=>{
        this.setState({endOfCaptchaQuery: true});
    }

    setSelectedDate=(date)=>{
        this.setState({selectedDate: date});
    }
    setTsIsOk=(pTimeStamp)=>{
        this.setState({tsIsOk: pTimeStamp});
    }
    
    setIsDateClicked=(pIsDateClicked)=>{
        this.setState({isDateClicked: pIsDateClicked});
    }

    setSelectedValue=(pValue)=>{
        this.setState({selectedValue: pValue});
    }
    setAgeError=(pError)=>{
        this.setState({ageError: pError});
    }
    setLevelError=(pError)=>{
        this.setState({levelError: pError});
    }

    setChoiceIsOk=(pChoiceIsOk)=>{
        this.setState({choiceIsOk: pChoiceIsOk});
    }
    setServerStatus = (pServerStatus)=>{
        this.setState({serverStatus: pServerStatus});
    }
    setIsBookingSubmitted = (pSubmitted)=>{
        this.setState({isBookingSubmitted: pSubmitted});
    }
    getLocalStorage = () =>{
        // au reload de la page
        /*gets localStorage when refresh page*/    
          const storedIsAuth = localStorage.getItem('isAuthState');
          const storedEmail = localStorage.getItem('mailStorage');
          const storedPassword = localStorage.getItem('passwordStorage');
          if (storedIsAuth !== undefined && storedEmail !== undefined && storedPassword !== undefined){
              console.log("storedIsAuth : " , storedIsAuth);
              console.log("storedEmail : " , storedEmail);
              console.log("storedPassword : " , storedPassword);
              console.log("au reload, isAuth  : " , this.state.isAuth);
              this.setIsAuth(storedIsAuth);
              this.setState({...this.state.user, mail: storedEmail, password: storedPassword});
              return storedIsAuth;

          }
    }
    setLocalStorage(pIsAuth, pEmail, pPassword){

        localStorage.setItem('isAuthState', pIsAuth);
        localStorage.setItem('mailStorage', pEmail);
        localStorage.setItem('passwordStorage', pPassword);

        console.log ("localStorage : " , localStorage.getItem('isAuthState'));
    }
     

    getServerStatus = async () =>{         
        const CancelToken = axios.CancelToken;  
        let cancel;
        
        try {                
            // envoi du nouvel utilisateur en db
            const resp = this._isMounted && await axios.post(`${SERVER}/getserverstatus`                    
            ,this.STATUSOPTION,
            {cancelToken: new CancelToken(function executor(cancelParam) {
                // An executor function receives a cancel function as a parameter
                cancel = cancelParam;
            })}, {timeout: 3000}
            );
            if (resp !== undefined){                        
                //console.log("dans le else, server response : " + resp.data);           
                if (resp.data !== undefined)
                {                    
                        //console.log("retour serveurstatus ok " + resp.data.serverStatus);
                        cancel(); // désabonnement
                        return ({serverStatus : resp.data.serverStatus}); // 0 : local 1: dev 2/3 : prod
                }
            }else{
                this.setState({message: "...Chargement en cours"});               
                return ({error: "...Chargement en cours"});
            }         
            
            /*GESTION DES ERREURS*/
        }catch(error){         
            cancel(); // Désabonnement
            return ({error: "Un problème de communication avec le serveur est survenu, merci de réessayer ultérieurement"});               
        }
        
        /**
        * FIN POST captcha
        */ 
    }
        
 

   getClientApiKey = async (api)=>{
   
    const options = this.state.options;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    if (options !== undefined) {// on a chargé le type de serveur
        console.log( "les options : " , options);
        
        if (api === undefined || api === null || api === ""){
            console.log("Prob api string");           
            return ({error: "Problème avec la génération de la clé cliente"});
        } else {            
            try {                
                
                const resp = this._isMounted && await axios.post(`${SERVER}/getclientkey`, 
                {                 
                    api : api,
                }
                , options ,
                {
                    cancelToken: source.token
                }, 
                {timeout: 3000}
                )
                if (resp !== undefined){                          
                    if (resp.data !== undefined)
                    {
                        
                        if (resp.data.error){
                            console.log("erreur apiclientstring : " + resp.data.error); 
                            source.cancel();
                            return ({error: resp.data.error});
                        }else{ // resp.data.success (false / true)                            
                            source.cancel(); // désabonnement
                            return ({clientApiKey : resp.data.clientApiKey});
                        }
                    }else{                    
                        source.cancel();
                        return ({error: "...Chargement en cours"});
                    }
                }                
                
                /*GESTION DES ERREURS*/
            }catch (error){
                console.log ("C'est l'erreur : " + error); 
                return ({error: "Un problème de communication avec le serveur est survenu, merci de réessayer ultérieurement"});               
            }
            
                /**
                * FIN POST getApiClientKey
                */           
        }
    }
}


    /**
     * 
     * @param {*} myToken 
     * @returns isCaptchaOk
     * @returns error
     */

    getCaptchaResponse = async (myToken)=>{
        console.log("on rentre dans getCaptcharesponse");
        const options = this.state.options;
        console.log ("les options dans getCaptchaResponse : " + options);
        const CancelToken = axios.CancelToken;  
        let cancel;
        if (myToken === undefined || myToken === null || myToken === ""){
            console.log("Prob token");           
            return ({message: "Problème avec la génération du token"});
        } else {         
            try {                
                // envoi du nouvel utilisateur en db
                const resp = this._isMounted && await axios.post(`${SERVER}/captchaverify`, 
                {                 
                    clientresponse : myToken,
                }
                ,options,
                {cancelToken: new CancelToken(function executor(cancelParam) {
                 // An executor function receives a cancel function as a parameter
                 cancel = cancelParam;
                 })}, {timeout: 3000}
                 );
                if (resp !== undefined){
                    let captchaIsOk = this.state.captchaIsOk;                                
                    if (resp.data !== undefined)
                    {
                        if (resp.data.error){
                            console.log("erreur captcha : " + resp.data.error);
                            captchaIsOk = false;
                            this.setState({captchaIsOk});
                            this.setState({message: resp.data.error});
                            cancel();
                            return ({error: resp.data.error});
                        }else{ // resp.data.success (false / true)                            
                            captchaIsOk = resp.data.success;
                            this.setState({captchaIsOk});                            
                            cancel(); // désabonnement
                            return ({isCaptchaOk : captchaIsOk});
                        }
                    }else{
                        this.setState({message: "...Chargement en cours"});
                        cancel();
                        return ({error: "...Chargement en cours"});
                    }
                }                
                
                /*GESTION DES ERREURS*/
            }catch(error){         
                cancel(); // Désabonnement
                return ({error: "Un problème de communication avec le serveur est survenu, merci de réessayer ultérieurement"});               
            }
            
                /**
                * FIN POST captcha
                */           
        }
    }

    setCaptchaRes = (pRes) =>{
        this.setState({captchaRes: pRes});
    }
    setCaptchaMessage = (pMessage)=>{
        this.setState({captchaMessage: pMessage});
    }

        /**
         * @returns user
         * @param {*} dbUser 
         */
    convertDbUserToUser(dbUser){
        const user = {
            username: dbUser.username,
            authenticated: true, // on le connecte tout de suite à son compte
            email: dbUser.mail,
            password: dbUser.password, 
            bookings: dbUser.bookings ? dbUser.bookings:[]
        }
        return user
    }

  
    /*
    récupération des données saisies par l'utilisateur, comparaison et utilisation*/
    signIn = async (user) => {
       
        const CancelToken = axios.CancelToken;           
        if (user === undefined) {            
            return ({error: "... chargement en cours"});
        }
        else{
            let cancel;
            /** Load User et bookings de la db
            * GET PARAMS AXIOS
            */
            try {            
                const res = this._isMounted && await axios.get(`${USER}`,                
                {params: {mail : user.email, password : user.password}},
                {cancelToken: new CancelToken(function executor(cancelParam) {
                    // An executor function receives a cancel function as a parameter
                    cancel = cancelParam;
                })
                },
                {timeout : 3000}
                )
                if (res !== undefined){
                    console.log("réponse user");
                    if (res.data.error){
                        console.log("réponse user error : " );
                        console.log("erreru ", JSON.stringify(res.data.error));
                        this.setState({isAuth: 0}); // user non authentifié                        
                        this.setState({message: JSON.stringify(res.data.error)});                        
                        cancel(); // désabonnement
                        return ({error: JSON.stringify(res.data.error)});
                       
                    }else{
                    const dbUser = res.data.user;      
                    console.log("réponse user ok : " , res.data.user);              
                    // si l'utilisateur est retourné par la db, on le garde en state
                    // s'il n'est pas trouvé, user reste en authenticated : false                                    
                  
                    // si le retour est un utilisateur valide                    
                        const authUser = {
                            id: dbUser.id,
                            username: dbUser.username,
                            authenticated: true,
                            email: dbUser.mail,
                            password: dbUser.password,
                            bookings: dbUser.bookings,
                            status: dbUser.status
                        }
                        this.setState({user:authUser});
                        this.setState({dbUser});
                        if (dbUser.status === 99){
                            this.setState({isAuth:5});
                            cancel(); // désabonnement
                        }else{
                            if(dbUser.is_confirmed === true){
                                cancel(); // désabonnement
                                console.log("avant setloc storage")
                                this.setLocalStorage(2, dbUser.mail, dbUser.password);
                                this.setState({isAuth:2});
                                                               
                               
                            }else{
                                cancel(); // désabonnement
                                console.log("avant setloc storage 1")
                                this.setLocalStorage(1, dbUser.mail, dbUser.password);
                                this.setState({isEmailSent: false})          
                                this.setState({isAuth: 1}); 
                            }
                        }
                        //cancel(); // désabonnement
                        return ({user:authUser});
                    }   
                }else{ 
                        //cancel();
                        return ({error: "...Chargement en cours"});
                    }
        
            } catch (error) {
                console.log("erreur dans la récupération de l'utilisateur");
                this.setState({isAuth: 4});
                cancel(); // désabonnement
                this.setState({message: "Un problème de communication avec le serveur est survenu, merci de réessayer ultérieurement"});
                return ({error: "Un problème de communication avec le serveur est survenu, merci de réessayer ultérieurement"});
            }
        }        
    }
    reloadUserAndBookings = async email => {
        const CancelToken = axios.CancelToken;           
        if( email === undefined || email === "") {
            console.log("mail non valide");
            return({message: "Email non trouvé, merci de vous reconnecter"});
        }
        else{
            let cancel;
            /** Load User et bookings de la db
            * GET PARAMS AXIOS
            */
            try {                        
                const reloadedUser = await axios.get(`${SERVER}/userreload`,                
                {params: {mail : email}},
                {cancelToken: new CancelToken(function executor(cancelParam) {
                    // An executor function receives a cancel function as a parameter
                    cancel = cancelParam;
                })}, {timeout: 3000}
            )
            if (reloadedUser !== undefined){            
                if (reloadedUser.data.error){
                    console.log("on rentre dans le 404, utilisateur non trouvé");
                    cancel(); // désabonnement
                    this.setState({error: reloadedUser.data.error});
                    return ({message: reloadedUser.data.error});                  
                    
                }else{ // on a un user
                    
                    const dbUser = reloadedUser.data.user;
                    // si l'utilisateur est retourné par la db, on le garde en state
                    // s'il n'est pas trouvé, user reste en authenticated : false                                    
                  
                    // si le retour est un utilisateur valide                    
                        const authUser = {
                            id: dbUser.id,
                            username: dbUser.username,
                            authenticated: true,
                            email: dbUser.mail,
                            password: dbUser.password,
                            bookings: dbUser.bookings,
                            status: dbUser.status
                        }
                        this.setState({user:authUser});
                        this.setState({dbUser});
                        if (dbUser.status === 99){
                            this.setState({isAuth:5});
                            cancel();
                        }else{
                            if(dbUser.is_confirmed === true){
                                this.setState({isAuth:2});
                                cancel();
                            }else{
                            this.setState({isAuth: 1});
                            this.setState({isEmailSent: false});                            
                            cancel();
                            }
                        }
                        cancel(); // désabonnement
                        return ({user:authUser});

                }                
                
            }else{ // undefined
                //cancel();
                this.setState({error: "... Mise à jour en cours"})
                return ({message: "... Mise à jour en cours"})
            }
        } catch (error) {
            console.log("erreur dans la récupération des réservations"); 
            this.setState({error: "Un problème de communication est survenu, merci de réessayer ultérieurement"});          
            cancel(); // désabonnement   
            return ({message: "Un problème de communication est survenu, merci de réessayer ultérieurement"});            
        }         
    }
    }
   
   
    
/**
 * 
 * @param {*} user 
 * @returns message string
 * @returns user obj
 */

    /*Méthode de création d'un utilisateur, envoi du mail de validation*/
    signUp = async (user) => {   
        const CancelToken = axios.CancelToken;
        if ( user === undefined) {
            return;
        }
        else
        {
        const newUser = {
            username: user.username,
            authenticated: false, // pas encore connecté
            email: user.email,
            password: user.password,
            bookings: user.bookings
        }  
        this.setState({user: newUser});  
          /***           
           * APPEL POST AXIOS
           * @param username
           * @param mail
           * @param password
           * @param is_confirmed // false by default       
           */
           const options = this.state.options;
          
           this.setState({isDone: false});
           let cancel;
           
            try {
            // envoi du nouvel utilisateur en db
            const resp = this._isMounted && await axios.post(`${USER}`, 
            { 
                username: user.username,
                mail: user.email,
                password: user.password,
                is_confirmed: false                
            }
            , options,
            {cancelToken: new CancelToken(function executor(cancelParam) {
                // An executor function receives a cancel function as a parameter
                cancel = cancelParam;
                })},
                {timeout : 3000}
                )
            if (resp !== undefined){                                
                        if (resp.data.canCreateUser === "false"){
                            console.log("dans le else de signUp: " + resp.data.canCreateUser);
                            //this.setState({message: "Cet email est déjà utilisé"});
                            this.setState({isDone: true});
                            cancel(); // désabonnement
                            return ({message: "Cet email est déjà utilisé"});
                            
                        }else if (resp.data.error){
                            
                            console.log("erreur createuser du context");
                            this.setState({isDone: true});
                            cancel(); // désabonnement
                            return ({message: resp.data.error});
                        }else{                        
                            const userData = resp.data.user;                            
                            this.setState({dbUser: userData});                            
                            cancel();
                            this.setState({goToAc:true});
                            this.setState({isDone: true});
                            return ({user: userData});                
                        }
                    
                    
            /**
             * FIN POST createUser
             */  
            
                }else{                    
                    //this.setState({message: "...enregistrement en cours"})
                    return ({message: "...enregistrement en cours"})
                }
         
        }catch (error){
            console.log("Erreur lors de la création de votre compte, veuillez réessayer ultérieurement " + error);
            //this.setState({message: "Erreur lors de la création de votre compte, veuillez réessayer ultérieurement :" + error});
            this.setState({isDone: true});
            cancel();
            return({message: "Un problème de communication avec le serveur est survenu, merci de réessayer ultérieurement"});
        }
   
    }
}

/**
 * 
 * @param {asyn function} 
 * serveru indisponible 
 */
    handleTimeOut = (asyncFunc)=>{
        setTimeout (()=>{
            const resp = asyncFunc;
            console.log("on passe dans les 2sec");
            if(!resp || resp === undefined){
                console.log("on passe dans le prob de connexion");
            }else{
                console.log("réponse handletO");
            }   
            
        }, 2000);
        
    }
     /**
      * 
      * @param {*} user 
      */
      /*Méthode de modification d'un utilisateur, modifie le password*/
    updateUser = async (user) => {  
        if (window.navigator.onLine){
            this.setState({updateError: ""}); // reset message d'erreur
            const CancelToken = axios.CancelToken;            
            /***           
            * APPEL PUT AXIOS
            * @param mail 
            */
            const options = this.state.options;            
            let cancel;        
           
            // modification du user en db
            try { 
                
                const response = await axios.put(`${USER}/?mail=${user.email}`,
                { 
                    password: user.password,
                }
                , options,
                {cancelToken: new CancelToken(function executor(cancelParam) {
                    // An executor function receives a cancel function as a parameter
                    cancel = cancelParam;
                })}, {timeout : 3000}
                )
                
                if (response !== undefined){
                    console.log("on passe dans le then du put contexte");               
                
                    /* si on a une réponse et qu'elle ne contient pas d'erreur*/
                    if (response.data !== undefined)
                    {
                        if (response.data.error){
                            console.log("erreur update pwd");
                            this.setState({updateError: response.data.error});
                            cancel();
                            return({error: response.data.error });
                        }else{
                            console.log(" update ok, on envoie les data");
                            const userData = response.data.user;
                            this.setState({dbUser: userData}); // récup le user pour identification
                            const user = this.convertDbUserToUser(userData);
                            console.log("user update : ", user);
                            this.setState({user: user});
                            this.setState({goTo: true});                            
                            cancel();
                            return ({user : user});
                        }
                    }else{
                        console.log ("loading...");
                        cancel();
                        this.setState({updateError: "...mise à jour de vos donnée en cours"});                     
                        return ({error: "...mise à jour de vos donnée en cours"});
                    }
                    
                    /*GESTION DES ERREURS*/
                }
                /**
                * FIN PUT updateUser
                */  
            } catch (error) {
                cancel();
                console.log("dans catch user update");                
                this.setState({updateError: "Un problème de communication avec le serveur est survenu, merci réessayer ultérieurement"});
                return ({error: "Un problème de communication avec le serveur est survenu, merci réessayer ultérieurement"});
            }
        }else{
            
            this.setState({updateError: "Oups, il semblerait que vous ayez perdu votre connexion internet"});
            return ({error: "Oups, il semblerait que vous ayez perdu votre connexion internet"});
        }
    }

    /*Méthode de modification d'un utilisateur, modifie le nom et le tel*/
    updateUserFromStripe = async (user) => {
        const CancelToken = axios.CancelToken;        
        /***           
         * APPEL PUT AXIOS 
         * @param mail 
         */
        const options = this.state.options;       
        let cancel;
        // envoi du nouvel utilisateur en db
        if (window.navigator.onLine){
        try { 
            const resp = this._isMounted && await axios.put(`${USER}/?mail=${user.mail}`,
                {        
                name: user.name,
                phone: user.phone
                }
                , options,
                {cancelToken: new CancelToken(function executor(cancelParam) {
                    // An executor function receives a cancel function as a parameter
                    cancel = cancelParam;
                })},{timeout : 3000}
                )        
                                  
                    if (resp.data !== undefined)
                    {
                                        
                        if (resp.data.error){
                            cancel();
                            return ({error : "La mise à jour des coordonnées a échoué"});
                        }else{                            
                            const userData = resp.data.user; 
                            this.setState({dbUser: userData}); // récup le user pour identification
                            const user = this.convertDbUserToUser(userData);
                            this.setState({user});
                            this.setState({goTo: true});                            
                            cancel(); 
                            return ({user:userData});
                        }    
                    }
            } catch (error) {
                cancel();
                return ({error: "Un problème de communication avec le serveur est survenu, merci de réessayer ultérieurement"});
            }
        }else{
            return ({error : "Oups, il semblerait que vous ayez perdu votre connexion internet"});
        }
    
    }
  

 /**Envoie le mail de validation d'adresse
    * @param mail
    */     
    
    sendEmail = async (mailUserData) =>{
        const CancelToken = axios.CancelToken;
        this.setState({isEmailSent: false}); // reset l'écouteur d'envoi        
        let cancel;
        try {
            const res =  this._isMounted && await axios.get(`${SERVER}/emailsend`,
            {params: {mail : mailUserData}},
            {cancelToken: new CancelToken(function executor(cancelParam) {
                // An executor function receives a cancel function as a parameter
                cancel = cancelParam;
            })
        }, {timeout:3000}
        )
        if (res  !== undefined) {            
            this.setState({isEmailSent: true});
            cancel();
            return res.data; 
        };
    } catch (error) {
        cancel();
        console.log("le serveur ne répond pas : " + error);
        return "Un problème de communication avec le serveur est survenu, merci de réessayer ultérieurement"
    }
}

    updateUserFromFront = (updatedUser) => {        
        this.setState({user:updatedUser})
        return updatedUser
    }
    updateIsAuth=(pIsAuth)=>{
        this.setState({isAuth:pIsAuth})
        return pIsAuth
    }

    logout = async() =>{
        const loggedOutUser = {
            username: "",
            email: "",
            password: "",
            authenticated: false,
            bookings: []
        }        
        this.setState({user:loggedOutUser});
        this.setState({isAuth: 3});     
        /*close db connect if not yet*/
        let cancel;
        try {            
            const res = this._isMounted && await axios.get(`${LOGOUT}`, 
            {cancelToken: new CancelToken(function executor(cancelParam) {
                // An executor function receives a cancel function as a parameter
                cancel = cancelParam;
            })
            },
            {timeout : 3000}
            )
            if (res !== undefined){
                if (res.data.message){
                    console.log(res.data.message)
                }
                cancel();
            }
        }catch(error){
            console.log("erreur dans la déconnexion de l'utilisateur");
        }
        return loggedOutUser;
    }
    
    resetGos = () => {
        this.setState({
            goTo: false,
            goToAc: false
        })
    }
    resetErrors = () =>{
        this.setState({updateError: ""});
    }
    setIsAuth = (pIsAuth) =>{
        this.setState({isAuth: pIsAuth});
    }
    setQuantityError = (pError)=>{
        this.setState({quantityError: pError});
    }
 

    render(){
        return(

            <UserContext.Provider value = 
            {{  
                    ...this.state,
                    signUp: this.signUp,
                    signIn: this.signIn,    
                    logout: this.logout,
                    updateUser: this.updateUser,
                    updateUserFromStripe: this.updateUserFromStripe,
                    sendEmail: this.sendEmail,
                    updateIsAuth: this.updateIsAuth,
                    updateUserFromFront: this.updateUserFromFront,                    
                    resetGos: this.resetGos,                   
                    resetErrors: this.resetErrors,
                    getCaptchaResponse: this.getCaptchaResponse,
                    handleTimeOut: this.handleTimeOut,
                    setIsAuth: this.setIsAuth,
                    getLocalStorage: this.getLocalStorage,
                    reloadUserAndBookings: this.reloadUserAndBookings,
                    setSelectedDate: this.setSelectedDate,
                    setSelectedValue: this.setSelectedValue,
                    setChoiceIsOk: this.setChoiceIsOk,
                    setTsIsOk: this.setTsIsOk,
                    setQuantityError: this.setQuantityError,
                    setIsDateClicked: this.setIsDateClicked,
                    getClientApiKey : this.getClientApiKey,
                    getServerStatus : this.getServerStatus,
                    setServerStatus: this.setServerStatus,
                    handleAccesControl: this.handleAccesControl,
                    setCaptchaRes: this.setCaptchaRes,
                    setCaptchaMessage: this.setCaptchaMessage,
                    setIsBookingSubmitted: this.setIsBookingSubmitted,
                    setAgeError: this.setAgeError,
                    setLevelError: this.setLevelError,
                    setIsFullWeek: this.setIsFullWeek,
                    setEndOfCaptchaQuery: this.setEndOfCaptchaQuery,
                    setShowSlide: this.setShowSlide
                    
                
            
            }}>
            {this.props.children}
            </UserContext.Provider>
        
        )
    }
}   

/**
 * 
 * @param {HOC} Component 
 */

const UserConsumer = UserContext.Consumer
export function withUser(Component){
    return function ConsumerWrapper(props){
        return <UserConsumer>
        {value => <Component {...props} context={value} />}
        </UserConsumer>
    }
}

export {UserProvider, UserContext, UserConsumer}

