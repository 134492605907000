import React from "react";

const CgvDetails=(props)=> {
return (
    <>
    {props.titleH3 && <h3>{props.titleH3}</h3>}
    <div className="cgv-details">
        <div className="first-cgv">
            {props.titleDetail &&         
                <span className="cgv-span-details">
                    {props.titleDetail}
                </span>        
            }
            {props.spanDetail &&
            <span>
            {props.spanDetail}
            </span>}
        </div>
        {props.spanDetail02 &&
        <p>
            <span>
            {props.spanDetail02}
            </span>
        </p>
        }
    {props.lstDetails02 && props.lstDetails02}
        {props.spanDetail03 &&
        <p>
            <span>
            {props.spanDetail03}
            </span>
        </p>        
        }
        {props.spanDetail04 &&
        <p>
            <span>
            {props.spanDetail04}
            </span>
        </p>
        }
    </div>
    </>

)
}
export default CgvDetails