


import React from 'react';

export const AtelierLogo = (props)=>(

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width={props.width} height={props.height} viewBox="0 0 250 250" enableBackground="new 0 0 250 250" xmlSpace="preserve">
<g id="cerceau">
	<g>
		<g>
			<path fill="#E01A35" d="M149.895,115.972c0.301-2.986,0.463-5.986,0.423-8.983c-0.045-2.995-0.281-5.994-0.887-8.92l-0.003-0.018
				v-0.02l-0.004-4.437c0.003-1.479,0.037-2.956,0.003-4.426c-0.06-2.938-0.482-5.862-0.896-8.785l-0.021-0.156l0.123-0.098
				c2.304-1.83,4.037-4.287,5.473-6.865c1.447-2.587,2.581-5.34,3.908-8.033c0.688-1.33,1.374-2.705,2.538-3.807
				c1.175-0.934,2.174-1.996,3.215-3.069c2.051-2.155,4.046-4.372,6.042-6.585l5.995-6.646c2.02-2.201,4.017-4.414,6.198-6.498
				c1.128-1.054,2.32-1.914,3.448-2.885c1.146-0.942,2.212-1.947,3.299-2.981l3.29-3.046c1.105-1.011,2.315-1.971,3.619-2.748
				l-0.147,0.157c0.029-0.05,0.029-0.129,0.04-0.024c0.055,0.116,0.093,0.09,0.021,0.077c-0.125-0.029-0.352-0.022-0.56,0.002
				c-0.43,0.049-0.878,0.151-1.32,0.264c-0.887,0.23-1.776,0.518-2.643,0.827l-1.086,0.388l0.621-0.961
				c1.016-1.569,2.097-3.075,3.25-4.538c1.16-1.455,2.385-2.87,3.802-4.117l-0.094,0.131c0.187-0.451,0.376-0.904,0.532-1.351
				c0.074-0.213,0.151-0.466,0.156-0.605c0.008-0.035-0.021-0.031,0.037,0.05c0.085,0.094,0.178,0.076,0.15,0.078
				c-0.027,0.001-0.14,0.045-0.23,0.095c-0.788,0.455-1.603,1.081-2.409,1.624l-4.846,3.365l0.037-0.03l-2.131,2.015l-1.066,1.007
				c-0.333,0.299-0.663,0.702-0.995,1.047l-3.98,4.31l-0.015,0.016l-0.017,0.013l-16.624,13.345
				c-2.747,2.244-5.567,4.437-8.225,6.755c-2.486,2.499-4.514,5.452-6.608,8.311c-2.1,2.86-4.134,5.806-6.672,8.332
				c-2.498,2.535-5.073,4.985-7.667,7.417l-3.92,3.616l-2.001,1.786c-0.388,0.325-0.906,0.454-1.373,0.477
				c-0.475,0.022-0.938-0.037-1.385-0.13c-1.788-0.399-3.408-1.201-4.985-2.038c-1.572-0.854-3.086-1.793-4.562-2.813l0.404-0.344
				c0.509,1.096,0.918,2.223,1.079,3.447c0.069,0.606,0.076,1.261-0.197,1.889c-0.137,0.31-0.366,0.599-0.657,0.794
				c-0.286,0.197-0.611,0.294-0.954,0.339c-1.3,0.114-2.456-0.293-3.581-0.676c-1.123-0.408-2.204-0.897-3.271-1.407
				c-1.08-0.509-2.084-1.059-3.192-1.526c-1.072-0.582-2.038-1.236-3.028-1.885c-1.964-1.309-3.897-2.656-5.808-4.041
				c-0.957-0.719-1.898-1.341-2.833-2.222c-0.813-0.918-1.507-1.846-2.234-2.783c-1.433-1.874-2.835-3.765-4.227-5.666
				c-2.805-3.788-5.438-7.709-7.999-11.664l-0.075-0.115l0.002-0.131c0.024-1.547,0.167-3.059,0.442-4.574
				c0.291-1.504,0.686-3.026,1.536-4.406l-0.071,0.278c-0.025-0.598-0.223-1.266-0.505-1.367c-0.257-0.148-0.878,0.154-1.31,0.552
				c-0.902,0.811-1.647,1.964-1.91,3.118l-0.188,0.824l-0.594-0.586c-0.922-0.911-1.714-1.939-2.384-3.04
				c-0.688-1.111-1.143-2.299-1.68-3.418l-3.134-6.831l0.239,0.236c-0.425-0.179-0.909-0.347-1.322-0.407
				c-0.101-0.014-0.198-0.017-0.258-0.009c-0.071,0.008-0.027,0.028,0.034-0.063c0.019-0.211,0.048,0.199,0.116,0.402
				c0.233,0.972,0.497,1.954,0.756,2.931l1.599,5.863l0.734,2.691l-1.59-2.292c-1.252-1.804-2.517-3.604-3.801-5.384
				c-0.644-0.888-1.291-1.776-1.957-2.636l-0.505-0.628c-0.084-0.1-0.171-0.2-0.251-0.283c-0.032-0.035-0.094-0.087-0.074-0.073
				c-0.079-0.053-0.075-0.047-0.096-0.056c-0.096-0.037-0.16-0.026-0.262,0.055c-0.099,0.082-0.182,0.248-0.214,0.437
				c-0.065,0.398,0.05,0.819,0.298,1.292c0.242,0.515,0.458,1.018,0.676,1.527l1.276,3.051l2.488,6.119l0.691,1.702L69.5,43.768
				l-3.24-2.518c-1.084-0.825-2.178-1.669-3.278-2.423l0.523,0.018c0.045-0.011,0.089-0.129,0.076-0.095
				c0.002,0.023,0.006,0.097,0.041,0.186c0.058,0.175,0.165,0.379,0.281,0.575c0.236,0.397,0.525,0.791,0.823,1.175
				c0.601,0.769,1.253,1.516,1.916,2.252c1.331,1.471,2.724,2.897,4.129,4.311l4.262,4.207c0.713,0.702,1.428,1.396,2.131,2.129
				c0.776,0.789,1.202,1.722,1.705,2.586c1.906,3.534,3.65,7.122,5.53,10.642c1.922,3.498,3.675,7.102,6.726,9.708
				c2.866,2.769,5.84,5.441,8.773,8.156c1.468,1.356,2.933,2.717,4.374,4.106c1.43,1.404,2.886,2.771,4.14,4.384
				c0.606,0.873,0.955,1.793,1.392,2.701l1.204,2.75c0.783,1.841,1.535,3.694,2.258,5.558c0.722,1.865,1.416,3.741,2.051,5.638
				c0.607,1.902,1.273,3.805,1.483,5.814l0.001,0.01l-0.003,0.01c-0.144,0.524-0.313,1.038-0.485,1.55l-0.095-0.031
				c0.164-0.514,0.325-1.03,0.459-1.55l-0.002,0.02c-0.234-1.971-0.926-3.87-1.56-5.754c-0.662-1.883-1.382-3.745-2.13-5.596
				c-0.75-1.851-1.528-3.689-2.336-5.514l-1.24-2.723c-0.44-0.882-0.829-1.842-1.374-2.606c-1.213-1.536-2.689-2.909-4.12-4.29
				c-1.447-1.373-2.92-2.72-4.398-4.063c-2.953-2.69-5.946-5.336-8.85-8.098c-0.724-0.696-1.439-1.392-2.127-2.151
				c-0.687-0.766-1.251-1.616-1.795-2.461c-1.079-1.701-2.052-3.453-3.009-5.212c-1.914-3.514-3.694-7.103-5.608-10.588
				c-0.5-0.852-0.944-1.764-1.594-2.394c-0.686-0.704-1.41-1.396-2.126-2.09l-4.302-4.18c-1.423-1.409-2.835-2.831-4.196-4.313
				c-0.681-0.742-1.349-1.497-1.975-2.304c-0.313-0.406-0.618-0.818-0.892-1.28c-0.136-0.234-0.268-0.473-0.369-0.772
				c-0.05-0.146-0.094-0.322-0.089-0.547c-0.014-0.215,0.154-0.588,0.424-0.72l0.291-0.142l0.233,0.16
				c1.159,0.796,2.234,1.63,3.33,2.465l3.248,2.53l-0.764,0.571l-2.478-6.114l-1.268-3.04c-0.215-0.502-0.433-1.011-0.658-1.491
				c-0.257-0.493-0.502-1.179-0.376-1.879c0.063-0.349,0.213-0.718,0.532-1c0.302-0.292,0.861-0.414,1.274-0.22
				c0.107,0.042,0.229,0.121,0.263,0.146c0.13,0.095,0.166,0.142,0.231,0.204c0.11,0.115,0.204,0.224,0.296,0.333l0.528,0.659
				c0.681,0.88,1.329,1.771,1.975,2.666c1.288,1.79,2.552,3.592,3.807,5.404l-0.856,0.4l-1.595-5.874
				c-0.26-0.982-0.521-1.959-0.76-2.959l-0.086-0.389c-0.016-0.094-0.1-0.392,0.067-0.693c0.185-0.321,0.501-0.392,0.675-0.414
				c0.192-0.022,0.35-0.008,0.5,0.011c0.59,0.091,1.07,0.27,1.579,0.479l0.162,0.067l0.077,0.169l3.126,6.834
				c0.543,1.139,0.99,2.303,1.625,3.325c0.631,1.04,1.379,2.012,2.238,2.862l-0.781,0.238c0.157-0.736,0.468-1.387,0.841-1.991
				c0.378-0.602,0.823-1.158,1.373-1.639c0.277-0.238,0.58-0.46,0.948-0.627c0.354-0.158,0.85-0.289,1.35-0.066
				c0.493,0.231,0.719,0.68,0.843,1.042c0.126,0.378,0.172,0.744,0.199,1.103l0.011,0.146l-0.082,0.131
				c-0.741,1.193-1.137,2.639-1.412,4.079c-0.266,1.447-0.408,2.943-0.432,4.407l-0.072-0.246c2.547,3.952,5.178,7.838,7.936,11.646
				c1.374,1.907,2.761,3.807,4.173,5.683c0.707,0.928,1.421,1.89,2.162,2.739c0.772,0.741,1.787,1.449,2.707,2.146
				c1.89,1.391,3.809,2.75,5.751,4.067c0.971,0.648,1.955,1.319,2.948,1.868c1.058,0.453,2.119,1.041,3.164,1.542
				c1.052,0.513,2.114,1.002,3.201,1.407c1.076,0.386,2.226,0.763,3.29,0.669c0.491-0.036,0.969-0.329,1.152-0.781
				c0.207-0.456,0.224-1.026,0.161-1.58c-0.134-1.115-0.526-2.235-1-3.271l-0.42-0.92l0.825,0.576
				c1.443,1.008,2.948,1.953,4.493,2.803c1.547,0.833,3.15,1.629,4.837,2.013c0.823,0.17,1.754,0.25,2.364-0.243l1.984-1.753
				l3.934-3.591c2.604-2.415,5.19-4.85,7.694-7.363c2.522-2.483,4.566-5.407,6.682-8.256l3.172-4.287
				c1.082-1.413,2.177-2.82,3.456-4.085c2.68-2.371,5.461-4.561,8.201-6.83l16.534-13.458l-0.03,0.029l3.951-4.336
				c0.342-0.361,0.628-0.723,1.018-1.084l1.06-1.014l2.119-2.028l0.017-0.016l0.021-0.014l4.824-3.395
				c0.822-0.558,1.562-1.148,2.473-1.684c0.126-0.063,0.238-0.131,0.454-0.171c0.09-0.018,0.324-0.026,0.487,0.169
				c0.132,0.18,0.119,0.314,0.122,0.401c-0.021,0.353-0.112,0.571-0.189,0.823c-0.166,0.482-0.354,0.938-0.54,1.396l-0.03,0.075
				l-0.063,0.057c-1.354,1.205-2.553,2.602-3.685,4.042c-1.127,1.445-2.193,2.952-3.176,4.492l-0.465-0.572
				c0.895-0.326,1.787-0.621,2.711-0.867c0.464-0.121,0.93-0.232,1.431-0.293c0.255-0.025,0.503-0.057,0.838,0.01
				c0.146,0.038,0.438,0.113,0.565,0.465c0.084,0.339-0.036,0.489-0.095,0.649l-0.032,0.088l-0.115,0.069
				c-2.504,1.5-4.538,3.722-6.736,5.729c-1.067,1.029-2.166,2.078-3.32,3.037c-1.127,0.982-2.371,1.858-3.426,2.841
				c-2.146,2.025-4.164,4.231-6.19,6.412l-6.031,6.606c-2.012,2.203-4.021,4.409-6.098,6.563c-1.038,1.061-2.102,2.169-3.271,3.085
				c-1.026,0.95-1.732,2.291-2.409,3.592c-1.325,2.648-2.485,5.418-3.97,8.032c-1.473,2.606-3.26,5.118-5.658,7.001l0.102-0.253
				c0.396,2.928,0.804,5.874,0.847,8.852c0.031,2.971-0.104,5.916-0.11,8.872l-0.003-0.038c0.564,2.968,0.754,5.987,0.751,8.994
				c-0.007,3.009-0.217,6.012-0.564,8.996L149.895,115.972z"/>
		</g>
		<g>
			<path fill="#E01A35" d="M115.062,120.795l-9.49,26.333c-1.575,4.39-3.124,8.789-4.602,13.21
				c-1.467,4.423-2.906,8.863-4.04,13.369c-0.275,1.127-0.532,2.26-0.713,3.395c-0.168,1.115-0.252,2.315-0.448,3.468
				c-0.356,2.319-0.82,4.609-1.285,6.897c-0.939,4.572-1.907,9.131-2.66,13.723c-0.75,4.59-1.308,9.213-1.447,13.848
				c-0.119,4.624,0.215,9.299,1.539,13.72l0.052,0.173l-0.095,0.15c-1.118,1.759-2.297,3.472-3.365,5.233
				c-0.524,0.881-1.041,1.779-1.368,2.707c-0.155,0.453-0.26,0.952-0.142,1.293c0.094,0.328,0.476,0.501,0.946,0.551
				c1.997,0.052,4.132,0.025,6.191,0.042l6.232,0.02l-0.31,0.271l1.117-8.182l0.005-0.037l0.013-0.032l4.403-10.898l-0.002,0.177
				l-1.622-4.371l-0.03-0.081l0.03-0.08c3.839-10.089,7.747-20.152,11.51-30.27l0.009,0.144l-2.194-4.086l-0.045-0.084l0.042-0.085
				c1.599-3.273,3.35-6.467,5.357-9.514c1.012-1.517,2.086-3.001,3.35-4.34c0.637-0.662,1.319-1.298,2.13-1.786
				c0.407-0.237,0.847-0.449,1.339-0.532c0.487-0.094,1.026-0.028,1.479,0.21l0.048,0.024l0.036,0.048
				c3.67,4.801,7.636,9.411,11.44,14.133c3.819,4.714,7.61,9.458,11.138,14.413c1.742,2.493,3.474,5.003,4.822,7.785
				c1.187,2.825,2.424,5.543,3.829,8.23c1.397,2.676,2.87,5.32,4.502,7.851c0.409,0.631,0.828,1.254,1.269,1.854
				c0.22,0.3,0.445,0.595,0.68,0.87c0.25,0.279,0.449,0.526,0.714,0.636c1.206,0.53,2.808,0.63,4.248,0.667l0.8,0.021l-0.418,0.691
				c-0.26,0.431-0.431,1.023-0.404,1.545c0.026,0.558,0.219,0.985,0.602,1.518c0.704,1.076,1.364,2.165,2.007,3.276l0.168,0.289
				l-0.229,0.254c-1.113,1.235-2.188,2.536-3.156,3.886c-0.479,0.677-0.937,1.371-1.308,2.088c-0.183,0.356-0.346,0.724-0.441,1.078
				c-0.104,0.352-0.092,0.699,0.012,0.794l-0.359-0.168c2.165,0.076,4.103,0.683,6.086,1.102c1.963,0.433,3.958,0.871,5.884,0.766
				c0.958-0.044,1.816-0.363,2.682-0.847c0.854-0.472,1.664-1.055,2.445-1.674c1.557-1.244,3.022-2.649,4.376-4.113l-0.082,0.52
				l-3.553-7.91l-0.041-0.092l-0.002-0.107c-0.018-1.323-0.077-2.684-0.297-3.959c-0.06-0.313-0.132-0.628-0.233-0.886
				c-0.037-0.117-0.142-0.265-0.123-0.245c-0.026-0.021-0.078-0.07-0.083-0.067c-0.011-0.001,0-0.002-0.029-0.014
				c-0.04-0.021-0.091-0.041-0.15-0.058c-0.506-0.144-1.188-0.064-1.944-0.146c-0.198-0.032-0.408-0.049-0.685-0.216
				c-0.132-0.076-0.286-0.235-0.354-0.422c-0.077-0.191-0.068-0.363-0.05-0.503c0.13-0.562,0.309-0.725,0.41-1.049
				c0.238-0.598,0.426-1.232,0.588-1.873c0.16-0.642,0.296-1.294,0.396-1.943l0.063-0.483l0.021-0.233l0.006-0.11l0.001-0.051
				l0.001-0.013c-0.005-0.067,0.006,0.079,0.004,0.058c-0.002-0.01-0.006-0.03-0.002,0.002c0.002,0.019,0.008,0.038,0.016,0.056
				l-0.008-0.017l-0.019-0.039l-0.051-0.098l-0.118-0.209l-0.254-0.427c-2.866-4.563-5.909-9.061-8.876-13.586l-18.041-27.03
				l-0.038-0.057l-0.013-0.063l-5.894-27.857l-0.005-0.024v-0.021l-0.124-20.465v-0.009l0.002-0.009
				c0.273-1.324,0.496-2.662,0.695-4l0.1,0.014c-0.181,1.344-0.383,2.685-0.639,4.02l0.002-0.017l0.423,20.461l-0.005-0.045
				l6.114,27.81l-0.051-0.12l18.259,26.89c3.024,4.501,6.049,8.979,8.936,13.598l0.265,0.445l0.132,0.234l0.066,0.128l0.035,0.075
				l0.019,0.042c0.016,0.037,0.028,0.076,0.038,0.115l0.014,0.063l0.006,0.089v0.02l-0.002,0.076l-0.008,0.144l-0.025,0.271
				l-0.069,0.524c-0.106,0.688-0.248,1.363-0.416,2.035c-0.172,0.672-0.368,1.339-0.632,1.999c-0.114,0.318-0.347,0.722-0.352,0.829
				c0.017,0.079-0.036-0.073-0.038-0.049c0.013,0.017,0.147,0.056,0.281,0.07c0.587,0.079,1.283-0.03,2.096,0.181
				c0.104,0.029,0.212,0.069,0.322,0.126c0.049,0.021,0.127,0.073,0.188,0.117c0.067,0.048,0.074,0.061,0.114,0.093
				c0.257,0.261,0.303,0.427,0.392,0.622c0.146,0.373,0.22,0.723,0.287,1.074c0.237,1.396,0.29,2.753,0.309,4.135l-0.043-0.199
				l3.545,7.914l0.13,0.291l-0.212,0.229c-1.403,1.516-2.88,2.928-4.496,4.219c-0.811,0.64-1.654,1.247-2.579,1.758
				c-0.452,0.25-0.967,0.493-1.464,0.658c-0.533,0.179-1.078,0.266-1.613,0.302c-2.146,0.11-4.169-0.358-6.157-0.793
				c-1.979-0.428-3.97-1.022-5.878-1.085l-0.239-0.009l-0.121-0.16c-0.445-0.594-0.298-1.198-0.195-1.637
				c0.126-0.461,0.313-0.868,0.515-1.263c0.406-0.783,0.883-1.501,1.38-2.201c1.002-1.394,2.085-2.699,3.232-3.972l-0.061,0.543
				c-0.63-1.091-1.287-2.177-1.975-3.229c-0.366-0.488-0.747-1.257-0.75-1.985c-0.02-0.75,0.165-1.435,0.56-2.091l0.382,0.713
				c-0.788-0.01-1.551-0.062-2.328-0.162c-0.778-0.114-1.543-0.234-2.346-0.616c-0.1-0.052-0.168-0.084-0.301-0.176
				c-0.103-0.076-0.186-0.15-0.264-0.226c-0.155-0.148-0.288-0.299-0.417-0.45c-0.257-0.303-0.492-0.611-0.721-0.923
				c-0.455-0.623-0.881-1.259-1.296-1.9c-1.647-2.572-3.145-5.226-4.526-7.939c-1.387-2.698-2.642-5.516-3.78-8.291
				c-1.278-2.692-2.983-5.215-4.694-7.7c-3.47-4.955-7.22-9.723-11-14.461c-3.765-4.756-7.691-9.368-11.365-14.224l0.083,0.072
				c-0.715-0.396-1.611-0.146-2.346,0.299c-0.75,0.445-1.417,1.055-2.039,1.695c-1.239,1.299-2.313,2.763-3.323,4.26
				c-2.007,3.011-3.773,6.191-5.379,9.436l-0.004-0.168l2.172,4.098l0.038,0.07l-0.028,0.073
				c-3.876,10.075-7.607,20.205-11.408,30.309l0-0.161l1.651,4.359l0.033,0.088l-0.035,0.089l-4.329,10.929l0.018-0.067
				l-1.063,8.188l-0.035,0.271l-0.275,0.001l-6.232,0.02c-2.094-0.006-4.116,0.039-6.271-0.008
				c-0.284-0.031-0.583-0.094-0.874-0.247c-0.292-0.146-0.56-0.44-0.666-0.769c-0.206-0.665-0.027-1.238,0.131-1.757
				c0.36-1.037,0.887-1.951,1.416-2.856c1.075-1.799,2.243-3.517,3.342-5.271l-0.043,0.322c-1.386-4.529-1.75-9.284-1.659-13.969
				c0.089-4.697,0.689-9.358,1.476-13.967c0.786-4.612,1.785-9.178,2.75-13.738c0.479-2.281,0.955-4.562,1.321-6.853
				c0.202-1.147,0.284-2.278,0.47-3.469c0.194-1.167,0.461-2.31,0.747-3.446c1.171-4.538,2.64-8.973,4.137-13.395
				c1.508-4.418,3.085-8.811,4.688-13.193c3.202-8.766,6.625-17.447,9.869-26.195L115.062,120.795z"/>
		</g>
		<g>
			<path fill="#E01A35" d="M112.323,60.229c0.545-2.153,1.254-4.263,2.065-6.337c0.819-2.069,1.81-4.09,3.114-5.936
				c1.293-1.836,3.01-3.481,5.17-4.399c2.177-0.892,4.699-0.749,6.798,0.147c4.156,1.824,7.622,5.208,9.17,9.445
				c1.58,4.218,1.379,8.949-0.031,13.21l0.016-0.166c0.354,2.056-0.051,4.122-0.684,6.041c-0.662,1.921-1.629,3.734-2.893,5.346
				c-0.637,0.802-1.353,1.557-2.211,2.178c-0.43,0.309-0.895,0.586-1.413,0.787c-0.143,0.047-0.239,0.089-0.421,0.138
				c-0.126,0.027-0.229,0.059-0.379,0.082c-0.288,0.054-0.547,0.077-0.816,0.087c-2.146,0.054-4.127-0.629-5.968-1.469
				c-1.84-0.863-3.558-1.965-5.092-3.28c-1.544-1.302-2.93-2.795-4.054-4.471c-1.123-1.67-2.011-3.515-2.451-5.475
				C111.781,64.211,111.775,62.15,112.323,60.229z M112.419,60.255c-0.49,1.915-0.46,3.949,0.073,5.84
				c0.543,1.891,1.506,3.635,2.699,5.179c2.418,3.07,5.581,5.527,9.079,7.182c1.748,0.793,3.646,1.403,5.49,1.338
				c1.8,0.014,3.319-1.38,4.528-2.825c1.216-1.487,2.152-3.217,2.84-5.024c0.66-1.809,1.069-3.755,0.836-5.647l-0.012-0.095
				l0.028-0.072c1.563-4.054,1.924-8.733,0.372-12.873c-0.799-2.043-2.034-3.923-3.653-5.397c-1.606-1.469-3.499-2.679-5.509-3.432
				c-2.006-0.754-4.232-0.864-6.186-0.042c-1.982,0.758-3.603,2.291-4.969,3.973C115.311,51.796,113.6,56.001,112.419,60.255z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#E01A35" d="M148.994,127.762c1.99-0.399,3.886-1.154,5.875-1.62c1.986-0.44,3.967-0.878,5.94-1.356
				c3.941-0.969,7.893-1.947,11.698-3.31c0.944-0.35,1.887-0.728,2.774-1.178l0.165-0.086c0.017-0.008,0.078-0.042,0.059-0.03
				c0.068-0.045-0.055,0.015-0.056,0.125c0.037,0.2,0.042,0.094-0.001,0.094c-0.036-0.023-0.084-0.049-0.134-0.073
				c-0.206-0.096-0.443-0.175-0.679-0.246c-0.472-0.14-0.966-0.256-1.444-0.329c-1.999-0.299-4.021-0.434-6.045-0.552
				c-4.048-0.228-8.105-0.274-12.165-0.233c-8.119,0.114-16.236,0.374-24.341,0.892c-8.112,0.491-16.214,1.002-24.304,1.719
				c-8.102,0.685-16.176,1.477-24.228,2.559c-4.023,0.544-8.048,1.1-12.041,1.812c-1.996,0.356-3.986,0.753-5.959,1.206
				c-0.986,0.23-1.974,0.479-2.932,0.759c-0.22,0.06-0.448,0.201-0.46,0.263c-0.058-0.026,0.124,0.161,0.338,0.215
				c0.42,0.124,0.979,0.193,1.459,0.279c8.019,1.18,16.154,1.617,24.271,1.834c8.119,0.247,16.245,0.257,24.372,0.307l0.227,0.001
				l0.013,0.229l0.019,0.322l0.014,0.241l-0.243,0.016c-8.959,0.563-17.933,0.953-26.917,1.024
				c-4.49,0.033-8.983-0.013-13.479-0.216c-2.24-0.104-4.492-0.248-6.737-0.473c-2.236-0.234-4.487-0.512-6.708-1.126
				c-0.543-0.157-1.114-0.356-1.648-0.617c-0.267-0.132-0.557-0.311-0.798-0.515c-0.253-0.21-0.517-0.529-0.615-0.909
				c-0.233-0.869,0.362-1.55,0.812-1.922c0.126-0.106,0.232-0.188,0.381-0.287l0.357-0.23c0.267-0.159,0.528-0.298,0.788-0.426
				c2.088-0.975,4.285-1.601,6.458-2.172c4.372-1.12,8.8-1.947,13.231-2.688c8.872-1.468,17.801-2.509,26.743-3.35
				c17.893-1.599,35.86-2.499,53.831-2.016c4.49,0.146,8.986,0.373,13.456,0.929c2.23,0.314,4.479,0.609,6.636,1.444
				c0.534,0.231,1.075,0.48,1.508,0.989c0.223,0.256,0.363,0.616,0.326,0.956c-0.02,0.215-0.059,0.336-0.146,0.518
				c-0.054,0.116-0.148,0.261-0.258,0.4c-0.848,0.919-1.932,1.336-2.975,1.777c-4.247,1.596-8.685,2.403-13.1,3.208
				c-4.429,0.757-8.877,1.345-13.325,1.941L148.994,127.762z M148.997,127.761c4.436-0.67,8.876-1.333,13.28-2.162
				c2.201-0.421,4.393-0.886,6.568-1.417c2.174-0.536,4.334-1.119,6.402-1.939c1.002-0.424,2.074-0.888,2.749-1.649
				c0.063-0.084,0.129-0.169,0.192-0.309c0.039-0.067,0.082-0.221,0.083-0.286c0.022-0.223-0.063-0.428-0.21-0.59
				c-0.297-0.355-0.804-0.614-1.294-0.813c-2.045-0.774-4.297-1.06-6.498-1.348c-4.443-0.512-8.923-0.699-13.4-0.804
				c-8.961-0.183-17.928,0.047-26.884,0.468c-8.956,0.408-17.906,1.01-26.83,1.836c-8.924,0.859-17.83,1.912-26.676,3.32
				c-4.421,0.721-8.826,1.527-13.154,2.617c-2.164,0.558-4.305,1.166-6.292,2.083c-0.892,0.416-2.054,1.169-1.857,1.771
				c0.063,0.312,0.486,0.626,0.932,0.852c0.475,0.232,0.964,0.409,1.498,0.565c2.107,0.589,4.325,0.888,6.545,1.14
				c2.218,0.241,4.444,0.403,6.685,0.526c4.468,0.24,8.951,0.324,13.434,0.329c8.965,0.006,17.933-0.299,26.888-0.817l-0.229,0.256
				l-0.017-0.322l0.24,0.229c-8.125-0.098-16.254-0.157-24.38-0.453c-8.119-0.32-16.256-0.581-24.33-1.739
				c-0.519-0.092-0.976-0.132-1.533-0.285c-0.135-0.05-0.27-0.101-0.405-0.202c-0.125-0.082-0.309-0.308-0.226-0.568
				c0.19-0.424,0.514-0.468,0.753-0.572c0.99-0.298,1.972-0.552,2.962-0.792c1.983-0.472,3.976-0.885,5.978-1.257
				c4.003-0.746,8.026-1.333,12.051-1.908c8.05-1.139,16.151-1.99,24.24-2.73c8.094-0.773,16.21-1.378,24.328-1.765
				c8.12-0.463,16.254-0.667,24.387-0.724c4.066-0.013,8.139,0.063,12.204,0.319c2.03,0.132,4.066,0.283,6.086,0.599
				c0.524,0.084,1.021,0.206,1.521,0.357c0.25,0.078,0.497,0.16,0.751,0.28c0.064,0.032,0.129,0.065,0.199,0.111
				c0.055,0.072,0.186,0.045,0.23,0.379c-0.009,0.176-0.187,0.28-0.171,0.267l-0.109,0.06l-0.173,0.089
				c-0.929,0.463-1.878,0.835-2.841,1.184c-3.854,1.373-7.837,2.238-11.796,3.15c-1.984,0.442-3.979,0.847-5.967,1.251
				c-1.978,0.425-3.895,1.147-5.901,1.514L148.997,127.761z"/>
		</g>
	</g>
</g>
</svg>
)