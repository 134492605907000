import React, { PureComponent } from 'react';
import Accordion from "../components/Accordion";
import BabyContent from './BabyContent';
import BookingList from './BookingList';
import NoBabyContent from './NoBabyContent';
import {ActivitiesLogo} from "./ActivitiesLogo";
import {ActivityLogo} from "./ActivityLogo";
import {withBooking} from "../contexts/BookingContext";
import {FaAlignJustify} from 'react-icons/fa';
import ErrorWrapper from "./ErrorWrapper";
import Badge from './Badge';
import { AtelierLogo } from './AtelierLogo';

class Cpresta extends PureComponent {
    constructor(props){
        super(props);
        this.props.context.resetGos();
        this._isMounted = false;
        console.log ("alors, recup ou pas ? ", props.location)
    }
    state = {
        validTs: [],
        prestaIsChosen : false,
        presta: ""      
        
    }

    async componentDidMount(){
        this._isMounted = true;
    }
     componentWillUnmount(){
        this._isMounted = false;
    }



    handleClick=(event)=>{
       this.setState({prestaIsChosen : true})
    }
    
    render(){
        
        const presta = [
            {
                title : "PRESTATIONS MULTIPLES",
                subtitle : "ATELIERS DE CENTRES DE LOISIRS, AQUABIKE, COURS D'ÉQUITATION, D'ESCRIME, STAGE DE PLONGÉE, KITESURF...",
                description : "Choisissez votre atelier, le nombre de personnes concernées par l'activité, leur niveau ou leur âge puis le jour et l'heure du rendez-vous",
                content: <NoBabyContent start = {1} step={0} tsToDisable={this.props.tsToDisable && this.props.tsToDisable}  validTsStage = {this.props.validTs} messageStage={this.props.tsmessage}  validTs = {this.props.validTsWithTimes} message={this.props.tswtmessage}/>,
                presta: "1",
                type: "0",
                svg: <Badge><ActivitiesLogo width="8em" height="8em"/></Badge>
            },
            {
                title : "PRESTATION UNIQUE",
                subtitle : "Si vos besoins se limitent à une seule activité, pour un minimum de clics !",
                description : <div className="svg-deco-01" ><AtelierLogo width="20em" height="20em"/></div>,
                content: <BabyContent tsToDisable = {this.props.tsToDisable} start = {0} step={1} />,
                presta: "0",
                type: "0",                
                svg: <Badge><ActivityLogo width="8em" height="8em"/></Badge>
            },            
            {
                title: "LISTE DES PROCHAINES RÉSERVATIONS",
                subtitle: <ErrorWrapper><BookingList tsToDisable={this.props.tsToDisable} validTsStage = {this.props.validTs} messageStage={this.props.tsmessage}  validTs = {this.props.validTsWithTimes} message={this.props.tswtmessage}/></ErrorWrapper>,
                type: "-1",                
                svg: <FaAlignJustify className="nav-icon-presta" ></FaAlignJustify>
            }
        ]

     return(          
            <Accordion presta={presta}  />       
        )
    }       
}





export default withBooking(Cpresta)