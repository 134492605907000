import React, { Component } from 'react';
import '../App.css';
import {StyledFormCard, ConfirmLabel, RadioGp, Label } from '../common';
import {SubmitButtonTable} from "./SubmitButtonTable";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { parseFromTimeZone } from 'date-fns-timezone';
import DatePickerInput from './DatePickerInput';
import { withBooking } from '../contexts/BookingContext';
import { getDay } from 'date-fns';
import {RbForwardRef} from "./RbForwardRef";
import {setAmountByCatAndQuantity, formatUnits} from "../data/clientUtils";


class BookingCard extends Component {
    constructor(props){ // user, category, categoryid, chosen_time_stamp, created_at
        super(props);
        this.rbForwardedRef = React.createRef();
        this._isMounted = false;
       
    }
   
    state={
        date: null,
        selectedOption: "",
        choiceIsOk: false,
        myError: "",     
        myHourTable: [],
        isSubmitted: false,
        isProcessing: false,
        isToHide: true,            
        updateMessage: "",
        formatedDateChoice: "",
        updatedBooking: null,
        freeTs: [],
        days: [],
        daysToDisable: [],
        bookingsByCat: [],
        loadingTs: true, // init à true au chargement des créneaux
        loadingBookings: true,
        errorBooking:"",
        res: null,
        isDateClicked: false
        
        
       
    }
    resetBookingIsUpdate=()=>{
        this.props.context.setUpdateState(0);
        this.setState({
            choiceIsOk : false,
            isSubmitted : false,
            isToHide: true
        })     
    }

    

    async componentDidMount (){    
    this._isMounted = true;
    
    const day = this._isMounted && this.formatToUtcDate(this.props.chosen_time_stamp);
    const date = this.state.date;
    // on set les tsByCategory ici
    const {items, categoryid} = this.props;   
    let daysToDisable = [];
    if (items !== undefined && items !== null){
        if (categoryid !== 2){        
            daysToDisable = this.props.context.setListToDisable(items, categoryid);
            console.log("category : " + categoryid +  " daysToDisableLength : " + daysToDisable.length);
            console.log("category : " + categoryid +  " itemsLength : " + items.length);
        }else{
            daysToDisable = items;
        }
    }   
    
    this.setState({daysToDisable});
        //const formatedDateChoice = this.props.context.formatToLocalDateTime(this.state.updatedChosenTs);
        const formatedDateChoice = this.handleChosenTimeStamp();
        console.log("heure formatée : " + formatedDateChoice);
        this.setState({formatedDateChoice: this.handleChosenTimeStamp()});        
    
    /**
     * Préselection de la date au créneau horaire choisi
     */
    const numDay = day !== undefined && this.convertDateToNumDay(day);
    if (day !== undefined){            
        if(numDay !== undefined && daysToDisable !== undefined && daysToDisable !== null){
            if (categoryid !== 2) {
                const inTheList = daysToDisable.find(aDay => aDay.numday === numDay);            
               
                if (inTheList !== undefined){
                    if ( inTheList.isToDisable === false){
                        this.setState({date:day});                        
                    }else{
                        this.setState({date: null});
                    }                
                    
                }
            }else{
                // ici, pour chaque créneau de stage, on cherche le créneau choisi
                this.setState({dayToSelect : day});
                // on convertit au meme format
                let dateToSelect = 
                {
                    dt_start: "",
                    dt_end: ""
                }
                this.props.items.forEach(item => {
                    dateToSelect.dt_start = this.formatToUtcDate(item.dt_start);
                    dateToSelect.dt_end = this.formatToUtcDate(item.dt_end);
                    if ( day >= dateToSelect.dt_start && day <= dateToSelect.dt_end){
                        this.setState({date: day});
                    }
                });
                this.setState({dayInTsSouldBeSel : this.props.items});
            }
            setTimeout(() =>this.setState({loadingTs: false}), 1000);
        }
    }
    if (this.props.categoryid !== undefined){
        // stages et baby, plusieurs réservations possibles pour le même créneau
        
        if (this.props.categoryid.toString() === "0" || this.props.categoryid.toString() === "2"){
            this.setState({loadingBookings: false});
        }
    const bookingsByCat = await this.getBookingsByCat(this.props.categoryid.toString());
    
    if (bookingsByCat !== undefined){
        if (bookingsByCat.error){
            //this.setState({errorBooking : bookingsByCat.error});
            this.setState({errorBooking : ""});
            console.log("on rentre dans l'erreur de récupération des bookings"+bookingsByCat.error);
        }else{
        this.setState({bookingsByCat: bookingsByCat.bookings});
        console.log("bookings ok",bookingsByCat.bookings);
        this.setState({loadingBookings: false});
    }
    }
}
}

 getBookingsByCat = async (categorieId) =>{
    
    try {
      const res = await this.props.context.getBookingsByCat(categorieId);
      if (res !== undefined){
          this.setState({res : res});
        if (res.message){
            console.log("on rentre dans l'erreur getBookingsbyCat");
            return ({error : res.message});
        }else{
            console.log("bookings récup bc : ", res.bookings);
            return ({bookings : res.bookings});         
        }
      }
      
    } catch (error) {
      return({error: " " + error});
    }

}
   
        
    componentDidUpdate(prevState, prevProps){
        const {date,selectedOption, isSubmitted} = this.state;       
        const dbBooking = this.props.context.dbBooking;
       
        if (prevState !== null && prevState !== undefined && prevState.selectedOption!== null && prevState.selectedOption !== undefined && selectedOption !== null && selectedOption !== undefined && selectedOption !== prevState.selectedOption){
            this.setState({selectedOption});            
        }
        if(this.props.context.updateState === 2){ 
           setTimeout(() =>this.resetBookingIsUpdate(), 2000);
            
        }
     
    }

  componentWillUnmount(){
      this._isMounted = false;
  }
      

    

 
   
  

 formatDate(date){        
    // Set the date to "2018-09-01T16:01:36.386Z"
    const utcDate = parseFromTimeZone(date, { timeZone: 'Europe/Berlin' });
    let localDay = format (utcDate, 'dd MMM yyyy', { locale: fr });       
    return localDay;
}
formatHourFromDate(date){        
    // Set the date to "2018-09-01T16:01:36.386Z"
    const utcDate = parseFromTimeZone(date, { timeZone: 'Europe/Berlin' });       
    let localHour = format (utcDate, 'HH mm', { locale: fr });
    localHour = localHour.replace(' ', 'h');
    return localHour;
}

 

    /**  
   * 
   * @param {table : date} items 
   * @returns {table : string} tableau des horaires disponibles pour 1 jour
   */
  handlePossibleHours = async (date, pCategory, items)=>{
    this.setState({isDateClicked : true}); 
   
    console.log("date sélectionnée : " + date);    
    this.setState({selectedOption: ""}); //reset les créneaux dispos à chaque changement de date
    this.setState({isSubmitted: false});
    
    this.setState({isToHide: false});    
    this.props.context.setUpdateState(0);
    this.setState({freeTs: []});
    
    //this.props.context.setSelectedDate(date); // date sélectionnée
    this.setState({isSubmitted: false});
    // Si l'activité ne possède pas de créneau d'horaire, le choix est ok à la selection de la date
    if (pCategory.toString() !== "2"){        
        this.setState({choiceIsOk: false});
    }else{        
        this.setState({choiceIsOk: true});
    }
    this.setState({isToHide: false});
    let myHourTable = []; // reset du tableau des horaires, on ne cumule pas les horaires dispos
    let timeSlotsPerDay = [];    
    // 2- on récupère le numéro du jour de la date sélectionnée
    const numDay = getDay(date);
    // 3- on charge les horaires dispos pour ce jour et la catégorie    
    timeSlotsPerDay = items && items.find(item=> parseInt(item.num_day, 10) === numDay && item.category.toString() === pCategory.toString());
    // 4- On trie les horaires futurs
    const todayInt = new Date().getTime();    
    const dateInt = date.getTime();
    const sSelectedDate = date.toString(); 
    // tableau de booleens retournent vrai si l'heure est une heure future supérieure à 3 heures
    let areValidTimeSlots = [];    
    timeSlotsPerDay && timeSlotsPerDay.times.map(ts=>{
        const formattedTs = ts.value.replace("h", ":");          
        const hourToReplace = formatUnits(date.getHours().toString()) + ":" + formatUnits(date.getMinutes().toString());          
        const sNewSelectedDate = sSelectedDate.replace(hourToReplace, formattedTs);        
        const dNewSelectedDate = new Date(sNewSelectedDate).getTime();        
        const isValid = dNewSelectedDate > todayInt + (3*3600000);
        areValidTimeSlots = [...areValidTimeSlots, isValid];
        
    });
    
    // gestion des réservations passées (cp (1) et gouters (3))
    if (pCategory === "1"){ 
        console.log("on rentre dans la condition rés passées cat 1");
        // on recherche le jour dans les réservations passées - retourne obj booking si date réservée
        if (this.state.bookingsByCat !== undefined){    
            console.log("réservations passées : ", this.state.bookingsByCat);        
            const bookedDays = this.state.bookingsByCat.filter(booking=> this.formatDate(booking.chosen_time_stamp) === this.formatDate(date));            
            // si on trouve au moins un jour réservé sur le créneau, on filtre les timeslots qui sont encore dispo
            if (bookedDays !== undefined && bookedDays !== null && bookedDays !== [] && bookedDays.length > 0 )
            {                
                let tFreeTs = [];                 
                // Pour chaque créneau du jour, on cherche s'il n'est pas déjà réservé et on l'ajoute aux créneaux dispos
                for (let k = 0 ; k < timeSlotsPerDay.times.length ; k++)  {
                   
                    // Pour chaque créneau, on cherche au moins une correspondance, si on n'en trouve pas, on l'ajoute aux dispo
                    const busyTs = bookedDays.find (bookedDay => this.formatHourFromDate(bookedDay.chosen_time_stamp) === timeSlotsPerDay.times[k].value);
                    if (busyTs === undefined || busyTs === null){
                        if (areValidTimeSlots[k] === true){
                        // tableau des timestamps réservés 
                        tFreeTs.push(timeSlotsPerDay.times[k].value);
                        }
                    }
                }
                console.log("reserve ou pas...", tFreeTs);          
                this.setState({myHourTable: tFreeTs});                
            }else{                
                // si le jour n'est pas réservé, on lance tout
                timeSlotsPerDay  && timeSlotsPerDay.times.map((item, index)=>{        
                    if (areValidTimeSlots[index] === true){
                        myHourTable.push(item.value);
                    }
                    this.setState({myHourTable});
                })                
            }
        }
    }else{ // autres catégories        
        
        timeSlotsPerDay  && timeSlotsPerDay.times.map((item, index)=>{        
            if (areValidTimeSlots[index] === true){
                myHourTable.push(item.value);
            }
            this.setState({myHourTable});
        })
        
    }
    if (pCategory === "2"){
        this.setState({myHourTable: []}); // reset table if stage
    }
        this.setState({date}); 
        this.setState({formatedDateChoice : this.props.context.handleFormatedDateHourForFullWeek(date, null, this.props.isFullWeek)})    
        this.setState({selectedOption: null}); //reset la sélection de l'heure à chaque changement de date
        
    }

   

handleRadioChange=(event)=>{
    console.log("on rentre dans handleradiochange")
    let selectedOption = this.state.selectedOption;
    console.log("heure sélectionnée : " + event.target.value);
    this.setState({selectedOption : event.target.value});    
    this.setState({choiceIsOk : true});
    this.setState({isSubmitted: false})
    this.setState({formatedDateChoice : this.props.context.handleFormatedDateHour(this.state.date, event.target.value)})
   
    //this.setState({chosen_time_stamp: this.props.context.formatDateHourForDb(this.state.date, selectedOption)});
    
 }


onFormSubmit = async(event)=>{
    this.setState({isProcessing : true});
    this.setState({isDateClicked: false});
    event.preventDefault();
    const bookingId = this.props.index;
    const userMail = this.props.user.mail;
    const {date, selectedOption, choiceIsOk, isSubmitted} = this.state;
    // au submit, on doit avoir sélectionné un créneau horaire
    if (choiceIsOk === null || choiceIsOk === false || choiceIsOk === undefined){
        this.setState({choiceIsOk: false});
        if (this.rbForwardedRef !== null && this.rbForwardedRef !== undefined && this.rbForwardedRef.current !== null && this.rbForwardedRef.current !== undefined){
         this.rbForwardedRef.current.focus();
        }
    }    
 
    this.setState({isSubmitted : true});      
    
    if (choiceIsOk === true){
        let chosenTimeStamp;
        if (date !== undefined && date !== null){
            chosenTimeStamp = this.props.context.formatDateForDb(date);            
        }
        if (date !== undefined && date!== null && selectedOption !== undefined && selectedOption !== null){
            chosenTimeStamp = this.props.context.formatDateHourForDb(date, selectedOption);            
        }

         // mise à jour de la réservation
        const bookingToUpdate = {
            id : bookingId,
            chosen_time_stamp : chosenTimeStamp,                
        }
        
        const updateDbBooking =  this._isMounted && this.props.context && await this.props.context.updateBooking(bookingToUpdate, userMail); 
        this.setState({isToHide: true});
        if (updateDbBooking !== undefined){            
            if (updateDbBooking.message){
                this.setState({message: updateDbBooking.message});
                console.log("erreur update booking : " + updateDbBooking.message);
                this.setState({isProcessing: false});
            }else{ // renvoie une booking
                // on reload la liste des réservations                
                this.setState({updatedBooking:updateDbBooking.booking});
                if (updateDbBooking.booking !== undefined && updateDbBooking.booking !== null && updateDbBooking.booking.chosen_time_stamp !== null ){
                    const formatedDateChoice = this.handleUpdatedChosenTimeStamp(updateDbBooking.booking.chosen_time_stamp, parseInt(updateDbBooking.booking.category));                    
                    this.setState({formatedDateChoice});
                }                            
                this.setState({isProcessing: false});
               
            }
        }else{
            this.setState({message: "... Modification en cours"})
        }
    } 
}
    
    formatToLocalDateTime(date){        
        // Set the date to "2018-09-01T16:01:36.386Z"
        const utcDate = parseFromTimeZone(date, { timeZone: 'Europe/Berlin' });
        let localDay = format (utcDate, 'dd MMM yyyy', { locale: fr });
        let localHour = format (utcDate, 'H mm', { locale: fr });
        localHour = localHour.replace(' ', 'h');
        return localDay + " à " + localHour;
    }
    formatToUtcDate(date){      
      // Set the date to "2018-09-01T16:01:36.386Z"
      const utcDate = parseFromTimeZone(date, { timeZone: 'Europe/Berlin' }); 
      return utcDate;
    }
    /** CONVERTIT UNE DATE EN UN NUMDAY */
    convertDateToNumDay(date){
        const theDay = date.toString().substring(0,3);        
        const daysTable = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const result = daysTable.find(day=> day === theDay);
        let numDay;
        switch (result) {
            
            case "Sun":
                numDay = 0;
                break;
            case "Mon":
                numDay = 1;
                break;
            case "Tue":
                numDay = 2;
                break;
            case "Wed":
                numDay = 3;
                break;
            case "Thu":
                numDay = 4;
                break;
            case "Fri":
                numDay = 5;
                break;
            case "Sat":
                numDay = 6;
                break;
                
            default: 
                numDay = -1;
                break;
        }
        return numDay;

    }

    

    setMyError(bookingState, choiceIsOk, isSubmitted){
        let message = "";
    
        if (isSubmitted && choiceIsOk === false){
            message = "Vous devez choisir un créneau horaire"
        }
        if (isSubmitted && choiceIsOk === true && bookingState === 2) {
            message = "Modification effectuée, un mail de confirmation vous a été envoyé"; 
        } 
        if (isSubmitted && choiceIsOk && bookingState === 1){
            message = "Chargement en cours..."
        }
       
    
        return message;
    }

    /**
     * Méthode de rendu du créneau choisi
     */
    handleChosenTimeStamp (){
       
        let formatedDateChoice = "";
        // on choisit une date
        const date = this.state.date;
        const hour = this.state.selectedOption;
        
        if (date !== null){
            formatedDateChoice = this.props.context.handleFormatedDateHour(date, hour);
            //this.setState({updatedChosenTs: date});
            console.log("date not null : " + date);
        
        }else{
            if (this.props.chosen_time_stamp !== undefined){
                if (this.props.categoryid !== 2){
                    formatedDateChoice = this.props.context.formatToLocalDateTime(this.props.chosen_time_stamp);
                }else{
                    formatedDateChoice = this.props.context.formatToLocalDate(this.props.chosen_time_stamp);
                }
                //console.log("date null, updatedChosenTs : " + this.state.updatedChosenTs);
            }
        } 
        
        return formatedDateChoice;
    }

        /**
     * Méthode de rendu du créneau choisi
     */
    handleUpdatedChosenTimeStamp = (pChosenTimeStamp, pCategoryId) => {
       
        let formatedDateChoice = "";
       
            if (pChosenTimeStamp !== undefined){
                if (pCategoryId !== 2){
                    formatedDateChoice = this.props.context.formatToLocalDateTime(pChosenTimeStamp);
                }else{
                    formatedDateChoice = this.props.context.formatToLocalDate(pChosenTimeStamp);
                }                
            }       
        
        return formatedDateChoice;
    }
      
    handleCalendarMessage=(myHourTable, presta)=>{
        console.log("on rentre dans handlecalendarmessage")
        
        if(this.state.isDateClicked === true){
            if (myHourTable.length > 0){                
                if (presta !== "2"){
                return "Sélectionnez l'horaire de votre choix";
                }
            }else{                 
                if (presta !== "2"){
                    return "Il n'y a plus de créneau disponible pour ce jour";
                }
            
                
            }
        }
    }
      
    

    render() {
        const {user, category, categoryid, created_at, index, quantity, tsToDisable, isFullWeek} = this.props
        const {isSubmitted, isToHide, choiceIsOk, myHourTable} = this.state            
        const updateState = this.props.context.updateState
        const isProcessing = this.state.isProcessing
        const datechoice = this.state.date
        const amount = setAmountByCatAndQuantity(categoryid, quantity, isFullWeek)
        
        let formatedDateChoice = this.state.formatedDateChoice;
        
        const hourchoice=this.state.selectedOption
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        console.log("datechoice : " + index  + " " + datechoice)
        console.log("hourchoice : " + hourchoice);
        
        // affichage du créneau horaire chargé ou du choix date/heure
        
        //let myError = this.setMyError(updateState, choiceIsOk)
        const myError = updateState === 2 && choiceIsOk && isSubmitted ? "Modification effectuée, un mail de confirmation vous a été envoyé" : (!choiceIsOk&& isSubmitted ? "Merci de choisir un créneau horaire":"")
         // On vérifie que la date n'appartient pas à un créneau effacé
  
        
        return (
            <>
            <StyledFormCard onSubmit={this.onFormSubmit}>
            <div className="cardlinewrapper">
                <div className="bookingcolumn"><span className="table-header">Référence</span></div>
                <div className="bookingcolumn">{index}</div>
                <div className="bookingcolumn">&nbsp;</div>
            </div>
            <div className="cardlinewrapper">
                <div className="bookingcolumn"><span className="table-header">Catégorie</span></div>
                <div className="bookingcolumn">
                {category}{(categoryid.toString() === "2" && isFullWeek === true) ? " semaine complète" : ""}
                </div>
                <div className="bookingcolumn">&nbsp;</div>
            </div>
            <div className="cardlinewrapper">
                <div className="bookingcolumn"><span className="table-header">Créneau choisi</span></div>
                <div className="bookingcolumn">
                    <span className="table-header">
                     {(categoryid.toString() === "2" && isFullWeek === true) ?
                    `Semaine du ${formatedDateChoice}` : formatedDateChoice}
                    </span>
                    </div>
                <div className="bookingcolumn">&nbsp;</div>
            </div>
            <div className="cardlinewrapper">
                <div className="bookingcolumn"><span className="table-header">Nb personnes</span></div>
                <div className="bookingcolumn"><span className="table-header">{quantity}</span></div>
                <div className="bookingcolumn">&nbsp;</div>
            </div>
            <div className="cardlinewrapper">
                <div className="bookingcolumn"><span className="table-header">Arrhes </span></div>
                <div className="bookingcolumn">{amount/100}€</div>
                
            </div>
            <div className="cardlinewrapper">
                <div className="bookingcolumn"><span className="table-header">Passée le</span></div>
                <div className="bookingcolumn"> {created_at ? `${this.props.context.formatToLocalDateTime(created_at)} ` : ''}</div>
                <div className="bookingcolumn">&nbsp;</div>
            </div>
            
                <div className="cardline-center"><span className="table-title">Modifier mon créneau</span>
                
                    <DatePickerInput 
                    loadingts= {this.state.loadingTs}
                    loadingbookings= {this.state.loadingBookings}
                    usermail={user.mail} 
                    bookingid={index} 
                    chosen_time_stamp={this.props.chosen_time_stamp}
                    date={datechoice}
                    selectedOption={hourchoice}
                    choiceIsOk={choiceIsOk}
                    myHourTable={myHourTable}
                    handlePossibleHours={this.handlePossibleHours}                    
                    issubmitted= {isSubmitted}
                    choiceisok = {choiceIsOk}
                    category = {category}
                    categoryid = {categoryid}
                    istohide = {isToHide}
                    days = {this.props.items}
                    daysToDisable = {this.state.daysToDisable}
                    tsToDisable = {tsToDisable}
                    
                    />
                    </div>
                   <Label isvalid={false}>{this.state.errorBooking}</Label>
                    
                    <div className="bookingcolumn">
                    
                    <SubmitButtonTable 
                        type="submit" 
                        processing={updateState === 1 && isSubmitted && choiceIsOk}
                        error={myError}                 
                        issubmitted= {isSubmitted}
                        choiceisok = {choiceIsOk}               
                    > Valider ma modification
                    </SubmitButtonTable>    
                                    
                  
                    </div>
                    
        
        <div className="cardline">
        <div className="calendar-message">{this.handleCalendarMessage(myHourTable, categoryid.toString())}</div>
          <RadioGp istohide={isToHide}>
            {myHourTable.map((item, index)=>{                
            return <div className="radio-elt" key={index}>
              <label className="container">
                <RbForwardRef 
                id={index}
                type="radio"
                className="radio-input"
                name="radiogroup"
                value={item} 
                onChange={(e)=>this.handleRadioChange(e)}
                checked={hourchoice === item}
                ref={this.rbForwardedRef}             
                />                
                {item}
              <span className="checkmark"></span>
              </label>              
                     
            </div>
            })}
            </RadioGp>
            </div>
            <div className="cardline">
                <ConfirmLabel isvalid={updateState === 2}>{myError}</ConfirmLabel>
            </div>
        
         
            {/* <div className="linewrapper">
                <span>{isUpdated && " Mise à jour effectuée"}
            </span>
            </div> */}
            </StyledFormCard>
           </>
            )
        }
    }
    export default withBooking(BookingCard)