import React, { Component } from 'react';
import {withUser} from '../contexts/UserContext';
import Hero from '../components/Hero';
import stripeApi from "../stripeApi";
import {IoMdCheckboxOutline} from "react-icons/io";


class Home extends Component {
    constructor(props){
        super(props);
        // disable reactdevtools for production
        stripeApi.getServerStatus().then((status)=>{            
            if (status === 1) {      
                this.betaMessage = "Serveur beta de la plateforme de réservation";
            }else if (status === 3){
                this.betaMessage = "La plateforme est en cours de maintenance, les réservations sont momentanément indisponibles.";
            }else if(status === 0){
                this.betaMessage = "Plateforme locale"
            }else{
                this.betaMessage = "";
            }
        });
        
        
    }
    
    
    render() {
        return (
            <>           
            <div className="page">     
            <Hero/>           
            <p>                
            Ce lieu vous permet de simuler une prise de rendez-vous en temps réel.        
            </p>
            <p>
                {this.betaMessage}
            </p>
            <div className="plus-wrapper">
            <div className='plus-list'>
            <IoMdCheckboxOutline/><span>Un ou plusieurs ateliers</span>
            </div>
            <div className='plus-list'>
            <IoMdCheckboxOutline/><span>Activités récurrentes ou ponctuelles</span>
            </div>
            <div className='plus-list'>
            <IoMdCheckboxOutline/><span>Module d'administration pour gérer vos créneaux disponibles et suivre vos rendez-vous</span>
            </div>
            <div className='plus-list'>
            <IoMdCheckboxOutline/> <span>Solution simple et sécurisée</span>
            </div>
            <div className='plus-list'>
            <IoMdCheckboxOutline/> <span>Les cartes de crédit test de <a href="https://stripe.com/docs/testing">Stripe </a>vous permettent de simuler le parcours utilisateur jusqu'au bout !</span>
            </div>
            </div>
            </div>
            </>
            )
        }
    }
    export default withUser(Home)
