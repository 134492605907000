


import React from 'react';

export const DesactivateLogo = (props)=>(

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width={props.width} height={props.height} viewBox="0 0 250 250" enableBackground="new 0 0 250 250" xmlSpace="preserve">
<g id="calendrier">
	<g>
		<rect x="30.427" y="41.792" fill="#FFFFFF" width="185.438" height="170.73"/>
		<g>
			<path fill="#64C0BD" d="M215.889,209.605c-11.775,0-23.551,0-35.324,0c-24.547,0-49.092,0-73.638,0c-21.151,0-42.302,0-63.453,0
				c-4.342,0-8.683,0-13.025,0c0.966,0.979,1.932,1.959,2.896,2.939c0-12.545,0-25.088,0-37.631c0-24.906,0-49.811,0-74.715
				c0-18.175,0-36.351,0-54.526c0-1.287,0-2.574,0-3.86c-0.98,0.965-1.959,1.931-2.939,2.896c11.774,0,23.55,0,35.323,0
				c24.547,0,49.092,0,73.638,0c21.15,0,42.303,0,63.453,0c4.342,0,8.684,0,13.025,0c-0.967-0.98-1.932-1.959-2.896-2.939
				c0,12.543,0,25.088,0,37.632c0,24.903,0,49.808,0,74.712c0,18.176,0,36.352,0,54.527c0,1.285,0,2.572,0,3.859
				c0,3.768,5.834,3.801,5.834,0.043c0-12.545,0-25.088,0-37.631c0-24.906,0-49.811,0-74.715c0-18.175,0-36.351,0-54.526
				c0-1.287,0-2.574,0-3.86c0-1.581-1.299-2.939-2.895-2.939c-11.775,0-23.551,0-35.324,0c-24.547,0-49.092,0-73.638,0
				c-21.151,0-42.302,0-63.453,0c-4.342,0-8.683,0-13.025,0c-1.581,0-2.938,1.3-2.938,2.897c0,12.543,0,25.088,0,37.632
				c0,24.903,0,49.808,0,74.712c0,18.176,0,36.352,0,54.527c0,1.285,0,2.572,0,3.859c0,1.582,1.299,2.939,2.896,2.939
				c11.774,0,23.55,0,35.323,0c24.547,0,49.092,0,73.638,0c21.15,0,42.303,0,63.453,0c4.342,0,8.684,0,13.025,0
				C219.613,215.441,219.646,209.605,215.889,209.605z"/>
		</g>
	</g>
	<g>
		<rect x="91.357" y="99.989" fill="#B8BCBC" width="19.87" height="18.948"/>
	</g>
	<g>
		<rect x="126.236" y="99.989" fill="#B8BCBC" width="19.869" height="18.948"/>
	</g>
	<g>
		<rect x="160.234" y="100.482" fill="#64C0BD" width="19.869" height="18.947"/>
	</g>
	<g>
		<rect x="56.477" y="132.871" fill="#64C0BD" width="19.869" height="18.947"/>
	</g>
	<g>
		<rect x="91.357" y="132.871" fill="#64C0BD" width="19.87" height="18.947"/>
	</g>
	<g>
		<rect x="56.477" y="166.596" fill="#64C0BD" width="19.869" height="18.947"/>
	</g>
	<g>
		<rect x="91.357" y="166.596" fill="#B8BCBC" width="19.87" height="18.947"/>
	</g>
	<g>
		<rect x="33.077" y="41.792" fill="#125250" width="182.788" height="35.832"/>
	</g>
	<g>
		<rect x="126.238" y="166.597" fill="#64C0BD" width="19.867" height="18.946"/>
	</g>
	<g>
		<rect x="126.238" y="132.871" fill="#64C0BD" width="19.867" height="18.946"/>
	</g>
	<g>
		<rect x="160.234" y="132.871" fill="#64C0BD" width="19.87" height="18.947"/>
	</g>
	<g>
		<rect x="160.234" y="166.596" fill="#64C0BD" width="19.87" height="18.947"/>
	</g>
</g>
<g id="Layer_2">
	<g>
		<path fill="#E01934" d="M87.656,91.875c2.583,2.73,5.082,5.529,7.626,8.291c2.516,2.784,5.006,5.589,7.469,8.416
			c1.233,1.412,2.525,2.777,3.797,4.158c1.267,1.385,2.439,2.845,3.516,4.382c1.073,1.54,2.097,3.118,3.036,4.764
			c0.949,1.638,1.837,3.324,2.556,5.146l-0.313,0.25c-1.615-1.108-3.06-2.352-4.444-3.643c-1.395-1.284-2.703-2.635-3.964-4.025
			c-1.257-1.393-2.417-2.863-3.484-4.407c-1.061-1.549-2.103-3.113-3.203-4.631c-2.203-3.033-4.38-6.088-6.531-9.163
			c-2.123-3.097-4.291-6.159-6.374-9.288L87.656,91.875z"/>
	</g>
	<g>
		<path fill="#E01934" d="M115.5,92c-1.728,3.065-3.748,5.858-5.854,8.569c-2.119,2.7-4.352,5.293-6.684,7.795
			c-2.331,2.502-4.765,4.909-7.312,7.209c-2.559,2.289-5.208,4.495-8.15,6.427c1.725-3.068,3.743-5.862,5.85-8.573
			c2.119-2.7,4.353-5.293,6.688-7.791c2.335-2.499,4.769-4.905,7.316-7.205C109.914,96.142,112.562,93.935,115.5,92z"/>
	</g>
	<g>
		<polygon fill="#E01934" points="126.036,91.131 152.472,127.535 122.907,93.624 		"/>
	</g>
	<g>
		<path fill="#E01934" d="M152.472,92.377c-1.729,3.065-3.747,5.858-5.854,8.569c-2.119,2.7-4.353,5.293-6.684,7.795
			c-2.331,2.502-4.765,4.909-7.313,7.209c-2.559,2.289-5.207,4.495-8.149,6.427c1.725-3.068,3.742-5.862,5.851-8.573
			c2.119-2.7,4.353-5.293,6.688-7.791c2.335-2.499,4.769-4.905,7.316-7.205C146.886,96.52,149.533,94.313,152.472,92.377z"/>
	</g>
	<g>
		<path fill="#E01934" d="M86.429,159.744c2.583,2.731,5.082,5.529,7.626,8.292c2.516,2.784,5.006,5.589,7.469,8.415
			c1.233,1.412,2.525,2.777,3.797,4.158c1.267,1.386,2.439,2.846,3.516,4.383c1.073,1.539,2.097,3.117,3.036,4.764
			c0.949,1.639,1.837,3.325,2.556,5.146l-0.313,0.25c-1.615-1.108-3.06-2.352-4.444-3.644c-1.395-1.283-2.703-2.635-3.964-4.025
			c-1.257-1.393-2.417-2.862-3.484-4.407c-1.061-1.549-2.103-3.113-3.203-4.631c-2.203-3.034-4.38-6.088-6.531-9.164
			c-2.123-3.098-4.291-6.158-6.374-9.287L86.429,159.744z"/>
	</g>
	<g>
		<path fill="#E01934" d="M114.418,160.006c-2.1,2.718-4.278,5.362-6.415,8.046c-2.163,2.659-4.35,5.296-6.561,7.909
			c-1.104,1.309-2.155,2.667-3.223,4.009c-1.073,1.337-2.234,2.593-3.485,3.764c-1.254,1.168-2.554,2.295-3.934,3.346
			c-1.37,1.061-2.796,2.067-4.382,2.927l-0.292-0.273c0.748-1.641,1.654-3.134,2.618-4.573c0.954-1.449,1.987-2.822,3.066-4.154
			c1.082-1.329,2.254-2.573,3.515-3.736c1.266-1.158,2.548-2.3,3.777-3.491c2.456-2.387,4.935-4.75,7.439-7.091
			c2.53-2.316,5.018-4.672,7.585-6.954L114.418,160.006z"/>
	</g>
</g>
</svg>
)