import React, { useContext, useEffect, useState } from 'react';
import {StyledFormCards} from '../common';
import { BookingContext } from '../contexts/BookingContext';
import { UserContext } from "../contexts/UserContext";
import BookingCard from './BookingCard';
import {filterBookings} from "../data/clientUtils";


/* function setCategoryString(nCategory){
    let response = "";
    switch (nCategory) {
        case 0:
        response =  "Baby";
        break;
        case 1:
        response =  "Prestation encadrée";
        break;
        
    }
    return response;
} */




function BookingList(props){
    
    const userContext = useContext(UserContext);
    const bookingContext = useContext(BookingContext);
    const user = userContext.dbUser; 
    const [bookings, setBookings] = useState();
    const [oldBookings, setOldBookings] = useState();    
    const [_isMounted, set_isMounted] = useState(false);
    const [message, setMessage] = useState("");
    
    console.log("le mail du dbUser : " + user.mail);
    
    useEffect(() => {
        
        set_isMounted(true); 
        if (userContext !== undefined && user !== undefined && user.bookings !== undefined){
            if (user.bookings !== bookings || bookings === null){
                setBookings(user.bookings);
                console.log("la liste des réservations : " + user.bookings);
                setOldBookings(user.bookings);
            }
        }  
            async function getReloadedBookings() {
                    const newBookings = bookingContext && await bookingContext.reloadUserBookings(user.mail);
                if (newBookings !== undefined){
                    if(newBookings.message){
                        console.log("retour erreur reloadbookings : " + newBookings.message);
                    }else{                    
                        console.log("nouvelle liste de réservations : " + newBookings.bookings);
                        setBookings(newBookings.bookings);
                        if (oldBookings !== undefined){
                        newBookings.bookings.forEach(booking => {
                            oldBookings.forEach(oldBooking =>{
                                if(booking.chosen_time_stamp !== oldBooking.chosen_time_stamp){
                                    console.log( "Ancien créneau enregistré : " + oldBooking.chosen_time_stamp);
                                    console.log( "Nouveau créneau enregistré : " + booking.chosen_time_stamp);
                                    
                                }
                            });
                        });
                    }
                    }
                }                 
            }
    
           //getReloadedBookings();    
            
         
        
    }, [user.bookings, _isMounted, bookingContext, oldBookings, user, userContext])    

   
    if (!window.navigator.onLine){
        return "Oups, il semblerait que vous ayez perdu votre connexion internet";
    }
    if (bookings === null || bookings === undefined ){        
        return "...Chargement";
    }
    if (bookings.length < 1){
        return "Aucune réservation enregistrée";
    }
    
    const  getTimeStampsByCat = (categoryId, timeStamps) =>{
        
        
    if (timeStamps !== undefined && timeStamps !== null && categoryId !== undefined){
        console.log("on rentre dans getTsByCat, la ctégorie : " + categoryId)       
        // si on a des créneaux, on ne retourne que ceux de la categorie en param      
          console.log("dans getTsByCat validts: " + timeStamps);  
          const finalTimeStamps = timeStamps.filter(ts=>
          ts.category.toString() === categoryId.toString());
          console.log("dans getTsByCat : " + finalTimeStamps);     
          return finalTimeStamps;       
    }
}
   
    
    return  (
        <>     
        <span>{message}</span>
        <StyledFormCards>
        {bookings && filterBookings(bookings).length > 0 ?
        filterBookings(bookings).map((filteredBooking, index)=>
      
            <BookingCard 
            user = {user}
            key={index}
            index = {filteredBooking.id}   
            category = {bookingContext.setCategoryString(filteredBooking.category)}
            categoryid = {filteredBooking.category}
            isFullWeek = {filteredBooking.is_full_week && filteredBooking.is_full_week}
            chosen_time_stamp = {filteredBooking.chosen_time_stamp}
            created_at = {filteredBooking.created_at}
            bookingstate = {filteredBooking.booking_state}            
            quantity = {filteredBooking.quantity}
            tsToDisable = {bookingContext.tsToDisable && bookingContext.tsToDisable} // chosen timestamps to disable
            //daystodisable = {(filteredBooking.category !== "2" && props.validTs !== undefined) ? bookingContext.setListToDisable(getTimeStampsByCat(filteredBooking.category, props.validTs), filteredBooking.category) : (props.validTsStage !== undefined && getTimeStampsByCat(filteredBooking.category, props.validTsStage))}            
            items = {filteredBooking.category !== 2 ? (props.validTs && getTimeStampsByCat(filteredBooking.category, props.validTs)) :  props.validTsStage && props.validTsStage}          
            /> 
            
            )
           
            
        :
        <span>Aucune réservation prévue prochainement</span>
       
        }</StyledFormCards>
            </>
            ) 
            
        }
        export default BookingList
