import React from 'react';
import "../styles/StripeCardDetails.css";
import {StyledButtonTable} from '../common';
import loadingGif from "../images/gif/loading-gear.gif";


const SubmitButtonTable = ({processing, error, children, disabled, choiceisok, issubmitted}) =>(
  
        <StyledButtonTable
        /* className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`} */
        type="submit"
        disabled={processing}>
            {processing ? <img src={loadingGif} className="loading" alt="chargement en cours..." /> : children }
        </StyledButtonTable>
   
 
);
export {SubmitButtonTable}