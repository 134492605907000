import React, {useEffect, useContext, useState} from 'react';
import {withUser, UserContext} from '../contexts/UserContext';
import AdminPresta from "../components/AdminPresta";
import Confirm from "../pages/Confirm";
import '../App.css';
import RendezVous from '../components/RendezVous';






function Admin(props) {
    
    const userContext = useContext(UserContext);

    useEffect(() => {
        window.scrollTo(0, 0); 
        
       
    }, []);



    console.log("authuser dans admin : " + userContext.authUser);
    console.log("isAuth admin : " + userContext.isAuth);
    return userContext.isAuth === 5 ? (
        <>
        {/* Pour centrer "nos rendez-vous" */}           
            
        <div className="header">            
            
            </div>
            <div className="admin-booking-page">
            
            <h1 className="admin-page-title">Plateforme d'administration de vos réservations</h1>
            </div>
            <div>
                <AdminPresta/>
            </div>
            </>
    ):
    (
        <Confirm {...props} 
            titleconfirm="Vous êtes déconnecté ou vous n'êtes pas autorisé à accéder à ce contenu"
            bodyconfirm= "La page que vous demandez n'est disponible que pour les administrateurs"
            infoconfirm= "Si vous êtes un administrateur, merci de vous connecter avec les identifiants qui vous ont été communiqués"
            labelconfirm="Retour à la page de connexion"   
            hourchoice = ""
            datechoice = ""
            isreinit={false}
            isbookingconfirm={false}
            
            /> 
    )
}

export default withUser(Admin)

