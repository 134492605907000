
import React, {useState, useContext, useEffect, useRef} from 'react';
import {   
    useElements, 
    useStripe,
    CardElement
    
} from '@stripe/react-stripe-js';

import '../styles/StripeCardDetails.css';
import {Field, CardField} from "./StripeField";
import {SubmitButton} from "./StripeSubmitButton";
import { format, set } from 'date-fns';
import { fr } from 'date-fns/locale';
import { CenteredLabel } from '../common';
import {Navigate} from 'react-router-dom';
import {StyledInputEmailStripe} from '../common';
import {UserContext, withUser} from "../contexts/UserContext";
import stripeApi from "../stripeApi";
import { useLocation } from 'react-router-dom';







function MyCheckoutForm(props) {   
    
    /**
    * 
    * CONTEXTS
    */
    
    const userContext = useContext(UserContext);     
    const OPTIONS = userContext.OPTIONS;
    /**
    * STRIPE
    */
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);   
    const [message, setMessage] = useState("");
    const [apiError, setApiError] = useState("");
    const [paymentError, setPaymentError] = useState("")
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);    
    const [currency, setCurrency] = useState("eur");   
    const [succeeded, setSucceeded] = useState(false);
    const [stripeMessage, setStripeMessage] = useState("");
    const [billingDetails, setBillingDetails] = useState({
        email : userContext.user.email,
        phone : userContext.dbUser.phone,
        name : userContext.dbUser.name,
        address: {country: "FR"}
    });
    const [nameIsDone,setNameIsDone] = useState(false);
    const [telIsDone, setTelIsDone] = useState(false);

    // gestion des erreurs
    const [myError, setMyError] = useState("");
    const [navError, setNavError] = useState("");
    const [tsError, setTsError] = useState("");
    const [coordError, setCoordError] = useState("");
    const [eventError, setEventError] = useState("");
    
    //Booking init
    const [newBooking, setNewBooking] = useState(null);
    const [completeUser, setCompleteUser] = useState(null);
    const [stripeResponse, setStripeResponse] = useState(null);
    const [endOfQueries, setEndOfQueries] = useState(false);
    const [endOfBookingDetails, setEndOfBookingDetails] = useState(false);
    const [endOfClientSecret, setEndOfClientSecret] = useState(false);    
    const [bookingIsOk, setBookingIsOk] = useState(false); // change to false after tests
    const bookings = userContext.dbUser.bookings;
    const [bookingAmount, setBookingAmount] = useState(0);
    const [clientSecret, setClientSecret] = useState("");
    const [isMounted, setIsMounted] = useState(null);
    const [ridersAreOk, setRidersAreOk] = useState(false);
    const timerRef = useRef(null);
    const location = useLocation();
    console.log ("location checkoutform : ", location);
  
   
useEffect(() => {
    let _isMounted = true;
    setIsMounted(_isMounted);
    const ueRidersOk = props.presta === "0"  || props.presta === "2"  ? ridersAgeOrLevelIsOk(props.ages) : props.presta === "1" ? ridersAgeOrLevelIsOk(props.levels) : true ;
     // Maj state choiceisok
     if (cardComplete && props.eventisok && props.quantity > 0  && userContext.tsIsOk && nameIsDone && telIsDone && ueRidersOk){
        if (userContext.choiceIsOk !== true){
            userContext.setChoiceIsOk(true);        
        }
    }
      // reset telIsDone et nameIsDone si load from db
      if (billingDetails.phone !== ""){
        setTelIsDone(true);
    }
    if (billingDetails.name !== ""){
     setNameIsDone(true);
     }
    // reset message tel || nom
    if (telIsDone || nameIsDone){
        setCoordError("");
    }
   

    if (window.navigator.onLine && navError === "Oups, il semblerait que vous ayez perdu votre connexion internet"){
        setNavError("");
    }else if(!window.navigator.onLine){
        setNavError("Oups, il semblerait que vous ayez perdu votre connexion internet");
    }   
   

   if (myError !== "" || apiError !== ""){
    timerRef.current = setTimeout(() => resetSeverErrors(), 3000);
    }
    
    // créneau horaire
    if (userContext.tsIsOk)
    {        
        setTsError ("");
        // focus sur l'erreur       
    }
    // reset message sélection du nbPersonnes
    if (props.quantity !== 0 && userContext.quantityError !== ""){  
        userContext.setQuantityError("");
    }
    
    
    // reset message events selection
    if (props.eventisok){        
        setEventError("");
    }
   
    function IntentProceed  (categoryId, quantity, _isMounted, ridersOk) {
        
        const isFullWeek = props.isFullWeek && props.isFullWeek;
        const nbDays = props.nbDays !== undefined && props.nbDays;
        //const thoseRidersAgeAreOk = props.presta === "0" || props.presta === "2" ? ridersAgeOrLevelIsOk(newBooking.ridersAge) : (props.presta === "1" ? ridersAgeOrLevelIsOk(newBooking.ridersLevel) : true);
            // SCA handling (authentification forte du client)
            // Step 1: Fetch product details such as amount and currency from
            // API to make sure it can't be tampered with in the client.   
            if (_isMounted && quantity > 0 && ridersOk){
                stripeApi.getBookingDetails(categoryId, quantity, isFullWeek, nbDays).then((bookingDetails) => {
                setBookingAmount(bookingDetails.amount / 100);
                console.log("retour bookingAmount ", bookingDetails.amount);
                console.log("retour currency ", bookingDetails.currency);
                setCurrency(bookingDetails.currency);
                setEndOfBookingDetails(true);
               
                })
                .catch((err) => {
                    console.log("catch getbookingdetails : ", err.message);
                    setError(err.message);
                }); 
            // Step 2: Create PaymentIntent over Stripe API 
            
                const options = {category: categoryId, quantity: quantity, isfullweek: isFullWeek, nbdays: nbDays}; 
                stripeApi
                .createPaymentIntent(options)
                .then((clientSecret) => {
                    console.log("on rentre dans la réponse createPaymentIntent")
                    setClientSecret(clientSecret);    
                    console.log ("clientsecret : ", clientSecret) ;           
                    setEndOfClientSecret(true);
                    
                })
                .catch((err) => {
                    console.log("on rentre dans le catch clientSecret")
                    setError(err.message);
                });
               
            }
          
    }
    console.log("useffect : userContext.choiceIsOk", userContext.choiceIsOk);
    console.log("ueRidersOk", ueRidersOk);

    

    if (userContext.choiceIsOk && ueRidersOk){
        const categoryId = parseInt (props.presta, 10);
        // Step 1 and 2 Stripe payment : get boooking infos and lauch payment intent
        IntentProceed(categoryId, props.quantity, _isMounted, ueRidersOk); 
     } 

 
    return () =>{ 
        _isMounted = false;
        setIsMounted(_isMounted);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    }
      
       
    }, [billingDetails.phone, ridersAreOk, billingDetails.name, apiError, props.presta, cardComplete, userContext.tsIsOk, navError, myError, userContext, props.eventisok, telIsDone, nameIsDone, props.quantity]
    
    );

    const ridersAgeOrLevelIsOk = (pRiders)=>{   
        console.log ("tab des riders age / level : " , pRiders);
        let isOk;     
        if(pRiders.length<=0){
            return false;
        }
        if ( props.presta.toString() === "0" || props.presta.toString() === "2"){
            console.log("ridersAgeIdOk, presta : ", props.presta);
           
            const zeroRider = pRiders && pRiders.find(rider=>rider.age === "0");            
            if (zeroRider !== undefined && zeroRider !== null){
                console.log( "on trouve un rider de 0 an : isok false");
                isOk = false;
           
            }else{
                isOk = true;
            }
        
    }else if (props.presta.toString() === "1"){ // presta = 1;
        console.log("on rentre dans la condition level");
        const zeroRider = pRiders && pRiders.find(rider=>rider.level.toString() === "0");            
        if (zeroRider !== undefined && zeroRider !== null){
            console.log( "on trouve un rider de galop 0 : isok false");
            isOk = false;
       
        }else{
            console.log("on rentre dans isOk level de ridersAgeOrLevelIsOk");
            isOk = true;
        }
    }else{
        isOk = true; // other activities no level / no age
    }
        return isOk;
    }
    const resetSeverErrors = () =>{
        if(userContext.user !== undefined && userContext !== null){
        setApiError("");
        setMyError("");
        }
    }
    
    const replaceAt = (theString, index, char) =>{
        return theString.slice(0, index) + char + theString.slice(index+char.length);
    }
    const formatUnits=(sHour)=>{
        const zero = "0";       
        let result;
        console.log(sHour.toString().length);
        if (sHour.length < 2){
            result = zero.concat(sHour);
        }else{
            result = sHour;
        }
        return result;
    }

    const formatDateForDb=(datechoice)=>{
        if (datechoice !== undefined){        
        // format hour units
        // regex for replace
        let sDateChoice = datechoice.toString();        
        sDateChoice = datechoice.toString();
    return sDateChoice;
        }
   }


   const localDateTimeToUtc = (localDate)=>{
    console.log("conversion " + localDate);  
    const parsedDate = new Date(localDate).toUTCString();
    console.log("utc.... : " + parsedDate);
    return parsedDate;
}


/**
* @returns string
*/
const utcToLocalDatetime=(utcDate)=>{
    console.log("le param date utc : " + utcDate)
    const result = new Date(utcDate).toString();        
    console.log("dateTime Locale : " + result);
    return result;
}

   const formatDateHourForDb = (datechoice, hourchoice)=>{
    if (datechoice !== undefined && hourchoice !== undefined){
    
    const hour = hourchoice.slice(0, hourchoice.indexOf("h"));    
    const min = hourchoice.substring(hourchoice.indexOf("h")+1);    
    // format hour units
    // regex for replace
    let sDateChoice = datechoice.toString();    
    sDateChoice = datechoice.toString();        
    // toInt
    const hourDozen = parseInt((sDateChoice.indexOf(":")-2), 10);
    const hourUnit = parseInt((sDateChoice.indexOf(":")-1), 10);
    const minUnit = parseInt((sDateChoice.indexOf(":")+2), 10);
    const minDozen = parseInt((sDateChoice.indexOf(":")+1), 10);
    // remplacement des heures
    sDateChoice = replaceAt(sDateChoice, hourDozen, formatUnits(hour)[0]); // remplace le 2eme caractère avant les deux points par les dizaines..       
    sDateChoice = replaceAt(sDateChoice,hourUnit, formatUnits(hour)[1]);  // remplace le 1er caractère avant les deux points par les unités..        
    //2- on remplace les deux digits d'après par les minutes
    sDateChoice = replaceAt(sDateChoice, minDozen, min[0]);        
    sDateChoice = replaceAt(sDateChoice, minUnit, min[1]);   
    return sDateChoice;
}
}
const handleErrorMessages = () =>{
    // si on a un message d'erreur pas de fin de requetes
    if (error || myError !== "" ||navError !== "" || tsError !== "" || coordError !== "" || eventError !== "" ){
        setEndOfQueries(false);
    }
}


const setBooking =(pUser)=>{
    // BOOKING
    const {datechoice, hourchoice} = props;
    let chosenTimeStamp = "";            
    if (datechoice !== undefined && datechoice !== null){
        chosenTimeStamp = formatDateForDb(datechoice);                
    }        
    if (datechoice !== undefined && datechoice !== null && hourchoice !== undefined && hourchoice !== null){
        chosenTimeStamp = formatDateHourForDb(datechoice, hourchoice);                
    }
    const newBooking = {
        category : props.presta,  // baby / cp / stage / gouter
        chosen_time_stamp :  chosenTimeStamp,
        quantity: props.quantity,
        booking_state :  1,
        user : pUser, // retour user mAj
        ridersAge: props.presta && (props.presta === "0" || props.presta ==="2") ? props.ages : [],
        ridersLevel: props.presta && props.presta === "1" ? props.levels : [],
        isFullWeek: props.isFullWeek && props.isFullWeek ? props.isFullWeek : false
    }
    const thoseRidersAgeAreOk = props.presta === "0" || props.presta === "2" ? ridersAgeOrLevelIsOk(newBooking.ridersAge) : (props.presta === "1" ? ridersAgeOrLevelIsOk(newBooking.ridersLevel) : true);
    console.log("ridesarecok : ", thoseRidersAgeAreOk)
    const thisBookingIsOk = (newBooking.category && newBooking.category !== "-1"
    && newBooking.quantity && newBooking.quantity !== 0
    && newBooking.chosen_time_stamp && newBooking.chosen_time_stamp !== ""
    && newBooking.user !== undefined && newBooking.user !== null
    && thoseRidersAgeAreOk);  
    console.log("booking what is bad: ", newBooking);

    console.log ("bookingIsOk : " + thisBookingIsOk);
    
    if (thisBookingIsOk) {
        console.log ("bookingIsOk de setBooking");
        setBookingIsOk(true);
        setNewBooking(newBooking);        
        PaymentProceed(thisBookingIsOk);
        
        
        
    }else{ // fin bookingisok
        console.log("bookingisNotOk");            
        // return le message en fonction de ce qui manque
        if (newBooking.category !== 0 && (!newBooking.category || newBooking.category === "-1")){            
            userContext.setQuantityError("Merci de sélectionner une activité");
            console.log ("La catégorie n'est pas renseignée")
        }else if (!newBooking.quantity || newBooking.quantity === 0){
          
            userContext.setQuantityError("Merci de sélectionner le nombre de participants");
            console.log( "Le nombre de personnes n'est pas renseigné")
        }else{
            userContext.setQuantityError("");
        }
        if (!newBooking.chosen_time_stamp || newBooking.chosen_time_stamp === ""){
            setTsError ("Merci de choisir votre créneau horaire avant de continuer");
            console.log ("Le créneau horaire n'est pas renseigné");
        }else{
            setTsError("");
        }
        if (!newBooking.user === undefined || newBooking.user === null){
            setMyError("Vous avez été déconnecté, merci de vous reconnecter pour prendre rendez-vous");
            console.log("L'utilisateur n'est pas connecté");
        }
        if (!thoseRidersAgeAreOk){
            console.log("L'âge de tous les cavaliers n'est pas renseigné");
            props.presta === "0" || props.presta === "2" ?
                userContext.setAgeError("Merci d'utiliser la glissière pour renseigner l'âge de chaque cavalier")
                : (props.presta === "1" ?
            userContext.setAgeError("Merci d'utiliser la glissière pour renseigner le niveau de chaque cavalier")
                : userContext.setAgeError(""));
        }else{
            userContext.setAgeError("");
        }
        
        //setMyError(" Merci de renseigner tous les champs avant de valider");
        setProcessing(false);
    }
    
    
}

const PaymentProceed = async (pBookingIsOk) =>{        
    console.log("on rentre dans paymentproceed");
    console.log("bookingIsok : " + pBookingIsOk);
    console.log("clientsecret : " + endOfClientSecret)
    // Step 3: Use clientSecret from PaymentIntent and the CardElement
    // to confirm payment with stripe.confirmCardPayment()
    if (endOfClientSecret && pBookingIsOk){ // si on a reçu un clientSecret et la réservation est bonne
        console.log("on lance stripeconfirm");
        console.log("name : " + billingDetails.name);
        try {
            const payload = props._is_mounted && await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: billingDetails,                    
                },
            });
            if (payload !== undefined){
                console.log ("payload not undefined");
                if (payload.error) {
                    setApiError(`L'authentification du paiment a échoué : ${payload.error.message}`);
                    setProcessing(false);
                    console.log("[error]", payload.error);
                } else {
                    setError(null); 
                    setApiError("");                     
                    setStripeResponse(payload.paymentIntent);                        
                    setSucceeded(true);
                    console.log("[payload]", payload.paymentIntent);
                    if ( cardComplete && endOfClientSecret && payload.paymentIntent && endOfBookingDetails && pBookingIsOk && userContext.selectedValue !== ""){
                        const amount = payload.paymentIntent.amount;                        
                        const paymentId = payload.paymentIntent.id;                                                                
                        setMyError("");                        
                        setStripeMessage(`Vos arrhes ont été acceptées sous la référence ${paymentId} pour un montant de ${amount/100}€`);                        
                        setEndOfQueries(true);
                        if(stripeMessage !== undefined && stripeMessage !== ""){
                            setProcessing(false); 
                        }
                    }                    
                }
            }else{
                setProcessing(true);
               
            }
        } catch (error) {
            setError("Un problème est survenu lors de votre paiement");
        }
    }
}
    
    
    
    /**
    * 
    * @param {*} event soumission du formulaire de paiement 
    */
    const handleSubmit = async (event) =>{
        
    event.preventDefault();        

        setMyError("");
        userContext.setIsBookingSubmitted(true);
        userContext.setAgeError(""); // reset erreur age and level
        const ridersOk = props.presta === "0"  || props.presta === "2"  ? ridersAgeOrLevelIsOk(props.ages) : props.presta === "1" ? ridersAgeOrLevelIsOk(props.levels) : true ;
        console.log("ridersOk : " + ridersOk);
        if (ridersOk !== undefined){
            setRidersAreOk(ridersOk);
           
            if (ridersOk === false && userContext.isBookingSubmitted === true){
                userContext.setShowSlide(true);
                props.presta === "0"?
                userContext.setAgeError("Merci d'utiliser la glissière pour renseigner l'âge de chaque cavalier")
                : (props.presta === "1" &&
                userContext.setAgeError("Merci d'utiliser la glissière pour renseigner le niveau de chaque cavalier"));
                
            }
        }
        
        if (userContext.dbUser.phone !== ""){
            setTelIsDone(true);
        }else{
            setTelIsDone(false);
        }
        if (userContext.dbUser.name !== ""){
            setNameIsDone(true);
        }else{
            setTelIsDone(false);
        }        
        if (cardComplete && props.eventisok && props.quantity > 0  && userContext.tsIsOk && nameIsDone && telIsDone && ridersOk){            
            userContext.setChoiceIsOk(true);
            console.log("choiceisok");           
        }else{
            userContext.setChoiceIsOk(false);
            setEndOfQueries(false);
            console.log("choiceisNOTok");
        }
        
        // gestion des messages d'info action utilisateur
        
        // si pas de num de carte au submit        
        if (cardComplete === false ){            
            setMyError("Merci de renseigner votre numéro de carte bleue");
            console.log("erreur carte value");                
        }
        // si pas de créneau horaire
        if (!userContext.tsIsOk)
        {
            setTsError ("Vous devez choisir votre créneau horaire avant de continuer");
            // focus sur l'erreur       
        }
        // si pas de sélection du nbPersonnes
        if (userContext.selectedValue === "" || props.quantity === 0){
            console.log("erreur quantité");
           
            setProcessing(false);
            userContext.setQuantityError("Merci de sélectionner le nombre de participants");                
        }
        const renderRidersOk = props.presta === "0"  || props.presta === "2"  ? ridersAgeOrLevelIsOk(props.ages) : props.presta === "1" ? ridersAgeOrLevelIsOk(props.levels) : true ;
        //title: `Vos arrhes de ${stripeResponse.amount/100}€ ont été acceptées sous la référence : ${stripeResponse.id}`,
        
        if (!renderRidersOk){
            // doit libérer la glissière
            userContext.setShowSlide(true);           
            setProcessing(false);
            console.log("erreur participants");
          
            props.presta === "0"?
            userContext.setAgeError("Merci d'utiliser la glissière pour renseigner l'âge de chaque cavalier")
            : (props.presta === "1" &&
            userContext.setAgeError("Merci d'utiliser la glissière pour renseigner le niveau de chaque cavalier"));
            
            
        }else{
            userContext.setAgeError("");
        }
        // si pas de tel || nom
        if (telIsDone === false || !nameIsDone === false || billingDetails.phone === "" || billingDetails.name === ""){
          
            setCoordError("Merci de renseigner vos coordonnées");                
        }
        if(!props.eventisok){
         
            setEventError("Merci de sélectionner l'une des activités disponibles à la réservation");
        }
  
        if (userContext.user === undefined || userContext.user === null  || userContext.user.email === ""){
          
            console.log("erreur user déconnecté");
            setMyError("Vous avez été déconnecté, merci de vous reconnecter pour prendre rendez-vous");
            
        }        
        
        // on désactive la soumission du formulaire de paiement tant que stripe n'est pas load
        if (!stripe || !elements){
            console.log("il manque stripe ou elements");
            setMyError("Merci de vous reconnecter, le module de paiement ne s'est pas chargé correctement");
            return;
        }       
        // on attend que l'utilisateur rectifie suite au message d'erreur   || myError !== "" || props.choiceisok === false    
        if (error) {
            elements.getElement('card').focus();
            if (error){
                console.log ('[error]' + error);
            }
            /*      return; */
        }
        
        // si tout ok, on envoie et plus de message d'erreur 
        if (window.navigator.onLine){            
            const authUser = userContext.user ;
            if (authUser !== undefined && authUser !== null){ 
                const letsGoAndPay = userContext.choiceIsOk && cardComplete && props.eventisok && props.quantity > 0  && userContext.tsIsOk && nameIsDone && telIsDone && renderRidersOk;
                    
                if (letsGoAndPay){   
                    setProcessing(true);
                    /**
                    * MISE A JOUR USER
                    */
                    // met à jour le user avec les nouvelles entrées
                    const completedUser = {                    
                        username: authUser.username,
                        name: billingDetails.name,
                        phone: billingDetails.phone,
                        mail: authUser.email, 
                        password: authUser.password                 
                    }
                    
                    try {
                        console.log("try de updateUserFromStripe");
                        const data = isMounted && await userContext.updateUserFromStripe(completedUser);  
                        if (data !== undefined){
                            if (data.error){
                                setMyError(data.error + " Vous devez vous connecter pour réserver");
                                console.log("on passe dans l'erreur : " + data.error);
                                setProcessing(false);
                            }else{
                                console.log("on lance le paiement");
                                setCompleteUser(data.user);
                                // booking + payment
                                setBooking(data.user);
                            }
                        }else{
                            setApiError("Processus en cours, merci de patienter...");                                
                        }
                        
                    } catch (error) {
                        setMyError("La tentative de mise à jour de vos données a échoué " + error);
                        setProcessing(false);
                        console.log("tentative d'update du User échouée");
                    } 
                }
            }else{
                setMyError("Utilisateur non valide");
                setProcessing(false);
           
            }
        }else{
            setNavError("Oups, il semblerait que vous ayez perdu votre connexion internet");
            setProcessing(false);
         
        }  
        
    }
    
    
    const keyPressed=(event)=>{
        console.log("dans keypressed : event : " + event.key)
        if (event.key === "Enter") {      
            event.preventDefault();              
        }
    }
    const keyTabPressed=(event)=>{
        console.log("dans keyTabpressed : event : " + event.key)
        if (event.key === "tab") {      
            event.preventDefault();              
        }
    }
    
    
    const handleTelChange = ({ target: { value } }) => {
        
        value = value
        // Remove all non-digits, turn initial 33 into nothing
        .replace(/\D+/, '')
        .replace(/^330?/, '0')
        // Stick to first 10, ignore later digits
        .slice(0, 13)
        // Add a space after any 2-digit group followed by more digits
        .replace(/(\d{2})(?=\d)/g, '$1 ')
        
        // stripe 
        setBillingDetails({...billingDetails, phone: value});
        setTelIsDone(true);
        
    } 
    
    
    
    const {datechoice, hourchoice, isFullWeek} = props
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    
    const formatedDateChoice = datechoice ? datechoice.toLocaleDateString('fr-FR', dateOptions) : ''
    
    const userMail = userContext.user.email
    const quantityError = userContext.quantityError
    const ageError = userContext && userContext.ageError
    const renderRidersOk = props.presta === "0"  || props.presta === "2"  ? ridersAgeOrLevelIsOk(props.ages) : props.presta === "1" ? ridersAgeOrLevelIsOk(props.levels) : true ;
    //title: `Vos arrhes de ${stripeResponse.amount/100}€ ont été acceptées sous la référence : ${stripeResponse.id}`,
    const letsGoAndPay = userContext.choiceIsOk && cardComplete && props.eventisok && props.quantity > 0  && userContext.tsIsOk && nameIsDone && telIsDone && renderRidersOk;
    console.log("Qui? context choiceisOk", userContext.choiceIsOk);
    console.log("cardComplete", cardComplete);
    console.log("props.eventisok ", props.eventisok )
    console.log("props.quantity", props.quantity);
    console.log("userContext.tsIsOk", userContext.tsIsOk);
    console.log("nameIsDone", nameIsDone);
    console.log("telIsDone", telIsDone);
    console.log("ridersAreOk", renderRidersOk);
    
    const messages = [
        {id: 0, message: "- Sélection de l'activité", condition:!props.eventisok},
        {id: 1, message: "- Nombre de participants", condition: !props.quantity},
        {id: 2, message: "- L'âge ou le niveau des enfants", condition: !renderRidersOk},
        {id: 3, message: "- Votre créneau horaire (jour et heure)", condition:!userContext.tsIsOk},
        {id: 4, message: "- Votre nom", condition:!nameIsDone},
        {id: 5, message: "- Votre numéro de téléphone", condition:!telIsDone},
        {id: 6, message: "- Informations de paiement", condition: !cardComplete}
    ]
    const showMessages =()=>{
        const visibleMessages = messages && messages.filter(aMessage=>aMessage.condition === true);
        if(visibleMessages && visibleMessages.length>0){
            return true;
        }
    }
    const confirmState = {
        title: stripeMessage && stripeMessage,       
        newbooking: newBooking,
        striperesponse : stripeResponse && stripeResponse
    }

    return (
        (endOfQueries && succeeded)?
        <Navigate to="/bookingconfirm" replace={true} 
            state={{title: stripeMessage && stripeMessage,       
            newbooking: newBooking,
            striperesponse : stripeResponse && stripeResponse}}/>
         : 
        
        <form className='Form' onSubmit={handleSubmit}>
        <label className="form-label">{props.start + props.step + 3} - Avance sur règlement</label>
        <h1 className="form-title">Nous vous remercions de verser des arrhes afin de confirmer votre présence</h1>
        <fieldset className="FormGroup">
        <Field            
        label = "Nom"
        id = "name"
        type = "text"
        placeholder ="Votre nom"
        required
        autoComplete ="name"
        value = {billingDetails.name}
        onChange = {(e)=>{
            setNameIsDone(true);
            setBillingDetails({...billingDetails, name: e.target.value});
        }}
        /* onKeyPress = {keyPressed} */
        />            
        
        
        <Field
        disabled = {userContext && userContext.dbUser && userContext.dbUser.phone !== ""}
        label="Téléphone"
        id="phone"
        type="tel"
        placeholder="Votre numéro"
        required
        autoComplete="tel"
        value={billingDetails.phone}
        onChange= {handleTelChange}
        /* onKeyPress = {keyPressed} */
        />
        <div className="FormRow">       
        <StyledInputEmailStripe
        label = "Email"
        id = "email"
        type="email"                
        value={userMail}
        disabled={true}
        
        /* onKeyPress = {keyPressed} */
        />
        </div>
        </fieldset>
        <fieldset className="FormGroup">
        
        
        <CardField
        id="card-element"
        required
        onChange={(e)=>{
            setError(e.error)
            setCardComplete(e.complete)
            setPaymentError("")
            setMyError("")               
            console.log("valeur cb : " + e)
        }}
        />
        </fieldset> 
        
        <fieldset className="relative-wrapper">
        {/* Erreurs*/}
        <CenteredLabel            
        isvalid = {false}>{eventError ? eventError : "" }                       
        </CenteredLabel>
        {/* Erreurs auto Stripe */}
        {error && <CenteredLabel isvalid = {!error}>{error.message }</CenteredLabel>}
        {showMessages() ? "Informations nécessaires à la réservation : " : ""}
        {messages && messages.map((aMessage)=>(
            aMessage.condition && <div key={aMessage.id} className='error-message-wrapper'>
            <CenteredLabel
            ref={props.focusInfo}
            isvalid = {false}>{aMessage.message}
            </CenteredLabel>
            </div>
        ))}
        <CenteredLabel
        ref={props.focusInfo}
        isvalid = {false}>{myError}
        </CenteredLabel>
        <CenteredLabel            
        isvalid = {false}>{navError ? navError : "" }                      
        </CenteredLabel>
        <CenteredLabel isvalid={false}>{apiError}</CenteredLabel>
        <CenteredLabel isvalid={false}>{(error && error.message === paymentError) ? "" : paymentError}   </CenteredLabel>
        
        
        <CenteredLabel            
        isvalid = {false}>{message ? message : "" }                       
        </CenteredLabel>
        <CenteredLabel            
        isvalid = {false}>{quantityError && quantityError }

        </CenteredLabel>
        <CenteredLabel            
        isvalid = {false}>{ageError && ageError}

        </CenteredLabel>
        <CenteredLabel            
        isvalid = {false}>{tsError ? tsError : "" }                       
        </CenteredLabel>
        <CenteredLabel            
        isvalid = {false}>{coordError ? coordError : "" }                             
        </CenteredLabel>
        
        
        <div className="choice" ref={props.focusInfo}> 
        {datechoice ? "Votre choix : " : ""}
        {datechoice && isFullWeek && props.presta === "2" ? `Semaine du ${format(datechoice, 'dd MMM yyyy', { locale: fr } )}` : (datechoice ? `le ${format(datechoice, 'dd MMM yyyy', { locale: fr } )} ` : '')}                
        {hourchoice ? `à ${hourchoice}` : ``}
        </div>          
        <SubmitButton 
        type="submit" 
        processing={processing}
        error={error} 
        disabled={!stripe || !letsGoAndPay}           
        keyPressed={keyTabPressed}
        >
        <span className={stripe && letsGoAndPay?'oklabel':'errorlabel'}>{stripe && letsGoAndPay ?  `Verser ${props.amount/100} € d'arrhes et réserver` : !stripe ? "Erreur module de paiement":"Tous les champs sont requis"}</span>
        </SubmitButton>           
        <span>{processing && "...Paiement en cours, merci de patienter"}</span>
        
        </fieldset>
        </form>
        )
    }

    
        export default withUser(MyCheckoutForm)
