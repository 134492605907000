import React, { useEffect, useState, useRef } from 'react'
import {Navigate, useLocation, Link} from 'react-router-dom';
import {StyledButtonWrapper, StyledButtonConfirm} from "../common"
import {UserContext, withUser} from "../contexts/UserContext";
import { BookingContext } from '../contexts/BookingContext';
import {AdminLink} from "../components/AdminLink"



function Confirm (props) {
    
    const { infoconfirm, labelconfirm} = props;
    const bookingContext = React.useContext(BookingContext);
    const userContext = React.useContext(UserContext);
   
    const is_confirmed = userContext.dbUser.is_confirmed; // renvoie true si l'adresse mail est approuvée
    const path = props.isbookingconfirm === true ? '/booking' : '/';
    const ismailnotfound = props.ismailnotfound;
    const adminClassName = ismailnotfound ? "admin-link" : "noadmin-link";
    const [_isMounted, set_isMounted] = useState(false);
    const [booking, setBooking] = useState(null);
    const [message, setMessage] = useState("");
    const [processing, setProcessing] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isCreatedBooking, setIsCreatedBooking] = useState(false); // against duplicates
    const [stripeMessage, setStripeMessage] = useState("");
    const [updateTime, setUpdateTime] = useState(-1)
    const [redirect, setRedirect] = useState(false);
    const [redirectHome, setRedirectHome] = useState(false);
    const timerRef = useRef(null);
    const timerSignUp = useRef(null);
    const firstUpdate = useRef(true);
    const location = useLocation();
    console.log("titleconfirm ",  props.titleconfirm);
    console.log("authuser : ", userContext.isAuth);
    
    const titleconfirm = props.titleconfirm ? props.titleconfirm : location.state.title;
    const bodyconfirm = props.bodyconfirm ? props.bodyconfirm : "";
    const striperesponse = location && location.state && location.state.striperesponse && location.state.striperesponse;

/** On reset les redirections */
    useEffect(() => {
     set_isMounted(true);
    // Define createbooking here to avoid dependencies warnings
    const createBooking = async (newBooking) =>{
        if (newBooking !== null && newBooking !== undefined){    
        try {     
            setProcessing(true);
            const res = !isCreatedBooking && await bookingContext.createBooking(newBooking, striperesponse);
            
            if (res !== undefined){
                if (res.error){
                    setMessage("" + res.error);
                    console.log("on rentre dans l'erreur response createbooking : " + res.error) ;
                    setProcessing(false);
                }
                if (res.bookingData) { // booking + envoi mail ok              
                    
                    userContext.setIsBookingSubmitted(false); // reset isSubmitted une fois la réservation créée
                    setBooking(res.bookingData); 
                    console.log("res.bookingData" + JSON.stringify(res.bookingData));
                    if (res.bookingData.category !== 2){
                        setMessage(`La réservation n° ${res.bookingData.id} a été effectuée. RDV le ${bookingContext.formatToLocalDateTime(res.bookingData.chosen_time_stamp)}`);
                    }else{
                        setMessage(`La réservation n° ${res.bookingData.id} a été effectuée. RDV le ${bookingContext.formatToLocalDate(res.bookingData.chosen_time_stamp)}`);
                    }
                    setIsCreatedBooking(true);
                    setIsEmailSent(true);
                    setProcessing(false);
                    timerRef.current = setTimeout(() => setRedirect(true), 10000);
                    
                }
                if (res.isEmail2Sent){
                    console.log("Envoi mail 1 ok");
                }
                if(res.isEmail0Sent){
                    console.log("Envoi mail 2 ok");
                }
                
              
                
            }else{            
                setMessage("Enregistrement de la réservation en cours, merci de patienter ...");
            }
        } catch (error) {
            console.log ("on rentre dans le catch createBooking : " + error);
            
            setProcessing (false);
        }
        }else{
            if ( newBooking === null ){
                setMessage ("La réservation n'a pas pu être effectuée, merci de nous contacter avec vos références de paiements pour finaliser le rendez-vous");
                setProcessing(false);
            }else{
            setMessage("Un problème de communication est survenu, merci de réessayer ultérieurement");
            setProcessing(false);
            }
        }

    // fin createBooking
    }
    if (firstUpdate.current){
        firstUpdate.current = false;
        // create booking first mount       
       
        if (userContext.isBookingSubmitted === true && !isCreatedBooking && props.isbookingconfirm && location && location.state && location.state.newbooking !== null && location.state.newbooking !== undefined && striperesponse !== undefined && striperesponse !== null && striperesponse.id !== ""){
            console.log("booking to create : ", location.state.newbooking);
            createBooking(location.state.newbooking);                    
        }
        return;
    }
    console.log("iscreatedbooking : ", isCreatedBooking);
    /* if(isCreatedBooking){
        console.log("on lance le timer");
        timerRef.current = setTimeout(() => setRedirect(true), 10000);
    } */
    console.log("not the first update");
   
            
        // redirection timeout after message unless bookingconfirm
        if ( props.isTimeOutAuto === true){
            timerSignUp.current = setTimeout(() => setRedirectHome(true), 10000);
        }
       
        bookingContext.resetGos();        
        if (window.navigator.onLine && message !== ""){
            setMessage("");   
        }
        console.log ("avant condition create booking : isMounted" , _isMounted)
       
        
   
        
        return ()=>{
            if (_isMounted !== undefined){
            // booking redirect
            if (timerRef.current && _isMounted === false) {
            clearTimeout(timerRef.current); 
            }
            // signup redirect
            if (timerSignUp.current && _isMounted === false) {
            clearTimeout(timerSignUp.current); 
            }
                
            }
        }
       
    }, [_isMounted, updateTime]);


   


const isFullAuth = userContext.isAuth === 2 || userContext.isAuth === 1;
    if (redirectHome){
        return <Navigate to="/" redirect="true"/>
    }else if (redirect) {
      return <Navigate to="/booking" redirect="true"/>
  }else{
      return<>
         <div className="confirm-page">
             <span className="pricing">{isFullAuth && "Bienvenue"}  <span className="welcome">{userContext.user && userContext.user.username}</span></span>
          <div className="pricing-body">
            {titleconfirm && titleconfirm !== "" && titleconfirm}            
           <div className="confirm-mess">
                {bodyconfirm && bodyconfirm !== "" && bodyconfirm} <AdminLink className = {adminClassName}/>
                
           </div>
           <div className="pricing">            
            {/* {datechoice && datechoice } {hourchoice && `à  ${hourchoice}` } */}
            <p>{message && message}</p>
            <p className="pricing-body">{props.isbookingconfirm === false ? infoconfirm : (props.isbookingconfirm && booking !== null && booking !== undefined && processing === false && isEmailSent) ? infoconfirm : "" }</p>
           </div>
        
        
        </div>
        
        <Link to = {path} className="link-alone"> 
                <StyledButtonWrapper>
                    <StyledButtonConfirm disabled={processing}>
                    {labelconfirm && labelconfirm}
                    </StyledButtonConfirm>
                </StyledButtonWrapper>           
        </Link>
        
        </div>
        </>

}}
export default Confirm