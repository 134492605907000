import React, { Component } from 'react';
import '../App.css';
import {BookingCardAdmin } from '../common/index';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { parseFromTimeZone } from 'date-fns-timezone';
import { withBooking } from '../contexts/BookingContext';
import { getDay } from 'date-fns';
import items from '../data/datatoupdate';
import {setAmountByCatAndQuantity} from "../data/clientUtils";





class AdminBookingCard extends Component {
    constructor(props){ // user, category, categoryid, chosen_time_stamp, created_at
        super(props);
        this.rbForwardedRef = React.createRef();
        
       
    }
   
    state={
        date: null,
        selectedOption: "",
        choiceIsOk: false,
        myError: "",     
        myHourTable: [],
        isSubmitted: false,
        isProcessing: false,
        isToHide: true
    }
    resetBookingIsUpdate=()=>{
        this.props.context.setUpdateState(0);
        this.setState({
            choiceIsOk : false,
            isSubmitted : false,
            isToHide: true
        })     
    }

     /**
     * Méthode de rendu du créneau choisi
     */
      handleChosenTimeStamp = () =>{        
        let formatedDateChoice = "";
        // on choisit une date
        const date = this.state.date;
        const hour = this.state.selectedOption;
        // au chargement            
            if (this.props.chosen_time_stamp !== undefined){
                if (this.props.categoryid !== 2){
                    formatedDateChoice = this.props.context.formatToLocalDateTime(this.props.chosen_time_stamp);
                }else{
                    formatedDateChoice = this.props.context.formatToLocalDate(this.props.chosen_time_stamp);
                }
                //console.log("date null, updatedChosenTs : " + this.state.updatedChosenTs);
            }
        
        
        return formatedDateChoice;
        }

    componentDidMount(){
       
        /*Préselection de la date au créneau horaire choisi*/        
        const day = this.formatToUtcDate(this.props.chosen_time_stamp);  
        this.setState({date:day}); 

        }
        
       
        
    componentDidUpdate(prevState, prevProps){
        const {date,selectedOption, isSubmitted} = this.state;       
        const dbBooking = this.props.context.dbBooking;   
               
        if (prevState !== null && prevState !== undefined && prevState.date!== null && prevState.date !== undefined && date !== null && date !== undefined && date !== prevState.date){
            this.setState({date});            
        }
        if (prevState !== null && prevState !== undefined && prevState.selectedOption!== null && prevState.selectedOption !== undefined && selectedOption !== null && selectedOption !== undefined && selectedOption !== prevState.selectedOption){
            this.setState({selectedOption});            
        }
        if(this.props.context.updateState === 2){
            
           setTimeout(() =>this.resetBookingIsUpdate(), 2000);
            
        }
    
    }

 
   
  /**  
   * 
   * @param {table : date} items 
   * @returns {table : string} tableau des horaires disponibles pour 1 jour
   */
  _handlePossibleHours=(date, category)=>{
    this.setState({isSubmitted: false});
    this.setState({choiceIsOk: false});
    this.setState({isToHide: false});
    this.props.context.setUpdateState(0);
    let myHourTable = []; // reset du tableau des horaires, on ne cumule pas les horaires dispos
    let timeSlotsPerDay = [];    
    // 2- on récupère le numéro du jour de la date sélectionnée
    const numDay = getDay(date);    
    // 3- on charge les horaires dispos pour ce jour
    // pour les baby, category 0, pour les prestas encadrées category 1
    timeSlotsPerDay = items.find(item=> parseInt(item.fields.numday, 10) === numDay && item.fields.category === category.toString());
    console.log("les créneaux dispos " + timeSlotsPerDay.fields.possiblehours)
    // 4- un peu de ménage
    timeSlotsPerDay.fields.possiblehours.map((item, index)=>{      
        myHourTable.push(item);
    })   
     this.setState({date});
     this.setState({myHourTable});  
     this.setState({selectedOption: null}); //reset les créneaux dispos à chaque changement de date
 }


    formatToUtcDate(date){        
        // Set the date to "2018-09-01T16:01:36.386Z"
        const utcDate = parseFromTimeZone(date, { timeZone: 'Europe/Berlin' }); 
        return utcDate;
      }

    
    formatToLocalDateTime(date){        
        // Set the date to "2018-09-01T16:01:36.386Z"
        const utcDate = parseFromTimeZone(date, { timeZone: 'Europe/Berlin' });
        let localDay = format (utcDate, 'dd MMM yyyy', { locale: fr });
        let localHour = format (utcDate, 'H mm', { locale: fr });
        localHour = localHour.replace(' ', 'h');
        return localDay + " à " + localHour;
    }

    

    setMyError(bookingState, choiceIsOk, isSubmitted){
        let message = "";
    
        if (isSubmitted && choiceIsOk === false){
            message = "Vous devez choisir un créneau horaire"
        }
        if (isSubmitted && choiceIsOk === true && bookingState === 2) {
            message = "Modification effectuée, un mail de confirmation vous a été envoyé"; 
        } 
        if (isSubmitted && choiceIsOk && bookingState === 1){
            message = "Chargement..."
        }
       
    
        return message;
    }

    ridersLine = (pCategoryId) =>{
        return  <div className="cardlinewrapper">
                    <div className="admincolumn-card">
                    <span className="table-header">{this.props.riders.length > 0 && ((pCategoryId === 0 || pCategoryId === 2) ? "Âge" : pCategoryId === 1 && "Niveau" ) }</span>
                    </div>
                <div className="admincolumn-card">
                    {(this.props.riders && this.props.riders.length > 0) && 
                    this.props.riders.map(rider=><span key={rider.id}> - {(pCategoryId === 0 || pCategoryId === 2) ? rider.age : pCategoryId === 1 && `${rider.level}`}{(pCategoryId === 0 || pCategoryId === 2) && (rider.age === "10" ? "ans et plus " : (rider.age === "1" ? "an " : "ans "))}</span>)}
                </div>
                </div>
    }
   

    render() {
        const {user, category, categoryid, quantity, created_at, index, isfullweek} = this.props
        const {isSubmitted, choiceIsOk, myHourTable} = this.state        
        const updateState = this.props.context.updateState
        const isProcessing = this.state.isProcessing
        const datechoice= this.state.date
        const hourchoice=this.state.selectedOption       
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        //const formatedDateHour = this.props.context.handleFormatedDateHour(datechoice, hourchoice)
        const formatedDateChoice = this.handleChosenTimeStamp()
        //let myError = this.setMyError(updateState, choiceIsOk)
        const myError = updateState === 2 && choiceIsOk && isSubmitted ? "Modification effectuée, un mail de confirmation vous a été envoyé" : (!choiceIsOk&& isSubmitted ? "Merci de choisir un créneau horaire":"")
        const amount = setAmountByCatAndQuantity(categoryid, quantity, isfullweek);        
        return (
            <>
            <BookingCardAdmin>
            <div className="cardlinewrapper">
                <div className="admincolumn-card"><span className="table-header">Référence</span></div>
                <div className="admincolumn-card">{index}</div>
               
            </div>
            <div className="cardlinewrapper">
                <div className="admincolumn-card"><span className="table-header">Catégorie</span></div>
                <div className="admincolumn-card">
                {category}{(categoryid.toString() === "2" && isfullweek === true) ? " semaine complète" : ""}
                </div>
               
            </div>
            <div className="cardlinewrapper">
                <div className="admincolumn-card"><span className="table-header">Créneau choisi</span></div>
                <div className="bookingcolumn-title">
                {(categoryid.toString() === "2" && isfullweek === true) ?
                    `Semaine du ${formatedDateChoice}` : formatedDateChoice}
                </div>
                
            </div>
            <div className="cardlinewrapper">
                <div className="admincolumn-card"><span className="table-header">Passée le</span></div>
                <div className="admincolumn-card">{created_at ? `${this.props.context.formatToLocalDateTime(created_at)} ` : ''}</div>
                
            </div>
            <div className="cardlinewrapper">
                <div className="admincolumn-card"><span className="table-header">Nb cavaliers</span></div>
                <div className="admincolumn-card">{quantity}</div>
                
            </div>            
                {this.ridersLine(categoryid)}        
              
            
            <div className="cardlinewrapper">
                <div className="admincolumn-card"><span className="table-header">Arrhes</span></div>
                <div className="admincolumn-card">{amount/100}€</div>                
            </div>
            <div className="cardlinewrapper">
                <div className="admincolumn-card"><span className="table-header">Par</span></div>
                <div className="admincolumn-card">
                    <span> {user.name}</span>
                </div>
            </div>
            <div className="cardlinewrapper">
                <div className="admincolumn-card"><span className="table-header">Tel</span></div>
                <div className="admincolumn-card">
                    <span> {user.phone}</span>
                </div>
            </div>
            <div className="cardlinewrapper">
                <div className="admincolumn-card"><span className="table-header">mel</span></div>
                <div className="admincolumn-card">
                    <span> {user.mail}</span>
                </div>
            </div>
            
            
        
         
            {/* <div className="linewrapper">
                <span>{isUpdated && " Mise à jour effectuée"}
            </span>
            </div> */}
            </BookingCardAdmin>
           </>
            )
        }
    }
    export default withBooking(AdminBookingCard)