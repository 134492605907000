import React, { Component } from 'react';
import '../App.css';
import { format } from 'date-fns';
import { parseFromTimeZone } from 'date-fns-timezone';
import { fr } from 'date-fns/locale';
import {withBooking} from '../contexts/BookingContext';
import FullDatePicker from './DatePickerFromTsx';


class DatePickerInput extends Component {
  
  constructor(props){
    super(props); 
    this._isMounted = false;   
    this.state = { 
     
      error: "",
      isDateClicked: false
     
    }
 
  }


  async componentDidMount(){
    this._isMounted = true;
   
  }
  
  componentDidUpdate(prevState){
   
}

componentWillUnmount(){
  this._isMounted = false;
}
handleHighLightByCat(cat, date){  
  let isToHighLight = false;
  const clickedDate = this.props.date && this.props.date;
  const stageDates = this.props.days && this.props.days;
  const isFullWeek = this.props.isFullWeek && this.props.isFullWeek;
  // si le numday n'est pas entre les dates de début et de fin de stage, on désactive
  if (cat === "2" && isFullWeek === true){    
    if (stageDates !== undefined && stageDates !== null && date !== null && date !== undefined && clickedDate !== undefined && clickedDate !== null){             
      
      const DatesOfWeek = stageDates.filter(stageTs => stageTs.dt_start <= clickedDate.toISOString() && stageTs.dt_end >= clickedDate.toISOString());
      const theDay = DatesOfWeek.find(stageTs => stageTs.dt_start <= date.toISOString() && stageTs.dt_end >= date.toISOString());
      const theClickedDateInStage = DatesOfWeek.find(stageTs => stageTs.dt_start <= clickedDate.toISOString() && stageTs.dt_end >= clickedDate.toISOString());
      console.log("les dates de la semaine : " , DatesOfWeek);
      if (theDay !== undefined && theClickedDateInStage !== undefined){
        console.log("on highlight : " + date);
        isToHighLight = true;
      }else{
        isToHighLight = false;
      }      
    }
  }
  return isToHighLight;
}

formatToLocalDate(date){        
  // Set the date to "2018-09-01T16:01:36.386Z"
  const utcDate = parseFromTimeZone(date, { timeZone: 'Europe/Berlin' });
  console.log("date utc : " + utcDate);
  let localDay = format (utcDate, 'dd MMM yyyy', { locale: fr });  
  return localDay.toString();
}
handleDisabledByCat(cat, date){
  
  if(cat !== 2){
   if (this.props.context && this.props.daysToDisable && this.props.tsToDisable ){
     return this.props.context.handleDayDisable(date, this.props.daysToDisable, this.props.tsToDisable, cat);
   }else{
     return date;
   }
  }else{ // stages
   if (this.props.days){
    
    return this.props.context.handleStageDayDisable(date, this.props.days);

  }
}
}
  
  render() { 
    const {date, days} = this.props
    const day = this.formatToLocalDate(this.props.chosen_time_stamp) 
 
    const modifiers = {   
      disabled: date => this.props.categoryid !== undefined ? this.handleDisabledByCat(this.props.categoryid, date):true, // disable les créneaux non dispos
      highlight: date => this.props.category ? this.handleHighLightByCat(this.props.categoryid.toString(), date.date): false // nothing highlighted by default
  }

    
     
     const datePickerProps = {
      startDate: date,
      endDate: null,
      onStartDateChange: (date) => this.props.handlePossibleHours(date, this.props.categoryid.toString(), days),
      onEndDateChange: null,
      name:"datePicker",
      format:'dd MMM yyyy',
      locale:"fr-fr",
      modifiers: modifiers,
      inputDisabled: this.props.loadingts || this.props.loadingbookings
  }
  
    return (
      <>
     <div className='datetime-wrapper'>
      <FullDatePicker {...datePickerProps} />
     </div>
  
           
        
        </>
    )
    
  }
}

export default withBooking(DatePickerInput)