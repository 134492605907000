import React, { Suspense, useState, useEffect } from 'react'
import { withUser } from '../contexts/UserContext'




function AuthUser(props) {

   return props.context.user && props.context.isAuth !== -1 &&(
    <>
   
    <Suspense fallback={<h1>Chargement de profil en cours...</h1>}>
     
      <h1> {props.context.user.username}</h1>
    </Suspense>
    </>
    )
}
export default withUser(AuthUser)