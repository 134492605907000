import React, { Component } from 'react'
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import{loadStripe} from '@stripe/stripe-js';
import MyCheckoutForm from "./StripeCheckoutForm";
import DatePicker from "./DatePicker";
import { getDay, addDays } from 'date-fns';
import '../styles/StripeCardDetails.css';
import "../App.css";
import {CheckBoxWeek, CheckWeekWrapper, StyledForm, VerticalWrapper, FormWrapper, Label, StyledInputCard, LabelFullWeek} from "../common";
import {SelectInput} from "./SelectInput";

/* import "../styles/DatePicker.css"; */
import {RbForwardRef} from "./RbForwardRef";
import {withUser} from "../contexts/UserContext";
import { parseFromTimeZone } from 'date-fns-timezone';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import stripeApi from '../stripeApi';
import InputRange from './InputRange';
import {setAmountByCatAndQuantity, formatUnits} from "../data/clientUtils";




const ELEMENTS_OPTIONS = {
    fonts : [
        {cssSrc : 'https://fonts.googleapis.com/css?family=Roboto',
    },
    ],
};

const NBLIST = ["1","2","3","4","5","6","7","8","9","10", "11", "12", "13", "14", "15"];
const maxAge = 10;
const minAge = 3;

const minLevel = 1;
const maxLevel = 7;
const stripePromise = stripeApi.getPublicStripeKey().then(key => loadStripe(key));


class Stripe extends Component {  
      
    constructor(props){
        super(props);
        this.focusInfo = React.createRef();
        this.focusInput = React.createRef();
        this.rbForwardedRef = React.createRef();
        this._isMounted = false;
    }

    state={
        selectedOption: "",
        date: null,
        myHourTable: [],     
        tofocus: false,
        message: "",       
        quantity: 0,
        amount : 1000,
       
        isDateFocused: false,
        tFreeTs : [],
        tBusyTs: [],
        calendarInfo:"",
        stripePromise: null,
        stripe: null,
        stripeTest: null,
        /*Riders age*/       
        ages: [],
        levels: [],
        riders: [],
        age: 1,
        showRidersAge: false,
        showRidersLevel: false,
        nbDays: 1 // init à nbDays mini
       
        
        
    }
    componentDidUpdate(prevState){
        
        if (this.rbForwardedRef !== null && this.rbForwardedRef.current !== null && this.state.isDateFocused){ 
            this.rbForwardedRef.current.focus();
            this.setState({isDateFocused: false}); // reset focus date
       }
       if (this.state.message === "" && !window.navigator.onLine){
           this.setState({message : "Oups, il semblerait que vous ayez perdu votre connexion internet"});
       }
       if(this.state.message !== "" && window.navigator.onLine){
           this.setState({message: ""});
       }
       // reset amount when noQuantity selected choiceIsOk = false
        if (this.props.context.selectedValue === '' && this.state.amount !== 1000){
            this.setState({amount : 1000}); // reset amount as baby
            this.props.context.setChoiceIsOk(false);
        }
        // reset quantity if input selectedValue is ''
         // on set la quantity à 0 si selectedValue ''
        if (this.props.context.selectedValue === '' && this.state.quantity !== 0){
            this.setState({quantity: 0});
        }
        if (this.props.context.showSlide === true){
            if (this.props.presta === "0" || this.props.presta === "2"){
                this.setState({showRidersAge: true});
                this.setState({showRidersLevel: false}); // reset riderLevel
                
            }else if(this.props.presta === "1" || this.props.presta === "4" || this.props.presta === "5"){
                this.setState({showRidersAge: false}); //reset riderAge
                this.setState({showRidersLevel: true});
        }
      this.props.context.setShowSlide(false);
    }      
    
    }
  async componentDidMount(){
        this._isMounted = true;
        // reset trace date select
        this.props.context.setIsDateClicked(false);               
    }

            
        
    componentWillUnmount(){
        this._isMounted = false;
        // reset trace date select
        this.props.context.setIsDateClicked(false);
    }

  

    getNbTrainingDays(pDate){     
           
        const trainingDays = this.props.items && this.props.items;
        console.log("longueur dates de stage " + trainingDays.length);
        console.log("date cliquée stripe : " + pDate);
        // number of dates in a training
        if ( trainingDays !== undefined && trainingDays !== null && trainingDays.length > 0){
            const datesOfWeek = trainingDays.filter(stageTs => stageTs.dt_start <= pDate.toISOString() && stageTs.dt_end >= pDate.toISOString());    
            console.log("dates de la semaine : " , datesOfWeek);
            if (datesOfWeek !== undefined && datesOfWeek !== null && datesOfWeek.length > 0 ){                        
                const date1 = new Date(datesOfWeek[0].dt_start);
                const date2 = new Date(datesOfWeek[0].dt_end);                       
                const diffTime = Math.abs(date2 - date1);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                console.log(diffTime + " milliseconds");
                console.log(diffDays + " days");
                console.log("nombre de jours : " + diffDays);
                this.setState({nbDays: diffDays + 1});                        
            }
        }
    }
     

    formatDate(date){        
        // Set the date to "2018-09-01T16:01:36.386Z"
        const utcDate = parseFromTimeZone(date, { timeZone: 'Europe/Berlin' });
        let localDay = format (utcDate, 'dd MMM yyyy', { locale: fr });       
        return localDay;
    }

    formatHourFromDate(date){        
        // Set the date to "2018-09-01T16:01:36.386Z"
        const utcDate = parseFromTimeZone(date, { timeZone: 'Europe/Berlin' });       
        let localHour = format (utcDate, 'HH mm', { locale: fr });
        localHour = localHour.replace(' ', 'h');
        return localHour;
    }
    replaceAt = (theString, index, char) =>{
        return theString.slice(0, index) + char + theString.slice(index+char.length);
    }
    
    getGMT (pLocalDate){
        const sLocalDate = pLocalDate.toString();
        const GMTindex = parseInt((sLocalDate.indexOf("+")+1), 10);
        const GMTEnd = parseInt((sLocalDate.indexOf("(")-1), 10);        
        const GMT = sLocalDate.slice(GMTindex, GMTEnd);
        console.log ("GMT : " + GMT);        
        return GMT;        
    }

    replaceHour (sDateChoice, dozen, units) {
        // toInt
        const hourDozen = parseInt((sDateChoice.indexOf(":")-2), 10);
        const hourUnit = parseInt((sDateChoice.indexOf(":")-1), 10);        
        // remplacement des heures
        sDateChoice = this.replaceAt(sDateChoice, hourDozen, dozen.toString());  
        sDateChoice = this.replaceAt(sDateChoice,hourUnit, units.toString()); 
        console.log ("la nouvelle heure : " + sDateChoice) ;
        return sDateChoice;
    }

    setNewGMT = (datechoice, tomorrow) => {
        let sTomorrow = tomorrow.toString();
        let sDateChoice = datechoice.toString();
        let GMT = datechoice;
        console.log("la datechoice à remplacer : " + sDateChoice);        
        
        // toInt
        const GMTindex = parseInt((sDateChoice.indexOf("+")+2), 10);   
        
        // jour de passage heure d'été
        console.log("gmt lendemain : " + this.getGMT (sTomorrow));
        console.log("gmt date : " + this.getGMT (sDateChoice));

        if (this.getGMT (sTomorrow) > this.getGMT(sDateChoice)){
            // passage heure d'été
            sDateChoice = this.replaceAt(sDateChoice, GMTindex, "2");
            console.log("new GMT été : " + sDateChoice); 
            sDateChoice = this.replaceHour (sDateChoice, 0, 7);          
        }
        // jour de passage heure d'hiver
        if (this.getGMT (sTomorrow) < this.getGMT(sDateChoice)){
            // passage heure d'hiver
            sDateChoice = this.replaceAt(sDateChoice, GMTindex, "1"); 
            sDateChoice = this.replaceHour (sDateChoice, 0, 7);           
            console.log("date new  hiver : " + sDateChoice);
        }
           
    return sDateChoice;
        
    }
 
/**  
   * 
   * @param {table : date} items 
   * @returns {table : string} tableau des horaires disponibles pour 1 jour
   */
  handlePossibleHours = async (pDate)=>{
    const items = this.props.items && this.props.items;
    // ici, gestion du GMT au changement d'heure. L'heure du datepicker est par défaut 00:00
    // le changement d'heure se fait à 03:00. Il faut donc changer le GMT à date -1 du changement d'heure
   
    const tomorrow = addDays (pDate, 1);    
    let sDate = this.setNewGMT(pDate, tomorrow);    
    const date = new Date(sDate);    
    this.props.context.setIsDateClicked(true);
    this.setState({isDateFocused : true}); 
    this.setState({freeTs: []});   
    
    this.props.context.setSelectedDate(date); // date sélectionnée
    this.setState({isSubmitted: false});
    // Si l'activité ne possède pas de créneau d'horaire, le choix est ok à la selection de la date
    if (this.props.presta !== undefined && items !== undefined){
        if (this.props.presta.toString() !== "2"){         
            this.props.context.setTsIsOk(false);
        }else{        
            this.props.context.setTsIsOk(true);
            //déselection de la checkbox au changement de date
            this.props.context.setIsFullWeek(false); 
            // gestion de la sélection de la semaine entière quand stage
            // récupère le nombre de jours du stage
            this.getNbTrainingDays(date);
        }
    
    this.setState({isToHide: false});
    let myHourTable = []; // reset du tableau des horaires, on ne cumule pas les horaires dispos
    let timeSlotsPerDay = [];    
    // 2- on récupère le numéro du jour de la date sélectionnée
    const numDay = getDay(date);
    // 3- on charge les horaires dispos pour ce jour et la catégorie    
    timeSlotsPerDay = await items && items.find(item=> parseInt(item.num_day, 10) === numDay && item.category.toString() === this.props.presta.toString());
    
    // 4- On trie les horaires futurs
    const todayInt = new Date().getTime();    
    const dateInt = date.getTime();
    const sSelectedDate = date.toString();   
    // tableau de booleens retournent vrai si l'heure est une heure future supérieure à 3 heures
    let areValidTimeSlots = [];
    timeSlotsPerDay && timeSlotsPerDay.times.map(ts=>{
        const formattedTs = ts.value.replace("h", ":");        
        const hourToReplace = formatUnits(date.getHours().toString()) + ":" + formatUnits(date.getMinutes().toString());          
        const sNewSelectedDate = sSelectedDate.replace(hourToReplace, formattedTs);        
        const dNewSelectedDate = new Date(sNewSelectedDate).getTime();        
        const isValid = dNewSelectedDate > todayInt + (3*3600000);
        areValidTimeSlots = [...areValidTimeSlots, isValid];
    });    
    
    // gestion des réservations passées (cp (1) et gouters (3))
    if (this.props.presta === "1" || this.props.presta === "3"){        
        // on recherche le jour dans les réservations passées - retourne obj booking si date réservée
        if (this.props.bookingsbycat !== undefined){            
            const bookedDays = this.props.bookingsbycat.filter(booking=> this.formatDate(booking.chosen_time_stamp) === this.formatDate(date));            
            // si on trouve au moins un jour réservé sur le créneau, on filtre les timeslots qui sont encore dispo
            if (bookedDays !== undefined && bookedDays !== null && bookedDays !== [] && bookedDays.length > 0 )
            {                
                let tFreeTs = [];                 
                // Pour chaque créneau du jour, on cherche s'il n'est pas déjà réservé et on l'ajoute aux créneaux dispos
                for (let k = 0 ; k < timeSlotsPerDay.times.length ; k++)  {
                    
                    // Pour chaque créneau, on cherche au moins une correspondance, si on n'en trouve pas et que l'heure est future, on l'ajoute aux dispo
                    const busyTs = bookedDays.find (bookedDay => this.formatHourFromDate(bookedDay.chosen_time_stamp) === timeSlotsPerDay.times[k].value);
                    if (busyTs === undefined || busyTs === null){
                        if (areValidTimeSlots[k] === true){
                            tFreeTs.push(timeSlotsPerDay.times[k].value);
                        }
                    }
                }
                this.setState({myHourTable: tFreeTs});                
            }else{                
                // si le jour n'est pas réservé, on lance tout si l'heure est supérieure à l'heure en cours
                timeSlotsPerDay  && timeSlotsPerDay.times.map((item, index)=>{ 
                    console.log("index : " + index);
                    if (areValidTimeSlots[index] === true){       
                        myHourTable.push(item.value);                        
                    }
                    this.setState({myHourTable});
                })                
            }
        }
    }else{ // autres catégories        
        
        timeSlotsPerDay  && timeSlotsPerDay.times && timeSlotsPerDay.times.map((item, index)=>{ 
            if (areValidTimeSlots[index] === true){                     
                myHourTable.push(item.value);
                
            }
            this.setState({myHourTable});
        })
        
    }
    if (this.props.presta === "2"){
        this.setState({myHourTable: []}); // reset table if stage
    }
        this.setState({date});     
        this.setState({selectedOption: null}); //reset la sélection de l'heure à chaque changement de date
        
    }
}
    /*riders age or level*/
    handleRangeChange=(event)=>{   
        this.props.context.setAgeError("");
        const name = event.target.name;
        console.log("name : " + name);
        const age = event.target.name;
        const value = event.target.value;        
        this.setState({[name] : parseInt(value, 10)});
        if (name.includes("age")){ 
            
            const ages = this.state.ages;
            const namedId = parseInt(name.slice(0, -3), 10);
            console.log("ageeventtarget : " + age);
            // update rider with range values          
            let updatedArr = ages.map(ageObj => 
            (ageObj.id === namedId) ? {...ageObj, age: value} : ageObj
            );
            this.setState({ages: updatedArr});  
        }
        if(name.includes("level")){
            const levels = this.state.levels;
            console.log("level : " + event.target.name);
            const namedId = parseInt(event.target.name.slice(0, -3), 10);
            
            // update rider with range values          
            let updatedArr = levels.map(levelObj => 
                (levelObj.id === namedId) ? {...levelObj, level: value} : levelObj
                );
                this.setState({levels: updatedArr});  
        }
    }
        


handleSelectChange=(event)=>{
    console.log("la presta : " + this.props.presta);
    const quantity = event.target.value;
    this.props.context.setSelectedValue(quantity);
    this.setState({quantity: quantity});
    this.props.context.setQuantityError(""); // reset l'erreur nb personnes
    let newAges = []; 
    let newLevels = [];
    const levels = this.state.levels;
    const ages = this.state.ages;
    const isFullWeek = this.props.context.isFullWeek && this.props.context.isFullWeek;
        
    if (this.props.presta !== undefined && this.props.presta !== null){
        
        // init un tab d'ages de la quantité de cavaliers
        // BABY presta 0 STAGE presta 2
        if (this.props.presta === "0" || this.props.presta === "2"){
            for (let x = 1 ; x <= quantity ; x++ ){
                // init a table of riders from quantity select
                let rider = {id: x, age: "0"};
                newAges = [...newAges, rider];
                // si le cavalier est déjà dans la liste, on update son age
                if ( ages !== null && ages !== undefined){
                    const existingRider =  ages.find(exRider => exRider.id === x);
                    if (existingRider !== undefined){
                        newAges = newAges.map(ageObj => 
                            (ageObj.id === existingRider.id) ? {...ageObj, age: existingRider.age} : ageObj
                            );
                            
                        }
                    }
                }        
                this.setState({ages: newAges});
                console.log("les ages : " , newAges);
                
            // CP presta 1
            } else if (this.props.presta === "1") {
                
                // init un tab de levels de la quantité de cavaliers
                for (let x = 1 ; x <= quantity ; x++ ){
                    // init a table of riders from quantity select
                    let rider = {id: x, level: "0"};
                    newLevels = [...newLevels, rider];
                    // si le cavalier est déjà dans la liste, on update son age
                    if ( levels !== null && levels !== undefined){
                        const existingRider =  levels.find(exRider => exRider.id === x);
                        if (existingRider !== undefined){
                            newLevels = newLevels.map(levelObj => 
                                (levelObj.id === existingRider.id) ? {...levelObj, level: existingRider.level} : levelObj
                                );                                
                            }
                        }
                    }
                }
                this.setState({levels: newLevels});
                console.log("les niveaux : " , newLevels);
                 /** if baby or stage, show input range for riders age*/
                 if (this.props.presta === "0" || this.props.presta === "2"){
                    this.setState({showRidersAge: true});
                    this.setState({showRidersLevel: false}); // reset riderLevel
                    
                }else if(this.props.presta === "1" || this.props.presta === "4" || this.props.presta === "5"){
                    this.setState({showRidersAge: false}); //reset riderAge
                    this.setState({showRidersLevel: true});
                }else{
                    //reset input ranges if birthdays
                    this.setState({showRidersAge: false}); 
                    this.setState({showRidersLevel: false});
                }
                /**
                * Gestion du montant des arrhes
                */
                const result = setAmountByCatAndQuantity(parseInt(this.props.presta, 10), parseInt(event.target.value, 10), isFullWeek );
                this.setState ({amount:result});
               
            }
        }

    
    handleRadioChange=(event)=>{   
          
        // reset message
        this.setState({message: ""});
        this.setState({selectedOption : event.target.value});       
        this.props.context.setChoiceIsOk(true);
        this.props.context.setTsIsOk(true);
        // reset input
       /*  this.setState({showRidersAge: false});
        this.setState({showRidersLevel: false}); */
     }

     handleCalendarMessage=(myHourTable, presta)=>{       
      
        if(this.props.context.isDateClicked && this.props.context.isDateClicked === true){
            if(presta === "2"){
                return "De 9h00 à 17h00";
            }
            if (myHourTable.length > 0){                
                if (presta !== "2"){
                return "Sélectionnez l'horaire de votre choix";
                }
            }else{                 
                if (presta !== "2"){                    
                    return "Il n'y a plus de créneau disponible pour ce jour";
                }
            
                
            }
        }
    }
    ChooseAge = (pRidersNumber, pRiders) =>{      
  
    if (this.state.showRidersAge === true){
        if (pRidersNumber !== null && pRidersNumber !== undefined && pRidersNumber > 0){
                return <div className ="rider-div">                    
                    <label className="form-label"> {this.props.start + 1 + this.props.step} - {pRidersNumber === 1 ? "Âge de l'enfant" : "Âge des enfants"}</label>
                {pRiders.map ((index, value) => 
                    <InputRange 
                    name="age"
                    key={index}
                    index={index} 
                    min={minAge} 
                    max={maxAge} 
                    ridersNumber={pRidersNumber} 
                    value={value} 
                    age={this.state[index+"age"] | 0} 
                    onChange={this.handleRangeChange}/>         
                ) 
            }</div>
        }
    }
}
ChooseLevel = (pRidersNumber, pRiders) =>{
      
    //const labelAge = this.state[index+"age"] === 10 ? "10 ans et plus" : (this.state[index+"age"] === 1 ? this.state[index+"age"] + " an" : this.state[index+"age"] + " ans");
if (this.state.showRidersLevel === true){
    if (pRidersNumber !== null && pRidersNumber !== undefined && pRidersNumber > 0){
            return <div className ="rider-div">                    
                <label className="form-label"> {this.props.start + 1 + this.props.step} - {pRidersNumber === 1 ? "son niveau" : "Leur niveau"}</label>
            {pRiders.map ((index, value) => 
                <InputRange
                name="level" 
                key={index}
                index={index} 
                min={minLevel} 
                max={maxLevel} 
                ridersNumber={pRidersNumber} 
                value={value} 
                level={this.state[index+"level"] | 0} 
                onChange={this.handleRangeChange}/>         
            ) 
        }</div>
    }
}
}
handleCheckBoxChange=(e)=>{
    
    console.log("enter in checkbox change : " +  e.target.checked)      
    this.props.context.setIsFullWeek(e.target.checked);
    if (this.props.presta !== undefined && this.props.presta !== null){
        const result = setAmountByCatAndQuantity(parseInt(this.props.presta, 10), parseInt(this.state.quantity, 10), e.target.checked );
        this.setState ({amount:result});
    }
}

    render() {
        const {presta, items, daysToDisable, start, tsToDisable, step} = this.props        
        const date = this.state.date
        const myHourTable = this.state.myHourTable
        const selectedOption = this.state.selectedOption
        
        let tofocus = this.state.tofocus
        const message = this.state.message
        const isFullWeek = this.props.context.isFullWeek && this.props.context.isFullWeek;
        const ridersNumber = this.props.context.selectedValue && parseInt(this.props.context.selectedValue, 10)
        console.log("le nombre de cavaliers : " , ridersNumber);
         // init the table of riders to display ranges
         const riders = NBLIST.filter(rider=> parseInt(rider, 10) <= ridersNumber)         
         const showAges = this.ChooseAge(ridersNumber, riders)
         const showLevels = (presta === "1" || presta === "4" || presta === "5") && this.ChooseLevel(ridersNumber, riders)
        return !this.props.schoolIsSelected &&(
        <>
        <Label isvalid={false}>{message && message}</Label>
        
        <StyledInputCard>
            <label className="form-label">{start + 1} - Nombre de participants</label>
        <SelectInput 
            className = "intro_select_input"
            refinput = {this.focusInput}
            table = {NBLIST}
            onChange={this.handleSelectChange}
            value={this.props.context.selectedValue}
           
        />        
        { (this.state.showRidersAge ? showAges : (this.state.showRidersLevel && showLevels))}
        </StyledInputCard>
        <VerticalWrapper>
        
        <FormWrapper> 
            <label className="form-label">{start + 2 + step } - Votre créneau</label>
            <StyledForm>
            <DatePicker 
                days = {items}                
                daysToDisable = {daysToDisable}
                tsToDisable = {tsToDisable}
                category = {presta}        
                myhourtable = {myHourTable}
                date = {this.props.context.selectedDate ? date : null } 
                onDateChange = {(date) => {this.handlePossibleHours(date)} }
                isFullWeek = {isFullWeek}>
            </DatePicker>

        {/* RADIO BUTTON HOURS*/}
        <p> </p>
        <div className="form-title">
            {this.handleCalendarMessage(myHourTable, presta, this.props.eventisok)}
        </div>
         
          
          <div className="radio">
            {myHourTable !== null && myHourTable !== [] && myHourTable.length > 0 && myHourTable.map((item, index)=>{
                
            return <div className= {`${this.props.context.selectedDate ? "radio-elt" : 'radio-hidden'}`} key={index}>
              <label className="container">
                <RbForwardRef                 
                id={index}
                type="radio"
                className="radio-input"
                name="radiogroup"
                value={item} 
                onChange={(e)=>this.handleRadioChange(e)}
                checked={selectedOption === item}
                ref={this.rbForwardedRef}
                
                />
                
                {item}
              <span className="checkmark"></span>
              </label>            
            </div>
            })}
            </div>
            {/* semaine complète stage (presta = 2) et semaine de 5 jours*/}
            {presta && presta === "2" && this.state.nbDays === 5 && this.props.context.selectedDate !== undefined  && this.props.context.selectedDate !== null && date !== null && date !== undefined &&
            <CheckWeekWrapper>
                <CheckBoxWeek
                    type="checkbox"                                                
                    name= "fullweek"
                    className="check"                       
                    label= "Semaine"
                    onChange={(e)=>this.handleCheckBoxChange(e)}
                    checked={isFullWeek}
                                                         
                    />
                <LabelFullWeek className="container"> Semaine complète </LabelFullWeek>
            </CheckWeekWrapper>
            
            }
            </StyledForm>
            

            </FormWrapper>
                <div className="stripe-wrapper">              
                    <Elements stripe = { stripePromise } options = {ELEMENTS_OPTIONS}>
                        <ElementsConsumer>
                            {({stripe, elements})=>( this._isMounted &&
                            <MyCheckoutForm nbDays={isFullWeek === true ? 5 : 1} isFullWeek={isFullWeek} levels={this.state.levels} ages={this.state.ages} step={this.props.step} start = {this.props.start}  eventisok = {this.props.eventisok} amount={this.state.amount} refinput={this.focusInput} quantity={this.state.quantity} _is_mounted = {this._isMounted} stripe={stripe} elements={elements} presta = {this.props.presta} datechoice={this.props.context.selectedDate ? this.state.date : null} hourchoice={this.props.context.selectedDate ? this.state.selectedOption : null} focusInfo={this.focusInfo}/>
                            )}
                        </ElementsConsumer>
                    </Elements>                    
                </div>                
        </VerticalWrapper>
        </>
        )       
        
    }
}

export default withUser(Stripe)

