
import React, {useState, useContext, useEffect} from 'react'
import Stripe from './Stripe';
import {BookingContext} from "../contexts/BookingContext";
import {UserContext} from "../contexts/UserContext";
import {Label, StyledEvent, VerticalWrapper} from "../common";
import connectionStrings from "../data/connectionStrings";
import { useLocation } from 'react-router-dom';

const INITIALSTATE = [];

function NoBabyContent(props) {
  const [selectedOption, setSelectedOption] = useState("");
  const [items, setItems] = useState([]);
  const [_isMounted, set_isMounted] = useState(false);
  const [daysToDisable, setDaysToDisable] = useState([]);
  const [myError, setMyError] = useState("")
  const bookingContext = useContext(BookingContext);
  const userContext = useContext(UserContext);
  const [messageStorage, setMessageStorage] = useState([]);
  const [defaultSelection, setDefaultSelection] = useState('');
  const [eventSelection, setEventSelection] = useState(false);
  const [bookingsByCat, setBookingsByCat] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [schoolIsSelected, setSchoolIsSelected] = useState(false);
  const location = useLocation()
  console.log ("no baby loc : ", location);

useEffect(() => {
  set_isMounted(true);
  //getStripeApiKeyByServer();
  async function getBookingsOnRadioChange(){    
    try {
      const res = await bookingContext.getBookingsByCat(selectedEvent);
      if (res.error){
        console.log("erreur récupération bookings");
      }else{
        const bookings = res.bookings;
        console.log("bookings récupérées : " + res.bookings.length);
        // tri des valides
        console.log("on compare la date : " + new Date().toISOString());          
        setBookingsByCat(bookings);         
      }
    } catch (error) {
      console.log("récupération des bookingsbycat en cours")
    }
    
  }
  
  
 
    
  
  // pour les cp, on vérifie si le créneau n'est pas déjà réservé
  if (selectedEvent === "1"){
    getBookingsOnRadioChange();
  }
  
  
  
  
}, [_isMounted, selectedEvent]);

  

  const  getTimeStampsByCat = (categoryId, timeStamps) =>{ 
    
    
    console.log("on rentre dans getTsByCat, la ctégorie : " + categoryId)       
    // si on a des créneaux, on ne retourne que ceux de la categorie en param
    if (timeStamps !== undefined && timeStamps !== null){        
      const finalTimeStamps = timeStamps.filter(stageTs=>
      stageTs.category.toString() === categoryId.toString());           
      return {tsByCat: finalTimeStamps};
    }else{
      return {message: "... Chargement en cours"};
    }
}


  const handleRadioChange =   (event) =>{
    setSelectedEvent(event.target.id);
    userContext.setIsFullWeek(false);
    userContext.setSelectedDate(null);
    // school
    if (event.target.id !== "4"){
      setSchoolIsSelected(false);
      userContext.setIsDateClicked(false);
      // on a sélectionné un evenement
      setEventSelection(true);
      // reset choiceIsOk
      userContext.setChoiceIsOk(false);
      //reset input select on chbox change
      userContext.setSelectedValue('');
      //reset timestamp on chbox change
      userContext.setTsIsOk(false);
      console.log("val de select input : " + defaultSelection);
      /*reset message storage on chbox change*/    
      setMyError("");   
      setSelectedOption(event.target.id);
      console.log("clearMessageStorage : " + messageStorage);
      userContext.setSelectedDate(null);
      if (event.target.id !== "2"){
        // message cours
          if (props.message){
            setMyError(props.message);
          }
      
      const resp = getTimeStampsByCat(event.target.id, props.validTs);
      if (resp !== undefined){        
        if (resp.tsByCat){       
          setItems (resp.tsByCat);                  
          console.log(resp.tsByCat);             
          setDaysToDisable(bookingContext.setListToDisable(resp.tsByCat, event.target.id));
        }
      }else{
        setMyError("... Chargement en cours");
      }
    }else{ //stage
      const resp = getTimeStampsByCat(event.target.id, props.validTsStage);
        // stage
        if (props.messageStage){
          setMyError(props.messageStage);
        }    
      if (resp !== undefined){
        setItems (resp.tsByCat);                  
        console.log(resp.tsByCat);             
        
      }
    }
  }else{
    setSchoolIsSelected(true);
    userContext.setIsDateClicked(false);
    setEventSelection(false);
    // reset choiceIsOk
    userContext.setChoiceIsOk(false);
    //reset input select on chbox change
    userContext.setSelectedValue('');
    //reset timestamp on chbox change
    userContext.setTsIsOk(false);
    console.log("activité select : " + event.target.id);
    /*reset message storage on chbox change*/    
    setMyError("");   
    setSelectedOption("");
  }
}

  const EVENTS = [{id: 1, description: "balade / cours"}, {id: 2, description: "Stage sportif"}, {id: 3, description: "Anniversaire ludique"}, {id: 4, description: "Activité sur devis"}]
  const eventList = 
  
      <div className="radio" onChange = {handleRadioChange}> 
          {EVENTS.map((item)=>
          // radio buttons events
          <div className="radio-elt" key={item.id} >
          <label className="radio-label">
              <input 
              type = "radio" 
              id={item.id}              
              className="radio-input"
              name="rbEvents"
              value={item.description}                
              defaultChecked={false}
              
              
              />
               
            <span className="checkmark"></span>
            </label>   

          {item.description}</div>
          )
          }
      </div>;



  return (
    <>
    <div className="pricing">
    <h1 className="form-h1">Vos tarifs </h1>
    <span className="pricing-title"> Cours / balade :  </span>                   
    <span className="pricing-span"> Prix du cours </span>
    <div className="pricing-p">
    <span className="pricing-title"> Goûter d'anniversaire ludique :  </span>
    
    <div>
    <span className="pricing-span"> Prix</span>
    </div>
    
    </div>
    <div className="pricing-p">
    <span className="pricing-title"> Stages 5 jours : </span>
    <div>
    <span className="pricing-span"> Prix du stage à la journée / à la semaine</span>
    </div>
    </div> 
    <div className="pricing-p">
    <span className="pricing-title"> Activité sur devis : </span>
    <div>
    <span className="pricing-span"> Pour les activités ne pouvant pas être réservées en ligne</span>
    </div>
    </div>    
    </div>
  
    
    {/* radioButtons */}
    
    <StyledEvent>       
      <div className="event-div">
        <label className="form-label">1 - Exemples de prestations</label>
      </div>
        {eventList}
      <div className="school">{schoolIsSelected && "Merci de nous contacter pour convenir des horaires et obtenir un devis personnalisé"} </div>
    </StyledEvent>
    
    <div>
      <Label isvalid={false}>{myError && myError}</Label>
    </div>
    <Stripe schoolIsSelected={schoolIsSelected} tsToDisable={props.tsToDisable} step = {selectedOption === "3" ? props.step : props.step+1} start = {props.start} bookingsbycat={bookingsByCat} eventisok = {eventSelection} presta = {selectedOption} items={items} daysToDisable={selectedOption !== "2" ? daysToDisable : items}/>
    
    </>
    )
  }
  export default NoBabyContent
          
