import React from 'react';

export const BaladeLogo = (props)=>(

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 className={props.className} width={props.width} viewBox="0 0 250 250" enableBackground="new 0 0 250 250" xmlSpace="preserve">
<g id="corps_x5F_cavalier">
	<g>
		<path fill="#415E67" d="M85.749,99.606c-0.902,0.568-4.245,1.066-5.064,2.693c-0.821,1.628-1.09,3.013-0.706,3.846
			c0.385,0.834,2.886,0,2.886,0l4.809-2.307l2.499-2.886l6.862-8.655l10.899-14.747l-8.977-8.976c0,0-8.044,21.617-6.398,17.194
			c1.646-4.423-3.199,8.162-4.116,9.605C87.524,96.818,86.653,99.039,85.749,99.606z"/>
		<path fill="#415E67" d="M77.903,154.227c0,0-17.637,11.898-19.41,13.501c-1.771,1.604-4.388,6.246-4.725,7.427
			s2.363,6.076,2.194,8.945c-0.169,2.87-0.506,7.426-0.506,9.452c0,2.025,2.363,3.206,3.208,4.05
			c0.843,0.844,7.956,9.306,7.931,9.415c-0.025,0.108,4.139,2.609,5.657,3.073c1.517,0.465,2.999-4.764,2.445-6.244
			c-0.553-1.48-1.096-3.628-3.037-5.231c-1.941-1.604-6.077-0.844-6.077-0.844l-3.375-4.388l-0.674-8.693
			c0,0-0.422-8.523,1.012-9.705c1.436-1.181,28.355-11.307,28.355-11.307L77.903,154.227z"/>
		<path fill="#415E67" d="M37.187,84.605l7.722-4.135c0,0,7.472-0.27,11.139,0c3.667,0.27,6.8-1.772,9.748,0.717
			c2.946,2.489,4.05,5.696,7.341,6.203c3.292,0.506,4.938,2.91,4.938,2.91l7.468,7.469l8.355,4.304l1.266,2.878l-15.19,3.451
			l-19.748-8.733l-20.759-11.14l-4.937-2.025L37.187,84.605z"/>
		<path fill="#415E67" d="M170.893,154.855c0,0,12.75,13.295,16.549,17.093c3.797,3.798,5.779,9.741,5.823,15.568
			c0.03,4.176-0.027,8.216-0.56,9.988c-0.534,1.771-1.716,3.443-5.536,6.176c-3.82,2.73-4.284,6.438-4.284,6.438l8.86,0.744
			c0,0,0.947-1.855,2.278-3.346c1.104-1.236,2.954-2.321,4.726-4.178c1.773-1.856,0.337-22.784,2.11-31.392
			c-8.523-8.438-11.393-9.958-14.983-19.24c3.844-9.621,10.042-20.043-1.305-34.179"/>
		<path fill="#10514F" d="M28.368,91.525c-1.435-1.773-2.955-9.03-2.955-9.03h0.844l3.376,2.531v-3.712l2.025-0.844l2.531,2.025
			l1.35,2.784c0,0,24.81,1.941,42.363,20.507c4.22,0,4.22,0,9.114-1.52c-2.194,0.845,8.945,0.675,8.945,0.675
			s14.009,3.375,19.748,2.194c5.739-1.181,16.371-8.608,18.059-8.102c1.688,0.507-0.844,4.557-1.182,5.233
			c1.27,1.997,0.645,3.882,1.756,3.882c1.113,0,20.186-5.063,31.831-1.688c11.646,3.375,13.334,6.246,13.334,6.246
			s13.839-2.701,20.591,4.725c6.751,7.427,14.347,11.814,17.046,12.997c2.7,1.181-4.726,1.518-4.726,1.518s6.871,4.963,6.075,4.389
			c-0.794-0.575-8.102-2.194-8.102-2.194s5.57,17.553,10.128,20.928c4.556,3.375,15.357,0.508,18.459,2.373
			c3.101,1.863,4.494,6.235,4.494,6.235s-8.778-0.843-20.563,3.407c-11.336-0.875-17.216-12.259-19.1-18.429
			c-0.939-3.076-1.174-15.699-5.738-22.447c-5.098-7.533-16.803-8.011-13.502-7.679c0,0,3.713,5.147,4.388,7.848
			c0.675,2.701,0.507,13.67-8.605,20.591c-9.115,6.92-22.618,17.89-21.942,33.756c0,2.7-3.544,3.376-5.232,4.895
			c-1.688,1.518-17.89,15.02-20.422,18.228c-3.377,0.506-9.115,2.532-8.439,6.582c-3.545-0.338-9.114,0.338-10.97-2.7
			c4.05-4.895,8.775-4.725,8.775-4.725l17.215-14.178c0,0,5.402-3.883,7.476-9.673c1.699-4.744,2.315-14.8,2.398-18.429
			c0.085-3.628,0.221-7.077,0.221-7.077s-5.924,7.732-44.15,9.168c-5.594,0.692-10.732,1.04-13.654,1.12
			c-1.768,1.673-1.56,3.891-7.166,7.291c-0.571,1.219-2.422,5.11-4.28,9.669c-1.857,4.556-4.313,11.669-4.642,13.144
			c-0.328,1.476-3.289,14.079-3.289,16.052l-2.281,0.338c0,0-2.7,2.869-2.025,4.557c0.675,1.689-0.178,3.917-0.178,3.917
			s-4.042,1.486-8.936-0.203c0.591-2.531,1.013-3.205,4.557-5.569c2.701-3.039,2.533-3.29,3.376-6.835
			c0.844-3.546,4.05-13.333,4.22-18.06c0.169-4.726,3.207-18.819,3.544-23.883c0.134-2.004-0.393-3.85-1.326-5.916
			c-1.422-3.158-3.789-6.838-6.185-12.396c-4.473-18.987-10.119-25.475-9.03-24.051c1.089,1.426-3.122-3.967-5.992-11.646
			c-0.379,2.364-2.147,5.116-3.164,6.034c-1.939,1.755-6.035,4.599-6.035,4.599l-1.96,4.621c0,0,0.357,2.806-0.401,3.987
			c-0.76,1.183-5.401,1.181-5.908,2.868c-0.506,1.688-3.376,2.7-6.414,1.013c-0.967-0.537-1.021-1.666-1.519-2.109
			c-1.064-0.947-2.096-1.032-2.025-1.604c0.928-7.509,1.17-9.283,1.519-12.404c0.349-3.122,1.688-10.717,1.182-13.418
			C26.765,101.23,28.199,93.213,28.368,91.525z"/>
		<path fill="#10514F" stroke="#415E67" stroke-miterlimit="10" d="M98.547,33.083c7.037,0.667,10.314,11.201,6.37,16.084
			c-1.687,2.363-2.637,1.74-2.637,1.74s-1.251,1.194-1.013,3.375c0.316,2.885,1.045,5.218,4.107,6.906
			c1.856,4.895,14.208,34.229,10.234,40.681c-2.92,4.741-15.428,22.989-15.428,22.989s-0.675,0.843,0.507,1.35
			s8.608,12.828,12.996,28.524c0.506,2.193,0.675,6.919,0.675,6.919h-5.738l-1.181-1.013l-9.791,1.013l-2.194-0.675l0.845-2.363
			l4.894-1.856c0,0,1.183-0.589,3.713-1.35c-0.843-7.679-14.683-25.57-15.358-29.537c-0.167-2.445,3.769-11.117,4.726-13.67
			c0.956-2.554,4.683-12.784,6.843-16.126C100.497,94.783,97.25,77.5,97.25,77.5L82.629,97.643c0,0-4.304,4.051-3.796,5.569
			c-0.791,0.929-5.598,1.519-5.598,1.519s-2.522-2.5-0.051-6.583c3.623-2.658,5.086-2.4,7.962-5.82
			c2.876-3.42,14.521-29.578,14.521-29.578c0.667-3.75-0.932-6.5-0.932-6.5s-1.571,0.842-3.763-0.114
			c-5.013-2.469-4.083-8.491-2.489-11.218c0.246-0.419-1.464-0.497-1.464-0.497s-1.056-0.266-0.206-1.374
			C86.875,41.542,88.603,31.671,98.547,33.083z"/>
	</g>
</g>
</svg>
)