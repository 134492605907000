import React, { Component, useEffect } from 'react';
import {
    StyledForm,  
    StyledInput, 
    Label,
    StyledInputwrapper
}
from "../common";
import {Navigate, Link} from "react-router-dom";
import {SubmitButton} from "./SubmitButton";
import PwdInput from "./PwdInput";
import { withUser } from '../contexts/UserContext';
import {checkEmailAddress} from "../data/clientUtils";
import {ImUser} from 'react-icons/im';


import "../App.css";



class SignInForm extends Component {
  

    constructor(props){
        super(props);
        this.authRef = null;
        this._isMounted = false;

    this.setAuthRef = element => {
      this.authRef = element;
    };

    this.focusauthRef = () => {
      // Focus the text input using the raw DOM API
      if (this.authRef) this.authRef.focus();
    };
  }

    state=({       
        email: "",
        password: "",
        passwordType: "password",
        emailValid: false,
        isAuth: -1, // initialisation
        isSubmited: false, // clic sur signIn button
        user: 
        {   username: "",
            authenticated: false,
            email: "",
            password: "",
            bookings: [],
            status: null
        },       
        isInvalidPassWord: true, // check if password == dbuserpassword     
       
        message: "",
        processing: false,
        downMessage: "",
       
        emailMessage: "Email"       
       
     
    })

    

    componentDidMount(){
      this._isMounted = true;  
      const count = this.state.count;
      const isConfirmed = this.state.isConfirmed;


       const isSubmited = this.state.isSubmited;
       this.setState(
        {
               emailValid: true,      
               isInvalidPassWord: false,
             
               
        });
        //console.log("dans compodidmount : isAuth : " + this.props.context.isAuth)
        
      
    }
  
    componentDidUpdate=(prevState)=>{
        const isAuth = this.props.context.isAuth;
        
         if(!this.state.emailValid){
            this.focusauthRef();       
         }
        //console.log("dans compoupdate signinform isAuth : " + isAuth)
        if (isAuth === -1 || isAuth === 0 || isAuth === 4){
            // focus
            this.focusauthRef();
        }
        if (this.state.downMessage !== "" && window.navigator.onLine){
            this.setState({downMessage:""});
        }
        if(this.state.processing === true && isAuth !== -1){
            this.setState({processing: false});
        }
       
            
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    

    updateMessage(isAuth){        
        let message = "";       

        switch (isAuth) {
            case -1:
                message = ""
                break;
            case 0:
                if (window.navigator.onLine){                    
                        // si connexion + serveur => erreur login
                        message = "Adresse mail et / ou mot de passe incorrect(s)"
                    
                }else{
                    message = "Merci de vérifier votre connexion internet";
                }
                break;
           
            case 2 : 
                message = "";
                
            break; 
            case 4 :                           
                message = "Une erreur de communication avec le serveur est survenue, merci de réessayer ultérieurement";
                break;
           

            default:
                message = "";
            break;
        }
        return message;
    }
   

 
   
  
    handleChange =(event)=>{       
        this.setState({emailMessage:"Email"});
        const value= event.target.value;
        this.setState({[event.target.name]: event.target.value});
        this.setState({isSubmited: false}); // reset de l'historique de soumission  
        const updatedIsAuth = this.props.context.updateIsAuth(-1);
        
    }  
   
   
     submitForm =  async (event) =>{ 
        event.preventDefault();
        await this.handleEmailCheck(this.state.email);
       
        //console.log("formulaire soumis");
        this.setState({processing: true});
        this.setState({isSubmited: true});
        
       
        /*On vérifie les validités*/ 
        let signInUser = {
            username: "",
            authenticated: false,
            email: this.state.email,
            password: this.state.password,
            bookings: [],
            status: null
        } 
        signInUser.email = this.state.email; 
        signInUser.password = this.state.password;
        
        /* this.setState({user: {email: this.state.email, password: this.state.password}}); */
        //console.log("le user qu'on envoie : " + signInUser.username + "son mail" + signInUser.email + " son pass : " + signInUser.password)
        if (window.navigator.onLine){
            if(this.state.emailValid !== undefined){
                if (this.state.emailValid === true){
            try {
                //console.log("async signIn dans signiform")
                const res = this._isMounted &&  this.props.context.signIn(signInUser);        
                //console.log("dans async signin isAuth context:" + this.props.context.isAuth)
                
                if (res !== undefined){
                //console.log("user reçu");
                }
               
            } catch (error) {            
                //console.log("l'utilisateur n'est pas récupéré : " );
                //console.log("auth status : " + this.state.isAuth);
                this.setState({message : error});            
                //const updatedUser = this.props.context.updateUserFromFront(signInUser); // mAj du contexte
                //const updatedAuth = this.props.context.updateIsAuth(0);
                this.setState({processing: false});
            }
        }else{            
            this.setState({processing: false});
        }
    }
        }else{
            this.setState({downMessage: "Oups, il semblerait que vous ayez perdu votre connexion internet"});
            this.setState({processing: false});
        }
 
       
    }
    handleEmailCheck= async (mail) => {
        console.log ("le mail que l'on compare : " + mail);
        const result = await checkEmailAddress(mail);
        if (result !== undefined){
        //console.log ("email valide : " + result)      
            this.setState ({emailValid : result});
            console.log ("handleEmailCheck emailValid : " + result);
            result === true ?
            this.setState({emailMessage: "Email"}) 
            :
            this.setState({emailMessage: "Email non valide"});
        }else{
            this.setState({emailMessage: "Vérification du mail en cours..."});
        }
    }
   
    handleMouseLeave= async (event)=>{
        const value= event.target.value;      
        this.setState({[event.target.name]: event.target.value});
        
        if (event.target.name === "email"){
            console.log("event name : " + event.target.name);
            console.log("event value" + value);            
            this.handleEmailCheck(event.target.value);
        }
    }

    keyPressed(event) {
        //console.log("dans keypressed : event : " + event.key)
        if (event.key === "Enter") {          
                event.preventDefault();              
        }
    }

   

    render() {
     const emailMessage = this.state.emailMessage
        
        const {email, password, isSubmited,  emailValid, processing} = this.state
        const {isAuth} = this.props.context
        
        const downMessage = this.state.downMessage
        const message = this.updateMessage(isAuth)
        const isFullAuth = isAuth === 2 || isAuth === 1;
      if( isSubmited && isFullAuth ){
          console.log("isAuth ok")
            return(
                    // Si utilisateur authentifiée : Page de réservation
                    <Navigate to="/booking" replace={true} state={{isAuth: isAuth}}/>
                           
                   )
            }else if (isSubmited && isAuth === 5){ // admin
             return(
                // Si utilisateur admin : Page d'administration
                <Navigate to="/admin" replace={true} state={{authuser : isAuth}}/>
                 
             )
            }else{
             return(
                <>       
            
                <div className="form-wrapper">            
                <h1 className="form-title">Connectez-vous pour réserver</h1>        
                          
                <StyledForm onSubmit={this.submitForm}>
                <div className="poney-account-wrapper">
                
                <ImUser className="user-logo"/>
                </div>
               <StyledInputwrapper>
                    <Label isvalid = {emailMessage !== "Email non valide"}>
                    {emailMessage}
                    </Label> 
                    
                        <StyledInput
                        isvalid={emailValid}
                        type="email" 
                        name="email" 
                        value={email}
                        placeholder= "Votre email"
                        onBlur= {this.handleMouseLeave} 
                        onChange={this.handleChange}                       
                        required/>
                        
                        <PwdInput 
                        password={password} 
                        name="password" 
                        onChange={this.handleChange}
                        placeholder="Votre mot de passe"                  
                        Label= "Mot de passe"                       
                        ref={this.authRef}
                        required      
                        />
                    <div className="link-forget-wrapper">
                        <Label isvalid={false} >{message && message}</Label>
                    <Link to="/pwdreinit" className="link-forgetpwd">Mot de passe oublié ?</Link>
                    </div>
                    
                    <div>
                    <Label isvalid={false}>{downMessage}</Label>
                    </div>
                    </StyledInputwrapper>
                    <fieldset className="relative-wrapper-reinitpwd">                             
                        <SubmitButton type="submit" processing={processing}>                       
                        Connexion
                        </SubmitButton>           
                    </fieldset>
                </StyledForm>
                </div>
                </>
             )
            }
        
    }
}
export default withUser(SignInForm)
