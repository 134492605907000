import React from 'react';

export const ActivitiesLogo = (props)=>(

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width={props.width} viewBox="0 0 250 250" enableBackground="new 0 0 250 250" xmlSpace="preserve">
<g id="corps_x5F_cavalier">
	<g>
		<path fill="#E01A35" d="M134.605,92.309c-0.532,0.336-2.506,0.629-2.99,1.59c-0.484,0.961-0.643,1.779-0.416,2.271
			c0.228,0.493,1.703,0,1.703,0l2.84-1.362l1.477-1.704l4.051-5.11l6.434-8.707l-5.299-5.299c0,0-4.75,12.763-3.778,10.152
			s-1.888,4.819-2.429,5.671C135.654,90.663,135.14,91.974,134.605,92.309z"/>
		<path fill="#E01A35" d="M129.974,124.559c0,0-10.413,7.024-11.459,7.971c-1.046,0.946-2.591,3.688-2.789,4.385
			c-0.2,0.696,1.395,3.588,1.294,5.281c-0.1,1.693-0.298,4.385-0.298,5.58c0,1.197,1.395,1.895,1.894,2.393
			c0.498,0.498,4.698,5.494,4.683,5.559c-0.015,0.063,2.444,1.541,3.34,1.814c0.896,0.273,1.771-2.813,1.443-3.688
			c-0.327-0.873-0.647-2.141-1.794-3.088c-1.145-0.947-3.587-0.498-3.587-0.498l-1.993-2.592l-0.398-5.133
			c0,0-0.25-5.031,0.597-5.729c0.848-0.698,16.741-6.677,16.741-6.677L129.974,124.559z"/>
		<path fill="#E01A35" d="M105.935,83.452l4.559-2.441c0,0,4.411-0.159,6.576,0c2.165,0.159,4.015-1.046,5.755,0.423
			s2.392,3.363,4.333,3.662c1.943,0.299,2.916,1.719,2.916,1.719l4.409,4.41l4.933,2.542l0.748,1.699l-8.969,2.038l-11.659-5.156
			L107.28,85.77l-2.915-1.196L105.935,83.452z"/>
		<path fill="#E01A35" d="M184.876,124.929c0,0,7.527,7.85,9.77,10.091c2.242,2.243,3.413,5.752,3.438,9.193
			c0.018,2.467-0.016,4.852-0.33,5.896c-0.315,1.047-1.013,2.033-3.268,3.646c-2.257,1.613-2.53,3.803-2.53,3.803l5.231,0.439
			c0,0,0.56-1.096,1.344-1.977c0.652-0.729,1.744-1.371,2.791-2.465c1.047-1.098,0.199-13.455,1.246-18.537
			c-5.032-4.98-6.727-5.879-8.846-11.359c2.269-5.68,6.699-11.705,0-20.052"/>
		<path fill="#E01A35" d="M100.728,87.538c-0.848-1.047-1.745-5.332-1.745-5.332h0.498l1.993,1.495v-2.192l1.196-0.499l1.494,1.195
			l0.797,1.644c0,0,14.648,1.146,25.011,12.107c2.491,0,2.491,0,5.382-0.896c-1.296,0.498,5.281,0.398,5.281,0.398
			s8.271,1.993,11.658,1.295c3.389-0.697,9.667-5.082,10.662-4.783c0.997,0.299-0.497,2.69-0.697,3.089
			c0.751,1.179,0.381,2.292,1.038,2.292c0.656,0,11.917-2.989,18.793-0.997c6.876,1.993,7.872,3.687,7.872,3.687
			s8.17-1.595,12.156,2.791c3.986,4.384,8.471,6.975,10.064,7.673c1.596,0.697-2.789,0.896-2.789,0.896s4.057,2.93,3.586,2.59
			c-0.469-0.338-4.783-1.295-4.783-1.295s3.289,10.363,5.98,12.357c2.689,1.991,9.066,0.299,10.898,1.399
			c1.83,1.102,2.652,3.682,2.652,3.682s-5.182-0.497-12.141,2.013c-6.693-0.517-10.164-7.238-11.277-10.881
			c-0.555-1.816-0.691-9.269-3.387-13.254c-2.709-4.003-7.972-4.533-7.972-4.533s2.192,3.039,2.591,4.633
			c0.398,1.596,0.299,8.072-5.082,12.157c-5.381,4.086-13.353,10.563-12.954,19.931c0,1.595-2.092,1.993-3.089,2.89
			s-10.563,8.869-12.058,10.762c-1.994,0.299-5.382,1.496-4.983,3.887c-2.093-0.199-5.381,0.199-6.477-1.594
			c2.393-2.891,5.182-2.791,5.182-2.791l10.164-8.369c0,0,3.188-2.293,4.414-5.713c1.002-2.8,1.365-8.737,1.415-10.88
			c0.05-2.142,0.13-4.179,0.13-4.179s-3.497,4.565-26.066,5.413c-3.303,0.409-6.336,0.614-8.062,0.662
			c-1.044,0.987-0.921,2.296-4.23,4.304c-0.337,0.719-1.431,3.017-2.526,5.709c-1.098,2.689-2.547,6.891-2.741,7.762
			c-0.193,0.869-1.941,8.311-1.941,9.477l-1.347,0.199c0,0-1.595,1.693-1.195,2.689c0.398,0.998-0.105,2.314-0.105,2.314
			s-2.387,0.875-5.276-0.121c0.349-1.494,0.598-1.893,2.69-3.289c1.595-1.793,1.496-1.943,1.993-4.035
			c0.499-2.092,2.392-7.871,2.491-10.662c0.1-2.789,1.894-11.112,2.093-14.101c0.079-1.184-0.232-2.272-0.782-3.494
			c-0.84-1.864-2.237-4.037-3.652-7.317c-2.641-11.211-5.975-15.041-5.331-14.2c0.643,0.842-1.843-2.342-3.538-6.875
			c-0.224,1.395-1.268,3.02-1.869,3.562c-1.145,1.036-3.563,2.715-3.563,2.715l-1.157,2.729c0,0,0.211,1.657-0.237,2.354
			c-0.449,0.698-3.189,0.696-3.488,1.693c-0.298,0.996-1.993,1.594-3.787,0.598c-0.571-0.317-0.603-0.983-0.897-1.245
			c-0.628-0.56-1.237-0.61-1.195-0.947c0.548-4.434,0.69-5.48,0.896-7.323c0.207-1.844,0.997-6.328,0.698-7.923
			C99.781,93.268,100.628,88.535,100.728,87.538z"/>
		<path fill="#E01A35" d="M134.807,55.924c0.557-2.05,1.971-4.826,5.918-4.37c3.734,0.433,6.639,5.491,3.449,9.428
			c-0.996,1.395-1.246,1.096-1.246,1.096s-1.096,0.797-0.598,1.993c0.498,1.195,3.084,4.091,4.18,6.98
			c1.096,2.891,1.445,7.487,3.738,10.477c3.979,4.752,4.509,8.423,2.654,11.137c-1.855,2.713-9.775,14.553-9.775,14.553
			s-0.398,0.498,0.299,0.797c0.699,0.299,5.082,7.573,7.674,16.84c0.298,1.296,0.398,4.086,0.398,4.086h-3.388l-0.698-0.598
			l-5.779,0.598l-1.297-0.398l0.499-1.396l2.89-1.095c0,0,0.699-0.348,2.192-0.798c-0.497-4.533-8.669-15.097-9.067-17.438
			c-0.1-1.444,2.226-6.564,2.791-8.071c0.564-1.508,2.764-7.548,4.039-9.521c-0.367-0.762-3.441-7.145-3.441-7.145l-7.475,8.072
			c0,0-2.541,2.392-2.24,3.288c-0.468,0.549-3.307,0.897-3.307,0.897s-1.489-1.476-0.029-3.887c2.139-1.569,3.002-1.417,4.701-3.437
			c1.697-2.019,6.346-8.644,6.346-8.644s-2.016-0.832-2.165-4.195c-0.067-1.537,1.966-5.229,2.021-6.818
			c0.043-1.308-0.759-3.075-0.759-3.075l-1.079-0.115c-3.139-1.693-2.41-5.013-1.47-6.624c0.146-0.247-0.655-0.277-0.655-0.277
			s-0.623-0.156-0.121-0.811C134.885,57.507,134.678,56.403,134.807,55.924z"/>
	</g>
</g>
<g id="parapente">
	<g>
		<path fill="#84CCD5" d="M69.103,21.353c0,0,58.344-42.218,95.982,14.336C120.702,27.745,69.103,21.353,69.103,21.353z"/>
		<path fill="#84CCD5" d="M141.854,9.12c-5.07-3.972,112.984,30.956,61.828,109.869c-10.6,16.35-19.09,12.175-19.979-34.296
			c0.467-16.992-0.68-23.324-8.998-39.714c-8.947-17.626-33.982-35.189-33.982-35.189"/>
		<g>
			<polyline fill="#84CCD5" points="52.612,148.959 62.696,87.667 72.886,26.381 72.99,26.387 72.49,30.227 71.958,34.062 
				70.891,41.734 68.607,57.07 68.032,60.906 67.424,64.736 66.197,72.4 63.741,87.728 61.126,103.044 59.819,110.704 
				59.161,114.532 58.467,118.359 55.684,133.669 54.217,141.316 53.484,145.143 			"/>
		</g>
		<g>
			<polygon fill="#84CCD5" points="105.447,26.256 104.045,30.07 102.61,33.875 99.741,41.486 93.859,56.667 92.387,60.46 
				90.88,64.246 87.86,71.813 81.819,86.948 75.627,102.041 72.534,109.588 70.978,113.359 69.393,117.123 63.043,132.172 
				59.799,139.674 58.175,143.425 56.521,147.169 56.42,147.143 80.834,86.671 105.347,26.228 			"/>
		</g>
		<g>
			<polygon fill="#84CCD5" points="137.915,31.144 135.481,34.833 133.021,38.509 128.101,45.856 118.132,60.479 115.637,64.133 
				113.114,67.771 108.059,75.043 97.949,89.585 87.706,104.051 82.586,111.285 80.021,114.898 77.429,118.495 67.046,132.882 
				61.795,140.041 59.171,143.613 56.513,147.183 56.426,147.129 97.083,89.087 137.829,31.095 			"/>
		</g>
		<g>
			<polygon fill="#84CCD5" points="183.729,84.733 179.962,87.044 176.183,89.329 168.62,93.896 153.423,102.902 149.624,105.154 
				145.806,107.375 138.171,111.814 122.896,120.683 107.548,129.427 99.875,133.797 96.035,135.979 92.181,138.132 76.752,146.738 
				69.006,150.982 65.136,153.1 61.243,155.196 61.194,155.113 122.415,119.84 183.681,84.651 			"/>
		</g>
		<g>
			<polygon fill="#84CCD5" points="187.558,102.512 183.653,104.275 179.741,106.012 171.911,109.48 156.202,116.283 
				152.276,117.983 148.335,119.647 140.452,122.977 124.688,129.631 108.872,136.147 100.965,139.403 97.01,141.027 
				93.043,142.621 77.168,148.991 69.21,152.111 65.227,153.669 61.235,155.201 61.201,155.109 124.345,128.719 187.522,102.422 			
				"/>
		</g>
		<g>
			<path fill="#84CCD5" d="M190.366,120.777c0.008-0.076,0.082,0.118,0.025,0.107l-0.037,0.016l-0.064,0.028l-0.123,0.048
				l-0.252,0.09l-0.502,0.173l-1.006,0.338l-2.012,0.664l-4.041,1.278l-8.084,2.528l-16.209,4.88l-4.049,1.216l-4.063,1.181
				l-8.123,2.35l-16.248,4.697l-16.282,4.538l-8.138,2.267l-4.072,1.129l-4.081,1.097l-16.316,4.375l-8.174,2.119l-4.088,1.058
				l-4.093,1.025l-0.023-0.096l65.058-18.476l32.529-9.227l16.258-4.642l8.125-2.338l4.061-1.183l2.027-0.599l1.014-0.307
				l0.504-0.157l0.252-0.082l0.121-0.045l0.059-0.021l0.023-0.012c-0.021-0.021,0.035,0.149,0.018,0.084L190.366,120.777z"/>
		</g>
	</g>
</g>
<g id="surf_x5F_body">
	<g>
		<path fill="#295D60" stroke="#B3DEE4" strokeMiterlimit="10" d="M41.906,202.729c0,0,36.973,46.204,78.245,37.696
			c-12.104-7.955-31.813-17.788-31.813-17.788l-14.981-10.1l-20.134-15.344L41.906,202.729z"/>
		<path fill="#295D60" d="M59.745,144.57c0,0-12.687,4.316-10.479,3.391c2.209-0.927-15.334,7.22-18.672,8.423
			c-3.338,1.203-5.783,3.392-6.482,6.685c-0.382,1.963-0.57,5.786-0.57,5.786s-2.619,0.636-3.101,2.503
			c-0.482,1.866,0.271,4.599,2.062,5.723c1.791,1.126,3.191,0.313,4.644-1.212c1.453-1.522,1.234-1.06,1.926-2.771
			c0.692-1.712-0.301-3.18,1.481-6.037c1.783-2.859,3.065-3.992,3.065-3.992l3.205-0.077c0,0-10.178,14.906-0.413,22.78
			c7.726,3.066,11.868,0.034,16.479,1.298c4.831,2.724,10.026,3.47,10.026,3.47l-0.611,1.296c0,0-10.426,6.528-13.609,7.522
			c-3.183,0.992-4.086,1.479-4.086,1.479s-1.325,4.012,3.287,5.275c6.339-1.207,11.033,1.566,12.543,1.483
			c1.51-0.083,3.282-0.293,3.282-0.293l-0.377-2.755l-1.881-0.092l-4.213-1.868c0,0,19.271-6.329,21.202-6.101
			c1.93,0.229,9.2-2.238,7.738-5.867c-1.462-3.631-12.42-9.688-12.42-9.688l-8.181-6.467l-8.629-3.869c0,0,4.822-2.428,7.903-2.848
			c3.081-0.421,6.471-4.259,7.8-6.125c1.33-1.863,6.458-3.866,6.458-3.866s20.092,14.314,20.747,14.848
			c0.653,0.534,7.214,4.637,8.717,6.25c1.507,1.613,3.365,4.638,4.772,5.979c1.069,2.54,1.62,4.496,2.487,4.338
			s0.489-2.485-0.115-2.883c-0.604-0.398-0.479-1.83-0.479-1.83s7.773,4.127,8.23,4.116c0.459-0.011,0.375-1.521-1.865-5.178
			c-2.242-3.656-2.154,0.007-6.695-4.054c-4.542-4.06-8.534-10.011-8.534-10.011s-5.088-3.863-10.583-7.58
			c-2.689-3.188-5.189-5.211-8.664-7.808c-5.664-7.812-11.669-8.881-11.669-8.881s-11.556-0.654-15.771,0.141"/>
		<path fill="#295D60" d="M63.676,132.838c-2.247,0.81-4.214,2.828-4.369,4.963c-0.155,2.133,0.907,7.217,0.907,7.217
			s-1.592-0.102-0.838,1.34c0.753,1.439,1.598,2.601,2.723,3.678c1.815,3.965,5.55,6.852,7.599,6.344s4.005-2.982,4.572-6.11
			c0.679-4.095,0.679-4.095,0.679-4.095s0.784-1.668,0.567-3.129c-0.219-1.461-1.554-1.749-1.554-1.749s0.489-2.486-1.741-5.686
			C69.989,132.412,65.925,132.027,63.676,132.838z"/>
		<path fill="#295D60" d="M42.027,171.398c-4.692,0.133-7.35,2.131-8.459,4.19c-1.109,2.059-3.375,15.521,16.851,10.694
			c5.479-0.742,9.989-2.429,13.563-4.032c-1.014,2.572-1.993,18.679,0.757,32.165c0.15,1.685-2.304,6.861,1.114,7.077
			c3.417,0.214,13.934,2.778,13.934,2.778s6.699-4.8,6.638-6.454c-0.06-1.654-8.454-1.718-8.454-1.718s-5.557-1.479-6.405-3.688
			c-0.846-2.209,0.021-26.352,1.388-33.301c0.241-7.468-11.724-7.709-16.34-7.575C51.998,171.668,46.719,171.266,42.027,171.398z"/>
	</g>
</g>
<g id="plongee">
	<g>
		<path fill="#125250" d="M170.564,214.269l16.031,4.02c0,0,3.011-3.751,5.3-6.035c6.888-5.551,8.745-9.985,10.322-10.861
			c3.312-1.063,11.535,2.376,15.836,4.494c4.303,2.118,18.007,4.895,19.342,5.582c1.334,0.688,1.35,4.21,1.35,4.21
			s-12.669,0.921-19.108-1.672c-6.441-2.594-13.864-5.584-13.864-5.584s-12.768,11.152-13.574,13.27
			c-0.808,2.116-12.388,7.129-12.388,7.129l-14.443-8.554l-0.963-8.792L170.564,214.269z"/>
		<path fill="#125250" d="M133.887,199.717c0,0-5.429,0.442-5.533,4.747c-0.105,4.306,1.674,5.221,1.944,6.063
			c-2.425,1.705-2.748,0.696-2.425,1.705c0.323,1.012,1.693,3.545,3.041,3.111c1.348-0.431,1.307-1.719,2.315-2.043
			c1.011-0.324,3.073,0.314,3.073,0.314l3.742,5.301l-0.915,1.779c0,0,0.144,2.184,2.481,3.104c0.191,2.911,1.521,4.156,2.631,4.728
			c-0.355,1.786,0.415,3.025,1.027,3.2c0.613,0.174,2.615,1.204,2.615,1.204s-0.604,3.909,0.723,5.153
			c1.33,1.247,2.462,0.142,3.033-0.973c0.57-1.11-0.279-4.924-0.279-4.924l-0.703-2.189l0.651-3.181c0,0,0.985,1.913,0.974,3.031
			c1.608,0.969,1.608,0.969,1.608,0.969s1.89,1.254,2.852,0.201s-1.91-4.217-1.458-4.548c0.45-0.33,2.163,1.535,2.163,1.535
			s3.509-3.539,4.027-4.82c1.893,0.693,18.189,8.654,21.997,8.361c3.807-0.292,17.352-6.123,22.775-6.004
			c6.231-2,7.656-2.585,12.683-3.51c2.237-0.412,11.401-1.915,18.465-10.566c4.128-3.925,1.7-6.861-0.297-8.449
			c-2.802,0.527-10.997,7.983-10.997,7.983l-20.807,6.737c0,0-2.835,3.813-8.632,4.75c-5.795,0.933-8.964,1.866-15.068,2.046
			c-4.205-2.305-12.917-10.159-12.917-10.159l4.926-0.279c0,0,4.969-4.194,2.837-8.525c-2.133-4.33-28.324-7.818-28.324-7.818
			l-4.858,4.541l-2.717,2.531l-3.088-3.839l-2.946-1.653"/>
	</g>
</g>
</svg>
)