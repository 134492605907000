import React, { Component } from 'react';
import Accordion from "../components/Accordion";
import Bookings from './AdminBookings';
import baby from "../images/baby.png";
import stage from "../images/medaillon_equipeFrance.png";
import cissou from "../images/medaillon_concours_00.png";
import kids from "../images/kids-group.jpg";
import corse from "../images/medaillon_corse.png";
import {withBooking} from "../contexts/BookingContext";
import Days from "./AdminDays";
import connectionStrings from "../data/connectionStrings";
import axios from "axios";
import { Label } from '../common';
import AdminRDPToDisable from './AdminRDPToDisable';
import AdminRangeDatePicker from './AdminRangeDatePicker';
import {FaAlignJustify} from 'react-icons/fa';
import { DesactivateLogo } from './Desactivate';
import { ActivityLogo } from './ActivityLogo';
import { ActivitiesLogo } from './ActivitiesLogo';
import { BaladeLogo } from './BaladesLogo';
import { Balloons } from './BalloonsLogo';


const REF_DATE = new Date();

const presta = [
    {
        title : "DÉSACTIVER DES CRÉNEAUX",
        subtitle : <AdminRDPToDisable/>,
        description : "",
        content: "",
        presta: "4", // non vide
        svg: <DesactivateLogo width="8em" height="8em"/>
    },

    {
        title: "MODIFIER LES CRÉNEAUX COURS / BALADE",
        subtitle: <Days category={1}/>,
        description : "",
        content: "",
        presta: "1",
        svg: <BaladeLogo className="balade-logo" width="8em" height="8em"/>
    },
    {
        title: "MODIFIER LES CRÉNEAUX ANNIVERSAIRE LUDIQUE",
        subtitle: <Days category={3}/>,
        description : "",
        content: "",
        presta: "1",
        svg: <Balloons width="8em" height="8em"/>
    },
    {
        title : "MODIFIER LES CRÉNEAUX PRESTATION UNIQUE",
        subtitle : <Days category={0}/>,
        description : "",
        content: "",
        presta: "0",
        svg: <ActivityLogo width="8em" height="8em" />
    },
    {
        title : "MODIFIER LES CRÉNEAUX DE STAGES",
        subtitle : <AdminRangeDatePicker/>,
        description : "",
        content: "",
        presta: "2",
        svg: <ActivitiesLogo width="8em" height="8em" />
    },
    {
        title: "LISTE DE VOS PROCHAINS RENDEZ-VOUS",
        subtitle: <Bookings/>,
        svg: <FaAlignJustify className="nav-icon-presta" ></FaAlignJustify>
    },

];



class AdminPresta extends Component {
    constructor(props){
        super(props);
        this.props.context.resetGos();
        this._isMounted = false;            
        this.SERVER = connectionStrings.SERVER;


    }
    state = {
        prestaIsChosen : false,
        presta: "",
        message:"" 
    }

    async componentDidMount(){
        this._isMounted = true;             
        
    }
        
    
  
 

    getSortedTimeStamps = async ()=>{
        const CancelToken = axios.CancelToken;
        let cancel;
        try {
              const resp = await axios.get(`${this.SERVER}/orderedts`, {
                headers:{'sortedtimestampsheader': 'sortedtimestamps'}
                },       
              {cancelToken: new CancelToken(function executor(cancelParam) {
                    // An executor function receives a cancel function as a parameter
                    cancel = cancelParam;
              })});
              if (resp !== undefined){
                  
                    if(resp.data.error){
                          console.log("Erreur pendant le chargement des créneaux : " + resp.data.error);
                          cancel();
                          return {message: "Une erreur est survenue pendant le chargement des créneaux"};
                    }else{
                          console.log("les timeStamps récupérés : " + resp.data.timestamps.id);
                          cancel();
                          return {timeStamps : resp.data.timestamps};
                    }
              }else{
                    console.log("... chargement en cours");
                    return {message : "... chargement en cours"};
              }
        } catch (error) {
              console.log("erreur dans la récupération des créneaux : " + error);
              cancel()
              return {message : "Erreur dans la récupération des créneaux : " + error};
        }
  }





    handleClick=(event)=>{
       this.setState({prestaIsChosen : true})
    }
    
    render(){
    const {message} = this.state
    
  



     return( 
         <>
            <Accordion presta={presta}/>
            <Label isvalid={false}>{message}</Label>
        </>
        )
    }       
}
export default withBooking(AdminPresta)