
import React, {useState, useContext, useEffect} from 'react';
import Stripe from './Stripe';
import {withBooking, BookingContext} from "../contexts/BookingContext";
import {UserContext} from "../contexts/UserContext";
import connectionStrings from "../data/connectionStrings";

function BabyContent(props) {
    const [items, setItems] = useState();
    const [_isMounted, set_isMounted] = useState(false);
    const [daysToDisable, setDaysToDisable] = useState([]);
    const [myError, setMyError] = useState("");
    
    const bookingContext = useContext(BookingContext);
    const userContext = useContext(UserContext);
    

    useEffect(() => {
      set_isMounted(true);
      async function getTimeStampsFromContext(){
        /**
       * LOAD TIMESTAMPS!!!!
       */
        const resp = _isMounted === true && await bookingContext.getFinalTimeStamps(0);
        if (resp !== undefined){ 
          console.log("resp not undefined dans babyContent : " + resp);
          if (resp.timeStamps !== undefined){ 
            setItems (resp.timeStamps);     
            setDaysToDisable(bookingContext.setListToDisable(resp.timeStamps, 0));
          }   
        }else{
        // undefined
        setMyError("...Chargement en cours");
        }        
      }
   
    
      getTimeStampsFromContext();


      return () => {
        {/* <div>{myError}</div> */}
      }
    }, [_isMounted, myError, bookingContext])



    return (
        <>
        
        <div className="pricing">
        <h1 className="form-h1">Exemple d'activité</h1>
        <span className="pricing-title"> Atelier centre de loisirs  </span>                   
        <span className="pricing-span"> Conditions et tarification </span>
                       
        </div>
        <Stripe step = {props.step} start = {props.start} eventisok = {true} presta = "0" items = {items} tsToDisable = {props.tsToDisable} daysToDisable={daysToDisable}/>
        </>
        )
    }
    export default withBooking(BabyContent)
          
