
import connectionStrings from '../data/connectionStrings';
import axios from 'axios';

const SERVER = connectionStrings.SERVER;

/**
 * 
 * @param {array} bookings 
 * @returns {array} filtered by date bookings
 */
 export const filterBookings=(bookings)=>{
    
    let filteredBookings=[];
    if (bookings !== undefined){
    console.log(bookings.length);
    /*Réservation modifiable jusqu'à 24 heures après la réservation*/
    bookings && bookings.forEach(booking => {
        if (((new Date(booking.chosen_time_stamp)).getTime()+(24*3600000)) >= ((new Date().getTime()))){
            filteredBookings.push(booking)
        };
    });
    
    console.log("dans filteredbookings clientsUtils" + JSON.stringify(filteredBookings));
    return filteredBookings;   
}
}

export const checkEmailAddress = async (emailAddress)=> { 
    let foundCorresp = false;
    let blackList = [];
    if(emailAddress !== undefined){
        if (emailAddress.length > 0){
            if (emailAddress !== undefined && emailAddress !== ""){            
                const regExp =  new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);           
                foundCorresp = regExp.test(emailAddress);           
            }
            let source = axios.CancelToken.source();
            try{
                const res = await axios.get(`${SERVER}`+'/blacklist',            
                {
                    cancelToken: source.token
                }
                );
                if (res !== undefined){
                    blackList = res.data.blackList;
                    console.log("blackList : " + JSON.stringify(blackList));
                    source.cancel();
                    const forbiddenAddress = blackList.find(address=>
                        address === emailAddress);
                    const isForbiddenAddress = forbiddenAddress !== undefined ? true : false;
                    console.log("isForbidden : " + isForbiddenAddress);
                    console.log("email ok : " + foundCorresp && !isForbiddenAddress);
                return foundCorresp && !isForbiddenAddress ? true : false
                }else{
                    source.cancel();
                }
            }catch(error){
                console.log("erreur blackList : " + error);
            }
        }else{
            return false;
        }
    }
}
export const _checkEmailAddress = async (emailAddress)=> { 
    let foundCorresp = false;
    let blackList = [];
    if(emailAddress !== undefined){
        if (emailAddress.length > 0){
            if (emailAddress !== undefined && emailAddress !== ""){            
                const regExp =  new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);           
                foundCorresp = regExp.test(emailAddress);           
            }
            const CancelToken = axios.CancelToken;  
            let cancel;
            try{
                const res = await axios.get(`${SERVER}`+'/blacklist',            
                {cancelToken: new CancelToken(function executor(cancelParam) {
                    // An executor function receives a cancel function as a parameter
                    cancel = cancelParam;
                })
                },
                {timeout : 3000}
                )
                if (res !== undefined){
                    blackList = res.data.blackList;
                    console.log("blackList : " + JSON.stringify(blackList));
                    cancel();
                    const forbiddenAddress = blackList.find(address=>
                        address === emailAddress);
                    const isForbiddenAddress = forbiddenAddress !== undefined ? true : false;
                    console.log("isForbidden : " + isForbiddenAddress);
                    console.log("email ok : " + foundCorresp && !isForbiddenAddress);
                return foundCorresp && !isForbiddenAddress ? true : false
                }else{
                    cancel();
                }
            }catch(error){                
                console.log("erreur blackList : " + error);
            }
        }else{
            return false;
        }
    }
}
export const setAmountByCatAndQuantity = (pCategory, pQuantity, pIsFullWeek) =>{
    let result = 1000;
    switch (pCategory) {
        case 0: // baby 
        result = 1000 * pQuantity;
        break;
        case 1: // cp 
        result = 1000 * pQuantity;
        break;
        case 2: // stage 
        
        if (pIsFullWeek !== undefined && pIsFullWeek === true){
            result = 1800 * pQuantity * 5; // number of days is not saved in db. For the list, and for the moment count is 5 days for fullweek
        }else{
            result = 1800 * pQuantity;
        }
        break;
        case 3: // goûter 
        result = 3000;
        break;        
        default:
        result = 0;
        break;
    }
    return result;
}
/**
 * 
 * @param {*} sNumber : string
 * @returns  a formatted string (plus 0 if < 10)
 */
export const formatNumber=(sNumber)=>{
    const iNumber = parseInt(sNumber, 10);
    if(iNumber < 10){
        return "0" + sNumber
    }else{
        return sNumber;
    }
}
/**
 * 
 * @param {sHour: string} sHour 
 * @returns sHour: string
 */
export const formatUnits=(sHour)=>{
    const zero = "0";       
    let result;
    console.log(sHour.toString().length);
    if (sHour.length < 2){
        result = zero.concat(sHour);
    }else{
        result = sHour;
    }
    return result;
}
/**
 * 
 * @param {*} date 
 * @returns Date formatée à 23h59min59s
 */
export const setHourForCalendarDate=(date)=>{
    /* const todayHour = formatNumber(new Date().getHours().toString());
    const todayMin = formatNumber(new Date().getMinutes().toString());
    const todaySec = formatNumber(new Date().getSeconds().toString());
    const todayHourString = todayHour + ":" + todayMin + ":" + todaySec; */
 
    // Give today hour to date of the calendar
    let dateString = date.toString();
    dateString = dateString.replace("00:00:00", "23:59:59");    
    const formatedDate = new Date(dateString);    
    return formatedDate;
}