import React, { useState, useEffect, useContext, useRef } from 'react';
import { fr } from 'date-fns/locale';
import { format, parseISO, set } from 'date-fns';
import getMonth from 'date-fns/getMonth';
import FullDatePicker from './DatePickerFromTsx';
import connectionStrings from "../data/connectionStrings";
import axios from 'axios';
import {SelectInputAdmin} from "./SelectInputAdmin";
import {StyledFormCardAbs, RoundButtonAbs, StyledFormCardAdmin,StyledFormCardStage, Label, StyledFormCardsDaysAdmin, StyledFormWrapperStage} from "../common";
import { parseFromTimeZone } from 'date-fns-timezone';
import "../App.css"
import { UserContext } from '../contexts/UserContext';




export default function AdminRDPToDisable(props) {
    
  
  const userContext = useContext(UserContext);
  const focusInput = useRef();
  const CATLIST = [{id: 0, label: "Baby Poney"}, {id: 1, label: "Cours personnalisé"}, {id: 3, label: "Goûter d'anniversaire"}];
   
    
    const SERVER = connectionStrings.SERVER;
    const OPTIONS = {
        AccessControlAllowOrigin : userContext.handleAccesControl(userContext.serverStatus), 
        xsrfCookieName: 'XSRF-TOKEN',
        xsrfHeaderName: 'X-XSRF-TOKEN',
        SameSite : 'None',
        Secure : 'true'    
    };
   

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [_isMounted, set_isMounted] = useState(false);
  const [message, setMessage] = useState("");
  const [delMessage, setDelMessage] = useState("");
  const [timeStamps, setTimeStamps] = useState([]);
  const [categoryId, setCategoryId] = useState("");

  useEffect(() => {
    /**
 * 
 * @param {*} bDisabledDt
 * @param {*} categoryId
 */
  async function  getDisabledTimeStamps (bDisabledDt)   {
  const CancelToken = axios.CancelToken;
  let cancel;
  // on récupère tous les créneaux
  try {
        const resp = await axios.get(`${SERVER}/timestamps`, {
          headers:{'timestamps': 'timestamps'}
          },            
          {cancelToken: new CancelToken(function executor(cancelParam) {
              // An executor function receives a cancel function as a parameter
              cancel = cancelParam;
        })}, {timeout: 3000}
        );
        if (resp !== undefined){              
              if(resp.data.error){
                    console.log("Erreur pendant le chargement des créneaux : " + resp.data.error);
                    cancel();
                    setMessage(resp.data.error);
              }else{
                    console.log("les créneaux : " , resp.data.timestamps);
                    cancel();
                    // si on a des créneaux, on ne retourne que les dates désactivées (isDisable = true)
                    const finalTimeStamps = resp.data.timestamps.filter(timestamp=>
                    timestamp.is_to_disable === bDisabledDt);
                    console.log(finalTimeStamps);
                    const timeStampsToDisable = finalTimeStamps.filter(timeStamp => new Date(timeStamp.dt_end) >= Date.now());
                    setTimeStamps(timeStampsToDisable);
              }
        }else{
              console.log("... chargement en cours");
              setMessage("... chargement en cours");
        }
  } catch (error) {
        console.log("erreur dans la récupération des créneaux de stage : " + error);
        cancel();
        setMessage("Erreur de communication avec le serveur, merci de réessayer ultérieurement");
  }
}
    set_isMounted(true);
    getDisabledTimeStamps(true);
    
    }, 
    [_isMounted, SERVER])
   

  
  const createTimeStampToDisable = async (pDtStart, pDtEnd)=>{
    console.log("on rentre dans createTimeStampToDisable");
    const CancelToken = axios.CancelToken;
    if (pDtStart === undefined || pDtStart === null || pDtStart === ""){        
        const message =  "Merci de choisir une date de début d'absence'";
        console.log("date début non choisie");
        return {message: message};
    }else if (pDtEnd === undefined || pDtEnd === null || pDtEnd === ""){
        const message =  "Merci de choisir une date de retour";
        console.log("date fin non choisie");
        return {message: message};
        
    }else{  
        console.log("on lance le traitement")      
        const options = OPTIONS;            
        setIsProcessing(true);
        let cancel; 
        try {
            const resp = await axios.post(`${SERVER}/timestamp`,
            // add new params
            {                
                dt_start: pDtStart,
                dt_end: pDtEnd,                
                category: categoryId,
                is_to_disable: true

            },
            options,
            {cancelToken : new CancelToken(function executor(cancelParam){
                cancel = cancelParam
            })});
            
            if (resp !== undefined){
                if (resp.data.error){
                    const message = resp.data.error;                    
                    console.log("on reçoit une erreur : " + resp.data.error);
                    cancel();
                    setIsProcessing(false);                  
                    return {message: message};
                }else{
                    const newTimeStamp = resp.data.timestamp;
                    console.log(resp.data.timestamp);
                    setIsProcessing(false);
                    cancel();
                    return {timeStamp: newTimeStamp};
                }
            }else{
                const message = "... Enregistrement en cours"
                return {message: message};
            }
        } catch (error) {           
            const message =  "Une erreur est survenue pendant l'enregistrement du créneau";
            console.log("erreur pendant l'enregistrement cu créneau : " + error);
            return {message: message};
        }
        
    }
}


const handleDelete =  async (id, e) =>{
  e.preventDefault();
  console.log("on rentre dans handleDelete")  
  const resp =  _isMounted && await deleteTimeStamp(id);   
  if (resp !== undefined ){ 
      if (resp.message){
      console.log("la réponse : " + resp.message);
      setTimeout(()=>setDelMessage(resp.message), 3000);
      } else {
      // mise à jour de la liste
      if (resp.deletedid !== undefined){
        console.log("timestamp supprimé : " + resp.deletedid);          
      // mise à jour de la liste
      const toDisableList = timeStamps;
      const newList = toDisableList.filter((timestamp)=> timestamp.id !== resp.deletedid);
      setTimeStamps(newList); 
      setDelMessage("Absence supprimée");
      setTimeout(()=>setDelMessage(""), 3000);
      }
  }
  } else{
    setDelMessage("... Suppression en cours");
      console.log("...undefined");
  }
}

const deleteTimeStamp = async (id)=> { 
  const CancelToken = axios.CancelToken;
  console.log("dans deletetimestamp, ts to delete " + id);
  /***           
  * APPEL PUT AXIOS 
  * @param mail 
  */
  const options = OPTIONS;
  let cancel;
  try {    
      // set dt expirity to now for the moment, change if not compatible with timestamps frontend
      console.log("url : " + `${SERVER}/timestamp/${id}`);    
      const res = _isMounted && await axios.delete(`${SERVER}/timestamp/${id}`, 
      options,
      {cancelToken: new CancelToken(function executor(cancelParam) {
      // An executor function receives a cancel function as a parameter
      cancel = cancelParam;
      })}
      );
      if (res !== undefined){            
          if (res.data !== undefined){
              if (res.data.error){
                  const message = "Erreur lors de la suppression du créneau, merci de réessayer ultérieurement"            
                  console.log("erreur pendant la suppression : " + res.data.error);
                  cancel();
                  return ({message: message});

              }else{ 
                  if(res.data.deletedid !== undefined){
                  console.log("on retourne la réponse : " + res.data.deletedid);
                  const deletedid = res.data.deletedid;
                  cancel();
                  return ({deletedid:deletedid});
                  }
              }
          }
      }else{
          console.log("res undefined");
          return ({message: "...Suppression en cours"});          
      }

  } catch (error) {
      console.log("catch axios del");
      return ({message: "...Suppression en cours"});
  }
  cancel();

}
const handleSelectChange = (e) =>{
    setCategoryId(e.target.value);
    console.log(typeof(e.target.value));   
    console.log(e.target.value); 
}

 const handleSubmit = async (event)=>{    
    event.preventDefault();
    console.log("formulaire soumis");
    const resp = await createTimeStampToDisable(startDate, endDate, categoryId, );
    if (resp !== undefined){
        if(resp.message){
            setMessage(resp.message);
            
        }else{
          const newTimeStamp = resp.timeStamp;
          // mise à jour de la liste
          const stageList = timeStamps;
          const newList = stageList.concat(newTimeStamp);
          console.log("le nouveau créneau : " + newTimeStamp);
          console.log("la nouvelle liste :: " + newList);
          setTimeStamps(newList);
          setMessage("Créneaux désactivés");
          setTimeout(()=>setMessage(""), 3000);
          
        }
    }else{
      setMessage("Enregistrement en cours");
    }

}
const formatToLocalDateTime=(date)=>{        
  // Set the date to "2018-09-01T16:01:36.386Z"
  /**
   * abbreviated pour datefns month
   */
  const utcDate = parseFromTimeZone(date, { timeZone: 'Europe/Berlin' });  
  let localDay = format (utcDate, 'dd MMM yyyy', { locale: fr });
  return localDay;
}
const getTsLabel = (sCatId) =>{
  let labelCat = "";
  switch (sCatId) {
    case "0" :
      labelCat =  "Atelier";
      break;
    case "1" : 
      labelCat =  "Cours / balade";
      break;
    case "3" : 
      labelCat =  "Anniversaire ludique";
      break;
  
    default:
      labelCat = "";
      break;
      
  }
  return labelCat;
}

const content = timeStamps.map((tStampToDis) =>
<StyledFormCardAdmin  key={tStampToDis.id}>
  <div key={tStampToDis.id}>  
    <h3>{fr.localize.month(getMonth(parseISO(tStampToDis.dt_start)), {width: 'full'})}</h3>
    <h3>Activité : {getTsLabel(tStampToDis.category.toString())}</h3>
    <h3><button className="btnless" type="button" value={getMonth(parseISO(tStampToDis.dt_start))} onClick={(e)=>handleDelete(tStampToDis.id, e)}><label className="labelless-abs">-</label></button></h3>
    <p> Départ : {formatToLocalDateTime(tStampToDis.dt_start)}</p>
    <p> Retour : {formatToLocalDateTime(tStampToDis.dt_end)}</p>
  </div>
</StyledFormCardAdmin>
)
const modifiers = {   
  disabled: date => date < new Date() // disable les créneaux non dispos
 
}

  return (
      
      <>
      <StyledFormCardStage onSubmit={handleSubmit}>
          <h3> Choisir la période à désactiver</h3>
      <StyledFormWrapperStage>
        
        <div className="bookingcolumnstage">
        Départ : {startDate ? format(startDate, 'dd MMM yyyy', { locale: fr }) : 'Aucune date sélectionnée'}.
      </div>
      <div className="bookingcolumnstage">
        Retour : {endDate ? format(endDate, 'dd MMM yyyy', { locale: fr }) : 'Aucune date sélectionnée'}.
      </div>
    
      </StyledFormWrapperStage>
       <div className = "bookingcolumnadmin">
        
    <FullDatePicker 
            name="rangeDatePicker"
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            disabled={new Date()}            
            format='dd MMM yyyy'
            locale='fr'
            modifiers={modifiers}
            />
    </div>
    <div className="bookingcolumnstage">
    <SelectInputAdmin
            className = "intro_select_input_abs"
            refinput = {focusInput}
            table = {CATLIST}
            onChange={handleSelectChange}
            
           
      />
    </div>
    
    <div className="bookingcolumnstage">
    <RoundButtonAbs type="submit">+</RoundButtonAbs>
    </div>
    <StyledFormCardAbs istohide={message === ""}>{message}</StyledFormCardAbs>
    </StyledFormCardStage>
      <div>
        <h2 className="h2-abs">Liste des périodes désactivées </h2>
        </div>
        <StyledFormCardAbs istohide={delMessage === ""}>{delMessage}</StyledFormCardAbs>
        <StyledFormCardsDaysAdmin>
          {content}         
          
 
    </StyledFormCardsDaysAdmin>
    </>
  )
}