import React, { useState, useContext, useEffect, useRef } from 'react';
import Presta from "../components/Presta";
import AuthUser from '../components/AuthUser';
import '../App.css';
import { UserContext } from '../contexts/UserContext';
import {Navigate, useLocation} from 'react-router-dom';
import { BookingContext } from '../contexts/BookingContext';
import {AuthMessage} from '../components/AuthMessage';





function Booking(props){  
   const userContext = useContext(UserContext);
   const bookingContext = useContext(BookingContext);   
   const [_isMounted, set_isMounted] = useState(false);    
   const [response, setResponse] = useState(null);
   const isMailSent = userContext.isEmailSent;
   const email = userContext.user.email;
   const timerRef = useRef(null);   
   const [homeRedirect, setHomeRedirect] = useState(false);
   // reset périodiquement le compteur des mousemove
   const [mouseMove, setMouseMove] = useState(0);
   const [resetMouseEvent, setResetMouseEvent] = useState(false);
   const [isTimeOut, setIsTimeOut] = useState(false);
   const location = useLocation();
    console.log("location booking : ", location);

   const handleClick = async (e, email) =>{
    
    console.log("lien cliqué et mail récupéré pour envoi : " + email);
    try {
        const response = _isMounted && await userContext.sendEmail(email);
        if (response !== undefined){
            setResponse (true);            
        }  
         // attendu true
    } catch (error) {
      //console.log("Erreur dans l'envoi du mail " + error);
      setResponse(false);
    }    
}

  


   useEffect(() => {
       set_isMounted(true);
       window.scrollTo(0, 0);
   
   }, [])

   if (homeRedirect){
    return <Navigate redirect to="/"/> 
   }else{
      
        return userContext.isAuth === 2 || userContext.isAuth === 1 ? (
            <> 
           
                <div className="booking-page">
                    <div className="home">       
                        <AuthUser/> 
                        {/* L'utilisateur n'a pas encore validé son adresse mail */}                        
                       
                        <AuthMessage onClick={(e)=>handleClick(e, email)} ismailsent={isMailSent} isauth = {userContext.isAuth} email={email}/>
                    
                        <div className="page-title">Bienvenue dans l'espace réservation</div>
                            <p>Du même endroit, votre client peut réserver un atelier ou le modifier</p>
                            <p><span className="pricing-body">Prestations multiples </span> : ateliers ou stages</p>
                            <p>ou <span className="pricing-body">Prestation unique : </span> réservation à la journée</p> 
                            <p>La <span className="pricing-body">liste des réservations</span> permet de visualiser et de modifier les prochains rendez-vous.</p>                           
                        <Presta tsToDisable={bookingContext.tsToDisable} validTs={bookingContext.timeStamps} tsmessage={bookingContext.tsMessage} validTsWithTimes={bookingContext.timeStampsWithTimes} tswtmessage={bookingContext.tsWtMessage}/>     
                        
                    </div>
                </div>  
                
                 {/* Background */}
                 <div className="booking-bckg">
                            
                </div>   
            
            </>
            ):
            (
                <Navigate redirect to={{
                    pathname : "/"                    
                    }}
                    /> 
            )
            
        }
    }
  
    export default Booking
