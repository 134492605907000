import React, { useState } from 'react';

import {
    MenuBurger,
    Menu    
} from '../common';
import { GiCrossFlare } from 'react-icons/gi';
import { withUser } from "../contexts/UserContext";
import {Link} from "react-router-dom";





function Header(props) {
    const [user, setUser] = useState(null)
    const [menuIsOpen, setMenuIsOpen] = useState(false)
   
    
    const logout = async () => {
        console.log("on rentre dans logout")
        const user = await props.context.logout();
        if (user !== undefined) {
            console.log("user not undefined ", user);
            setUser(user);
        }
        setMenuIsOpen(false);
        props.context.resetGos(); // on reset les redirections
        
    }
    const ClearColor ="grey" ;
    const MiddleColor = "#77B82A";
    const signupProps = {clearcolor: ClearColor, middlecolor: MiddleColor, comefrom : "home", title : "Créez votre espace et réservez", istohide: "false", btnlabel: "Inscription"}
    const signInProps = {clearcolor: ClearColor, middlecolor: MiddleColor, btnlabel: "Inscription"}
    return (
        <header className="header-rel">
        <Menu >   
        <li className="menu-li"><Link to="/login" end="true" {...signInProps}><span className="menu-span">Se connecter</span></Link></li>
        <li className="menu-li"><Link to="/signup" end="true" {...signupProps}><span className="menu-span">S'inscrire</span></Link></li>
        <li className="menu-li">
        <a href="https://latoiledenbouyssou.fr">
        <span className="menu-span">Retour au site</span>
        </a>
        </li>
        <li className="menu-li">
        <span className="menu-span"><GiCrossFlare/></span>
        <Link to="/" end="true"><span className="menu-span" onClick={logout}>
            Se déconnecter</span></Link>
        </li>
        </Menu>
        
        </header>
        
        
        
        
        
        )
    }
    export default withUser(Header)