import React from 'react';
import styled from 'styled-components';




export const StyledForm = styled.form`
  padding: 0em;
  margin: 0 auto;  
  height: fit-content;
  width: 100%;  
  border: 0.5px solid lightgrey;  
  border-radius: 1%;
  margin-top: 2em;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); 
  line-height: normal;
  background-color: whitesmoke; 
  
`
/**
 * margin-top: ${props => props.istohide ? "0" : "18em;"};
 */
export const ReinitMarginTop = styled.div`


display: ${props => props.istohide ? "none" : "block;"};

@media(max-width: 800px){
  margin-top: 0;
}
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
  margin-top: 0;
}


` 

export const StyledFormLine = styled.form`
  padding-top: 1em;
  height: inherit;
  width: inherit;
  margin: 0 auto;
  border: 0.5px solid lightgrey;  
  border-radius: 1%;
  margin-top: 0.5em;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); 
  line-height: normal;
  background-color: whitesmoke;
  align-items: middle;
  min-width: 100%; 
`
export const StyledFormCard = styled.form`
  padding: 1em; 
  min-width: fit-content;
  margin: 0 auto;
  border: 0.5px solid darkcyan;
  background-color: whitesmoke;
  border-radius: 4%;
  margin-top: 0.5em;
  /* box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);  */
  line-height: normal;  
  align-items: middle;
  @media(max-width: 800px){
    width: 100%;
    padding: 0em;
  }
  @media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
    width: 100%;
    padding: 0em;
  }
 
`

export const BookingCardAdmin = styled.form`
  padding: 1em; 
  min-width: fit-content;
  margin: 0 auto;
  border: 0.5px solid lightgrey;  
  border-radius: 4%;
  margin-top: 0.5em;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); 
  line-height: normal;
  background-color: whitesmoke;
  align-items: middle;
  width: 80%;
 
`


export const StyledFormCardAdmin = styled.form`
  padding: 1em;
  height: fit-content;
  width: inherit;   
  margin: 0 auto;
  border: 0.5px solid lightgrey;  
  border-radius: 3%;
  margin-top: 0.5em;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); 
  line-height: normal;
  background-color: whitesmoke;
  align-items: middle;
  min-width: 100%; 
  max-width: inherit;
  @media(max-width: 800px){
    width: 100%;
    padding: 0em;
  }
  @media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
    width: 100%;
    padding: 0em;
  }
`

export const StyledFormCardStage = styled.form`
  
  padding: 1em;
  height: fit-content;
  width: 100%;   
  margin: 0 auto;
  border: 0.5px solid lightgrey;  
  border-radius: 3%;
  margin-top: 0.5em;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); 
  line-height: normal;
  background-color: whitesmoke;
  align-items: middle ;
 
  min-width: 100%; 
  max-width: inherit;
  @media(max-width: 800px){
    width: 100%;
    padding: 0em;
  }
  @media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
    width: 100%;
    padding: 0em;
  }
`

export const StyledFormCardInfo = styled.section`

  display: ${ props => props.istohide ? "none" : "inline-block"};
  color: purple;
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
  padding: 1em;
  height: fit-content;
  width: inherit;   
  margin: 0 auto;
  border-bottom: 0.5px solid darkcyan;  
  border-radius: 3%;
  margin-top: 0.5em;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 0px 0px rgba(0, 0, 0, 0.08); 
  line-height: normal;
  background-color: whitesmoke;
  align-items: middle;
  min-width: 100%; 
  max-width: inherit;
  @media(max-width: 800px){
    width: 100%;
    padding: 0em;
  }
  @media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
    width: 100%;
    padding: 0em;
  }
`

export const StyledFormCardAbs = styled.section`

  display: ${ props => props.istohide ? "none" : "inline-block"};
  color: #226D68;
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
  padding: 1em;
  height: fit-content;
  width: inherit;   
  margin: 0 auto;
  border-bottom: 0.5px solid #226D68;  
  border-radius: 3%;
  margin-top: 0.5em;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 0px 0px rgba(0, 0, 0, 0.08); 
  line-height: normal;
  background-color: whitesmoke;
  align-items: middle;
  min-width: 100%; 
  max-width: inherit;
  @media(max-width: 800px){
    width: 100%;
    padding: 0em;
  }
  @media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
    width: 100%;
    padding: 0em;
  }
`


export const StyledFormWrapperStage = styled.section`
  display: inline-block;
  
  width: 100%;
  margin: 0 auto;
  
  @media(max-width: 800px){
    width: 100%;
    padding: 0em;
  }
  @media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
    width: 100%;
    padding: 0em;
  }
`
export const ThreeColWrapper = styled.section`
  display: grid;
  grid-template-columns: 40% 40%;
  grid-row-gap: 2em;
  grid-column-gap: 2em;

  @media(max-width: 800px){
    width: 100%;
    padding: 0em;
  }
  @media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
    width: 100%;
    padding: 0em;
  }
`
export const StyledEvent = styled.form`
  
padding: 1em;
  height: fit-content;
  width: 80%;   
  margin: 0 auto;
  border: 0.5px solid lightgrey;  
  border-top: 0.5px solid purple;
  border-radius: 2px;
  margin-top: 0.5em;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); 
  line-height: normal;
  background-color: whitesmoke;
  align-items: middle;
  min-width: 80%; 
  max-width: inherit;
  display: inline-block;

  @media(max-width: 800px){
    width: 100%;
    padding: 0em;
  }
  @media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
    width: 100%;
    padding: 0em;
  }
`
export const StyledInputCard = styled.form`
  
padding: 1em;
  height: fit-content;
  width: 80%;   
  margin: 0 auto;
  border: 0.5px solid lightgrey;  
  border-radius: 2px;
  margin-top: 0.5em;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); 
  line-height: normal;
  background-color: whitesmoke;
  align-items: middle;
  min-width: 80%; 
  max-width: inherit;
  display: inline-block;
  background-color: whitesmoke;

  @media(max-width: 800px){
    width: 100%;
    padding: 0em;
  }
  @media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
    width: 100%;
    padding: 0em;
  }
`

export const StyledFormCards = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
grid-row-gap: 1%;
grid-column-gap: 7%;
max-width: 100%;
min-height: fit-content;
width: 100%;
background-color: whitesmoke;
@media(max-width: 800px){
  grid-column-gap: 15%;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
}
`
export const StyledFormCardsDaysAdmin = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
grid-row-gap: 1%;
grid-column-gap: 7%;
max-width: 100%;
min-height: fit-content;
width: 100%;
@media(max-width: 800px){
  grid-column-gap: 15%;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
}
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
  grid-column-gap: 15%;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
}
`
export const FormCardsDaysAdmin = styled.form`
display: grid;
grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
grid-row-gap: 1%;
grid-column-gap: 7%;
max-width: 100%;
min-height: fit-content;
width: 100%;
@media(max-width: 800px){
  grid-column-gap: 15%;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
}
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
  grid-column-gap: 15%;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
}
`
export const AdminStyledFormCards = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
grid-row-gap: 1%;
grid-column-gap: 7%;
max-width: 100%;
min-height: fit-content;
width: 100%;
@media(max-width: 800px){
  grid-column-gap: 15%;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
}
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
  grid-column-gap: 15%;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
}

`

export const StyledInputDatePicker = styled.div`
 
  width: inherit;  
  line-height: normal;
  display: grid;
  grid-template-columns: 50% 30%;
  grid-row-gap: 2em;
  grid-column-gap: 2em;
 
`
export const StyledInputwrapper = styled.div`
  display: block;
  width: fit-content;
  margin: 0 auto;

`
export const StyledInput = styled.input`

display: ${props => props.tohideforeinit ? "none" : "inline-block;"};
width: 80%;
max-width: 100%;
padding: 0.5em;    
margin-top: 0.1em;
margin-bottom: 0.1em; 
border-radius: 0.2em;   
border: 1px solid ${props => props.isvalid ? "whitesmoke" : "maroon;"};
font-size: 0.8em;
text-align: right;
margin-left: 1.5em;
margin-right: 1.5em;
height: 1.5em;
&:focus{
border-color: whitesmoke;
}
&:active{
  border: none;
  }
  @media(max-width: 800px){
    width: 70%;
  }
  @media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
    width: 70%;
  }

`

export const StyledInputSmall = styled.input`

display: inline-block;
width: 100%;
max-width: 100%;
min-width: fit-content;
padding: 0.1em;
border-radius: 4px;   
border: 1px solid ${props => props.isvalid ? "whitesmoke" : "maroon;"};
font-size: 1em;
text-align: center;
color: #282c34;
height: 1.5em;
&:focus{
border-color: whitesmoke;
}
&:active{
  border: none;
  }
&:disabled{
  background-color: lightgrey;
}

`

export const StyledInputStripe = styled.input`

display: inline-block;
width: inherit;
padding: 0.5em;    
margin-top: 0.5em;
margin-bottom: 0.5em;

width: 100%;
border-radius: 0.3em;
font-size: 0.8em;
text-align: right;
height: 1em;
border-style: none;


&:focus{
  border-style: none;
  color: darkcyan;

}


@media(max-width: 800px){
  width: 100%;
  margin: 0 auto;
  font-size: 0.7em;
  margin-right: 0.2em;
}

`
export const StyledInputEmailStripe = styled.input`
color: darkcyan;
opacity: 0.8;
display: inline-block;
padding: 0.5em;
width: 100%;
margin: 0 auto;
margin-right: 0.5em;

border-radius: 0.3em;
font-size: 1em;
text-align: right;
height: 1.5em;
border-style: none;


&:focus{
  border-style: none;
  color: darkcyan;
}

@media(max-width: 800px){
  font-size:  0.5em;
  position: relative;
  width: inherit;
  margin: 0 auto;
  margin-right: 0.5em;
}

`



export const RadioGp = styled.div`
 position: relative;
 display: ${props => props.istohide ? "none" : "grid"};
 grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
 grid-column-gap: 2px;
 
 text-align: center;
 width: 100%;
 margin: 0 auto;
 margin-bottom: 1em;
 margin-top: 1em;

`



export const InputWrapper = styled.div`
width: 100%;
margin: 0 auto;
display: inline-block;
`


export const StyledPwdInput = styled.input`
    
    display: inline-block;
    width: 80%;
    max-width: 100%;
    padding: 0.5em;    
    margin-top: 0.1em;
    margin-bottom: 0.1em;    
    border-radius: 0.2em;   
    border: 1px solid ${props => !props.pwdValid ? "whitesmoke" : "whitesmoke;"};
   font-size: 0.8em;
    text-align: right;
    margin-left: 1.5em;
    height: 1em;
    @media(max-width: 800px){
      width: 70%;
    }
    @media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
      width: 70%;
    }
    
`


export const Wrapper = styled.section`

  text-align: center;
  display: grid;
  grid-template-columns: 45% 5% 45% ;
  grid-column-gap: 1%;
  width: 100%;
  position: relative;
  margin: 0 auto;
  @media(max-width: 800px){
    display:  block;
    position: relative; 
  }
  
`

export const VerticalWrapper = styled.section`
 
text-align: justify;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 5%;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    justify-items: center;
    align-items: start;
    justify-content: center;
    align-content: center;

@media(max-width: 800px){
  display:  block;
  position: relative;
  

}
`

export const FormWrapper = styled.section`
  display: block;
  width: 80%;
  margin: 0 auto;
  margin-top: 2.5em;
  margin-bottom: 1em;
  max-width: 100%;
  height: fit-content;

  
  @media(max-width: 1000px){
  
    width: 100%;
  
  }
`
export const StyledButtonWrapper = styled.div`
  position: relative;
  display: flex;
  width: fit-content;
  margin: 0 auto;
  margin-top: 2em;
  display: flex;
  margin-bottom: 1em; 
`

export const StyledButton = styled.button`
  color: darkcyan;  
  background: ${props => props.isvalid ? "whitesmoke" : "whitesmoke"};
  cursor: ${props => props.processing ? "none" : "pointer"};
  font-size: 1em; 
  font-color: darkcyan;
  padding: 0.25em 1em; 
  margin-left: 1em;  
  border-radius: 5px;
  transition: all .35s;
  letter-spacing: 0.5px;
  vertical-align: bottom;
  text-decoration: none;
  align-items: bottom;
  position: relative;
  bottom: -2em;
  width: 10em;
  border-style: ${props => props.processing ? "none" : "solid"};
  border: ${props => props.processing ? "none" : "1px solid"};
  border-color: purple;
 

 
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-image: -webkit-gradient(linear, right top, right top, color-stop(0%,rgba(245, 232, 56, 0)), color-stop(100%, rgba(173,255,47, 1)));
    background-image: -webkit-linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));
    background-image: -moz-linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));
    background-image: -ms-linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));
    background-image: -o-linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));
    background-image: linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));filter:progid:DXImageTransform.Microsoft.gradient(GradientType=1,startColorstr=rgba(245, 232, 56, 0), endColorstr=rgba(173,255,47, 1));
    border: none;
    transition: all .35s;  
    max-height: inherit;
    max-width: inherit;
    border-style: none;
    
  }
  &:hover {    
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#6bcc33), color-stop(100%, #509627));
    background-image: -webkit-linear-gradient(top, #6bcc33, #509627);#6bcc33
    background-image: -moz-linear-gradient(top, #6bcc33, #509627);
    background-image: -ms-linear-gradient(top, #6bcc33, #509627);
    background-image: -o-linear-gradient(top, #6bcc33, #509627);
    background-image: linear-gradient(top, #6bcc33, #509627);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#6bcc33, endColorstr=#509627);
    color: greenyellow;
    border-style: none;
    border: none;
  }
 
  &:focus:after{
    width: 150%;
    border-style: none;
    border: none;
  }
  &:disabled {
    color: grey;
    opacity: 0.7;
    cursor: default;
    border-style: none;
    border: none;
  }
  &:active{
    border-style: none;
    outline: none;
    border: none;
  }
  &:active:after{
    border-style: none;
    outline: none;
    border: none;
  }
 
`



export const RoundButton = styled.button`
color: darkcyan;
font-size: 1.4em;
background: ${props => props.isvalid ? "whitesmoke" : "whitesmoke"};
cursor: ${props => props.processing ? "none" : "pointer"};

padding: 0 auto;
margin: 0 auto;  
border-radius: 50%;
transition: all .35s;
letter-spacing: 0.5px;
vertical-align: middle;
text-decoration: none;
align-items: center;
position: relative;
width: 1.4em;
height: 1.4em;
font-weight: 200;

border-style: ${props => props.processing ? "none" : "solid"};
border: ${props => props.processing ? "none" : "1px solid purple"};



  
}
&:hover {    
  color: whitesmoke;
  background-color: darkcyan;
  opacity: 0.5;
  border-style: none;
  border: none;
}

&:focus:{
  width: 100%;
  border-style: none;
  border: none;
}
&:disabled {
  color: grey;
  opacity: 0.7;
  cursor: default;
  border-style: none;
  border: none;
}
&:active{
  border-style: none;
  outline: none;
  border: none;
}

`

export const RoundButtonAbs = styled.button`
color: #226D68;
font-size: 1.4em;
background: ${props => props.isvalid ? "whitesmoke" : "whitesmoke"};
cursor: ${props => props.processing ? "none" : "pointer"};

padding: 0 auto;
margin: 0 auto;  
border-radius: 50%;
transition: all .35s;
letter-spacing: 0.5px;
vertical-align: middle;
text-decoration: none;
align-items: center;
position: relative;
width: 1.4em;
height: 1.4em;
font-weight: 200;

border-style: ${props => props.processing ? "none" : "solid"};
border: ${props => props.processing ? "none" : "1px solid #226D68"};



  
}
&:hover {    
  color: whitesmoke;
  background-color: #226D68;
  opacity: 1;
  border-style: none;
  border: none;
}

&:focus:{
  width: 100%;
  border-style: none;
  border: none;
}
&:disabled {
  color: grey;
  opacity: 0.7;
  cursor: default;
  border-style: none;
  border: none;
}
&:active{
  border-style: none;
  outline: none;
  border: none;
}

`


export const StyledButtonTableWrapper = styled.div`
  position: relative;
  display: inline-block;   
`

export const StyledButtonTable = styled.button`
  color: darkcyan; 
  border: 0.5px solid;
  border-color : purple;
  background: whitesmoke;
  cursor: pointer;
  font-size: 1em; 
  padding: 0.25em 1em;  
  border-radius: 5px;
  transition: all .35s;
  letter-spacing: 0.5px; 
  text-decoration: none; 
  position: relative;
  display: inline-block; 
  width: fit-content;
  min-width: fit-content;
  margin: 0 auto;
  margin-top: 1em;
 

 
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-image: -webkit-gradient(linear, right top, right top, color-stop(0%,rgba(245, 232, 56, 0)), color-stop(100%, rgba(173,255,47, 1)));
    background-image: -webkit-linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));
    background-image: -moz-linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));
    background-image: -ms-linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));
    background-image: -o-linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));
    background-image: linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));filter:progid:DXImageTransform.Microsoft.gradient(GradientType=1,startColorstr=rgba(245, 232, 56, 0), endColorstr=rgba(173,255,47, 1));
    border: none;
    transition: all .35s;  
    max-height: inherit;
    max-width: inherit;
    border-style: none;
    
  }
  &:hover {
    
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#6bcc33), color-stop(100%, #509627));
    background-image: -webkit-linear-gradient(top, #6bcc33, #509627);#6bcc33
    background-image: -moz-linear-gradient(top, #6bcc33, #509627);
    background-image: -ms-linear-gradient(top, #6bcc33, #509627);
    background-image: -o-linear-gradient(top, #6bcc33, #509627);
    background-image: linear-gradient(top, #6bcc33, #509627);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#6bcc33, endColorstr=#509627);
    color: greenyellow;
    border-style: none;
  }
 
  &:focus:after{
    width: 150%;
    border-style: none;
  }
  &:disabled {
    color: grey;
    opacity: 0.7;
    cursor: default;
    border-style: none;
  }
  &:active{
    border-style: none;
    outline: none;
  }
  &:active:after{
    border-style: none;
    outline: none;
  }
 
`

export const StyledButtonCentered = styled.button`
  color: darkcyan; 
  border: 0.5px solid;
  border-color:${props => props.isvalid ? "purple" : "purple"};
  background: ${props => props.isvalid ? "whitesmoke" : "whitesmoke"};
  cursor: ${props => props.isvalid ? "pointer" : "pointer"};
  font-size: 1em; 
  padding: 1em; 
  border-radius: 5px;
  transition: all .35s;
  letter-spacing: 0.5px;
  vertical-align: bottom;
  text-decoration: none;
  position: relative; 
  width: 100%;
  margin: 0 auto;
 
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-image: ${props=>!props.disabled &&  '-webkit-gradient(linear, right top, right top, color-stop(0%,rgba(245, 232, 56, 0)), color-stop(100%, rgba(173,255,47, 1)));'}
    background-image: ${props=>!props.disabled && '-webkit-linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));'}
    background-image: ${props=>!props.disabled && '-moz-linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));'}
    background-image: ${props=>!props.disabled && '-ms-linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));'}
    background-image: ${props=>!props.disabled && '-o-linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));'}
    background-image: ${props=>!props.disabled && 'linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));filter:progid:DXImageTransform.Microsoft.gradient(GradientType=1,startColorstr=rgba(245, 232, 56, 0), endColorstr=rgba(173,255,47, 1));'}
    background-color: ${props=>props.disabled && 'whitesmoke'}
    transition: all .35s;  
    max-height: inherit;
    max-width: inherit;
    border-style: none;
  }
  &:hover {
    
    background-image: ${props=>!props.disabled && '-webkit-gradient(linear, left top, left bottom, color-stop(0%,#6bcc33), color-stop(100%, #509627));'}
    background-image: ${props=>!props.disabled && '-webkit-linear-gradient(top, #6bcc33, #509627);'}
    background-image: ${props=>!props.disabled && '-moz-linear-gradient(top, #6bcc33, #509627);'}
    background-image: ${props=>!props.disabled && '-ms-linear-gradient(top, #6bcc33, #509627);'}
    background-image: ${props=>!props.disabled && '-o-linear-gradient(top, #6bcc33, #509627);'}
    background-image: ${props=>!props.disabled && 'linear-gradient(top, #6bcc33, #509627);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#6bcc33, endColorstr=#509627);'}
    background-color: ${props=>props.disabled && 'whitesmoke'}
    border: 1px solid lightgrey;
  }
 
  &:focus:after{
    width: 150%;
    border-style: none;
  }
  &:disabled {
    color: grey;
    opacity: 0.7;
    cursor: default;
    background-color: whitesmoke;
  }
  &:disabled:hover {
    color: grey;
    opacity: 0.7;
    cursor: default;
    background-color: whitesmoke;
  }
  &:active{
    border-style: none;
    outline: none;
  }
  &:active:after{
    border-style: none;
    outline: none;
  }
`
export const StyledButtonConfirm = styled.button`
  color: darkcyan;  
  border: 0.5px solid ;
  border-color: purple;
  background: whitesmoke;
  cursor: ${props => props.disabled ? "default" : "pointer"};
  font-size: 1em; 
  padding: 1em; 
  border-radius: 5px;
  transition: all .35s;
  letter-spacing: 0.5px;
  vertical-align: bottom;
  text-decoration: none;
  position: relative; 
  width: 100%;
  margin: 0 auto; 

 

 
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-image: -webkit-gradient(linear, right top, right top, color-stop(0%,rgba(245, 232, 56, 0)), color-stop(100%, rgba(173,255,47, 1)));
    background-image: -webkit-linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));
    background-image: -moz-linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));
    background-image: -ms-linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));
    background-image: -o-linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));
    background-image: linear-gradient(right, rgba(245, 232, 56, 0), rgba(173,255,47, 1));filter:progid:DXImageTransform.Microsoft.gradient(GradientType=1,startColorstr=rgba(245, 232, 56, 0), endColorstr=rgba(173,255,47, 1));
    transition: all .35s;  
    max-height: inherit;
    max-width: inherit;
    border-style: none;
  }
  &:hover {
    
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#6bcc33), color-stop(100%, #509627));
    background-image: -webkit-linear-gradient(top, #6bcc33, #509627);#6bcc33
    background-image: -moz-linear-gradient(top, #6bcc33, #509627);
    background-image: -ms-linear-gradient(top, #6bcc33, #509627);
    background-image: -o-linear-gradient(top, #6bcc33, #509627);
    background-image: linear-gradient(top, #6bcc33, #509627);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#6bcc33, endColorstr=#509627);
   color: greenyellow;
  }
 
  &:focus:after{
    width: 150%;
    border-style: none;
  }
  &:disabled {
    color: grey;
    opacity: 0.7;
    cursor: none;
    background: lightgrey;
    border: none;
  }
 
  &:active{
    border-style: none;
    outline: none;
  }
  &:active:after{
    border-style: none;
    outline: none;
  }
`


export const EyeButton = styled.button`
 width: fit-content;
 background: none;
 border-style: none;
 &:active{
   border-style: none;
 }
 &:focus{
   outline: none;
 }
`

export const EyeSpan = styled.span`
color: ${props=>props.ispwd ? "purple" : "grey"};

font-size: 1em;

`




export const StyledSpan = styled.span` 
position: relative;
z-index: 2;
`

export const RadioButon = styled.input`

    display: none;

    &:checked:before {
        border-color: $md-radio-checked-color;
        animation: ripple 0.2s linear forwards;   
    }

    &:checked:after {
        transform: scale(1);
    }
`
export const Label = styled.label`
color: ${props=>props.isvalid ? "darkcyan" : "maroon"};
font-variant: ${props=>props.isvalid ? "normal" : "small-caps"};
font-weight: ${props=>props.isvalid ? "400" : "500"};
text-align : left;
font-size: 1em;
padding-top: 1.5em;
display: ${props => props.tohideforeinit ? "none" : "block"};
padding-left: 1em;
`
export const LabelConditions = styled.label`
  font-size: 1em; 
  color: #282c34;
  display: ${props => props.tohideforeinit ? "none" : "inline-flex"};
  opacity: ${props => props.tohideforeinit ? "0" : "1"};
  width: fit-content;
  height: fit-content;
  display: block;
 
`
export const LabelFullWeek = styled.label`
  font-size: 1em; 
  color: #282c34;  
  width: fit-content;
  height: fit-content;
  display: inline-block;
 
`
export const LabelValidEmail = styled.label`
  font-size: 1em; 
  color: #282c34;
  display:  inline-flex; 
  font-weight: 500;
  display: block;
  text-align: center;
 
`
export const LabelHours = styled.label`
  font-size: 1em; 
  color: #282c34;
  display: inline-block;
  width: inherit;
  height: inherit;
  position: relative;
  margin: 1em auto; 
`

export const CgvButton = styled.button`
  color:rgb(214, 191, 219);
  background-color: #AB47A3;
  font-size: 0.8em;
  cursor: pointer;
  padding: 0.25em 1em; 
  border-radius: 3px;
  transition: all .35s;
  letter-spacing: 0.5px;
  text-decoration: none;
  align-items: bottom;
  position: relative; 
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  display: ${props => props.tohideforeinit ? "none" : "inline-block;"};
  
  &:hover {    
  background-color:rgb(214, 191, 219);
  color: #AB47A3;
    
    border-style: none;
    border: none;
  }
 
  &:focus:after{
    width: 150%;
    border-style: none;
    border: none;
  }
  &:disabled {
    color: grey;
    opacity: 0.7;
    cursor: default;
    border-style: none;
    border: none;
  }
  &:active{
    border-style: none;
    outline: none;
    border: none;
  }
  &:active:after{
    border-style: none;
    outline: none;
    border: none;
  }
 
`

export const CheckWrapper = styled.div`
line-height: 1em;
position: relative;
display: ${props => props.tohideforeinit ? "none;" : "grid;"}
grid-template-columns: 2% 64% 30%;
grid-column-gap: 2%;
opacity: ${props => props.tohideforeinit ? "0" : "1"};
width: 100%;
margin: 0 auto;
padding: 1em;
text-align: center;
`


export const InputCheck = styled.input`
opacity: ${props => props.tohideforeinit ? "0" : "1"};
transform : scale(2);

&:focus{
  border-style: none;
  color: darkcyan;
}
@media(max-width: 800px){
  transform : scale(3);
}

`

export const CheckBoxWeek = styled.input`
transform : scale(3);
border-width: 0.5em;
&:focus{
  border-style: none;
  color: darkcyan;
  transform : scale(2);
}
@media(max-width: 800px){
  transform : scale(3);
}

`
export const CheckWeekWrapper = styled.div`

display: block;
width: 100%
margin: 0 auto;
padding: 1em;
text-align: center;
`
export const CheckList = styled.input`

transform : scale(1);
&:focus{
  border-style: none;
 
}

`

export const CenteredLabel = styled.label`
color: ${props=>props.isvalid ? "darkcyan" : "maroon"};
font-variant: ${props=>props.isvalid ? "normal" : "small-caps"};
font-weight: ${props=>props.isvalid ? "500" : "500"};
text-align : center;
font-size: 1em;
display: block;
`
export const ConfirmLabel = styled.label`
color: ${props=>props.isvalid ? "purple" : "maroon"};
font-variant: ${props=>props.isvalid ? "normal" : "small-caps"};
font-weight: ${props=>props.isvalid ? "500" : "500"};
text-align : center;
font-size: 1em;
display: inline-block;
`


export const Menu = styled.ul`
background-color: whitesmoke;
height: 1em;
width: 100%;
text-align: right;
align-items: center;
display: flex;
display: webkit-flex;
justify-content: flex-end;
webkit-justify-content: flex-end;
position: relative;
list-style-type: none;
height: fit-content;
padding-left: 1em;
box-shadow: 0 1px 3px rgba(4, 250, 250, 0.8), 0 3px 6px rgba(4, 70, 70, 0.4);
flex-wrap: wrap;
z-index: 5;
margin-top: 0;
`
export const MenuBurger = styled.ul`

align-items: right;
display: ${props=>props.menuisopen ? "inline-grid" : "none"};
cursor: pointer;
position: relative;
list-style-type: none;
height: fit-content;
width: fit-content;
padding-left: 1em;
box-shadow: 0 2px 4px rgba(201, 4, 250, 0.4), 0 3px 6px rgba(0, 0, 0, 0.5);


`

export const PoneyButton = styled.button`

width: fit-content;
height: fit-content;
background-color: 0 0 0 0;
border-radius: 50%;
cursor: pointer;
border-style: none;


&:active{
  border-style: none;
}
&:focus{
  border-style: none;
}
&:clicked{
  border-style: none;
}

`

export const BckgdBottom = styled.img `
position: absolute;
  z-index: -2;
  object-fit: contain;
  left:0em; 
  bottom: -40em;
  width: 100%;
  @media(max-width: 800px){
    bottom:-10em; 
  }
`
export const HeaderImg = styled.img `
  position: relative; 
  object-fit: contain;  
  height: inherit;
  margin: 0 auto;
  
 
`

/*****PANELS ACCORDION*****/

const LineColor = "lightgrey";

export const StyledAccordion = styled.div` 
  padding: 1em;
  display: block;  
  margin: 0 auto;
  width: inherit; 
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); 
  @media(max-width: 800px){
    padding: 0em;
  }
`

export const StyledFold = styled.div`
  border-bottom: 0.5px solid ${LineColor};   
  margin: 0 auto;  
  width: inherit;
  display: grid;
  
  padding-right: 1.5em;
  padding-left: 1.5em; 
  
  @media(max-width: 800px){
    display:  block;
    max-height: inherit; 
    width: inherit;
    margin: 0 auto;
    padding: 0em;
   
  }
`

export const StyledFold_content =styled.div`
  display: none;
  max-height: 0;
  opacity: 0;
  transition: max-height 400ms linear;
  
  &.open {
    display: block;
    max-height: 200rem;
    height: fit-content;
    margin-bottom: 10rem;
    opacity: 1;
  
  @media(max-width: 800px){
    display:  block;
    max-height: 300rem; 
  }
}
`


export const PanelTitleDisplay = styled.div`
margin: 0 auto;
display: grid;
grid-template-columns: 10% 60% 5%;
grid-row-gap: 1em;
grid-column-gap: 10%;
width: inherit;
vertical-align: middle;
@media(max-width: 800px){
  grid-column-gap: 30%;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
}

`
export const StyledFold_trigger = styled.button`  
  
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  border-bottom: 0.5px solid ${LineColor};
  width: inherit;
  vertical-align: middle;  

  }
`