import React, {Component } from 'react';
import axios from 'axios';

import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { parseFromTimeZone } from 'date-fns-timezone';
import connectionStrings from '../data/connectionStrings';
import isPast from 'date-fns/ispast';
import isAfter from 'date-fns/isAfter';
import addMilliseconds from 'date-fns/addMilliseconds';
import { getDay } from 'date-fns';
import {setHourForCalendarDate} from "../data/clientUtils";

const BookingContext = React.createContext ();

const SERVER = connectionStrings.SERVER;
const BOOKING = `/api/v1/booking`;
const BOOKINGS = '/api/v1/bookings';
const ADMINBOOKINGS = '/api/v1/adminbookings';

/**
 * création de réservation - méthodes CRUD
 */
class BookingProvider extends Component {   

    

    OPTIONS = 
    {
     AccessControlAllowOrigin : "*", // à changer avec port serveur final
     xsrfCookieName: 'XSRF-TOKEN',
     xsrfHeaderName: 'X-XSRF-TOKEN',
     SameSite : 'None',
     Secure : 'true'            
    };

    constructor(props){
        super(props); 
        this._isMounted = false;
    }
  
    state = { 
        booking: {
            category: "",
            chosen_time_stamp: "",
            booking_state: 0,
            user: null
        },
        dbBooking: {
            category: "",
            chosen_time_stamp: "",
            booking_state: 1, // 1 created 2 updated
            created_at: null,
            updated_at: null,
            user: null
        },
        goToBc: false, //redirection confirmation
        goToUb: false,
        isEmailSent: -1,
        isProcessing: null,        
        error: "",
        updateState: 0,
        timeStamps: [],
        tsMessage: "",
        category: 0,
        bookings: [],
        timeStampsWithTimes: [],
        tsToDisable: [],
        tsWtMessage: "",
        infoMail: [{}]
    }
   

   async componentDidMount(){
       this._isMounted = true;
       // valid timestamps
       const validTsWithTimes = this._isMounted && await this.getValidTimeStampsWithTimes();
       // timestamps stage
       const validTs = this._isMounted && await this.getAllValidTimeStamps();
     
       }

    
    componentWillUnmount(){ // démontage
        this._isMounted = false;
        this.setState({updateState: 0});
        this.resetGos();
    }

    getAllBookings = async() =>{
        console.log("on rentre dans getAllBOOKINGS..");  
        const CancelToken = axios.CancelToken; 
        let cancel;
            /** Load User et bookings de la db
            * GET PARAMS AXIOS
            */
            try {            
                const res = await axios.get(`${ADMINBOOKINGS}`, {
                    headers:{'bookingsheaders': 'getallbookings'}
                },
                {cancelToken: new CancelToken(function executor(cancelParam) {
                    // An executor function receives a cancel function as a parameter
                    cancel = cancelParam;
                })
                }
                )
               
                if (res !== undefined){                    
                    if (res.data.error){               
                        console.log("réservations non trouvées : " + res.data.error);                                         
                        cancel(); // désabonnement
                        return ({message: "Aucune prochaine réservation"});                       
                    }else{ // si les réservations sont trouvées
                    console.log("réservations  trouvées : " , res.data.bookings);
                    const bookings = res.data.bookings;                    
                    cancel();                
                    return ({bookings:bookings});
                    }   
                }else{ 
                        //cancel(); // désabonnement
                        return ({message: "...Chargement en cours"});
                }
        
            } catch (error) {
                console.log("erreur dans la récupération des réservations : " + error);                         
                return ({message: "Aucune prochaine réservation"});
            }
        
   }



   /*  setIsUpdated=(pIsUpdated)=>{
        this.setState({isUpdated: pIsUpdated});
        
    } */
    setUpdateState=(pIsUpdated)=>{
        this.setState({updateState: pIsUpdated});        
    }

    /*Méthode de création d'une réservation*/
    createBooking = async (booking, stripeResponse) => {
        console.log("on rentre dans createBooking");
        const CancelToken = axios.CancelToken;
        let cancel;

        if (booking === undefined || booking === null) {
            console.log("réservation non trouvée");            
            return;
        }
        
        const ridersAgeOrLevelIsOk = booking.category.toString() === "0" ? booking.ridersAge.length > 0 : booking.category.toString() === "1" ? booking.ridersLevel.length > 0 : true;
        const bookingIsOk = (booking.category && booking.category !== -1
        && booking.quantity && booking.quantity !== 0
        && booking.chosen_time_stamp && booking.chosen_time_stamp !== ""
        && booking.user !== undefined && booking.user !== null 
        && ridersAgeOrLevelIsOk       
        )
        
        if (bookingIsOk){
        const newBooking = {
                category: booking.category,
                quantity: booking.quantity,
                chosen_time_stamp: booking.chosen_time_stamp,
                booking_state: booking.booking_state,
                user: booking.user,
                riders_age: booking.ridersAge, // pas de riders si cat !== 0 ou cat !== 1
                riders_level: booking.ridersLevel,
                is_full_week: booking.isFullWeek // pas de fullweek si cat !==2
            }           
            
            this.setState({booking: newBooking});  
          /***           
           * APPEL POST AXIOS
           * @param category
           * @param chosen_time_stamp
           * @param booking_state
           * @param user
           */
           const options = this.OPTIONS; 
           
           this.setState({isProcessing: true});
           try {
            //envoi de la réservation en db
            const resp = this._isMounted && await axios.post(`${BOOKING}`, 
            {
                category: booking.category,
                quantity: booking.quantity,
                chosen_time_stamp: booking.chosen_time_stamp,
                booking_state: booking.booking_state,
                user: booking.user,
                riders_age: booking.ridersAge,
                riders_level: booking.ridersLevel,
                is_full_week: booking.isFullWeek      
            }
            , options,
            {cancelToken: new CancelToken(function executor(cancelParam) {
                // An executor function receives a cancel function as a parameter
                cancel = cancelParam;
                })}, {timeout: 3000}
                );
                // attendu : dbBooking:{booking: ...}
            console.log("la réponse axios : " + JSON.stringify(resp.data));
            console.log("on passe le await bookingcontext");
            if (resp.data !== undefined){
                if (resp.data.error){
                    this.setState({error: resp.data.error});
                    console.log("erreur create booking : " + resp.data.error);
                    cancel();
                    return ({error: resp.data.error});                    
                }else{ // pas d'erreur, on envoie les mails                    
                    const bookingData = resp.data.dbBooking;
                    cancel();
                    console.log ("retour création de réservation : " , bookingData.booking);
                    this.setState({dbBooking: bookingData.booking});              
                    // create ulysse confirm = 2          
                    const respMailCat2 = this._isMounted && await this.sendConfirmEmail(bookingData.booking.user.mail, bookingData, 2, stripeResponse);
                    if (respMailCat2 !== undefined){
                        console.log("resp cat2 not undefined", respMailCat2);
                        if (respMailCat2.error){ 
                            this.setState({error: respMailCat2.error});
                            console.log("erreur 1er mail : ", respMailCat2.error);
                            this.setState({infoMail: [...this.state.infoMail,{id: 1, info: respMailCat2.error} ]});                           
                            //return {bookingData:bookingData.booking, error : respMailCat2.error};
                        }else{                            
                            this.setState({infoMail: [...this.state.infoMail,{id: 1, info: respMailCat2.isEmailSent} ]})
                            console.log("on rentre dans la réponse ok envoi 1er mail : ", respMailCat2.isEmailSent);
                        }
                        //2eme mail
                        const respMailCat0 = this._isMounted && await this.sendConfirmEmail(bookingData.booking.user.mail, bookingData, 0, stripeResponse);
                            if (respMailCat0 !== undefined){
                                console.log("réponse 2eme mail not undefined : " , respMailCat0);                  
                                this.setState({isProcessing : false});
                                this.setState({goToBc: true});
                                if (respMailCat0.error){
                                    console.log("erreur 2e mail");
                                    this.setState({infoMail: [...this.state.infoMail,{id: 2, info: respMailCat0.error} ]})  
                                    //return ({bookingData:bookingData.booking, error : respMailCat0.error});
                                }else { // pas d'erreur, on renvoie les ok                                    
                                    this.setState({infoMail: [...this.state.infoMail,{id: 2, info: respMailCat0.isEmailSent} ]})                           
                                    
                                }
                                console.log("bookingData : ", bookingData.booking);
                                console.log("infoMail : ", this.state.infoMail);
                                
                                return ({bookingData:bookingData.booking, infoMail : this.state.infoMail && this.state.infoMail});
                            }else{
                                    console.log(" respMailCat0  undefined");                                
                                    this.setState({error: "Envoi en cours..."})                            
                            }

                    }else{
                        console.log(" respMailCat2  undefined");                       
                        this.setState({error: "Envoi en cours..."});
                    }
                }
            }else{
                return({error: "... Enregistrement en cours"});
            }
            
            /**
            * FIN POST createBooking
            */  
            } catch (error) {
                console.log("createBooking : " + error);
                const bookingError = error.message;
                cancel();
                return {error : "Une erreur est survenue lors de l'enregistrement de votre rendez-vous. Merci de nous contacter avec les références de paiement ci-dessus pour finaliser la réservation"};
            
            } 
        }else{ // pbme dans la réservation
            cancel();
            // return le message en fonction de ce qui manque
            if (!booking.category || booking.category === -1){
                return {error: "La catégorie n'est pas renseignée"}
            }
            if (!booking.quantity || booking.quantity === 0){
                return {error: "Le nombre de personnes n'est pas renseigné"}
            }
            if (!booking.chosen_time_stamp || booking.chosen_time_stamp === ""){
                return {error: "Le créneau horaire n'est pas renseigné"}
            }
            if (!booking.user === undefined || booking.user === null){
                return {error: "L'utilisateur n'est pas connecté"}
            }
            
            
        }           
    }
   



     /**
      * 
      * @param {*} booking 
      */
   /*Méthode de modification de réservation, envoi du mail de validation*/
   updateBooking = async (booking, userMail) => {    
    console.log("on rentre dans update booking");
    const CancelToken = axios.CancelToken;
    console.log("dans updatebooking : " + booking.id);
    console.log("dans updatebooking, le créneau: " + booking.chosen_time_stamp);
      /***           
       * APPEL PUT AXIOS    
       * @param bookingId 
       */
       const options = this.OPTIONS;
       
       let cancel;
       this.setState({isProcessing: true});
       //this.setState({isUpdated: false});
       this.setState({updateState: 1}); // loading is coming
       
       try {
       const response = this._isMounted && await axios.put(`${BOOKING}/${booking.id}`,
        {
        mail: userMail,
        chosen_time_stamp: booking.chosen_time_stamp,   
        }
        , options,
        {cancelToken: new CancelToken(function executor(cancelParam) {
            // An executor function receives a cancel function as a parameter
            cancel = cancelParam;
        })}, {timeout: 3000}
        )
    
        if (response !== undefined){
            cancel();
            console.log("on passe dans le then du put"); 
            console.log("la réponse updatedbooking " + response);
            if(response.data.error){
                this.setState({message: response.data.error});
                console.log("on passe dans l'erreur :" + response.data.error);
            }else{
                let bookingData = response.data.booking;                 
                console.log("en front, l'updatebooking de la db cat: " + bookingData.category + " et sa date de rdv : " + bookingData.chosen_time_stamp);
                console.log("en front, l'updatebooking de la db : " + bookingData.id);
                this.setState({goToUb: true});
                this.setState({isProcessing: false});
                // dans la réponse, on set updateState à 2 (updated)
                bookingData.booking_state = 2;
                console.log("bookingData change state (attendu 2) : " + bookingData.booking_state);
               
                this.setState({dbBooking: bookingData});               
                //this.setState({isUpdated: true});
                
               if (bookingData.booking_state === 2){
                this.setState({updateState: 2}); // loading ending and update message is coming
               }
               const updateState = this.state.updateState
                console.log("on set isupdate à 2 : " + updateState);
                 // mAj de la liste des réservations
                //const user = this.reloadUserBookings(userMail);
                console.log("on set isupdate à true");
                // mAj confirm = 1 
                try {
                    console.log("updatebooking on lance la demande d'envoi du mail 1");
                    const resp1 = this._isMounted && await this.sendConfirmEmail(userMail, bookingData, 1);
                    if (resp1 !== undefined){
                        console.log("réponse mail1 updatebooking not undefined : " , resp1);
                        if (resp1.error){
                            console.log("erreur envoi mail 1 updatebooking: ", resp1.error);
                        }else{
                            console.log("on lance la demande d'envoi du mail 2 update booking");
                        // mAj ulysse confirm = 3
                        const resp2 = this._isMounted && await this.sendConfirmEmail(userMail, bookingData, 3);
                        if(resp2 !== undefined){
                            console.log("réponse mail2 updatebooking : ", resp2);
                        if (resp2.error){
                            console.log("erreur d'envoi mail 2 updatebooking", resp2.error);
                        }else{
                            console.log("mail 2 ok");
                        }
                    }
                                 
                        
                    }
                }
                    
                   
                } catch (error) {
                    console.log("erreur envoi mails");
                }
                return ({booking: bookingData}); // on retourne la res update dans tous les cas
            }
            }else{
                this.setState({error: "Un problème est survenu lors de la mise à jour"});
                cancel();
                return ({message: "Un problème est survenu lors de la mise à jour"});
            }           
      
            /**
            * FIN PUT updateBooking
            */  
        } catch (error) {
            cancel();
            console.log("erreur mise à jour réservation : " + error);
            this.setState({error: "Un problème de communication avec le serveur est survenu, merci de réessayer ultérieurement"});
            return ({message: "Un problème de communication avec le serveur est survenu, merci de réessayer ultérieurement"})
        }
    }

    /**
     * Formate les dates de la db pour l'affichage
     * @param {*} date 
     */
    formatToLocalDateTime=(date)=>{        
        // Set the date to "2018-09-01T16:01:36.386Z"
        const utcDate = parseFromTimeZone (date, { timeZone: 'Europe/Berlin' });        
        let localDay = format (utcDate, 'dd MMM yyyy', { locale: fr });        
        let localHour = format (utcDate, 'H mm', { locale: fr });        
        localHour = localHour.replace(' ', 'h');        
        return localDay + " à " + localHour;
    }
    

    /**
     * Formate les dates de la db pour l'affichage
     * @param {*} date 
     */
    formatToLocalDate=(date)=>{        
        // Set the date to "2018-09-01T16:01:36.386Z"
        const utcDate = parseFromTimeZone(date, { timeZone: 'Europe/Berlin' });        
        let localDay = format (utcDate, 'dd MMM yyyy', { locale: fr });    
        return localDay;
    }

    formatChosenTsForComparison(formatedDateHour){
        const localDate = formatedDateHour.slice(0, formatedDateHour.indexOf("à"));
        console.log("la date seule : " + localDate);
        return localDate;
    }


    /* 
     /**
     * Formate les choix date dtPicker et heure(radiobutton)
     */
    handleFormatedDateHour(datechoice, hourchoice){
        console.log("on rentre dans handleformateddatehour");
        let formatedDateChoice = "";
        
        if (datechoice !== null ){
            formatedDateChoice  = `le ${format(datechoice, 'dd MMM yyyy', { locale: fr } )} `;
            console.log("la date formatée : " + formatedDateChoice);
        }
        if (hourchoice !== null){
            if(hourchoice !== "0H00"){
                formatedDateChoice += `à ${hourchoice}`
            }
        }
        return formatedDateChoice;

    }

        /* 
     /**
     * Formate les choix date dtPicker et heure(radiobutton)
     */
      handleFormatedDateHourForFullWeek(datechoice, hourchoice, isFullWeek){
        console.log("on rentre dans handleformateddatehourforfullweek");
        let formatedDateChoice = "";
        
        if (datechoice !== null ){
            if (isFullWeek !== true){ // si ce n'est pas une semaine complète
                formatedDateChoice  = `le ${format(datechoice, 'dd MMM yyyy', { locale: fr } )} `;
            
            }else{
                formatedDateChoice  = `${format(datechoice, 'dd MMM yyyy', { locale: fr } )} `;
            }
            console.log("la date formatée : " + formatedDateChoice);
        }
        if (hourchoice !== null){
            if(hourchoice !== "0H00"){
                formatedDateChoice += `à ${hourchoice}`
            }
        }
        return formatedDateChoice;

    }

    /**
     * Renvoie les strings de category
     * @param {*} nCategory 
     */

    setCategoryString=(nCategory)=>{
        let response="";        
        switch (nCategory) {
            case 0:
                response = "Prestation unique";
                break;
            case 1:
                response = "Cours ou balade";
                break;
            case 2:
                response = "Stage";
                break;
            case 3 :
                response = "Anniversaire ludique";
                break;
            default:
                response="";   
        }        
        return response;
    }

 /**Envoie le mail de confirmation de réservation
    * @param mail
    */     
    
   sendConfirmEmail = async (userMail, bookingData, confirm, stripeResponse) =>{
    console.log("on va jusqu'à sendCOnfirmMail");
    const CancelToken = axios.CancelToken;
    this.setState({isEmailSent: -1}); // reset l'écouteur d'envoi    
    const options = this.OPTIONS; 
    /*En création, on récupère bookingData.booking / en update bookingData*/
    const booking = confirm === 0 || confirm === 2 ? bookingData.booking : bookingData;
    console.log("la réservation pour l'envoi de mail : " , booking);
    console.log("les ages pour l'envoi de mail : " , booking.riders);
    let cancel;
    let dateLabel="";
    // formate la date pour l'affichage
    if (booking.category.toString() !== "2"){
        try {
            dateLabel = this.formatToLocalDateTime(booking.chosen_time_stamp);
        } catch (error) {
            console.log("erreur dans le formatage de la date/heure");            
        }
    }else{ // stage
        try {
            dateLabel = this.formatToLocalDate(booking.chosen_time_stamp);
        } catch (error) {
            console.log("erreur dans le formatage de la date");            
        }
    }
    
    let activity = "";   
    let username = "";   
    
    activity = this.setCategoryString(booking.category);
    

    if (confirm === "1" || confirm === "3"){
        username = booking.user.name; // en modification, on récupère le nom de la bd, en création on récupère le nom du user et son tel du context
       
    }
    /*Rien si updateBooking : la réponse stripe n'est donnée qu'en création*/
    const amount = stripeResponse !== undefined ? stripeResponse.amount : "";
    const paymentId = stripeResponse !== undefined ? stripeResponse.id : "";
    console.log(paymentId);
    console.log ("avant le post montant:" + amount);
    
    try {
           const res = this._isMounted && await axios.post(`${SERVER}/bookingconfirm`, 
            
                {
                    mail : userMail,
                    username: username, // en update, on charge le user via la bd (init à "")
                    chosen_time_stamp: dateLabel,                   
                    bookingid: booking.id, 
                    quantity: booking.quantity,
                    riders: bookingData.riders,                    
                    confirm: confirm, // type de mail de confirmation : create / update -- user(0/1)/ulysse(2/3)
                    activity: activity, // baby or not
                    amount: amount,
                    paymentid: paymentId,
                    is_full_week: booking.is_full_week|false

                
            },options,
            {cancelToken: new CancelToken(function executor(cancelParam) {
                // An executor function receives a cancel function as a parameter
                cancel = cancelParam;
            })}, {timeout: 3000}
        )
            if (res !== undefined){ 
                console.log("res not undefined");           
                if (res.data.error){
                    this.setState({error: res.data.error});
                    console.log("erreur envoi mail sendconfirmMail : " , res.data.error);
                    cancel();
                    return {error : res.data.error};
                } else{
                    console.log(" envoi mail ok : " , res.data.ok);
                this.setState({isEmailSent: 0});
                cancel();
                return {isEmailSent : 0}; 
                }
            }
        } catch (error) {           
            console.log("le serveur ne répond pas : " + error);
            return {error : "Erreur de communication avec le serveur, merci de réessayer ultérieurement"};
        }
    }

    formatUnits(sHour){
        const zero = "0";       
        let result;        
        if (sHour.length < 2){
            result = zero.concat(sHour);
        }else{
            result = sHour;
        }
        return result;
    }
   replaceAt = (theString, index, char) =>{
        return theString.slice(0, index) + char + theString.slice(index+char.length);
}

    formatDateHourForDb=(datechoice, hourchoice)=>{
        if (datechoice !== undefined && hourchoice !== undefined){
        
        const hour = hourchoice.slice(0, hourchoice.indexOf("h"));        
        const min = hourchoice.substring(hourchoice.indexOf("h")+1);        
        // format hour units
        // regex for replace
        let sDateChoice = datechoice.toString();        
        sDateChoice = datechoice.toString();        
        // toInt
        const hourDozen = parseInt((sDateChoice.indexOf(":")-2), 10);
        const hourUnit = parseInt((sDateChoice.indexOf(":")-1), 10);
        const minUnit = parseInt((sDateChoice.indexOf(":")+2), 10);
        const minDozen = parseInt((sDateChoice.indexOf(":")+1), 10);
        // remplacement des heures
        sDateChoice = this.replaceAt(sDateChoice, hourDozen, this.formatUnits(hour)[0]); // remplace le 2eme caractère avant les deux points par les dizaines..       
        sDateChoice = this.replaceAt(sDateChoice,hourUnit, this.formatUnits(hour)[1]);  // remplace le 1er caractère avant les deux points par les unités..        
        //2- on remplace les deux digits d'après par les minutes
        sDateChoice = this.replaceAt(sDateChoice,minDozen, min[0]);        
        sDateChoice = this.replaceAt(sDateChoice,minUnit, min[1]);       
    
    return sDateChoice;
}
   }
    formatDateForDb=(datechoice)=>{
        if (datechoice !== undefined){
            let sDateChoice = datechoice.toString();
            console.log("la datechoice à remplacer : " + sDateChoice);
            sDateChoice = datechoice.toString();
        return sDateChoice;
        }
    }


    resetGos = () => {
        this.setState({
            goToBc: false,
            goToUb: false
           
        })
    }
   
   reloadUserBookings = async email => {
    const CancelToken = axios.CancelToken;           
    if( email === undefined || email === "") {
        console.log("mail non valide");
        return({message: "Email non trouvé, merci de vous reconnecter"});
    }
    else{
        let cancel;
        /** Load User et bookings de la db
        * GET PARAMS AXIOS
        */
        try {                        
            const reloadedBookings = await axios.get(`${SERVER}/userreload`,                
            {params: {mail : email}},
            {cancelToken: new CancelToken(function executor(cancelParam) {
                // An executor function receives a cancel function as a parameter
                cancel = cancelParam;
            })}, {timeout: 3000}
        )
        if (reloadedBookings !== undefined){            
            if (reloadedBookings.data.error){
                console.log("on rentre dans le 404, utilisateur non trouvé");
                cancel(); // désabonnement
                this.setState({error: reloadedBookings.data.error});
                return ({message: reloadedBookings.data.error});                  
                
            }else{ // on a un user
                console.log("le statut : " + reloadedBookings.data.user.id);
                const dbUser = reloadedBookings.data.user;                   
                console.log("dans getparams le mail retourné :  " + dbUser.mail + " et son pass : " + dbUser.password + " et le userId : " + dbUser.id);                          
                
                // si le retour est un utilisateur valide                       
                this.setState({bookings:dbUser.bookings});  
                console.log("les bookings du context : " + dbUser.bookings) 
                cancel(); // désabonnement                     
                return ({bookings:dbUser.bookings});                        
            }
            
            
        }else{ // undefined
            cancel();
            this.setState({error: "... Mise à jour en cours"})
            return ({message: "... Mise à jour en cours"})
        }
    } catch (error) {
        console.log("erreur dans la récupération des réservations"); 
        this.setState({error: "Un problème de communication est survenu, merci de réessayer ultérieurement"});          
        cancel(); // désabonnement   
        return ({message: "Un problème de communication est survenu, merci de réessayer ultérieurement"});            
    }         
}
}



    /**
     * TIMESTAMPS
     */


    getAllValidTimeStamps = async() =>{
        const CancelToken = axios.CancelToken;
        let cancel;
        // on récupère tous les créneaux
        try {
              const resp = await axios.get(`${SERVER}/timestamps`, {
                headers:{'timestamps': 'timestamps'}
                },            
                {cancelToken: new CancelToken(function executor(cancelParam) {
                    // An executor function receives a cancel function as a parameter
                    cancel = cancelParam;
              })}, {timeout: 3000}
              );
              if (resp !== undefined){              
                    if(resp.data.error){
                          console.log("Erreur pendant le chargement des créneaux : " + resp.data.error);
                          cancel();
                          this.setState({tsMessage: resp.data.error});
                          return {message : resp.data.error};
                    }else{                       
                        
                          cancel();
                          // récupération des dates valides                   
                          const validTimeStamps = resp.data.timestamps.filter(stageTs => new Date(stageTs.dt_end) >= Date.now() && stageTs.is_to_disable !== true);
                          this.setState({timeStamps: validTimeStamps});
                          // récupération des dates à désactiver
                          const timeStampsToDisable = resp.data.timestamps.filter(dt => dt.is_to_disable === true && new Date(dt.dt_end) >= Date.now());
                          this.setState({tsToDisable: timeStampsToDisable});                          
                          return {timeStamps : validTimeStamps};
                    }
              }else{
                    console.log("... chargement en cours");
                    this.setState({tsMessage: "... Chargement en cours"});
                    return {message : "... Chargement en cours"};
              }
        } catch (error) {
              console.log("erreur dans la récupération des créneaux : " + error);
              this.setState({tsMessage: "Erreur de communication avec le serveur, merci de réessayer ultérieurement"});
              return {message : "Erreur de communication avec le serveur, merci de réessayer ultérieurement"};
        }
    }

    getCategoryFromCompo = (category) =>{
        this.setState({category: category});
    }

    getFinalTimeStamps = async (category) =>{
        console.log("on rentre dans getFinalStamps");
        const resp = this._isMounted && await this.getTimeStampsWithTimes();
        if (resp !== undefined){
            if (resp.message){                
                this.setState({message: "Aucun créneau disponible"});
                return ({message : "Aucun créneau disponible"});
            }else{ // récupération des heures
                if (resp.timestamps !== undefined){           
                //console.log("les créneaux, avant traitement des valides : " + resp.timestamps);               
                
               const filteredTimeStamps = this.setValidTimes(resp.timestamps);
               console.log("retour après traitement des valides");
               if (filteredTimeStamps !== undefined){                
                // on trie par catégorie
                console.log("avant tri par catégorie : " + filteredTimeStamps);        
                const finalTimeStamps = this.sortTimeStampsByCat(filteredTimeStamps, category);
                return ({timeStamps : finalTimeStamps});          
            }
        }
        }
        }else{
            return ({message: "...chargement en cours"});
        }
    }
    
    getValidTimeStampsWithTimes = async () =>{
        //console.log("on rentre dans getValidTimeStampsWithTimes");
        const resp = this._isMounted && await this.getTimeStampsWithTimes();
        if (resp !== undefined){
            if (resp.message){                
                this.setState({tsWtMessage: resp.message});
                return ({message : resp.message});
            }else{ // récupération des heures
                if (resp.timestamps !== undefined){           
                //console.log("les créneaux, avant traitement des valides : " + resp.timestamps);                
                const filteredTimeStamps = this.setValidTimes(resp.timestamps);               
                //console.log("sans tri par catégorie : " + filteredTimeStamps);        
                this.setState({timeStampsWithTimes: filteredTimeStamps});
                return ({timeStamps : filteredTimeStamps});
            }
        }
        }else{
            this.setState({tsWtMessage: "... Chargement en cours"});
            return ({message: "...chargement en cours"});
        }
    }


     getTimeStampsWithTimes = async() =>{
        const CancelToken = axios.CancelToken;
        let cancel;
        try {
              const resp = await axios.get(`${SERVER}/times`, {
                headers:{'timesheader': 'times'}
                },            
                {cancelToken: new CancelToken(function executor(cancelParam) {
                    // An executor function receives a cancel function as a parameter
                    cancel = cancelParam;
              })}, {timeout: 5000}
              );
              if (resp !== undefined){              
                    if(resp.data.error){
                          console.log("Erreur pendant le chargement des heures : " + resp.data.error);
                          cancel();
                          return {message: "Une erreur est survenue pendant le chargement des heures"};
                    }else{
                          //console.log("les heures récupérées : " + resp.data.timestamps);
                          cancel();
                          return {timestamps : resp.data.timestamps};
                    }
              }else{
                    console.log("... chargement en cours");
                    return {message : "... chargement en cours"};
              }
        } catch (error) {
              console.log("erreur dans la récupération des heures : " + error);
              cancel()
              return {message : "Erreur de communication avec le serveur, merci de réessayer ultérieurement"};
        }
    }
 

    getBookingsByCat = async (categoryid) =>{

        // gestion des dates déjà réservées
       // gouter ou cours part, on cherche les réservations de la date cliquée, puis de l'heure
       if (categoryid === "1" || categoryid === "3"){
           console.log("category envoyée : " + categoryid);
        const CancelToken = axios.CancelToken; 
        let cancel;
            /** Load bookings de la db
            * GET PARAMS AXIOS
            */
            try {            
                const res = await axios.get(`${BOOKINGS}`,
                {params: {categoryid : categoryid}},
                {cancelToken: new CancelToken(function executor(cancelParam) {
                    // An executor function receives a cancel function as a parameter
                    cancel = cancelParam;
                })
                }
                )
               
                if (res !== undefined){
                    console.log("les data : ", res);
                    if (res.data.error){               
                        console.log("réservations non trouvées : " + res.data.error);                                         
                        cancel(); // désabonnement
                        return ({message: ""});                    
                    }else{ // si les réservations sont trouvées
                    console.log("réservations trouvées : " + res.data.bookings);
                    const bookings = res.data.bookings;                    
                    cancel();                
                    return ({bookings:bookings});
                    }   
                }else{ 
                        cancel(); // désabonnement
                        return ({message: "...Chargement en cours"});
                }
        
            } catch (error) {
                console.log("erreur dans la récupération des réservations : " + error);                         
                return ({message: ""});
            }
    }
    }

        /**
   * 
   * @param {table} items
   * @returns {table} on remplace le tableau d'horaires par la propriété true ou false pour désactiver la date si tab des heures vide
   */
  setListToDisable=(items, category)=>{
    /*trie les data à renvoyer en fonction de la catégorie*/   
    let daysPerCategory = [];
    if (items !== null && items !== undefined){
    items.forEach(element => {
        console.log("la categorie de l'element " + element.category);
        console.log("la categorie " + category);
      if (element.category.toString() === category.toString()){
        daysPerCategory.push(element);
      };      
      console.log("dans datepickerinput setListToDisable la catégorie : " + category)
      
    });
    console.log("dans setListToDisable longueur liste par category : " + daysPerCategory.length);
    let dateList = daysPerCategory.map((item, index)=>{     
      let timeSlot = 
      {
          id : item.id,
          numday : item.num_day,
          category : item.category,
          day : this.setDayString(item.num_day), 
          isToDisable : item.times.length === 0 ? true : false,
          category : item.category,
      }
      console.log("dans datepickerinput setListToDisable le timeslot : " + timeSlot.category)       
      return timeSlot
  })
  console.log("dans datepickerinput setListToDisable longueur de la liste à désactiver : " + dateList.length)
  return dateList;
}
  }

  formatToUtcDate(date){        
      console.log("on rentre dans formatToUTCDate")
    // Set the date to "2018-09-01T16:01:36.386Z"
    const utcDate = parseFromTimeZone(date, { timeZone: 'Europe/Berlin' }); 
    return utcDate;
  }

    setValidTimes(timeStamps){ 
          
        if(timeStamps !== undefined){            
            let newTimeStamp;
            let filteredTimeStamps=[];
            timeStamps.forEach(timeStamp => {
                
                newTimeStamp = {
                    category : timeStamp.category,
                    created_at : timeStamp.created_at,
                    updated_at : timeStamp.updated_at,
                    id : timeStamp.id,
                    num_day: timeStamp.num_day,
                    times :                
                    timeStamp.times.filter(time=>
                    (time.dt_expirity === "infinity"? Infinity : time.dt_expirity) > Date.now() && new Date (time.dt_effect) <= Date.now())
                    
                }
                filteredTimeStamps.push(newTimeStamp);
              
            });          
        
        return filteredTimeStamps;
        
    }
    }

    sortTimeStampsByCat(validTimestamps, p_category){
        const sortedTimeStamps = validTimestamps;
        console.log("timeStamps récupérés : " , validTimestamps);
        if (sortedTimeStamps !== undefined){       
        const byCat = sortedTimeStamps.filter(ts => ts.category === p_category);  
        return byCat;       
    }
    }
    /**
     * 
     * @param {*} date 
     * @param {*} daysToDisable : is not chosen by admin cards
     * @param {*} tsToDisable : a period of absence
     * @returns 
     */
    handleDayDisable=(date, daysToDisable, tsToDisable, categoryId)=>{
    
    let isToDisable = false;
    const today = new Date();
    
    const todayInt = today.getTime();    
    const dateHour = setHourForCalendarDate(date);
    const dateInt = dateHour.getTime();    
    
    /*Try to find a day between absence begin and dt back - if not undefined => isToDisable */
    if (tsToDisable !== undefined && tsToDisable !== null && tsToDisable.length>0){
        const tsToDisableByCat = tsToDisable.find(ts => ts.category.toString() === categoryId.toString() && new Date(ts.dt_start) <= date && new Date(ts.dt_end) > date);        
        if (tsToDisableByCat !== undefined) {
            isToDisable = true;
        }
    }

    
        if (daysToDisable !== undefined){ 
          /*Si le jour est une date passée, on retourne true */
          if (todayInt > dateInt){
            isToDisable = true;
        }
          // 3 mois après la date courante
          const milliDatePast3 = addMilliseconds(new Date(), 7776000000);    
          /*Si la date est supérieure à trois mois*/    
          if (isAfter(date,milliDatePast3)){isToDisable = true;}  
          const theDay = daysToDisable.find(element=>parseInt(element.numday, 10) === getDay(date));
          /*Si on ne trouve pas de correspondance (au loading) on désactive tout*/
          if (theDay === undefined){
            isToDisable = true;
          }else{
            if(theDay.isToDisable === true){
                isToDisable = true;
            }
            }
         
    }
    return isToDisable;
}
    handleStageDayDisable = (date, timeStamps)=>{    
       
        const todayInt = new Date().getTime();
        const dateInt = date.getTime();        
        let isToDisable = false;
          // si le numday n'est pas entre les dates de début et de fin de stage, on désactive
          if (timeStamps !== undefined){              
            const theDay = timeStamps.find(stageTs => stageTs.dt_start <= date.toISOString() && stageTs.dt_end >= date.toISOString());
           console.log("debut", timeStamps);

            console.log("le jour : ", theDay);
            if (theDay === undefined){
                isToDisable = true;
            }else if (theDay.isToDisable === true){
                isToDisable = true;
            }
          
        }
         /*Si le jour est une date passée, on retourne true */
         if(isPast(date)){
            isToDisable = true;
          }
        if (todayInt >= dateInt){
            isToDisable = true;
        }
        return isToDisable;
    }


    setDayString(numDay){
        let label;
        switch (numDay) {
      
            case 0:
                label = "dimanche";
            break;
            case 1:
                label = "lundi";
            break;
            case 2:
                label = "mardi";
            break;
            case 3:
                label = "mercredi";
            break;
            case 4:
                label = "jeudi";
            break;
            case 5:
                label = "vendredi";
            break;
            case 6:
                label = "samedi";
            break;  
            default:
                label="";
                break;
            
        }
        console.log("dans setDayString : " + label);
        return label;
    }
  
   


    render(){
        return(

            <BookingContext.Provider value = 
            {{  
                    ...this.state,
                    createBooking: this.createBooking,
                    updateBooking: this.updateBooking,
                    resetGos: this.resetGos,
                    formatDateForDb: this.formatDateForDb,
                    formatDateHourForDb: this.formatDateHourForDb,
                    formatUnits: this.formatUnits,
                    sendConfirmEmail: this.sendConfirmEmail,                    
                    formatToLocalDateTime: this.formatToLocalDateTime,
                    formatToLocalDate: this.formatToLocalDate,
                    reloadUserBookings: this.reloadUserBookings,
                    handleFormatedDateHour: this.handleFormatedDateHour,
                    handleFormatedDateHourForFullWeek: this.handleFormatedDateHourForFullWeek,
                    //setIsUpdated: this.setIsUpdated,
                    setUpdateState: this.setUpdateState,
                    getAllBookings: this.getAllBookings,
                    getFinalTimeStamps: this.getFinalTimeStamps,
                    getCategoryFromCompo : this.getCategoryFromCompo,
                    setListToDisable: this.setListToDisable,
                    formatToUtcDate: this.formatToUtcDate,
                    handleDayDisable: this.handleDayDisable,
                    formatChosenTsForComparison: this.formatChosenTsForComparison,
                    getBookingsByCat: this.getBookingsByCat,
                    setCategoryString: this.setCategoryString,
                    handleStageDayDisable: this.handleStageDayDisable,
                    
                    
                    
            }}>
            {this.props.children}
            </BookingContext.Provider>
        
        )
    }
}   

/**
 * 
 * @param {HOC} Component 
 */

const BookingConsumer = BookingContext.Consumer
export function withBooking(Component){
    return function ConsumerWrapper(props){
        return <BookingConsumer>
        {value => <Component {...props} context={value} />}
        </BookingConsumer>
    }
}

export {BookingProvider, BookingContext, BookingConsumer}

