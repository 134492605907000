import React from 'react'

const AdminLink = ({className}) =>(
    
    <div className = {className}>
        <p><a href="mailto:contact@latoiledenbouyssou.fr">Contacter l'administrateur du site</a></p>

    </div>


);
export {AdminLink}