import React, { Component } from 'react'
import { withBooking } from '../contexts/BookingContext';
import AdminBookingCard from './AdminBookingCard';
import '../App.css';
import {AdminStyledFormCards} from '../common/index';
import {filterBookings} from "../data/clientUtils";

class Bookings extends Component {
constructor(props){
    super(props);
    this._isMounted = false;
    this.state = {
        bookings : [],
        message: ""
    }
}

async componentDidMount(){
    this._isMounted = true;
    if (!window.navigator.onLine){
        this.setState({message: "Oups, il semblerait que vous ayez perdu votre connexion internet"});
    }else{
    try {
        const resp = await this.props.context.getAllBookings();        
        if (resp !== undefined){
            if (resp === null){
                this.setState({message : "Aucune réponse de la part du serveur, merci d'essayer ultérieurement"})
            }else{
            
            if(resp.message){
                console.log("le message : " + resp.message)
                this.setState({message:resp.message});
            }else if (resp.bookings !== null){
                if (filterBookings(resp.bookings)&&filterBookings(resp.bookings).length <= 0){
                    this.setState({message: "Aucune réservation à venir"});
                }else{                
                this.setState({bookings: resp.bookings});
            }
            }else{
                this.setState({message: "Aucune réservation à venir"});
            }
        }
        }else{
            this.setState({message : "...Chargement des réservations en cours"});
        }
    } catch (error) {
        this.setState({message : "Erreur dans le chargement des réservations, merci de réactualiser la page"});
    }
    }
}



componentWillUnmount(){
    this._isMounted = false;
}



 



    render(){
        const {bookings, message} = this.state
    return(
    <> 
        
       
        
        <AdminStyledFormCards>
        {filterBookings(bookings).length > 0 ?
        filterBookings(bookings).map((filteredBooking)=>
      
            <AdminBookingCard 
            key={filteredBooking.id}
            user = {filteredBooking.user}
            index = {filteredBooking.id}   
            category = {this.props.context.setCategoryString(filteredBooking.category)}
            categoryid = {filteredBooking.category}
            quantity = {filteredBooking.quantity}
            chosen_time_stamp = {filteredBooking.chosen_time_stamp}
            created_at = {filteredBooking.created_at}
            bookingstate = {filteredBooking.booking_state}
            riders = {filteredBooking.riders}
            isfullweek = {filteredBooking.is_full_week && filteredBooking.is_full_week ? Boolean(filteredBooking.is_full_week):false}
            /> 
              
            )// fin map
            
            
        :
        
        <h1 className="panel-title" color="maroon">{message && message}</h1>
        }
        </AdminStyledFormCards>
   
            </>
            ) 
            
}  
}
export default withBooking(Bookings)