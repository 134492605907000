import React from 'react'

export default function InputRange(props) {

    const labelAge = (
        <div>
            <label> {props.name && props.name.includes("age") && props.ridersNumber > 1 ? `Âge du participant n° ${props.value+1}` : ""}   </label>
            <label> {props.name && props.name.includes("level") && props.ridersNumber > 1 ? `Niveau du participant n° ${props.value+1}` : ""}   </label>
            </div>
    )
    const ageState = props.index + "age";
    
    const levelState = props.index + "level";
    return (
        <div key={props.index}>
            <label htmlFor="age">{labelAge}</label>
           
            <div className="input-div" >
                <input key={props.index} className="input-range-riders" id={props.index} name={props.name.includes("age")? ageState : props.name.includes("level") && levelState} type="range" min={props.min} max={props.max} value={props.age ? props.age : props.level ? props.level : 0} onChange={(e)=>props.onChange(e)}/>
               {props.name && props.name.includes("age") && (props.age === 10 ? "10 ans et plus" : (props.age <= 1 ? props.age + " an" : props.age + " ans"))}
               {props.name && props.name.includes("level") && props.level && props.level}
            </div>

        </div>
    )
}
